import { Outlet } from 'react-router-dom';
import LenderFooter from './LenderBrokerFooter';
import { useMediaQuery } from 'usehooks-ts';
const LenderBrokerLayout = () => {
  const isMobile = useMediaQuery('(max-width: 720px)');
  return (
    <div
      className="w-full flex flex-col bg-grey-50 overflow-y-auto"
      style={{ minHeight: 'calc(100vh - 120px)' }}>
      <div className="flex-auto flex flex-col bg-grey-50 relative no-scrollbar overflow-y-hidden justify-center">
        <Outlet />
      </div>
      <div className="fixed bottom-0 left-0 w-full">
        <LenderFooter />
      </div>
    </div>
  );
};

export default LenderBrokerLayout;
