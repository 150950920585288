import { useEffect, useRef, useState } from 'react';
import StatusListItem from '@components/widgets/StatusListItem';
import {
  DETAIL_VIEW_ITEMS,
  DOCUMENT_LIST,
  INSPECTION_SECURITY_CHECK_VARIANT,
  SECURITY_CHECK_LIST,
  licenseChecks
} from '@constants';
import {
  BankSecurityCheckEnum,
  InspectionSecurityStatusEnum,
  InspectionStatusEnum,
  LenderDocumentTypeEnum,
  NevdisSecurityCheckEnum,
  RegoSecurityCheckEnum
} from '@types';
import _reduce from 'lodash/reduce';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useInspectionDetailStore } from '@stores';
import SemiCircleProgress from '@components/widgets/SemiCircleProgressBar';
import { useMediaQuery } from 'usehooks-ts';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import _intersection from 'lodash/intersection';
import _uniqBy from 'lodash/uniqBy';
import { getAssetSpecificContent } from '@utils';
import { AssetType } from '@types';
type Props = {
  sendActiveStep: (step: string) => void;
  data: any;
};
const DetailedInfo = ({ sendActiveStep, data }: Props) => {
  const isMobile = useMediaQuery('(max-width: 1140px)');
  const [countOfAll, setCountOfAll] = useState({ done: 0, total: 10 });
  const {
    setActivity,
    setCollapse,
    inspectionDetailData,
    activeStep,
    setActiveStep
  } = useInspectionDetailStore();
  useEffect(() => {
    setActivity(false);
    sendActiveStep(activeStep);
    setCollapse(true);
    const totalObj = {
      done: inspectionDetailData.stepsComplete,
      total: inspectionDetailData.stepsTotal
    };
    setCountOfAll(totalObj);
  }, [activeStep, data]);
  const location = useLocation();
  const countOfDoneElements = (index: number) => {
    if (location.pathname.includes('/demo/inspection')) {
      return _reduce(
        DOCUMENT_LIST[index],
        (result, value) => {
          if (inspectionDetailData.documents[value].fileId) {
            result.done += 1;
          }

          if (['ROADWORTHY_CERT', 'PAYOUT_LETTER'].includes(value)) {
            if (
              inspectionDetailData.documents[value]?.needsResubmit === false
            ) {
              result.done += 1;
            }
          }
          result.total = DOCUMENT_LIST[index].length;
          return result;
        },
        { done: 0, total: 0 }
      );
    } else {
      const docTypesDefined =
        index === 0
          ? [
              LenderDocumentTypeEnum.SELLER_ID,
              LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
              LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
              LenderDocumentTypeEnum.FACE
            ]
          : index === 2
          ? ['REGO_PAPERS']
          : DOCUMENT_LIST[index];
      return _reduce(
        _intersection(inspectionDetailData.documentTypes, docTypesDefined),
        (result, item) => {
          if (index === 0 && docTypesDefined.includes('SELLER_ID')) {
            return {
              done: [
                LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
                LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
                LenderDocumentTypeEnum.FACE
              ].filter(
                (docKey) =>
                  inspectionDetailData.documents[docKey]?.fileId &&
                  !inspectionDetailData.documents[docKey]?.needsResubmit
              ).length,
              total: 3
            };
          } else {
            let hasFile =
              !['VIN', 'REGO_PLATE', 'REGO_PAPERS', 'BANK_STATEMENTS'].includes(
                item
              ) && inspectionDetailData.documents[item]
                ? true
                : false;

            if (item === 'VIN' && inspectionDetailData?.extraFields['vin']) {
              hasFile = true;
            }
            if (
              item === 'REGO_PLATE' &&
              inspectionDetailData?.extraFields['licencePlate']
            ) {
              hasFile = true;
            }
            if (
              item === 'REGO_PAPERS' &&
              inspectionDetailData?.extraFields['regoCertificate']
            ) {
              hasFile = true;
            }
            if (
              item === 'BANK_STATEMENTS' &&
              inspectionDetailData?.extraFields['bankStatement']
            ) {
              hasFile = true;
            }
            if (['ROADWORTHY_CERT', 'PAYOUT_LETTER'].includes(item)) {
              if (
                inspectionDetailData.documents[item]?.needsResubmit === false
              ) {
                hasFile = true;
              }
            }
            if (
              inspectionDetailData.documents[item] &&
              inspectionDetailData.documents[item]?.needsResubmit
            ) {
              hasFile = false;
            }
            return {
              done: hasFile ? result.done + 1 : result.done,
              total: result.total + 1
            };
          }
        },
        { done: 0, total: 0 }
      );
    }
  };
  const registrationPaperStatus =
    inspectionDetailData.files.filter(
      (item: any) => item.documentType === 'REGO_PAPERS'
    ).length > 0
      ? true
      : false;
  const getDocumentObj = (items: any, item: any, key: any) => {
    if (!items) return null;

    if (item.value === '3' && key === 'expiry')
      return items['registrationExpiry'];
    if (item.value === '3' && key === 'registrationAuthenticity')
      return items['regoCertificate'];
    if (item.value === '4') return items['bankStatement'];
    return items[key];
  };

  // Determine VIN status
  const getVinStatus = (items: any, isNotMatch: boolean) => {
    if (isNotMatch) return InspectionSecurityStatusEnum.discrepancyFound;

    const vinMatch =
      items?.vin?.licencePlateMatch === InspectionSecurityStatusEnum.match;
    const plateMatch =
      items?.licencePlate?.vinMatch === InspectionSecurityStatusEnum.match;

    if (vinMatch && plateMatch) return InspectionSecurityStatusEnum.match;

    const hasNoRecord = [
      items?.vin?.status,
      items?.licencePlate?.status
    ].includes(InspectionSecurityStatusEnum.noRecord);

    return hasNoRecord
      ? InspectionSecurityStatusEnum.notFound
      : InspectionSecurityStatusEnum.notPerformed;
  };

  // Get security check status
  const getSecurityStatus = (
    documentObj: any,
    type: string,
    items: any,
    key: string,
    isNotMatch: boolean
  ) => {
    // If no document object exists, return not performed
    if (!documentObj) return InspectionSecurityStatusEnum.notPerformed;

    // Handle VIN check
    if (type === 'VEHICLE_VIN') {
      return getVinStatus(items, isNotMatch);
    }

    // Handle REGO_CERTIFICATE and BANK_STATEMENT data edits
    if (
      ['REGO_CERTIFICATE', 'BANK_STATEMENT'].includes(type) &&
      [
        RegoSecurityCheckEnum.REGO_SECURITY_DATA_EDIT,
        BankSecurityCheckEnum.BANK_SECURITY_DATA_EDIT
      ].includes(key as any)
    ) {
      return documentObj.dataEditsStatus;
    }

    // Handle NEVDIS_REGISTRATION special case
    if (
      type === 'NEVDIS_REGISTRATION' &&
      documentObj.status === InspectionSecurityStatusEnum.notPerformed &&
      [items?.vin?.status, items?.licencePlate?.status].includes(
        InspectionSecurityStatusEnum.noRecord
      )
    ) {
      return InspectionSecurityStatusEnum.notFound;
    }

    // Handle BANK_STATEMENT_ADDRESS
    if (type === 'BANK_STATEMENT_ADDRESS') {
      if (!documentObj.status) return InspectionSecurityStatusEnum.notPerformed;
      return documentObj.status;
    }

    // Handle PPSR_ENCUMBRANCE
    if (type === 'PPSR_ENCUMBRANCE') {
      if (documentObj.ppsrData?.length > 0) {
        return InspectionSecurityStatusEnum.reportFound;
      }
      return documentObj.status;
    }

    // Handle RAV_COMPLIANCE
    if (type === 'RAV_COMPLIANCE') {
      if (documentObj.status === InspectionSecurityStatusEnum.pass) {
        return InspectionSecurityStatusEnum.reportFound;
      }
      return documentObj.status;
    }

    // Handle NEVDIS_STOLEN and NEVDIS_WRITTEN_OFF
    if (['NEVDIS_STOLEN', 'NEVDIS_WRITTEN_OFF'].includes(type)) {
      const details =
        type === 'NEVDIS_STOLEN'
          ? documentObj.stolenDetails
          : documentObj.writtenOffDetails;
      if (details?.length > 0) {
        return InspectionSecurityStatusEnum.reportFound;
      }
      return documentObj.status;
    }

    // Default return document status
    return documentObj?.status;
  };

  // Get flag based on security check status
  const getSecurityFlag = (
    key: string,
    status: string,
    type: string,
    item: any,
    documentObj: any
  ) => {
    // NEVDIS RAV check
    if (
      key === NevdisSecurityCheckEnum.NEVDIS_SECURITY_RAV &&
      status === InspectionSecurityStatusEnum.noRecord
    ) {
      return 'success_light';
    }

    // Add specific handling for VIN notPerformed status
    if (
      type === 'VEHICLE_VIN' &&
      (status === InspectionSecurityStatusEnum.notPerformed ||
        status === InspectionSecurityStatusEnum.discrepancyFound)
    ) {
      return 'warning_light'; // This will make flagStatus return 2
    }

    // PPSR check
    if (['PPSR_ENCUMBRANCE'].includes(type)) {
      if (status === InspectionSecurityStatusEnum.reportFound)
        return 'warning_light';
      if (status === InspectionSecurityStatusEnum.noRecord)
        return 'success_light';
    }

    // RAV compliance check
    if (['RAV_COMPLIANCE'].includes(type)) {
      if (status === InspectionSecurityStatusEnum.reportFound)
        return 'success_light';
      if (status === InspectionSecurityStatusEnum.noReport) return 'info_light';
    }

    if (
      type === 'BANK_STATEMENT_ADDRESS' &&
      status === InspectionSecurityStatusEnum.failed
    ) {
      return 'warning_light';
    }

    // NEVDIS registration check
    if (type === 'NEVDIS_REGISTRATION') {
      if (item.value !== '3') {
        if (
          status === InspectionSecurityStatusEnum.reportFound &&
          documentObj.regoStatus ===
            InspectionSecurityStatusEnum.registered.toLocaleUpperCase()
        ) {
          return 'success_light';
        }
        if (status === InspectionSecurityStatusEnum.noReport)
          return 'info_light';
      }
      if (item.value === '3' && registrationPaperStatus) {
        return INSPECTION_SECURITY_CHECK_VARIANT[
          InspectionSecurityStatusEnum.discrepancyFound
        ];
      }
    }

    // License edits check
    if (
      type === 'LICENSE_EDITS' &&
      status === InspectionSecurityStatusEnum.failed
    ) {
      return 'warning_light';
    }

    return INSPECTION_SECURITY_CHECK_VARIANT[
      status as InspectionSecurityStatusEnum
    ];
  };

  const flagStatus = (item: any, index: number) => {
    // Check special cases first
    if (
      item.value === '5' &&
      (inspectionDetailData.documents.ROADWORTHY_CERT?.isNotProvided ||
        inspectionDetailData.documents.SAFETY_CERT?.isNotProvided)
    ) {
      return 2;
    }

    if (
      item.value === '6' &&
      inspectionDetailData.documents.PAYOUT_LETTER?.isNotProvided
    ) {
      return 2;
    }

    if (item.value === '1') {
      // Check if any license check has failed
      const hasFailedCheck = SECURITY_CHECK_LIST[0].some((checkItem) => {
        const checkStatus =
          inspectionDetailData?.inspectionChecks[
            checkItem as keyof typeof inspectionDetailData.inspectionChecks
          ]?.status;
        return checkStatus !== 'PASS';
      });

      if (hasFailedCheck) {
        return 2;
      }
    }

    // Process security checks
    const securityCheckResult = _reduce(
      SECURITY_CHECK_LIST[parseInt(item.value) - 1],
      (result: any, key: any) => {
        const items = inspectionDetailData.inspectionChecks;
        const documentObj = getDocumentObj(items, item, key);
        const type = documentObj?.type;

        const isNotMatch =
          item.value === '2' &&
          key === 'vin' &&
          [
            items?.vin?.licencePlateMatch,
            items?.licencePlate?.vinMatch
          ].includes('DIFFERENT');

        const status = getSecurityStatus(
          documentObj,
          type,
          items,
          key,
          isNotMatch
        );
        const flag = getSecurityFlag(key, status, type, item, documentObj);

        // Determine final result
        if ([result, flag].includes('warning_light')) return 'warning_light';
        if (result === 'success_light' && flag === 'success_light')
          return 'success_light';
        return 'info_light';
      },
      'success_light'
    );

    // Convert result to numeric value
    return securityCheckResult === 'info_light'
      ? 0
      : securityCheckResult === 'warning_light'
      ? 2
      : 1;
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToItem = (index: number) => {
    // Get the item element by index
    if (isMobile) {
      const itemElement = containerRef.current?.children[index] as HTMLElement;
      // Scroll the container to the item element
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  };
  return (
    <>
      <div className="xl:flex w-full">
        <div className="flex flex-col min-h-[160px] detail-box justify-start items-center xl:hidden">
          <SemiCircleProgress
            percentage={
              parseFloat((countOfAll.done / countOfAll.total).toFixed(2)) * 100
            }
            size={{
              width: 200,
              height: 200
            }}
            strokeWidth={10}
            bgStrokeColor={'#EAECF0'}
            hasBackground={true}
            strokeColor={'#F63D68'}
          />
          <div className="mt-[-120px] flex flex-col justify-center items-center">
            <p style={{ fontSize: 12, color: '#475467' }}>Progress</p>
            <p
              style={{
                fontSize: 16,
                color: '#101828'
              }}>{`${countOfAll.done} of ${countOfAll.total}`}</p>
          </div>
        </div>
        <div
          ref={containerRef}
          className="xl:flex-shrink-0 flex flex-row xl:flex-col xl:flex-col lg:min-w-[170px] xl:w-full overflow-auto mb-4 items-center">
          {DETAIL_VIEW_ITEMS.map((item, index) => {
            const countObj = countOfDoneElements(index);
            const showRedIcon = () => {
              switch (item.keyPath) {
                case 'driverLicense':
                  return inspectionDetailData.documents.SAFETY_CERT
                    ?.isNotProvided;
                default:
                  return false;
              }
            };
            const showFlg = () => {
              switch (item.keyPath) {
                case 'driverLicense':
                  return (
                    _intersection(inspectionDetailData.documentTypes, [
                      LenderDocumentTypeEnum.SELLER_ID,
                      LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
                      LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
                      LenderDocumentTypeEnum.FACE
                    ]).length > 0
                  );
                case 'vehicle':
                  return (
                    _intersection(inspectionDetailData.documentTypes, [
                      LenderDocumentTypeEnum.ASSET_BACK,
                      LenderDocumentTypeEnum.ASSET_FRONT,
                      LenderDocumentTypeEnum.ASSET_LEFT,
                      LenderDocumentTypeEnum.ASSET_RIGHT,
                      LenderDocumentTypeEnum.INTERNAL_FRONT,
                      LenderDocumentTypeEnum.INTERNAL_BACK,
                      LenderDocumentTypeEnum.INTERNAL_BOOT,
                      LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
                      LenderDocumentTypeEnum.INTERNAL_KITCHEN,
                      LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
                      LenderDocumentTypeEnum.VIN,
                      LenderDocumentTypeEnum.REGO_PLATE,
                      LenderDocumentTypeEnum.ODOMETER,
                      LenderDocumentTypeEnum.OPERATING_HOURS,
                      LenderDocumentTypeEnum.NEVDIS
                    ]).length > 0
                  );
                case 'regoPaper':
                  return inspectionDetailData.documentTypes.includes(
                    LenderDocumentTypeEnum.REGO_PAPERS
                  );
                case 'bank':
                  return inspectionDetailData.documentTypes.includes(
                    LenderDocumentTypeEnum.BANK_STATEMENTS
                  );
                case 'cert':
                  return (
                    inspectionDetailData.documentTypes.includes(
                      LenderDocumentTypeEnum.ROADWORTHY_CERT
                    ) &&
                    (inspectionDetailData?.inspectionChecks?.registration
                      ?.status === 'UNREGISTERED' ||
                      inspectionDetailData?.inspectionChecks?.registration
                        ?.status === 'REGISTRATION_EXPIRED')
                  );
                case 'payoutLetter':
                  return (
                    inspectionDetailData.documentTypes.includes(
                      LenderDocumentTypeEnum.PAYOUT_LETTER
                    ) &&
                    inspectionDetailData?.inspectionChecks?.ppsr?.status ===
                      'RECORD_FOUND'
                  );
                case 'ownershipProof':
                  return inspectionDetailData.documentTypes.includes(
                    LenderDocumentTypeEnum.OWNERSHIP_PROOF
                  );
                default:
                  return false;
              }
            };
            return (
              <div
                key={index}
                className={clsx('flow w-full', !showFlg() && 'hidden')}>
                <StatusListItem
                  value={item.value}
                  label={
                    item.value === '2'
                      ? getAssetSpecificContent(
                          inspectionDetailData.assetType as AssetType
                        ).title + ' Details'
                      : item.shortLabel
                  }
                  onClick={() => {
                    scrollToItem(index);
                    setActiveStep(item.value);
                  }}
                  progressLabel={countObj.done + '/' + countObj.total}
                  active={activeStep === item.value}
                  success={
                    countObj.done === countObj.total &&
                    flagStatus(item, index) < 2
                  }
                  hideIcon={
                    (data.status === InspectionStatusEnum.Initiated &&
                      item.value !== '5') ||
                    countObj.done < countObj.total
                  }
                  className="justify-between"
                />
              </div>
            );
          })}
          <div className="hidden xl:block px-6 py-8 gap-6">
            <CircularProgressbarWithChildren
              value={countOfAll.done}
              maxValue={countOfAll.total}
              strokeWidth={10}
              styles={buildStyles({
                pathColor: '#F63D68',
                textColor: '#101828',
                trailColor: '#EAECF0'
              })}>
              <div style={{ fontSize: 12, color: '#475467' }}>PROGRESS</div>
              <div style={{ fontSize: 20, color: '#101828' }}>
                <strong>{`${countOfAll.done} of ${countOfAll.total}`}</strong>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailedInfo;
