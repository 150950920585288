import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function RouteGuard({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.pathname.includes('inspection')) {
      localStorage.removeItem('sellerToken');
    }
  }, []);

  return <>{children}</>;
}
