import _map from 'lodash/map';
import { DETAILS_VIEW_DRIVER_LICENSE } from '@constants';
import _get from 'lodash/get';
type Props = {
  driver: object;
};
const DriverLicense = ({ driver }: Props) => {
  return (
    <>
      {_map(
        DETAILS_VIEW_DRIVER_LICENSE,
        (row: any, index: number) =>
          _get(driver, row.keyPath) && (
            <div
              key={index}
              className="flex justify-between md:justify-start flex-row-reverse flex-row md:flex-col items-start gap-2">
              <p className="text-base text-grey-700 break-words text-right md:text-left">
                {_get(driver, [row.keyPath, 'userEditValue']) || _get(driver, [row.keyPath, 'ocrValue'])}
              </p>
              <p className="text-grey-500 text-xs leading-5">
                {row.displayName}
              </p>
            </div>
          )
      )}
    </>
  );
};
export default DriverLicense;
