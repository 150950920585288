import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as NoVehicle } from '@assets/icons/no-vehicle.svg';
import _map from 'lodash/map';
import _get from 'lodash/get';
import {
  InspectionVehicleDetailsSection,
  InspectionSecurityStatusEnum,
  DocumentFormType
} from '@types';
import { DETAILS_VIEW_VEHICLE_DETAILS } from '@constants';
import SecurityCheck from './SecurityCheck';
import { Switch } from '@components/forms';
const VehicleInfo = ({
  checks,
  verification,
  setType,
  setStatus,
  setIsCollapsed,
  setVariant,
  setStatusChipLabel
}: {
  checks: any;
  verification: any;
  setType: (type: DocumentFormType) => void;
  setStatus: (status: InspectionSecurityStatusEnum) => void;
  setIsCollapsed: (isCollapsed: boolean) => void;
  setVariant: (variant: string) => void;
  setStatusChipLabel: (statusChipLabel: string) => void;
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [rowHeights, setRowHeights] = useState<number[]>([]);
  const rowRefs = useRef<(HTMLDivElement | null)[][]>([]);
  const { candidateNvic } = verification;
  const [checked, setChecked] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const [differences, setDifferences] = useState<any[]>([]);
  useEffect(() => {
    checks?.glassesVehicleData?.differences?.forEach((vehicle: any) => {
      if (vehicle) {
        setDifferences((prev: any) => [...prev, vehicle.field]);
      }
    });
    if (checks?.glassesVehicleData?.status === 'RECORD_FOUND') {
      const fields = [
        'nvic',
        'year',
        'make',
        'model',
        'variant',
        'series',
        'bodystyle',
        'transmission',
        'vehicleMass',
        'drivetrain',
        'engineSize',
        'fuelType',
        'priceNew'
      ];
      const newRowHeights = fields.map((_, rowIndex) => {
        const rowElements = rowRefs.current.map((col) => col[rowIndex]);
        const maxHeight = Math.max(
          ...rowElements.map((el) => el?.offsetHeight || 0)
        );
        return maxHeight;
      });
      setRowHeights(newRowHeights);
    }
  }, [checks?.glassesVehicleData]);

  const getMatchLabel = (match: string) => {
    switch (match?.toLowerCase()) {
      case 'best':
        return {
          text: 'Strong Match',
          bgColor: 'bg-[#f0f9ff]',
          borderColor: 'border-[#b9e6fe]',
          textColor: 'text-[#026aa2]'
        };
      case 'good':
        return {
          text: 'Good Match',
          bgColor: 'bg-[#ecfdf3]',
          borderColor: 'border-[#abefc6]',
          textColor: 'text-[#067647]'
        };
      case 'ok':
        return {
          text: 'Partial Match',
          bgColor: 'bg-[#f9fafb]',
          borderColor: 'border-[#eaecf0]',
          textColor: 'text-[#344054]'
        };
      case 'poor':
        return {
          text: 'Poor Match',
          bgColor: 'bg-[#fef3f2]',
          borderColor: 'border-[#fecdca]',
          textColor: 'text-[#b42318]'
        };
      case 'bad':
        return {
          text: 'Mismatch',
          bgColor: 'bg-[#fef3f2]',
          borderColor: 'border-[#fecdca]',
          textColor: 'text-[#b42318]'
        };
      case 'unknown':
      default:
        return {
          text: 'Match Unknown',
          bgColor: 'bg-[#f9fafb]',
          borderColor: 'border-[#eaecf0]',
          textColor: 'text-[#344054]'
        };
    }
  };
  const vehicleFieldMap = {
    nvic: '',
    year: 'Year',
    make: 'Make',
    model: 'Model',
    variant: 'Variant',
    series: 'Series',
    bodystyle: 'Body Style',
    transmission: 'Transmission',
    vehicleMass: 'Vehicle Mass',
    drivetrain: 'Drivetrain',
    engineSize: 'Engine Size',
    fuelType: 'Fuel Type',
    gears: 'Gears',
    priceNew: 'GLS RRP'
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-6 mt-6 gap-x-6 lg:gap-x-8 xl:gap-y-1 md:gap-y-1 w-full">
        {checks &&
          checks?.glassesVehicleData &&
          checks?.glassesVehicleData?.status !== 'RECORD_FOUND' && (
            <div className="rounded-lg border border-grey-200 bg-grey-50 flex flex-col gap-6 xl:h-full  h-full md:min-h-[320px] col-span-6 lg:col-span-5 md:col-span-5 xl:col-span-4">
              <div className="border-b p-4 text-lg">
                <h1 className="text-black-title font-semibold text-base">
                  Vehicle Details
                </h1>
              </div>
              <div className="flex w-full">
                {checks?.nevdisVehicleDetails &&
                  checks?.nevdisVehicleDetails.status ===
                    InspectionSecurityStatusEnum.reportFound && (
                    <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-1 xl:grid-cols-4 auto-cols-max px-4 gap-3 xl:gap-y-6 md:gap-y-6 w-full pb-6">
                      {_map(
                        DETAILS_VIEW_VEHICLE_DETAILS,
                        (row: any, index: number) =>
                          _get(checks?.nevdisVehicleDetails, row.keyPath) && (
                            <div
                              key={index}
                              className="flex justify-between md:justify-start flex-row-reverse flex-row md:flex-col items-start gap-2">
                              <p className="text-base text-grey-700 break-words text-right md:text-left">
                                {_get(
                                  checks?.nevdisVehicleDetails,
                                  row.keyPath
                                )}
                              </p>
                              <p className="text-grey-500 text-xs leading-5">
                                {row.displayName}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  )}
                {(!checks?.nevdisVehicleDetails ||
                  checks?.nevdisVehicleDetails.status !==
                    InspectionSecurityStatusEnum.reportFound) && (
                  <div className="flex justify-center items-center h-full w-full flex-col gap-y-6 min-h-[240px]">
                    <NoVehicle />
                    <p className="text-grey-900 font-semibold text-center">
                      Cannot retrieve vehicle details
                    </p>
                    <p className="text-grey-900 text-center max-w-[527px]">
                      The vehicle identifiers provided cannot be found on
                      NEVDIS.
                      <br />
                      Please ensure the VIN and Number Plate details provided
                      are correct.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        {checks?.glassesVehicleData?.status === 'RECORD_FOUND' && (
          <div className="rounded-lg border pt-4 text-sm border-grey-200 bg-grey-50 col-span-6 lg:col-span-5 md:col-span-5 xl:col-span-4 overflow-hidden">
            <div className="flex">
              {/* Fixed first column */}
              <div className="flex-none w-48 z-10 bg-grey-50 text-[#667085]">
                {Object.entries(vehicleFieldMap).map(
                  ([field, label], index) => {
                    // Skip rendering if checked and not a difference (except for index 0)
                    if (
                      checked &&
                      index !== 0 &&
                      !differences.includes(field)
                    ) {
                      return null;
                    }

                    return (
                      <div
                        key={label}
                        className={`p-3 pl-6 flex items-center border-r border-gray-200 ${
                          activeIndex === index && index !== 0 ? 'bg-white' : ''
                        }`}
                        ref={(el) => {
                          if (!rowRefs.current[0]) rowRefs.current[0] = [];
                          rowRefs.current[0][index] = el;
                        }}
                        onMouseEnter={() => setActiveIndex(index)}
                        onMouseLeave={() => setActiveIndex(null)}
                        style={{
                          height: rowHeights[index]
                            ? `${rowHeights[index]}px`
                            : 'auto',
                          minHeight: label === '' ? '72px' : undefined
                        }}>
                        {label === '' && differences.length > 0 ? (
                          <div className="flex justify-between">
                            <div className="flex items-center gap-2">
                              Only Show Differences
                              <Switch
                                checked={checked}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        ) : (
                          label
                        )}
                      </div>
                    );
                  }
                )}
              </div>

              {/* Scrollable content */}
              <div className="flex-grow overflow-x-auto pb-6 text-[#344054]">
                <div className="flex">
                  {(checks?.glassesVehicleData?.nvicMatches || []).map(
                    (vehicle: any, vindex: number) => (
                      <div
                        key={vehicle?.nvic || vindex}
                        className="flex-grow w-48 min-w-[200px]">
                        {Object.entries(vehicleFieldMap).map(
                          ([field, label], index) => {
                            // Skip rendering if checked and not a difference (except for index 0)
                            if (
                              checked &&
                              index !== 0 &&
                              !differences.includes(field)
                            ) {
                              return null;
                            }

                            const vehicleValue =
                              field === 'priceNew'
                                ? typeof vehicle?.vehicle?.[field] === 'number'
                                  ? `$${vehicle.vehicle[
                                      field
                                    ].toLocaleString()}`
                                  : '$0'
                                : vehicle?.vehicle?.[field] || '\u00A0';

                            return (
                              <div
                                key={field}
                                className={`p-3 flex items-center
                                ${
                                  candidateNvic === vehicle?.nvic
                                    ? 'bg-[#f9f5ff]'
                                    : ''
                                }
                                ${
                                  activeIndex === index && index !== 0
                                    ? '!bg-white'
                                    : ''
                                }`}
                                ref={(el) => {
                                  if (!rowRefs.current[vindex + 1]) {
                                    rowRefs.current[vindex + 1] = [];
                                  }
                                  rowRefs.current[vindex + 1][index] = el;
                                }}
                                onMouseEnter={() => setActiveIndex(index)}
                                onMouseLeave={() => setActiveIndex(null)}
                                style={{
                                  height: rowHeights[index]
                                    ? `${rowHeights[index]}px`
                                    : 'auto'
                                }}>
                                <div className="break-words w-full">
                                  {field === 'nvic' ? (
                                    <div className="flex flex-col">
                                      <p className="text-[16px] font-bold">
                                        {vehicle?.nvic}
                                        {vehicle?.match &&
                                          vehicle.match !== 'UNKNOWN' && (
                                            <span
                                              className={`h-[22px] rounded-[16px] font-medium text-[12px] px-2 ml-2 border
                                          ${
                                            getMatchLabel(vehicle?.match)
                                              ?.bgColor || ''
                                          }
                                          ${
                                            getMatchLabel(vehicle?.match)
                                              ?.borderColor || ''
                                          }
                                          ${
                                            getMatchLabel(vehicle?.match)
                                              ?.textColor || ''
                                          }`}>
                                              {getMatchLabel(vehicle?.match)
                                                ?.text || ''}
                                            </span>
                                          )}
                                      </p>
                                      <p
                                        className={`text-[#667085] mt-2 text-[14px] ${
                                          candidateNvic === vehicle?.nvic
                                            ? 'font-bold'
                                            : ''
                                        }`}>
                                        {candidateNvic === vehicle?.nvic
                                          ? 'COMPARISON GLS NVIC'
                                          : 'GLS NVIC'}
                                      </p>
                                    </div>
                                  ) : (
                                    vehicleValue
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {checks && checks?.nevdisVehicleDetails && (
          <div className="flex flex-col gap-3 col-span-6 xl:col-span-2 xl:order-2 lg:order-3 mt-8 md:mt-0 lg:mt-8 xl:mt-0">
            <SecurityCheck
              docType={InspectionVehicleDetailsSection}
              data={checks}
              setType={setType}
              setStatus={setStatus}
              setIsCollapsed={setIsCollapsed}
              setVariant={setVariant}
              setStatusChipLabel={setStatusChipLabel}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default VehicleInfo;
