/* eslint-disable react/no-unescaped-entities */
import Button from '@components/forms/Button';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import CustomBank from '@assets/images/CustomBank';
import CustomCert from '@assets/images/CustomCert';
import CustomPayoutLetter from '@assets/images/CustomPayout';
import CustomCard from '@assets/images/CustomCard';
import CustomRegistration from '@assets/images/CustomRegistration';
import CustomVehicle from '@assets/images/CustomVehicle';
import { ReactComponent as QRSample } from '@assets/icons/qr-sample.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/Success.svg';
import { ReactComponent as InfoErrorIcon } from '@assets/icons/info_error_icon.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/empty_error_icon.svg';
import _intersection from 'lodash/intersection';
import SemiCircleProgress from '@components/widgets/SemiCircleProgressBar';
import {
  InspectionStatusEnum,
  LenderDocumentTypeEnum,
  SellerPage
} from '@types';
import { useOrgStore, useSellerStore } from '@stores';
import {
  GET_USER_VERIFICATION,
  post,
  START_SESSION_HANDOVER,
  COMPLETE_USER_VERIFICATION
} from '@services';
import { useEffect, useState, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import Loader from '@components/widgets/Loader';
import { FULL_DOC_TYPES } from '@constants';
import { isDesktop } from 'react-device-detect';
import { getAssetSpecificContent } from '@utils';
import { AssetType } from '@types';
export const Welcome = ({
  setPage
}: {
  setPage: (page: SellerPage) => void;
}) => {
  const {
    driverLicense,
    cert,
    payoutLetter,
    ownershipProof,
    bank,
    registration,
    state,
    assetType,
    vinObj,
    odometer,
    inspectionChecks,
    numberPlate,
    setDocumentType,
    setCheckType,
    vehicleFront,
    vehicleBack,
    vehicleDriver,
    vehiclePassenger,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    isHome,
    documentTypes,
    vehiclePhotoList,
    friendlyId,
    stepsComplete,
    idStepsRemaining,
    stepsTotal,
    status,
    setDriverLicenseAvailableStatus,
    setDriverLicenseStatus,
    setIsHome,
    sellerSession,
    documents,
    setSellerSession
  } = useSellerStore();
  const [truuthUrl, setTruuthUrl] = useState('');
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const [showIframe, setShowIframe] = useState(false);

  const { organisation } = useOrgStore();
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, [organisation.primaryColour]);
  const [isLoading, setLoading] = useState(false);
  const params = useParams();
  const contains = (target: string[], pattern: string[]) => {
    let value = 0;
    pattern.forEach((word) => {
      value = value + Number(target.includes(word));
    });
    return value > 0;
  };
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [showCert, setShowCert] = useState(false);
  const [showCertResubmit, setShowCertResubmit] = useState(false);
  const [showPayoutLetter, setShowPayoutLetter] = useState(false);
  const [showPayoutLetterResubmit, setShowPayoutLetterResubmit] =
    useState(false);

  const [showResupply, setShowResupply] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(stepsComplete);
  const [searchParams] = useSearchParams();
  const isFromTruuth = searchParams.get('isFromTruuth');
  const driverLicenseStatus = searchParams.get('status');
  function getUserVerification() {
    const inspectionId = params.id;
    post(GET_USER_VERIFICATION, { inspectionId }).then((data) => {
      setLoading(false);
      if (
        data.status &&
        (data.status === InspectionStatusEnum.Completed ||
          (isFromTruuth && driverLicenseStatus !== 'inprogress'))
      ) {
        setDriverLicenseAvailableStatus(true);
        setDriverLicenseStatus(true);
      } else {
        setDriverLicenseStatus(false);
      }
      if (data.truuthUrl) {
        setTruuthUrl(data.truuthUrl);
      }
    });
    if (isFromTruuth && searchParams.get('vid')) {
      post(COMPLETE_USER_VERIFICATION, {
        inspectionId: params.id,
        truuthId: searchParams.get('vid'),
        truuthStatus: driverLicenseStatus
      }).then((data) => {
        console.log(data);
      });
    }
  }
  useEffect(() => {
    if (
      (inspectionChecks?.registration?.status === 'UNREGISTERED' ||
        inspectionChecks?.registration?.status === 'REGISTRATION_EXPIRED') &&
      documentTypes.includes(LenderDocumentTypeEnum.ROADWORTHY_CERT)
    ) {
      setShowCert(true);
    }
    if (
      inspectionChecks?.ppsr?.status === 'RECORD_FOUND' &&
      documentTypes.includes(LenderDocumentTypeEnum.PAYOUT_LETTER)
    ) {
      setShowPayoutLetter(true);
    }
    setDocumentType('');
    setCheckType('');
  }, [inspectionChecks]);
  useEffect(() => {
    if (documents) {
      const hasResubmitRequired = Object.values(documents).some(
        (doc: any) => doc?.needsResubmit === true
      );
      setShowResupply(hasResubmitRequired);
      if (documents?.ROADWORTHY_CERT?.needsResubmit) {
        setShowCertResubmit(true);
      }
      if (documents?.PAYOUT_LETTER?.needsResubmit) {
        setShowPayoutLetterResubmit(true);
      }
    }
  }, [documents]);
  useEffect(() => {
    setIsHome(true);
    setPage(SellerPage.welcome);
    if (params.id && driverLicense.available) {
      setLoading(true);
      getUserVerification();
    }

    if (isFromTruuth && driverLicenseStatus === 'success') {
      setCompletedSteps(stepsComplete + (idStepsRemaining || 0));
    } else {
      setCompletedSteps(stepsComplete);
    }
  }, [isHome, isFromTruuth]);

  function startSessionHandover() {
    if (params.id && isDesktop) {
      const inspectionId = params.id;
      post(START_SESSION_HANDOVER, { inspectionId }, false)
        .then((data) => {
          setQrCode(data.qrCode);
          setSellerSession(data);
        })
        .catch((error) => {
          console.error('Error:', error.message);
        });
    }
  }

  useEffect(() => {
    if (
      sellerSession.qrCode &&
      sellerSession.expiry &&
      sellerSession.sessionToken &&
      sellerSession.url
    ) {
      if (new Date(sellerSession.expiry) > new Date()) {
        setQrCode(sellerSession.qrCode);
      } else {
        startSessionHandover();
      }
    } else {
      startSessionHandover();
    }
  }, [params.id]);
  let elements = [
    driverLicense,
    cert,
    bank,
    registration,
    vinObj,
    odometer,
    numberPlate,
    vehicleFront,
    vehicleBack,
    vehicleDriver,
    vehiclePassenger,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    ownershipProof,
    payoutLetter
  ].filter((item) => item.available);

  let totalElements = _intersection(FULL_DOC_TYPES, documentTypes);
  if (
    inspectionChecks?.registration?.status !== 'UNREGISTERED' &&
    inspectionChecks?.registration?.status !== 'REGISTRATION_EXPIRED' &&
    documentTypes.includes(LenderDocumentTypeEnum.ROADWORTHY_CERT)
  ) {
    totalElements = totalElements.filter(
      (item) => item !== LenderDocumentTypeEnum.ROADWORTHY_CERT
    );
    elements = elements.filter(
      (item) => item.type !== LenderDocumentTypeEnum.ROADWORTHY_CERT
    );
  }
  const countOfDoneElements = elements.reduce((count, item) => {
    if (!item.isDone) return count;
    switch (item.type) {
      case 'SELLER_ID':
        return count + 3;
      case 'VEHICLE':
        return count + vehiclePhotoList.length;
      default:
        return count + 1;
    }
  }, 0);

  type SemiCircleComponentProps = {
    containerClassName?: string;
    textClassName?: string;
    percentageClassName?: string;
  };
  const SemiCircleComponent = ({
    containerClassName,
    textClassName,
    percentageClassName
  }: SemiCircleComponentProps) => {
    return (
      <>
        <div className={containerClassName}>
          <SemiCircleProgress
            percentage={
              parseFloat((completedSteps / stepsTotal).toFixed(2)) * 100
            }
            size={{
              width: 200,
              height: 200
            }}
            strokeWidth={10}
            bgStrokeColor={'#EAECF0'}
            hasBackground={true}
            strokeColor={primaryColor}
          />
        </div>
        <div className={textClassName}>
          <p style={{ fontSize: 12, color: '#475467' }}>Complete</p>
          <p className={percentageClassName}>
            {completedSteps} / {stepsTotal}
          </p>
        </div>
      </>
    );
  };
  // Utility function to check conditions
  const checkAllAvailableItemsDone = () => {
    const items = [
      vinObj,
      numberPlate,
      odometer,
      vehicleFront,
      vehicleBack,
      vehicleDriver,
      vehiclePassenger,
      vehicleFrontSeats,
      vehicleBackSeats,
      vehicleBoot
    ];
    return items.every(
      (item) => !item.available || (item.available && item.isDone)
    );
  };
  if (isLoading)
    return <Loader bgColor="bg-gray-50" bgOpacity="bg-opacity-100" />;
  return (
    <>
      <>
        <div className="flex">
          <div className="grow">
            <h2 className="text-[24px] lg:text-[30px] font-semibold text-grey-900 mt-0 lg:mt-6">
              Welcome!
            </h2>
            <p className="mt-4 text-gray-600 text-sm">
              Your inspection reference number is{' '}
              <b className="whitespace-nowrap">{friendlyId}</b>.
            </p>
            <p className="mt-4 text-gray-600 text-sm">
              Please review and complete the following tasks:
            </p>
          </div>
          <div className="hidden lg:flex md:flex self-end relative !bg-white h-[136px] p-4 flex flex-col rounded-lg items-center justify-end w-[220px] shadow-paper gap-4">
            <SemiCircleComponent
              containerClassName="absolute -top-6 p-4"
              textClassName="mt-[-120px] flex flex-col justify-center items-center"
              percentageClassName="text-[24px] font-semibold"
            />
          </div>
        </div>
        <div className="hidden lg:block md:block mt-6">
          {showResupply && (
            <div
              className="bg_error_color mb-6 rounded error_text_color px-4 py-3 shadow-md border_error_color border"
              role="alert">
              <div className="flex">
                <div className="mt-[-5px]">
                  <ErrorIcon className="mr-1" />
                </div>
                <div>
                  <p className="font-semibold">Resubmission Request</p>
                  <p className="font-normal">
                    We were unable to verify some details you previously
                    submitted. Please resubmit the information for the steps
                    highlighted below, ensuring all documentation is valid and
                    photos are clear.
                  </p>
                </div>
              </div>
            </div>
          )}
          {showCert && !cert.isDone && !showCertResubmit && (
            <div
              className="bg_error_color mb-6 rounded error_text_color px-4 py-3 shadow-md border_error_color border"
              role="alert">
              <div className="flex">
                <div className="mt-[-5px]">
                  <ErrorIcon className="mr-1" />
                </div>
                <div>
                  <p className="font-semibold">Additional Submission Request</p>
                  <p className="font-normal">
                    The vehicle has been detected as unregistered. To proceed,
                    we need more information about its condition. Please review
                    the additional submission request below.
                  </p>
                </div>
              </div>
            </div>
          )}

          {showPayoutLetter &&
            !payoutLetter.isDone &&
            !showPayoutLetterResubmit && (
              <div
                className="bg_error_color mb-6 rounded error_text_color px-4 py-3 shadow-md border_error_color border"
                role="alert">
                <div className="flex">
                  <div className="mt-[-5px]">
                    <ErrorIcon className="mr-1" />
                  </div>
                  <div>
                    <p className="font-semibold">
                      Additional Submission Request
                    </p>
                    <p className="font-normal">
                      The vehicle has been detected as having finance owing. To
                      proceed, we need more information. Please review the
                      additional submission request below.
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div className="mt-6 flex flex-col md:flex-row-reverse w-full">
          <div className="relative lg:hidden md:hidden md:mb-0 mb-4  w-full !bg-white  md:h-[100px] h-[136px] p-4 flex flex-col rounded-lg items-center justify-end md:w-[220px] shadow-paper">
            <SemiCircleComponent
              containerClassName="absolute -top-6 p-4 z-10"
              textClassName="mt-[-120px] flex flex-col justify-center items-center"
              percentageClassName="text-[24px] font-semibold"
            />
          </div>

          <div className="lg:hidden md:hidden">
            {showResupply && (
              <div
                className="bg_error_color mb-6 rounded error_text_color px-4 py-3 shadow-md border_error_color border"
                role="alert">
                <div className="flex">
                  <div className="mt-[-5px]">
                    <ErrorIcon className="mr-1" />
                  </div>
                  <div>
                    <p className="font-semibold">Resubmission Request</p>
                    <p className="font-normal">
                      We were unable to verify some details you previously
                      submitted. Please resubmit the information for the steps
                      highlighted below, ensuring all documentation is valid and
                      photos are clear.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {showCert && !cert.isDone && !showCertResubmit && (
              <div
                className="bg_error_color mb-6 rounded error_text_color px-4 py-3 shadow-md border_error_color border"
                role="alert">
                <div className="flex">
                  <div className="mt-[-5px]">
                    <ErrorIcon className="mr-1" />
                  </div>
                  <div>
                    <p className="font-semibold">
                      Additional Submission Request
                    </p>
                    <p className="font-normal">
                      The vehicle has been detected as unregistered. To proceed,
                      we need more information about its condition. Please
                      review the additional submission request below.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {showPayoutLetter &&
              !payoutLetter.isDone &&
              !showPayoutLetterResubmit && (
                <div
                  className="bg_error_color mb-6 rounded error_text_color px-4 py-3 shadow-md border_error_color border"
                  role="alert">
                  <div className="flex">
                    <div className="mt-[-5px]">
                      <ErrorIcon className="mr-1" />
                    </div>
                    <div>
                      <p className="font-semibold">
                        Additional Submission Request
                      </p>
                      <p className="font-normal">
                        The vehicle has been detected as having finance owing.
                        To proceed, we need more information. Please review the
                        additional submission request below.
                      </p>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className="hidden lg:block md:block">
            <QRBlock qrCode={qrCode} />
          </div>
          <div className="flex flex-col w-full md:mr-8 z-50">
            {completedSteps === stepsTotal && (
              <div
                className="relative mb-4 bg-green-50 rounded-lg success_text_color px-4 py-3 border border-green-500"
                role="alert">
                <div className="text-center">
                  <p className="text-lg font-semibold">
                    You've completed the inspection!
                  </p>
                  <p className="text-sm">You can now close this window.</p>
                </div>
              </div>
            )}
            <div className="!bg-white grow rounded-xl shadow-paper">
              <Button
                onClick={() => {
                  scrollTo(0, 0);
                  setIsHome(false);
                  if (isDesktop) {
                    setPage(SellerPage.license);
                  } else {
                    window.open(truuthUrl, '_self');
                  }
                }}
                className={clsx(
                  'border-b border-grey-200 h-[92px] bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between items-center',
                  !contains(documentTypes, [
                    LenderDocumentTypeEnum.SELLER_ID
                  ]) && 'hidden'
                )}>
                <div className="flex">
                  <CustomCard color={primaryColor} />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <p className="text-left normal-case mr-1 text-lg font-semibold mb-1">
                        Licence or Photo Card
                      </p>
                      {driverLicense.isDone && driverLicense.isDone ? (
                        <SuccessIcon className="mb-1 w-5 h-5" />
                      ) : (
                        !driverLicense.isDone &&
                        (documents?.FACE?.needsResubmit ||
                          documents?.DRIVER_LICENCE_BACK?.needsResubmit ||
                          documents?.DRIVER_LICENCE_FRONT?.needsResubmit) && (
                          <InfoErrorIcon className="mb-1 w-5 h-5" />
                        )
                      )}
                    </div>
                    <p className="font-normal text-sm normal-case text-left">
                      Confirm and verify your identity
                    </p>
                  </div>
                </div>
                <LeftIcon />
              </Button>
              <Button
                onClick={() => {
                  scrollTo(0, 0);
                  setIsHome(false);
                  setPage(SellerPage.vehicle);
                }}
                className={clsx(
                  'border-b border-grey-200 h-[92px] bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between items-center',
                  !contains(documentTypes, [
                    LenderDocumentTypeEnum.VIN,
                    LenderDocumentTypeEnum.REGO_PLATE,
                    LenderDocumentTypeEnum.ODOMETER,
                    LenderDocumentTypeEnum.OPERATING_HOURS,
                    LenderDocumentTypeEnum.ASSET_BACK,
                    LenderDocumentTypeEnum.ASSET_FRONT,
                    LenderDocumentTypeEnum.ASSET_LEFT,
                    LenderDocumentTypeEnum.ASSET_RIGHT,
                    LenderDocumentTypeEnum.INTERNAL_BACK,
                    LenderDocumentTypeEnum.INTERNAL_BOOT,
                    LenderDocumentTypeEnum.INTERNAL_FRONT,
                    LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
                    LenderDocumentTypeEnum.INTERNAL_KITCHEN,
                    LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA
                  ]) && 'hidden'
                )}>
                <div className="flex">
                  <CustomVehicle color={primaryColor} />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <p className="text-left mr-1 text-lg font-semibold mb-1">
                        {getAssetSpecificContent(assetType as AssetType).title}
                      </p>
                      {checkAllAvailableItemsDone() ? (
                        <SuccessIcon className="mb-1 w-5 h-5" />
                      ) : documents?.ASSET_BACK?.needsResubmit ||
                        documents?.ASSET_FRONT?.needsResubmit ||
                        documents?.ASSET_LEFT?.needsResubmit ||
                        documents?.ASSET_RIGHT?.needsResubmit ||
                        documents?.INTERNAL_BACK?.needsResubmit ||
                        documents?.INTERNAL_BOOT?.needsResubmit ||
                        documents?.INTERNAL_FRONT?.needsResubmit ||
                        documents?.VIN?.needsResubmit ||
                        documents?.REGO_PLATE?.needsResubmit ||
                        documents?.OPERATING_HOURS?.needsResubmit ||
                        documents?.ODOMETER?.needsResubmit ? (
                        <InfoErrorIcon className="mb-1 w-5 h-5" />
                      ) : null}
                    </div>
                    <p className="font-normal text-sm text-left normal-case">
                      Verify{' '}
                      {
                        getAssetSpecificContent(assetType as AssetType)
                          .lowerTitle
                      }{' '}
                      and condition
                    </p>
                  </div>
                </div>
                <LeftIcon />
              </Button>
              <Button
                onClick={() => {
                  scrollTo(0, 0);
                  setIsHome(false);
                  setPage(SellerPage.registration);
                  setDocumentType(LenderDocumentTypeEnum.REGO_PAPERS);
                  setCheckType(inspectionChecks?.registration?.type);
                }}
                className={clsx(
                  'border-b border-grey-200 h-[92px] bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between items-center',
                  !contains(documentTypes, [
                    LenderDocumentTypeEnum.REGO_PAPERS
                  ]) && 'hidden'
                )}>
                <div className="flex">
                  <CustomRegistration color={primaryColor} />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <p className="text-left mr-1 text-lg font-semibold mb-1">
                        Registration
                      </p>
                      {registration.isDone ? (
                        <SuccessIcon className="mb-1 w-5 h-5" />
                      ) : (
                        !registration.isDone &&
                        documents?.REGO_PAPERS?.needsResubmit && (
                          <InfoErrorIcon className="mb-1 w-5 h-5" />
                        )
                      )}
                    </div>
                    <p className="font-normal text-sm normal-case text-left">
                      Verify vehicle's legal status
                    </p>
                  </div>
                </div>
                <LeftIcon />
              </Button>
              <Button
                onClick={() => {
                  scrollTo(0, 0);
                  setIsHome(false);
                  setPage(SellerPage.bank);
                  setDocumentType(LenderDocumentTypeEnum.BANK_STATEMENTS);
                  setCheckType(inspectionChecks?.bankStatement?.type);
                }}
                className={clsx(
                  'border-b border-grey-200 h-[92px] bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between items-center',
                  !contains(documentTypes, [
                    LenderDocumentTypeEnum.BANK_STATEMENTS
                  ]) && 'hidden'
                )}>
                <div className="flex">
                  <CustomBank color={primaryColor} />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <p className="text-left mr-1 text-lg font-semibold mb-1">
                        Bank Details
                      </p>
                      {bank.isDone ? (
                        <SuccessIcon className="mb-1 w-5 h-5" />
                      ) : (
                        !bank.isDone &&
                        documents?.BANK_STATEMENTS?.needsResubmit && (
                          <InfoErrorIcon className="mb-1 w-5 h-5" />
                        )
                      )}
                    </div>
                    <p className="font-normal text-sm normal-case text-left">
                      For secure transfer of funds
                    </p>
                  </div>
                </div>
                <LeftIcon />
              </Button>
              <Button
                onClick={() => {
                  scrollTo(0, 0);
                  setIsHome(false);
                  setPage(SellerPage.cert);
                  setDocumentType(LenderDocumentTypeEnum.ROADWORTHY_CERT);
                  setCheckType('');
                }}
                className={clsx(
                  'border-b border-grey-200 h-[92px] bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between items-center',
                  (!contains(documentTypes, [
                    LenderDocumentTypeEnum.ROADWORTHY_CERT
                  ]) ||
                    !showCert) &&
                    'hidden'
                )}>
                <div className="flex">
                  <CustomCert color={primaryColor} />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <p className="text-left mr-1 text-lg font-semibold mb-1">
                        Roadworthy / Safety Cert.
                      </p>
                      {cert.isDone ? (
                        <SuccessIcon className="mb-1 w-5 h-5" />
                      ) : (
                        !cert.isDone &&
                        documents?.ROADWORTHY_CERT?.needsResubmit && (
                          <InfoErrorIcon className="mb-1 w-5 h-5" />
                        )
                      )}
                    </div>
                    <p className="font-normal text-sm normal-case text-left">
                      Verify vehicle is ready to drive
                    </p>
                  </div>
                </div>
                <LeftIcon />
              </Button>
              <Button
                onClick={() => {
                  scrollTo(0, 0);
                  setIsHome(false);
                  setPage(SellerPage.payoutLetter);
                  setDocumentType(LenderDocumentTypeEnum.PAYOUT_LETTER);
                  setCheckType('');
                }}
                className={clsx(
                  'border-b border-grey-200 h-[92px] bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between items-center',
                  (!contains(documentTypes, [
                    LenderDocumentTypeEnum.PAYOUT_LETTER
                  ]) ||
                    !showPayoutLetter) &&
                    'hidden'
                )}>
                <div className="flex">
                  <CustomPayoutLetter color={primaryColor} />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <p className="text-left mr-1 text-lg font-semibold mb-1">
                        Payout Letter
                      </p>
                      {payoutLetter.isDone ? (
                        <SuccessIcon className="mb-1 w-5 h-5" />
                      ) : (
                        !payoutLetter.isDone &&
                        documents?.PAYOUT_LETTER?.needsResubmit && (
                          <InfoErrorIcon className="mb-1 w-5 h-5" />
                        )
                      )}
                    </div>
                    <p className="font-normal text-sm normal-case text-left">
                      Official document from your lender
                    </p>
                  </div>
                </div>
                <LeftIcon />
              </Button>
              <Button
                onClick={() => {
                  scrollTo(0, 0);
                  setIsHome(false);
                  setPage(SellerPage.ownershipProof);
                  setDocumentType(LenderDocumentTypeEnum.OWNERSHIP_PROOF);
                  setCheckType('');
                }}
                className={clsx(
                  'h-[92px] bg-white w-full !rounded-none p-5 text-dark-primary flex justify-between items-center',
                  !contains(documentTypes, [
                    LenderDocumentTypeEnum.OWNERSHIP_PROOF
                  ]) && 'hidden'
                )}>
                <div className="flex">
                  <CustomRegistration color={primaryColor} />
                  <div className="ml-4">
                    <div className="flex items-center">
                      <p className="text-left mr-1 text-lg font-semibold mb-1">
                        Proof of Ownership
                      </p>
                      {ownershipProof.isDone ? (
                        <SuccessIcon className="mb-1 w-5 h-5" />
                      ) : (
                        !ownershipProof.isDone &&
                        documents?.OWNERSHIP_PROOF?.needsResubmit && (
                          <InfoErrorIcon className="mb-1 w-5 h-5" />
                        )
                      )}
                    </div>
                    <p className="font-normal text-sm normal-case text-left">
                      Verify the owner of the asset
                    </p>
                  </div>
                </div>
                <LeftIcon />
              </Button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

function QRBlock({ qrCode }: { qrCode: string | null }) {
  return (
    <div className="shadow-paper rounded-lg text-sm bg-white p-4 w-[360px] text-center">
      <p className="text-gray-700">
        For your convenience, we have optimised the inspection process for
        completion on a mobile device.
      </p>
      <p className="text-gray-800 font-semibold mt-4">
        Use the QR code to transition to a mobile device for a more streamlined
        submission experience.
      </p>
      <div className="flex items-center justify-center mt-2">
        {qrCode ? (
          <img
            className="w-[180px] p-2"
            src={`data:image/svg+xml;base64,${qrCode}`}
            alt="QR Code"
          />
        ) : (
          <QRSample />
        )}
      </div>
    </div>
  );
}
