import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DocumentCaptureStepEnum,
  LenderDocumentTypeEnum,
  SellerPage,
  SellerPageTitle,
  SellerPageTitleType,
  documentCaptureType
} from '@types';
import DocumentSellerIcon from '@assets/icons/DocumentSellerIcon';
import { ReactComponent as PrimaryCheck } from '@assets/icons/check_activity_success_icon.svg';
import { Input } from '@material-tailwind/react';
import TakePicIcon from '@assets/icons/TakePicIcon';
import Registration from '@assets/images/vehiclePhoto/registration_certificate.png';
import Cert from '@assets/images/cert.png';
import LightIcon from '@assets/icons/LightIcon';
import EyeIcon from '@assets/icons/EyeIcon';
import MobileIcon from '@assets/icons/MobileIcon';
import { ReactComponent as SuccessIcon } from '@assets/icons/Success_light.svg';
import { ReactComponent as DocumentIcon } from '@assets/icons/document-seller-icon.svg';
import { Button } from '@material-tailwind/react';
import { useOrgStore, useSellerStore } from '@stores';
import { useForm } from 'react-hook-form';
import ConfirmSellerModal from '@components/widgets/ConfirmSellerModal';
import clsx from 'clsx';
import {
  ADD_INSPECTION_FILE,
  GET_INSPECTION_CHECK,
  GET_INSPECTION_FILE,
  GET_INSPECTION_FILE_OCR,
  SET_INSPECTION_DOCUMENT,
  post
} from '@services';
import Modal from '@components/widgets/Modal';
import Select from '@components/forms/Select';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { Option } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { processFile } from '@utils';
import { isDesktop } from 'react-device-detect';
import LoadingComponent from '@components/widgets/LoadingComponent';
import _find from 'lodash/find';

type DocumentStartProps = {
  type: documentCaptureType;
  setPage: (page: SellerPage) => void;
  init: () => void;
};

// The maximum file size, 2 MB. Larger files are compressed by adjusting the JPEG quality factor.
// File uploads that don't complete within 60s will trigger a 408 error in CloudFront.
const maxUploadFileSize = 2 * 1024 * 1024;

// Add Web Worker for image processing
const createImageProcessingWorker = () => {
  const workerCode = `
    self.onmessage = function(e) {
      const { imageDataUrl, file } = e.data;
      
      // Create a bitmap from the base64 data URL
      fetch(imageDataUrl)
        .then(response => response.blob())
        .then(blob => createImageBitmap(blob))
        .then(bitmap => {
          // Create canvas with bitmap dimensions
          const canvas = new OffscreenCanvas(bitmap.width, bitmap.height);
          const ctx = canvas.getContext('2d');
          
          // Draw bitmap to canvas
          ctx.drawImage(bitmap, 0, 0);
          
          // Convert canvas to blob
          canvas.convertToBlob({ type: file.type }).then(blob => {
            self.postMessage({ 
              imageDataUrl,
              processedFile: new File([blob], file.name, { type: file.type })
            });
          });
        })
        .catch(error => {
          // Handle any errors
          console.error('Error processing image:', error);
          self.postMessage({ error: error.message });
        });
    };
  `;

  const blob = new Blob([workerCode], { type: 'application/javascript' });
  return new Worker(URL.createObjectURL(blob));
};

const DocumentComponent: React.FC<DocumentStartProps> = ({
  type,
  setPage,
  init
}) => {
  const [step, setStep] = useState(0);
  const [needCert, setNeedCert] = useState(false);
  const [needPayoutLetter, setNeedPayoutLetter] = useState(false);
  const {
    registration,
    cert,
    ownershipProof,
    state,
    setActivePageTitle,
    setRegistrationImage,
    setCertImage,
    setOwnershipProofImage
  } = useSellerStore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  const [image, setImage] = useState('');
  const workerRef = useRef<Worker | null>(null);
  useEffect(() => {
    workerRef.current = createImageProcessingWorker();

    return () => {
      // Clean up worker and image URLs when component unmounts
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, []);
  const isSafetyCert = type === DocumentCaptureStepEnum.safetyCert;
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, []);
  const [isUpload, setIsUpload] = useState(false);
  const handleUpload = () => {
    if (fileInputRef.current) {
      setTimeout(() => fileInputRef.current?.click(), 200);
    }
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      // Clean up previous image URL if exists
      if (image) {
        URL.revokeObjectURL(image);
      }

      processFile(
        file,
        maxUploadFileSize,
        (imageDataUrl: string, compressedFile: File) => {
          if (workerRef.current) {
            workerRef.current.onmessage = (e: MessageEvent) => {
              const { imageDataUrl, processedFile } = e.data;
              setImage(imageDataUrl);

              if (type === DocumentCaptureStepEnum.registration) {
                setRegistrationImage({
                  ...registration,
                  fileName: processedFile.name
                });
              } else if (
                type === DocumentCaptureStepEnum.cert ||
                type === DocumentCaptureStepEnum.safetyCert
              ) {
                setCertImage({
                  ...cert,
                  fileName: processedFile.name
                });
              } else if (type === DocumentCaptureStepEnum.ownershipProof) {
                setOwnershipProofImage({
                  ...ownershipProof,
                  image: imageDataUrl,
                  fileName: processedFile.name
                });
              }
              setIsUpload(true);
              setStep(2);
            };

            workerRef.current.postMessage({
              imageDataUrl,
              file: compressedFile
            });
          }
        }
      );
    }
  };
  const captureImageBtn = useRef<HTMLInputElement>(null);
  const handleTakePicture = () => {
    if (captureImageBtn.current) {
      setTimeout(() => captureImageBtn.current?.click(), 200);
    }
  };

  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Clean up previous image URL
      if (image) {
        URL.revokeObjectURL(image);
      }

      processFile(
        file,
        maxUploadFileSize,
        (imageDataUrl: string, compressedFile: File) => {
          if (workerRef.current) {
            workerRef.current.onmessage = (e: MessageEvent) => {
              const { imageDataUrl, processedFile } = e.data;
              setImage(imageDataUrl);

              if (type === DocumentCaptureStepEnum.registration) {
                setRegistrationImage({
                  ...registration,
                  fileName: processedFile.name
                });
              } else if (
                type === DocumentCaptureStepEnum.cert ||
                type === DocumentCaptureStepEnum.safetyCert
              ) {
                setCertImage({
                  ...cert,
                  fileName: processedFile.name
                });
              } else if (type === DocumentCaptureStepEnum.ownershipProof) {
                setOwnershipProofImage({
                  ...ownershipProof,
                  fileName: processedFile.name
                });
              }
              setStep(2);
            };

            workerRef.current.postMessage({
              imageDataUrl,
              file: compressedFile
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    workerRef.current = createImageProcessingWorker();

    return () => {
      // Clean up worker and image URLs when component unmounts
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, []);

  useEffect(() => {
    scrollTo(0, 0);
    let title: SellerPageTitleType = SellerPageTitle.Blank;
    if (type === DocumentCaptureStepEnum.registration) {
      title =
        step === 0
          ? SellerPageTitle.registration
          : step === 2
          ? SellerPageTitle.registrationDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === DocumentCaptureStepEnum.payoutLetter) {
      title =
        step === 0
          ? SellerPageTitle.payoutLetter
          : step === 2
          ? SellerPageTitle.payoutLetterDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === DocumentCaptureStepEnum.ownershipProof) {
      title =
        step === 0
          ? SellerPageTitle.ownershipProof
          : step === 2
          ? SellerPageTitle.ownershipProofDetails
          : SellerPageTitle.Blank;
    } else {
      title =
        step === 0
          ? isSafetyCert
            ? SellerPageTitle.safetyCert
            : SellerPageTitle.cert
          : step === 2
          ? isSafetyCert
            ? SellerPageTitle.safetyCertDetails
            : SellerPageTitle.certDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    }
    setActivePageTitle(title);
  }, [type, step]);
  if (step === 1) {
    return (
      <DocumentCapture
        type={type}
        primaryColor={primaryColor}
        setLoadImage={setImage}
        setStep={setStep}
        isSafetyCert={isSafetyCert}
      />
    );
  } else if (step === 2) {
    return (
      <CaptureResult
        type={type}
        primaryColor={primaryColor}
        isUpload={isUpload}
        image={image}
        setLoadImage={setImage}
        setStep={setStep}
        isSafetyCert={isSafetyCert}
      />
    );
  } else if (step === 3) {
    return (
      <CaptureReview
        type={type}
        setLoadImage={setImage}
        primaryColor={primaryColor}
        setStep={setStep}
      />
    );
  } else if (step === 4) {
    return (
      <CaptureComplete
        type={type}
        primaryColor={primaryColor}
        setStep={setStep}
        setPage={setPage}
        init={init}
        isSafetyCert={isSafetyCert}
        needCert={needCert}
        needPayoutLetter={needPayoutLetter}
      />
    );
  } else if (step === 5) {
    return (
      <CertNullFinal
        type={type}
        primaryColor={primaryColor}
        setStep={setStep}
        isSafetyCert={isSafetyCert}
        setNeedCert={setNeedCert}
      />
    );
  } else if (step === 6) {
    return (
      <PayoutLetterFinal
        type={type}
        primaryColor={primaryColor}
        setStep={setStep}
        setNeedPayoutLetter={setNeedPayoutLetter}
      />
    );
  }
  return (
    <div className="flex flex-col tablet-portrait:w-[361px] tablet-portrait:mx-auto">
      <div className="flex flex-col gap-10">
        <div className="grow flex flex-col justify-center items-center gap-8">
          {type === DocumentCaptureStepEnum.registration && (
            <img className="" src={Registration} />
          )}
          {type === DocumentCaptureStepEnum.cert && (
            <img className="" src={Cert} />
          )}
          {type === DocumentCaptureStepEnum.safetyCert && (
            <img className="" src={Cert} />
          )}
          <p className="text-grey-600 text-lg text-center font-semibold">
            {!isDesktop &&
              type === DocumentCaptureStepEnum.registration &&
              'Take a photo of your vehicle’s registration certificate'}
            {isDesktop &&
              type === DocumentCaptureStepEnum.registration &&
              'Upload a copy of your vehicle’s registration certificate'}
            {!isDesktop &&
              type === DocumentCaptureStepEnum.cert &&
              'Take a photo of the roadworthy / safety certificate'}
            {!isDesktop &&
              type === DocumentCaptureStepEnum.payoutLetter &&
              'Take a photo of the payout letter'}
            {!isDesktop &&
              type === DocumentCaptureStepEnum.ownershipProof &&
              'Take a photo of the proof of ownership'}
            {!isDesktop &&
              type === DocumentCaptureStepEnum.safetyCert &&
              'Take a photo of the safety certificate'}
            {isDesktop &&
              type === DocumentCaptureStepEnum.cert &&
              'Upload a copy of the roadworthy / safety certificate'}
            {isDesktop &&
              type === DocumentCaptureStepEnum.safetyCert &&
              'Upload a copy of the safety certificate'}
            {isDesktop &&
              type === DocumentCaptureStepEnum.payoutLetter &&
              'Upload a copy of the payout letter'}
            {isDesktop &&
              type === DocumentCaptureStepEnum.ownershipProof &&
              'Upload a copy of the proof of ownership'}
          </p>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <input
            ref={captureImageBtn}
            type="file"
            accept="image/*"
            capture="environment"
            style={{ display: 'none' }}
            onChange={handleCapture}
          />
        </div>

        <div className="">
          {type === DocumentCaptureStepEnum.registration && (
            <div className="text-sm flex flex-col gap-10">
              <p className="text-gray-600">
                Your vehicle registration certificate proves the vehicle is
                legally registered and compliant with state or territory
                requirements.
              </p>
            </div>
          )}
          {type === DocumentCaptureStepEnum.cert && (
            <div className="flex flex-col gap-4">
              <p className="text-gray-600 text-sm">
                A roadworthy / safety certificate shows a vehicle has been
                inspected and meets safety requirements to be roadworthy / safe.
                It is issued by licensed vehicle testers at approved garages or
                inspection stations.
              </p>
            </div>
          )}
          {type === DocumentCaptureStepEnum.payoutLetter && (
            <div className="flex flex-col gap-4">
              <p className="text-gray-600 text-sm">
                A payout letter is a document that has detailed instructions on
                how to pay off your loan and the balance owing on your contract.
                You can request a payout letter from your lender.
              </p>
            </div>
          )}
          {type === DocumentCaptureStepEnum.ownershipProof && (
            <div className="flex flex-col gap-4">
              <p className="text-[#101828] text-sm">
                Provide a document confirming ownership of the asset. This can
                be:
              </p>
              <ul className="list-disc list-inside text-[#101828] text-sm">
                <li>
                  <span className="font-bold">Original Tax Invoice</span> from
                  the dealer/supplier, or
                </li>
                <li>
                  <span className="font-bold">
                    Signed and certified Statutory Declaration
                  </span>{' '}
                  stating:
                  <ul className="list-disc list-inside pl-6">
                    <li>the owner&apos;s full name,</li>
                    <li>date of birth,</li>
                    <li>address,</li>
                    <li>asset details (VIN, Rego, Engine No.),</li>
                    <li>how it was acquired (dealer, private, inherited),</li>
                    <li>
                      and confirming:
                      <blockquote className="text-[#101828] text-sm italic">
                        &quot;The asset is free from lien, encumbrance from any
                        finance or any claim from anyone. The asset is available
                        and accessible for the purchaser to access for
                        collection once payment is advanced.&quot;
                      </blockquote>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
          {type === DocumentCaptureStepEnum.safetyCert && (
            <div className="flex flex-col gap-4">
              <p className="text-gray-600 text-sm">
                A safety certificate shows a vehicle has been inspected and
                meets safety requirements to be safe. It is issued by licensed
                vehicle testers at approved garages or inspection stations.
              </p>
            </div>
          )}
        </div>
        {!isDesktop && (
          <div className="flex flex-col gap-3 mb-2">
            <h2 className="font-semibold">Tips</h2>
            <ul className="flex flex-col gap-3">
              <li className="inline-flex gap-2 text-grey-700 text-sm">
                <LightIcon color={primaryColor} />
                Find an area with good lighting
              </li>
              <li className="inline-flex gap-2 text-grey-700 text-sm">
                <EyeIcon color={primaryColor} />
                Make sure all details are visible
              </li>
              <li className="inline-flex gap-2 text-grey-700 text-sm">
                <MobileIcon color={primaryColor} />
                Hold your device steady
              </li>
            </ul>
          </div>
        )}
      </div>
      <div
        className={clsx(
          'lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto',
          (type === DocumentCaptureStepEnum.ownershipProof ||
            type === DocumentCaptureStepEnum.payoutLetter) &&
            'fixed bottom-0'
        )}>
        {!isDesktop && (
          <Button
            onClick={() =>
              (type === DocumentCaptureStepEnum.registration &&
                registration?.file?.fileId) ||
              ((type === DocumentCaptureStepEnum.cert ||
                type === DocumentCaptureStepEnum.safetyCert) &&
                cert.isDone) ||
              (type === DocumentCaptureStepEnum.ownershipProof &&
                ownershipProof.isDone)
                ? setStep(2)
                : handleTakePicture()
            }
            className={clsx(
              'align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[10px] px-[18px] rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case grow lg:flex-grow-0 text-white text-[16px]',
              primaryColor && `bg-[${primaryColor}]`
            )}
            style={{ backgroundColor: primaryColor }}>
            Continue
          </Button>
        )}
        <Button
          variant="text"
          className="text-grey-600 normal-case font-semibold text-base rounded py-[10px] px-[18px]"
          onClick={handleUpload}>
          Upload from your device or computer
        </Button>
        {(type === DocumentCaptureStepEnum.cert ||
          type === DocumentCaptureStepEnum.safetyCert) && (
          <Button
            className={clsx(
              'align-middle bg-white select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[10px] px-[10px] rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case grow lg:flex-grow-0 text-grey-600 border border-gray-300 text-[15px]'
            )}
            onClick={() => {
              setStep(5);
            }}>
            I don&apos;t have a Roadworthy / Safety Certificate
          </Button>
        )}
        {type === DocumentCaptureStepEnum.payoutLetter && (
          <Button
            className={clsx(
              'align-middle bg-white select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[10px] px-[10px] rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case grow lg:flex-grow-0 text-grey-600 border border-gray-300 text-[15px]'
            )}
            onClick={() => {
              setStep(6);
            }}>
            I&apos;ve recently paid out my vehicle loan
          </Button>
        )}
      </div>
    </div>
  );
};

interface DocumentCaptureProps {
  type: documentCaptureType;
  primaryColor: string;
  setStep: (step: number) => void;
  setLoadImage: (image: any) => void;
  isSafetyCert: boolean;
}

const DocumentCapture: React.FC<DocumentCaptureProps> = ({
  type,
  primaryColor,
  setStep,
  setLoadImage,
  isSafetyCert
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showVideo, setShowVideo] = useState(false);
  const { registration, cert, setRegistrationImage, setCertImage } =
    useSellerStore();
  const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: { exact: 'environment' },
          width: { min: 1920, ideal: 1920 },
          height: { min: 1080, ideal: 1080 },
          frameRate: { ideal: 30 }
        }
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const stopCamera = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream != null) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      videoRef.current.srcObject = null;
    }
  };

  const capturePicture = () => {
    if (canvasRef.current && videoRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      if (context) {
        try {
          // Clear the canvas before capturing
          context.clearRect(0, 0, canvas.width, canvas.height);

          const rectX = (canvas.width - 298) / 2;
          const rectY = 0; // Adjust rectY to account for the video offset
          const rectWidth = 298;
          const rectHeight = window.innerHeight < 698 ? 400 : 420;

          // Calculate the scaling factor to maintain aspect ratio
          const scale = Math.min(
            video.videoWidth / canvas.width,
            video.videoHeight / canvas.height
          );

          // Calculate the actual source dimensions
          const srcWidth = rectWidth * scale;
          const srcHeight = rectHeight * scale;
          const srcX = (video.videoWidth - srcWidth) / 2;
          const srcY = 56;

          // Draw the video frame to the canvas with specified dimensions
          context.drawImage(
            video,
            srcX,
            srcY,
            srcWidth,
            srcHeight, // Source rectangle from the video
            rectX,
            rectY,
            rectWidth,
            rectHeight // Destination rectangle on the canvas
          );
          const imageData = context.getImageData(
            rectX,
            rectY,
            rectWidth,
            rectHeight
          );
          canvas.width = rectWidth;
          canvas.height = rectHeight;
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.putImageData(imageData, 0, 0);
          const capturedImage = canvas.toDataURL('image/jpeg', 1.0);
          setCapturedImage(capturedImage);
          setLoadImage(capturedImage);

          // Clean up
          stopCamera();
          if (canvas) {
            context.clearRect(0, 0, canvas.width, canvas.height);
          }
          setStep(2);
        } catch (error) {
          console.error('Error capturing image:', error);
          stopCamera();
        }
      }
    }
  };

  useLayoutEffect(() => {
    setShowVideo(true);
    if (type === DocumentCaptureStepEnum.registration) {
      setRegistrationImage({
        image: '',
        available: true,
        isDone: false
      });
    } else if (
      type === DocumentCaptureStepEnum.cert ||
      type === DocumentCaptureStepEnum.safetyCert
    ) {
      setCertImage({
        image: '',
        available: true,
        isDone: false
      });
    }

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.beginPath();
        ctx.roundRect(
          (canvas.width - 298) / 2,
          48,
          298,
          window.innerHeight < 698 ? 400 : 420,
          20
        );
        ctx.stroke();
        ctx.fill();

        ctx.globalCompositeOperation = 'destination-out';
        ctx.beginPath();
        ctx.roundRect(
          (canvas.width - 298) / 2,
          48,
          298,
          window.innerHeight < 698 ? 400 : 420,
          20
        );
        ctx.stroke();
        ctx.fill();
      }
    }
    startCamera();
  }, [capturedImage]);

  // Add worker ref
  const workerRef = useRef<Worker | null>(null);

  // Initialize worker
  useEffect(() => {
    workerRef.current = createImageProcessingWorker();

    return () => {
      // Clean up worker when component unmounts
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <div
        className="bg-opacity-40 flex flex-col w-screen h-full mt-[-12px] ml-[-16px] overflow-y-hidden no-scrollbar tablet-portrait:absolute tablet-portrait:left-0 tablet-portrait:top-0 tablet-portrait:ml-0"
        style={{ height: window.innerHeight - 56 }}>
        <div className="flex w-full h-full">
          {showVideo && (
            <video
              ref={videoRef}
              height={window.innerHeight}
              width={window.innerWidth}
              style={{
                maxWidth: '100vw',
                maxHeight: '100vh',
                border: 'none',
                height: window.innerHeight,
                position: 'absolute',
                top: 57,
                left: 0,
                backdropFilter: 'blur(10px)',
                objectFit: 'cover'
              }}
              autoPlay
              playsInline
            />
          )}
          <canvas
            ref={canvasRef}
            width={window.innerWidth}
            height={window.innerHeight}
            style={{
              width: '100vw',
              border: 'none',
              zIndex: 20,
              backdropFilter: 'blur(10px)',
              height: window.innerHeight,
              position: 'absolute'
            }}
          />
        </div>
        <div
          className={clsx(
            'px-4 absolute z-100 inset-x-0 flex flex-col items-center justify-center gap-2',
            window.innerHeight > 698 ? 'bottom-[56px]' : 'bottom-[16px]'
          )}>
          <div className="text-white text-center">
            {type === DocumentCaptureStepEnum.registration &&
              'Please fit your Registration Certificate into the frame.'}
            {type === DocumentCaptureStepEnum.cert &&
              'Please fit your roadworthy / safety certificate into the frame.'}
            {type === DocumentCaptureStepEnum.safetyCert &&
              'Please fit your safety certificate into the frame.'}
          </div>
          <button onClick={capturePicture}>
            <TakePicIcon color={primaryColor} />
          </button>
        </div>
      </div>
    </>
  );
};

interface CaptureResultProps {
  type: documentCaptureType;
  isUpload: boolean;
  primaryColor: string;
  image: string;
  setLoadImage: (image: any) => void;
  setStep: (step: number) => void;
  isSafetyCert: boolean;
}

const CaptureResult: React.FC<CaptureResultProps> = ({
  type,
  image: initialImage,
  primaryColor,
  isUpload,
  setLoadImage,
  setStep,
  isSafetyCert
}) => {
  const captureImageBtn = useRef<HTMLInputElement>(null);
  const {
    registration,
    cert,
    ownershipProof,
    setCertImage,
    setOwnershipProofImage,
    setRegistrationImage,
    setRegistrationOCR,
    inspectionId,
    setRegoFile
  } = useSellerStore();

  const [isLoading, setLoading] = useState(false);
  const navigator = useNavigate();
  const handleUpload = () => {
    if ([DocumentCaptureStepEnum.registration].includes(type)) {
      uploadFile(type, image, (fileId?: string) => {
        setLoading(true);
        let tryTimes = 0;
        const checkOCRStatus = () => {
          post(
            GET_INSPECTION_FILE_OCR,
            {
              inspectionId,
              fileId: fileId || registration.file.fileId
            },
            false
          )
            .then((data) => {
              if (data?.status === 'COMPLETE' || data?.status === 'ERROR') {
                setRegistrationImage({
                  ...registration,
                  file: {
                    ...registration.file,
                    fileId: fileId || registration.file.fileId
                  },
                  ocrFields: data.ocrFields,
                  ocrStatus: data.status
                });
                setStep(3);
                setLoading(false);
              } else if (tryTimes < 25) {
                tryTimes++;
                setTimeout(checkOCRStatus, 1000);
              } else {
                setStep(3);
                setLoading(false);
              }
            })
            .catch((e) => {
              console.error('OCR check failed:', e);
              setStep(3);
              setLoading(false);
            });
        };
        checkOCRStatus();
      });
    } else if (type === DocumentCaptureStepEnum.ownershipProof) {
      setOwnershipProofImage({ ...ownershipProof, isDone: true });
      uploadFile(type, !isUpload ? image : ownershipProof.image, () =>
        setStep(4)
      );
    } else {
      setCertImage({ ...cert, isDone: true });
      uploadFile(type, image, () => setStep(4));
    }
  };
  const [geoLocation, setGeoLocation] = useState<{
    clientAccuracy?: number;
    clientLatitude?: number;
    clientLongitude?: number;
  }>({});
  const [isAddNew, setIsAddNew] = useState(true);
  const [targeFileId, setFileId] = useState<string | undefined>();
  const [image, setImage] = useState<string>(initialImage);
  const handleTakePicture = () => {
    if (captureImageBtn.current) {
      setTimeout(() => captureImageBtn.current?.click(), 200);
    }
  };
  const handleCaptureNewImage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file, maxUploadFileSize, (imageDataUrl, compressedFile) => {
        if (type === DocumentCaptureStepEnum.registration) {
          setRegistrationImage({
            ...registration,
            fileName: compressedFile.name
          });
        } else if (
          type === DocumentCaptureStepEnum.cert ||
          type === DocumentCaptureStepEnum.safetyCert
        ) {
          setCertImage({
            ...cert,
            fileName: compressedFile.name
          });
        } else if (type === DocumentCaptureStepEnum.ownershipProof) {
          setOwnershipProofImage({
            ...ownershipProof,
            fileName: compressedFile.name
          });
        }
        setImage(imageDataUrl);
        setLoadImage(imageDataUrl);
        // event.stopPropagation();
      });
    } else {
      console.log('No file selected.');
    }
  };
  const handleTakeNewPicture = () => {
    // setStep(1);
    handleTakePicture();
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleChangeUploadClick = () => {
    if (fileInputRef.current) {
      setTimeout(() => fileInputRef.current?.click(), 200);
    }
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file, maxUploadFileSize, (imageDataUrl, compressedFile) => {
        setLoadImage(imageDataUrl);
        if (type === DocumentCaptureStepEnum.registration) {
          setRegistrationImage({
            ...registration,
            fileName: compressedFile.name
          });
        } else if (
          type === DocumentCaptureStepEnum.cert ||
          type === DocumentCaptureStepEnum.safetyCert
        ) {
          setCertImage({
            ...cert,
            fileName: compressedFile.name
          });
        } else if (type === DocumentCaptureStepEnum.ownershipProof) {
          setOwnershipProofImage({
            ...ownershipProof,
            fileName: compressedFile.name
          });
        }
      });
    } else {
      console.log('No file selected.');
    }
  };
  useEffect(() => {
    const getLocation = () => {
      if (!window.navigator.geolocation) {
        return;
      }

      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setGeoLocation({
            clientAccuracy: coords.accuracy,
            clientLatitude: coords.latitude,
            clientLongitude: coords.longitude
          });
        },
        (error) => {
          console.error(`Error retrieving location: ${error.message}`);
        }
      );
    };
    getLocation();
    let fileId;
    if (
      type === DocumentCaptureStepEnum.registration &&
      registration.file &&
      registration.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(registration.file?.fileId);
      fileId = registration.file?.fileId;
    } else if (
      (type === DocumentCaptureStepEnum.cert ||
        type === DocumentCaptureStepEnum.safetyCert) &&
      cert.file &&
      cert.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(cert.file?.fileId);
      fileId = cert.file?.fileId;
    } else if (
      type === DocumentCaptureStepEnum.ownershipProof &&
      ownershipProof.file &&
      ownershipProof.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(ownershipProof.file?.fileId);
      fileId = ownershipProof.file?.fileId;
    }
    if (fileId && fileId !== '') {
      setLoading(true);
      post(
        GET_INSPECTION_FILE,
        { inspectionId: inspectionId, fileId: fileId },
        false
      )
        .then((data) => {
          setLoading(false);
          if (data && data.dataBase64) {
            setImage(data.dataBase64);
            setLoadImage(data.dataBase64);
            if (
              data.file &&
              data.file?.documentType === LenderDocumentTypeEnum.REGO_PAPERS
            ) {
              setRegoFile(data.file);
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    }
  }, []);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const handleRetry = () => {
    setShowErrorDialog(false);
    window.location.reload();
  };
  const uploadFile = (type: any, image: any, cb: (fileId?: any) => void) => {
    setLoading(true);
    const params = {
      comment: '',
      dataBase64:
        image.split(';base64,').length > 1 ? image.split(';base64,')[1] : image,
      documentType: type,
      extension: 'jpg',
      inspectionId: inspectionId,
      clientAccuracy: geoLocation?.clientAccuracy,
      clientLatitude: geoLocation?.clientLatitude,
      clientLongitude: geoLocation?.clientLongitude
    };
    post(ADD_INSPECTION_FILE, params, false)
      .then((data) => {
        setLoading(false);
        if (data && data.fileId) {
          setRegoFile({ fileId: data.fileId });
          cb(data.fileId);
        } else {
          alert('Failed to upload file');
        }
      })
      .catch((e) => {
        console.error(e);
        if (
          e.message.includes('timeout') ||
          e.message.includes('Network Error')
        ) {
          setShowErrorDialog(true);
        } else {
          alert('Failed to upload file, Please login again.');
        }
      });
  };
  if (isLoading) return <LoadingComponent color={primaryColor} />;
  const DocumentStart = () => {
    return (
      <div className="flex flex-col gap-8 w-full">
        <div className="gap-3 flex flex-col">
          <h2
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: 'left',
              color: 'text-grey-900'
            }}>
            Document upload
          </h2>
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
              color: 'text-grey-600'
            }}>
            Your document has successfully uploaded.
          </p>
        </div>
        <div className="w-full h-full">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          {type === DocumentCaptureStepEnum.registration && (
            <div className="flex flex-row gap-2 justify-between">
              <div className="flex flex-row gap-2 items-center">
                <DocumentSellerIcon color={primaryColor} />{' '}
                <span>{registration.fileName}</span>
              </div>
              <PrimaryCheck />
            </div>
          )}
          {type === DocumentCaptureStepEnum.ownershipProof && (
            <div className="flex flex-row gap-2 justify-between">
              <div className="flex flex-row gap-2 items-center">
                <DocumentSellerIcon color={primaryColor} />{' '}
                <span>{ownershipProof.fileName}</span>
              </div>
              <PrimaryCheck />
            </div>
          )}
          {(type === DocumentCaptureStepEnum.cert ||
            type === DocumentCaptureStepEnum.safetyCert) && (
            <div className="flex flex-row gap-2 justify-between items-start">
              <div className="flex flex-row gap-2 items-center">
                <DocumentIcon /> <span>{cert.fileName}</span>
              </div>
              <PrimaryCheck />
            </div>
          )}
        </div>

        <UploadErrorModal
          open={showErrorDialog}
          onClose={handleRetry}
          primaryColor={primaryColor}
        />
        <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto">
          <Button
            className={clsx(
              'font-semibold w-auto normal-case rounded text-base shadow-none py-[10px] px-[18px]',
              primaryColor && `bg-[${primaryColor}]`
            )}
            style={{ backgroundColor: primaryColor }}
            onClick={handleUpload}>
            Continue
          </Button>
          <Button
            variant="text"
            className="text-grey-600 normal-case font-semibold rounded text-base hover:bg-blue-500/0 active:bg-blue-500/0 focus:bg-blue-500/0 py-[10px] px-[18px]"
            onClick={handleChangeUploadClick}>
            Change uploaded document
          </Button>
        </div>
      </div>
    );
  };
  return (
    <>
      {isUpload ? (
        <DocumentStart />
      ) : (
        <div
          className={clsx(
            'flex flex-col gap-8 justify-start overflow-y-scroll no-scrollbar',
            window.innerHeight < 698
              ? 'h-[calc(100vh)]'
              : 'h-[calc(100vh-150)] '
          )}>
          <div className="gap-3 flex flex-col">
            <h2
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: 'text-grey-900'
              }}>
              Check readability
            </h2>
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                textAlign: 'left',
                color: 'text-grey-600'
              }}>
              {type === DocumentCaptureStepEnum.registration &&
                'Make sure the registration certificate details are clear to read, with no blur or glare.'}
              {type === DocumentCaptureStepEnum.cert &&
                'Make sure your roadworthy / safety certificate details are clear to read, with no blur or glare.'}
              {type === DocumentCaptureStepEnum.safetyCert &&
                'Make sure your safety certificate details are clear to read, with no blur or glare.'}
              {type === DocumentCaptureStepEnum.payoutLetter &&
                'Make sure your payout letter details are clear to read, with no blur or glare.'}
              {type === DocumentCaptureStepEnum.ownershipProof &&
                'Make sure your proof of ownership details are clear to read, with no blur or glare.'}
            </p>
          </div>
          <div
            className={clsx(
              'flex justify-center h-auto',
              window.innerHeight < 698 && 'mb-[72px]'
            )}>
            <img
              src={
                image.split('base64,').length > 1
                  ? image
                  : `data:image/jpeg;base64,${image}`
              }
              width={298}
              height={window.innerHeight < 698 ? 400 : 420}
              style={{
                width: '298px',
                height: 'auto',
                maxHeight: window.innerHeight < 698 ? '400px' : '420px',
                borderColor: primaryColor,
                objectFit: 'cover'
              }}
              alt="Captured Image"
              className="border-2 rounded-lg"
            />
            <input
              ref={captureImageBtn}
              type="file"
              accept="image/*"
              capture="environment"
              style={{ display: 'none' }}
              onChange={handleCaptureNewImage}
            />
          </div>
          <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto">
            <Button
              className={clsx(
                'font-semibold w-auto normal-case rounded text-base shadow-none py-[10px] px-[18px]',
                primaryColor && `bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={handleUpload}>
              Image is readable
            </Button>
            <Button
              variant="text"
              className="text-grey-600 normal-case font-semibold rounded text-base hover:bg-blue-500/0 active:bg-blue-500/0 focus:bg-blue-500/0 py-[10px] px-[18px]"
              onClick={handleTakeNewPicture}>
              Take a new picture
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
interface CaptureReviewProps {
  type: documentCaptureType;
  primaryColor: string;
  setStep: (step: number) => void;
  setLoadImage: (image: any) => void;
}
const CaptureReview: React.FC<CaptureReviewProps> = ({
  type,
  primaryColor,
  setStep,
  setLoadImage
}) => {
  const {
    registration,
    inspectionId,
    setRegistration,
    inspectionChecks,
    setInspectionChecks,
    setRegistrationFormStatus
  } = useSellerStore();
  const vinRegExp = /^[A-HJ-NPR-Z0-9]{17}$/;
  const recognizeForm = yup
    .object({
      stateValue: yup.string().required('Please select a state.'),
      vinValue: yup
        .string()
        .required('Please enter the VIN')
        .matches(vinRegExp, {
          message: (
            <span>
              The VIN <span className="font-bold">must be 17 characters</span>{' '}
              long and can only include the following characters: 0–9, A–Z
              (uppercase){' '}
              <span className="font-bold">excluding the letters</span> I, O and
              Q
            </span>
          )
        }),
      numberPlateValue: yup
        .string()
        .required('Please enter the registration plate number.')
        .max(9, 'Registration plate must be 9 or fewer characters'),
      expiryDate: yup
        .string()
        .required('Please enter the registration expiry date')
        .matches(
          /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
          'Date must be in the format dd/mm/yyyy'
        )
        .required('Please enter the registration expiry date'),
      givenName: yup.string().required('Please enter your given name'),
      middleName: yup.string(),
      familyName: yup.string().required('Please enter your family name'),
      addressLine1: yup.string().required('Please enter Address Line 1'),
      addressLine2: yup.string().required('Please enter Address Line 2')
    })
    .required();
  const stateOptions = ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT', 'NT'];
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    trigger,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(recognizeForm),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      vinValue: _find(registration.ocrFields, { key: 'vin' })?.value,
      numberPlateValue: _find(registration.ocrFields, {
        key: 'registrationNumber'
      })?.value,
      stateValue: stateOptions.includes(registration?.registrationState)
        ? registration.registrationState
        : '',
      expiryDate: _find(registration.ocrFields, {
        key: 'dateOfExpiry'
      })?.value?.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1'),
      givenName: _find(registration.ocrFields, { key: 'givenName' })?.value,
      middleName: _find(registration.ocrFields, { key: 'middleName' })?.value,
      familyName: _find(registration.ocrFields, { key: 'familyName' })?.value,
      addressLine1: _find(registration.ocrFields, { key: 'addressLine1' })
        ?.value,
      addressLine2: _find(registration.ocrFields, { key: 'addressLine2' })
        ?.value
    }
  });

  const {
    vinValue,
    numberPlateValue,
    stateValue,
    expiryDate,
    givenName,
    middleName,
    familyName,
    addressLine1,
    addressLine2
  } = watch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const formatExpiryDate = (e: any, value: any) => {
    const cleaned = value.replace(/\D+/g, ''); // Remove all non-digit characters
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);

    if (!match) return value;

    const [, dd, mm, yyyy] = match;
    let formatted = '';

    if (dd) {
      formatted += dd;
      if (dd.length === 2) {
        formatted += mm
          ? '/'
          : e.nativeEvent.inputType !== 'deleteContentBackward'
          ? '/'
          : '';
      }
    }
    if (mm) {
      formatted += mm;
      if (mm.length === 2) {
        formatted += yyyy
          ? '/'
          : e.nativeEvent.inputType !== 'deleteContentBackward'
          ? '/'
          : '';
      }
    }
    if (yyyy) {
      formatted += yyyy;
    }

    return formatted;
  };
  const [isLoading, setLoading] = useState(false);
  const navigator = useNavigate();
  const [submitStatus, setSubmitStatus] = useState(true);
  const onSubmit = (data: any) => {
    if (type === DocumentCaptureStepEnum.registration) {
      const cleaned = data.expiryDate.replace(/\D+/g, '');
      const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
      const [, dd, mm, yyyy] = match;
      if (
        registration.file?.fileId !== '' &&
        registration.file?.fileId !== ''
      ) {
        const params = {
          inspectionId: inspectionId,
          documentType: type,
          extraFields: {
            regoCertificate: {
              addressLine1: data.addressLine1,
              addressLine2: data.addressLine2,
              dateOfExpiry: `${yyyy}-${mm}-${dd}`,
              familyName: data.familyName,
              givenName: data.givenName,
              middleName: data.middleName,
              rego: data.numberPlateValue,
              state: data.stateValue,
              vin: data.vinValue
            }
          }
        };
        setRegistration({
          ...registration,
          file: {
            fields2: {
              regoCertificate: {
                addressLine1: data.addressLine1,
                addressLine2: data.addressLine2,
                dateOfExpiry: data.expiryDate,
                familyName: data.familyName,
                givenName: data.givenName,
                middleName: data.middleName,
                rego: data.numberPlateValue,
                state: data.stateValue,
                vin: data.vinValue
              }
            },
            fileId: registration.file?.fileId
          },
          isDone: true
        });
        setLoading(true);
        post(SET_INSPECTION_DOCUMENT, params, false)
          .then(() => {
            post(GET_INSPECTION_CHECK, { inspectionId: inspectionId }, false)
              .then((data) => {
                if (
                  data &&
                  data.inspectionChecks &&
                  Object.keys(data.inspectionChecks).length !== 0
                ) {
                  setRegistrationFormStatus(true);
                  setInspectionChecks(data.inspectionChecks);
                  setLoading(false);
                  if (submitStatus) {
                    // when need to compare the result with vin or rego
                    setIsOpenModal(true);
                    setSubmitStatus(false);
                  } else {
                    // not compare, it should be from modal
                    setStep(4);
                  }
                }
              })
              .catch((e) => {
                console.error(e);
              });
          })
          .catch(() => {
            alert('Failed to re-upload file, Please login again.');
            setLoading(false);
          });
      }
    }
  };
  const handleKeyDown = (event: any) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'];
    if (!allowedKeys.includes(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };
  const handleTextChangeUppercase = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: any
  ) => {
    const upperCaseTrimmedValue = event.target.value
      .replace(/\s+/g, '')
      .toUpperCase();
    setValue(fieldName, upperCaseTrimmedValue, { shouldValidate: true });
  };
  if (isLoading) return <LoadingComponent color={primaryColor} />;
  else
    return (
      <>
        <div
          className={clsx(
            'flex flex-grow flex-col gap-8 overflow-y-scroll no-scrollbar',
            window.innerHeight < 698
              ? 'h-[calc(100vh)]'
              : 'h-[calc(100vh-150)] '
          )}>
          <div className="flex flex-col gap-3">
            <h2 className="text-lg font-semibold text-grey-800">
              {DocumentCaptureStepEnum.registration === type &&
                'Registration Certificate'}
            </h2>
            <div className="p-5 border border-green-500 gap-6 flex flex-col rounded-lg">
              <h2 className="text-md font-semibold font-inter text-green-700">
                {DocumentCaptureStepEnum.registration === type &&
                registration.ocrFields &&
                Object.keys(registration.ocrFields).length !== 0
                  ? 'Have we captured the correct details for the registration certificate?'
                  : 'Please enter the details below.'}
              </h2>
              <p className="text-sm font-regular font-inter text-green-600">
                {DocumentCaptureStepEnum.registration === type &&
                registration.ocrFields &&
                Object.keys(registration.ocrFields).length !== 0
                  ? 'Please review and edit the text boxes below to ensure they exactly match your registration certificate.'
                  : 'Unfortunately we were unable to read the details from the document submitted. Please complete the text boxes below to ensure they exactly match the registration certificate provided.'}
              </p>
            </div>
          </div>
          <div className="mt-8 gap-8 flex flex-col pb-[150px]">
            {type === DocumentCaptureStepEnum.registration && (
              <>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Given Name
                  </h3>
                  <Input
                    placeholder=""
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.givenName && <ErrorIcon />}
                    value={givenName}
                    onKeyDown={(event) => {
                      if (!/[A-Za-z]+/i.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register(`givenName`, {
                      required: 'Please enter this field'
                    })}
                  />
                  {errors.givenName && (
                    <span className="text-xs text-error-light">
                      {errors.givenName.message}
                    </span>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Middle Name
                  </h3>
                  <Input
                    placeholder=""
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.middleName && <ErrorIcon />}
                    value={middleName}
                    onKeyDown={(event) => {
                      if (!/[A-Za-z]+/i.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register(`middleName`, {
                      required: 'Please enter this field'
                    })}
                  />
                  {errors.middleName && (
                    <span className="text-xs text-error-light">
                      {errors.middleName.message}
                    </span>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Family Name
                  </h3>
                  <Input
                    placeholder=""
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.familyName && <ErrorIcon />}
                    value={familyName}
                    onKeyDown={(event) => {
                      if (!/[A-Za-z]+/i.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register(`familyName`, {
                      required: 'Please enter this field'
                    })}
                  />
                  {errors.familyName && (
                    <span className="text-xs text-error-light">
                      {errors.familyName.message}
                    </span>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Address Line 1
                  </h3>
                  <Input
                    placeholder=""
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.addressLine1 && <ErrorIcon />}
                    value={addressLine1}
                    {...register(`addressLine1`, {
                      required: 'Please enter this field'
                    })}
                  />
                  {errors.addressLine1 && (
                    <span className="text-xs text-error-light">
                      {errors.addressLine1.message}
                    </span>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Address Line 2
                  </h3>
                  <Input
                    placeholder=""
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.addressLine2 && <ErrorIcon />}
                    value={addressLine2}
                    {...register(`addressLine2`, {
                      required: 'Please enter this field'
                    })}
                  />
                  {errors.addressLine2 && (
                    <span className="text-xs text-error-light">
                      {errors.addressLine2.message}
                    </span>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Registration Number Plate
                  </h3>
                  <Input
                    placeholder=""
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.numberPlateValue && <ErrorIcon />}
                    value={numberPlateValue}
                    onKeyDown={(event) => {
                      if (!/[A-Za-z0-9]+/i.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...register(`numberPlateValue`)}
                    onChange={(event) =>
                      handleTextChangeUppercase(event, 'numberPlateValue')
                    }
                  />
                  {errors.numberPlateValue && (
                    <span className="text-xs text-error-light">
                      {errors.numberPlateValue.message}
                    </span>
                  )}
                </div>
                <div className="gap-3.5 flex flex-col">
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Registration State
                  </h3>
                  <Select
                    placeholder="Select a State"
                    value={stateValue}
                    {...register(`stateValue`, {
                      required: 'Please enter this field'
                    })}
                    style={
                      {
                        '--dynamic-border-color': primaryColor
                      } as React.CSSProperties
                    }
                    className="custom-state-select dynamic-border"
                    onChange={(e) => {
                      if (e && e !== '') {
                        clearErrors('stateValue');
                      }
                      setValue('stateValue', e || '');
                    }}>
                    {stateOptions.map((state, index) => (
                      <Option key={index} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                  {errors.stateValue && (
                    <span className="text-xs text-error-light">
                      {errors.stateValue.message}
                    </span>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    Registration Expiry Date
                  </h3>
                  <Input
                    placeholder="dd/mm/yyyy"
                    variant="static"
                    className="rounded-none pb-4"
                    maxLength={10}
                    icon={errors.expiryDate && <ErrorIcon />}
                    style={{ borderColor: primaryColor }}
                    value={expiryDate}
                    onKeyDown={handleKeyDown}
                    {...register(`expiryDate`, {
                      onChange: (e) => {
                        clearErrors('expiryDate');
                        const formatted = formatExpiryDate(e, e.target.value);
                        setValue('expiryDate', formatted);
                      }
                    })}
                  />
                  {errors.expiryDate && (
                    <span className="text-xs text-error-light">
                      {errors.expiryDate.message}
                    </span>
                  )}
                </div>
                <div>
                  <h3 className="text-sm font-semibold font-inter text-gray-700">
                    VIN
                  </h3>
                  <Input
                    placeholder="VIN"
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.vinValue && <ErrorIcon />}
                    value={vinValue}
                    {...register(`vinValue`)}
                    onChange={(event) =>
                      handleTextChangeUppercase(event, 'vinValue')
                    }
                  />
                  {errors.vinValue && (
                    <span className="text-xs text-error-light">
                      {errors.vinValue.message}
                    </span>
                  )}{' '}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4">
          <Button
            onClick={() => {
              clearErrors();
              if (isValid) {
                if (
                  (inspectionChecks.vin && inspectionChecks.vin.userVin) ||
                  (inspectionChecks.licencePlate &&
                    inspectionChecks.licencePlate.userRego)
                ) {
                  handleSubmit(onSubmit)();
                } else {
                  setSubmitStatus(false);
                  setIsOpenModal(true);
                }
              } else {
                trigger();
              }
            }}
            className={clsx(
              'align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[18px] px-[10px] rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case w-full text-white text-[16px]  self-end',
              primaryColor && `bg-[${primaryColor}]`
            )}
            style={{ backgroundColor: primaryColor }}>
            Confirm
          </Button>
        </div>

        <ConfirmSellerModal
          open={isOpenModal}
          type={DocumentCaptureStepEnum.registration}
          vin={vinValue}
          licencePlate={{
            rego: numberPlateValue || '',
            state: stateValue || ''
          }}
          onSubmit={handleSubmit(onSubmit)}
          onClose={() => {
            setIsOpenModal(false);
            setSubmitStatus(true);
          }}
          setStep={() => setStep(4)}
        />
      </>
    );
};

interface CaptureCompleteProps {
  type: documentCaptureType;
  primaryColor: string;
  setStep: (step: number) => void;
  setPage: (page: SellerPage) => void;
  init: () => void;
  isSafetyCert: boolean;
  needCert: boolean;
  needPayoutLetter: boolean;
}
const CaptureComplete: React.FC<CaptureCompleteProps> = ({
  type,
  setStep,
  primaryColor,
  setPage,
  init,
  isSafetyCert,
  needCert,
  needPayoutLetter
}) => {
  return (
    <>
      <div className="gap-5 flex flex-grow flex-col w-full h-full justify-center items-center">
        <div className="flex flex-col gap-5 justify-center items-center">
          <SuccessIcon />
        </div>
        <h2 className="text-center font-semibold">Success</h2>
        <div className="gap-2 flex flex-col text-center text-sm ">
          {type === DocumentCaptureStepEnum.registration &&
            'Your registration certificate has been successfully scanned. '}
          {needPayoutLetter &&
            type === DocumentCaptureStepEnum.payoutLetter &&
            'Your payout letter has been successfully uploaded. '}
          {!needPayoutLetter &&
            type === DocumentCaptureStepEnum.payoutLetter &&
            'You’ve confirmed that you have recently pay off your loan and the balance owing on your contract.'}
          {type === DocumentCaptureStepEnum.ownershipProof &&
            'Your proof of ownership has been successfully uploaded. '}
          {needCert &&
            type === DocumentCaptureStepEnum.cert &&
            'Your roadworthy / safety certificate has been successfully scanned. '}
          {needCert &&
            type === DocumentCaptureStepEnum.safetyCert &&
            'Your safety certificate has been successfully scanned. '}
          {!needCert &&
            type === DocumentCaptureStepEnum.cert &&
            "You've confirmed that you will not be providing a Roadworthy / Safety Certificate at this time."}
          {!needCert &&
            type === DocumentCaptureStepEnum.safetyCert &&
            "You've confirmed that you will not be providing a Safety Certificate at this time."}
          {needPayoutLetter &&
            type === DocumentCaptureStepEnum.payoutLetter &&
            "You've confirmed that you have recently pay off your loan and the balance owing on your contract."}
        </div>
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        <Button
          onClick={() => {
            // TODO: a temporary workaround for a frontend issue with the image capture process
            window.location.reload();
          }}
          className={clsx(
            'align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[18px] px-[10px] rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case w-full text-white text-[16px]  self-end',
            primaryColor && `bg-[${primaryColor}]`
          )}
          style={{ backgroundColor: primaryColor }}>
          Continue
        </Button>
      </div>
    </>
  );
};
interface CertNullFinalProps {
  type: documentCaptureType;
  isSafetyCert: boolean;
  primaryColor: string;
  setStep: (step: number) => void;
  setNeedCert: (needCert: boolean) => void;
}
const CertNullFinal: React.FC<CertNullFinalProps> = ({
  type,
  isSafetyCert,
  primaryColor,
  setStep,
  setNeedCert
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { inspectionId } = useSellerStore();
  const onSubmit = () => {
    setIsLoading(true);
    post(
      SET_INSPECTION_DOCUMENT,
      {
        inspectionId: inspectionId,
        documentType: type,
        isNotProvided: true
      },
      false
    )
      .then(() => {
        post(GET_INSPECTION_CHECK, { inspectionId: inspectionId }, false)
          .then((data) => {
            setStep(4);
            setNeedCert(false);
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
          });
      })
      .catch(() => {
        alert('Failed to re-upload file, Please login again.');
        setIsLoading(false);
      });
  };
  if (isLoading) return <LoadingComponent color={primaryColor} />;
  return (
    <>
      <div className="flex mt-6 flex-col text-[16px] text-gray-800 font-bold w-full justify-center items-center">
        Are you sure you want to proceed without providing a Roadworthy / Safety
        Certificate?
      </div>
      <div className="gap-5 flex flex-col w-full mt-6 text-gray-600 justify-center items-center">
        If you&apos;re unable to supply one, we will need to contact you for
        additional information regarding your vehicle&apos;s condition.
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        <Button
          onClick={onSubmit}
          className={clsx(
            'align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[18px] px-[10px] rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case w-full text-white text-[16px]  self-end',
            primaryColor && `bg-[${primaryColor}]`
          )}
          style={{ backgroundColor: primaryColor }}>
          Proceed Without Roadworthy / Safety Cert.
        </Button>
        <Button
          variant="text"
          className="text-grey-600 normal-case font-semibold text-base rounded py-[10px] px-[18px]"
          onClick={() => {
            setStep(0);
          }}>
          Provide Roadworthy / Safety Certificate
        </Button>
      </div>
    </>
  );
};

interface PayoutLetterFinalProps {
  type: documentCaptureType;
  primaryColor: string;
  setStep: (step: number) => void;
  setNeedPayoutLetter: (needPayoutLetter: boolean) => void;
}
const PayoutLetterFinal: React.FC<PayoutLetterFinalProps> = ({
  type,
  primaryColor,
  setStep,
  setNeedPayoutLetter
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { inspectionId } = useSellerStore();
  const onSubmit = () => {
    setIsLoading(true);
    post(
      SET_INSPECTION_DOCUMENT,
      {
        inspectionId: inspectionId,
        documentType: type,
        isNotProvided: true
      },
      false
    )
      .then(() => {
        post(GET_INSPECTION_CHECK, { inspectionId: inspectionId }, false)
          .then((data) => {
            setStep(4);
            setNeedPayoutLetter(false);
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setIsLoading(false);
          });
      })
      .catch(() => {
        alert('Failed to re-upload file, Please login again.');
        setIsLoading(false);
      });
  };
  if (isLoading) return <LoadingComponent color={primaryColor} />;
  return (
    <>
      <div className="flex mt-6 flex-col text-[16px] text-gray-600  w-full justify-center items-center">
        When you pay off a vehicle loan, it can take time for the Personal
        Property Securities Register (PPSR) to update and show the encumbrance
        as cleared.
      </div>
      <div className="gap-5 flex flex-col w-full mt-6 text-gray-800 font-bold justify-center items-center">
        If you have recently paid off your vehicle loan, please confirm this by
        clicking &apos;Confirm and Continue&apos;.
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        <Button
          onClick={onSubmit}
          className={clsx(
            'align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[18px] px-[10px] rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case w-full text-white text-[16px]  self-end',
            primaryColor && `bg-[${primaryColor}]`
          )}
          style={{ backgroundColor: primaryColor }}>
          Confirm and Continue
        </Button>
        <Button
          variant="text"
          className="text-grey-600 normal-case font-semibold text-base rounded py-[10px] px-[18px]"
          onClick={() => {
            setStep(0);
          }}>
          Go Back
        </Button>
      </div>
    </>
  );
};

const UploadErrorModal = ({
  open,
  onClose,
  primaryColor
}: {
  open: boolean;
  onClose: () => void;
  primaryColor: string;
}) => {
  return (
    <Modal size="md" open={open} onClose={onClose} hideClose>
      <div className="p-6 flex flex-col items-center gap-6">
        <h2 className="text-xl font-semibold text-center">
          Upload Unsuccessful
        </h2>
        <p className="text-gray-600 text-center">
          It seems your image couldn't be uploaded. This may be due to a slow or
          unstable internet connection. Please check your connection and try
          again.
        </p>
        <Button
          className="w-full normal-case font-semibold text-white"
          style={{ backgroundColor: primaryColor }}
          onClick={onClose}>
          Try Again
        </Button>
      </div>
    </Modal>
  );
};

export default DocumentComponent;
