import Loader from '@components/widgets/Loader';
import { Button } from '@material-tailwind/react';
import { post, GET_INSPECTION_FILE } from '@services';
import { useInspectionDetailStore } from '@stores';
import {
  DocumentFormType,
  InspectionSecurityStatusEnum,
  RegoSecurityCheckEnum
} from '@types';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DETAILS_REGO_PAPER } from '@constants';
interface IProps {
  type: DocumentFormType;
  status: InspectionSecurityStatusEnum;
  data: any;
}

const RegoCheckCard = ({ type, data }: IProps) => {
  const { activePanel, isCollapsed, setCollapse, inspectionDetailData } =
    useInspectionDetailStore();
  const [imgsrc, setImgSrc] = useState<any>();
  const formattedDate = (date: any) => format(date, 'dd/MM/yyyy h:mma');
  const formattedDateWithoutTime = (date: any) => format(date, 'dd/MM/yyyy');
  const [formElements, setFormElements] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [seller, setSeller] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const param = useParams();
  const handleClose = () => {
    setCollapse(true);
  };
  useEffect(() => {
    if (type === RegoSecurityCheckEnum.REGO_SECURITY_DATA_EDIT) {
      const fileTypes = inspectionDetailData.files.filter(
        (e: any) => e.documentType === 'REGO_PAPERS'
      );
      if (fileTypes.length > 0) {
        setSeller(fileTypes[0].user);
        setSelectedFile(fileTypes[0]);
        setLoading(true);
        post(
          GET_INSPECTION_FILE,
          { inspectionId: param.id, fileId: fileTypes[0].fileId },
          false
        )
          .then((data) => {
            setLoading(false);
            if (data && data.dataBase64) {
              setImgSrc(data.dataBase64);
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
      }
      setFormElements(DETAILS_REGO_PAPER);
    }
  }, [type]);
  if (isLoading) return <Loader />;
  switch (type) {
    case RegoSecurityCheckEnum.REGO_SECURITY_AUTHENTICITY:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                Unable to verify with high confidence that the Registration
                Certificate provided is authentic.
              </p>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case RegoSecurityCheckEnum.REGO_SECURITY_EXPIRY:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <p className="font-medium text-grey-700 text-base bg-error-50 p-4 rounded-md">
                The Registration Expiry Date on the registration certificate
                does not match the vehicle registration expiry date found on the
                NEVDIS government registry.
              </p>
              <div className="flex flex-col border-none w-full">
                <p className="font-semibold border-b border-grey-200 text-grey-800  py-3.5">
                  Seller submitted details
                </p>
                <div className="grid grid-cols-2  py-3.5 border-b border-grey-200">
                  <p className="text-grey-500 text-sm font-medium leading-5">
                    Registration Expiry Date
                  </p>
                  <p className="text-sm font-medium text-grey-950  text-right">
                    {data?.regoCertificateExpiryDate}
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-full border-b border-grey-200">
                <p className="font-semibold text-grey-800 border-b border-grey-200   py-3.5">
                  NEVDIS registration details
                </p>
                <div className="grid grid-cols-2 py-3.5 last:border-none  border-b border-grey-200 ">
                  <p className="text-grey-500 text-sm font-medium leading-5">
                    Registration Expiry Date
                  </p>
                  <p className="text-sm font-medium text-grey-950 text-right">
                    {data?.nevdisRegistrationExpiryDate}
                  </p>
                </div>
              </div>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case RegoSecurityCheckEnum.REGO_SECURITY_DATA_EDIT:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                Seller-submitted data differs from OCR-extracted data. This may
                be due to an OCR misread or manual input where OCR retrieval was
                incomplete. Compare the extracted data with the seller&apos;s
                entry and the document image to verify accuracy.
              </p>
              <h1 className="text-grey-800 font-semibold text-base">
                Provided by{' '}
                {seller
                  ? seller.firstName + ' ' + seller.lastName
                  : inspectionDetailData.seller.firstName +
                    ' ' +
                    inspectionDetailData.seller.lastName}
              </h1>
              <div className="flex flex-col gap-8">
                {imgsrc && <img src={`data:image/jpeg;base64,${imgsrc}`} />}
              </div>
              <div className="flex flex-col justify-center items-center">
                {_map(
                  formElements,
                  (row: any, index: number) =>
                    (_get(selectedFile, row.keyPath) ||
                      (row.key === 'location' &&
                        _get(selectedFile, 'clientAddress'))) && (
                      <div key={index} className="flex flex-row gap-x-1">
                        <p className="text-grey-500 text-xs leading-[18px]">
                          {row.displayName + ':'}
                        </p>
                        <p className="text-grey-500 text-xs leading-[18px]">
                          {row.type === 'Date'
                            ? formattedDate(_get(selectedFile, row.keyPath))
                            : row.keyPath === 'location'
                            ? _get(selectedFile, 'clientAddress.label')
                            : _get(selectedFile, row.keyPath)}
                        </p>
                      </div>
                    )
                )}
              </div>
              <div className="flex flex-col gap-4">
                <h2 className="font-semibold text-[16px]">
                  Seller submitted details
                </h2>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Given Name
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.givenName?.userEditValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Middle Name
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.middleName?.userEditValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Family Name
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.familyName?.userEditValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Address Line 1
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.addressLine1?.userEditValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Address Line 2
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.addressLine2?.userEditValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Number Plate
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.registrationNumber?.userEditValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Registration State
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.licencePlate
                          ?.userState
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Registration expiry date
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {inspectionDetailData?.inspectionChecks?.regoCertificate
                        ?.dateOfExpiry?.userEditValue
                        ? formattedDateWithoutTime(
                            new Date(
                              inspectionDetailData?.inspectionChecks?.regoCertificate?.dateOfExpiry?.userEditValue
                            )
                          )
                        : ''}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      VIN
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.vin?.userEditValue
                      }
                    </p>
                  </div>
                </div>
                <h2 className="font-semibold text-[16px]">
                  OCR-extracted data
                </h2>
                <div className="flex flex-col">
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Given Name
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.givenName?.ocrValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Middle Name
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.middleName?.ocrValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Family Name
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.familyName?.ocrValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Address Line 1
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.addressLine1?.ocrValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Address Line 2
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.addressLine2?.ocrValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Number Plate
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.registrationNumber?.ocrValue
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Registration State
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.licencePlate
                          ?.ocrState
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Registration expiry date
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {inspectionDetailData?.inspectionChecks?.regoCertificate
                        ?.dateOfExpiry?.ocrValue
                        ? formattedDateWithoutTime(
                            new Date(
                              inspectionDetailData?.inspectionChecks?.regoCertificate?.dateOfExpiry?.ocrValue
                            )
                          )
                        : ''}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      VIN
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.regoCertificate
                          ?.vin?.ocrValue
                      }
                    </p>
                  </div>
                </div>
              </div>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return <> </>;
  }
};
export default RegoCheckCard;
