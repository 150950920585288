export type SelectOption = {
  label: string;
  value: string;
};
export enum InspectionStatusEnum {
  All = 'ALL',
  Completed = 'COMPLETED',
  Submitted = 'SUBMITTED',
  Initiated = 'INITIATED',
  InProgress = 'IN_PROGRESS',
  UpdateRequired = 'UPDATE_REQUIRED'
}
export type InspectionStatusType =
  | InspectionStatusEnum.All
  | InspectionStatusEnum.Completed
  | InspectionStatusEnum.Submitted
  | InspectionStatusEnum.Initiated
  | InspectionStatusEnum.InProgress
  | InspectionStatusEnum.UpdateRequired;
export enum UserStatusEnum {
  All = 'all',
  ACTIVE = 'Active',
  INVITATION_PENDING = 'Invitation Pending',
  INVITATION_EXPIRED = 'Invitation Expired',
  REVOKED = 'Revoked'
}
export enum VehiclePhotosEnum {
  'ASSET_FRONT',
  'ASSET_RIGHT',
  'ASSET_LEFT',
  'ASSET_BACK'
}
export enum UserActionEnum {
  ACTIVE = 'Revoke access',
  INVITATION_PENDING = 'Revoke access',
  REVOKED = 'Reactivate'
}
export enum InspectionDocumentStatusEnum {
  Completed = 'complete',
  Incomplete = 'incomplete',
  notRequested = 'NOT_REQUESTED',
  notProvided = 'NOT_PROVIDED',
  notFound = 'NOT_FOUND',
  notPerformed = 'UNAVAILABLE',
  warning = 'WARNING',
  registrations = 'registrations',
  registered = 'registered',
  noRegister = 'UNREGISTERED',
  inprogress = 'inprogress',
  ResupplyPending = 'ResupplyPending',
  RecentlyPaidOutFinance = 'RecentlyPaidOutFinance'
}

export enum InspectionSecurityStatusEnum {
  notRequested = 'NOT_REQUESTED',
  notFound = 'NOT_FOUND',
  notPerformed = 'UNAVAILABLE',
  notAvailable = 'NOT_AVAILABLE',
  notProvided = 'NOT_PROVIDED',
  warning = 'WARNING',
  pass = 'PASS',
  failed = 'FAIL',
  registrations = 'registrations',
  expired = 'REGISTRATION_EXPIRED',
  registered = 'registered',
  noRegister = 'UNREGISTERED',
  reportFound = 'RECORD_FOUND',
  noReport = 'NO_REPORT',
  noRecord = 'NO_RECORD',
  match = 'MATCH',
  discrepancyFound = 'DIFFERENT',
  error = 'ERROR',
  authenticityWarning = 'AUTHENTICITY_WARNING',
  ocrFailed = 'OCR_FAIL',
  inprogress = 'inprogress',
  ResupplyPending = 'ResupplyPending',
  RecentlyPaidOutFinance = 'RecentlyPaidOutFinance'
}
export enum InspectionDocumentLabelStatusEnum {
  complete = 'Complete',
  incomplete = 'Incomplete',
  inprogress = 'in Progress',
  notfound = 'Not found',
  NOT_PROVIDED = 'Not Provided',
  ResupplyPending = 'Resupply Pending',
  RecentlyPaidOutFinance = 'Recently Paid Out Finance'
}
export enum InspectionCardTypeEnum {
  VehicleInfo = 'vehicleInfo',
  BuyerCard = 'buyerCard',
  SellerCard = 'sellerCard',
  LenderCard = 'lenderCard'
}

export enum AssetEnum {
  All = 'all',
  optionA = 'Car',
  optionB = 'Motor cycle',
  optionC = 'Campervan'
}
export enum AssetTypesEnum {
  CAR = 'CAR',
  CAMPERVAN = 'CARAVAN',
  TRAILER = 'TRAILER',
  MOTOR_CYCLE = 'MOTORBIKE',
  EQUIPMENT = 'EQUIPMENT'
}

export type AssetType =
  | AssetTypesEnum.CAR
  | AssetTypesEnum.CAMPERVAN
  | AssetTypesEnum.TRAILER
  | AssetTypesEnum.MOTOR_CYCLE
  | AssetTypesEnum.EQUIPMENT;
export enum LenderEnum {
  All = 'all',
  optionA = 'Maria',
  optionB = 'Steve Brewer'
}

export enum LenderStatusEnum {
  Active = 'ACTIVE',
  Pending = 'INVITATION_PENDING',
  InvitationExpired = 'INVITATION_EXPIRED',
  Revoked = 'REVOKED',
}
export enum LenderDocumentTypeEnum {
  SELLER_ID = 'SELLER_ID',
  DRIVER_LICENCE_FRONT = 'DRIVER_LICENCE_FRONT',
  DRIVER_LICENCE_BACK = 'DRIVER_LICENCE_BACK',
  FACE = 'FACE',
  VIN = 'VIN',
  REGO_PLATE = 'REGO_PLATE',
  ODOMETER = 'ODOMETER',
  ASSET_FRONT = 'ASSET_FRONT',
  ASSET_BACK = 'ASSET_BACK',
  ASSET_LEFT = 'ASSET_LEFT',
  ASSET_RIGHT = 'ASSET_RIGHT',
  INTERNAL_FRONT = 'INTERNAL_FRONT',
  INTERNAL_BACK = 'INTERNAL_BACK',
  INTERNAL_BOOT = 'INTERNAL_BOOT',
  INTERNAL_LIVING_AREA = 'INTERNAL_LIVING_AREA',
  INTERNAL_KITCHEN = 'INTERNAL_KITCHEN',
  INTERNAL_SLEEPING_AREA = 'INTERNAL_SLEEPING_AREA',
  OPERATING_HOURS = 'OPERATING_HOURS',
  REGO_PAPERS = 'REGO_PAPERS',
  BANK_STATEMENTS = 'BANK_STATEMENTS',
  ROADWORTHY_CERT = 'ROADWORTHY_CERT',
  SAFETY_CERT = 'SAFETY_CERT',
  PAYOUT_LETTER = 'PAYOUT_LETTER',
  OWNERSHIP_PROOF = 'OWNERSHIP_PROOF',
  PPSR = 'PPSR',
  RAV = 'RAV',
  NEVDIS = 'NEVDIS',
  GLASSES = 'GLASSES'
}

export enum RoleEnum {
  ADMIN = 'ADMIN',
  STANDARD = 'STANDARD'
}

export enum CreateInspectionStepEnum {
  Lender,
  Documents,
  Seller,
  Review
}
export enum CreateInvoiceStepEnum {
  Seller,
  Buyer,
  Vehicle
}

export enum SellerPage {
  welcome,
  vehicle,
  registration,
  license,
  bank,
  cert,
  payoutLetter,
  ownershipProof,
  driverLicenseCapture,
  driverLicense,
  VIN,
  Review,
  Plate,
  registrationDetails,
  bankDetails,
  certDetails
}
export type cardCaptureType =
  | CardCaptureStepEnum.vin
  | CardCaptureStepEnum.plateNumber
  | CardCaptureStepEnum.odometer
  | CardCaptureStepEnum.vehiclePhoto
  | CardCaptureStepEnum.assetFront
  | CardCaptureStepEnum.assetLeft
  | CardCaptureStepEnum.assetBack
  | CardCaptureStepEnum.assetRight
  | CardCaptureStepEnum.internalFront
  | CardCaptureStepEnum.internalBack
  | CardCaptureStepEnum.internalBoot
  | CardCaptureStepEnum.internalLivingArea
  | CardCaptureStepEnum.internalKitchen
  | CardCaptureStepEnum.internalSleepingArea
  | CardCaptureStepEnum.operatingHours
  | DocumentCaptureStepEnum.bank
  | DocumentCaptureStepEnum.payoutLetter
  | DocumentCaptureStepEnum.ownershipProof;
export type documentCaptureType =
  | DocumentCaptureStepEnum.bank
  | DocumentCaptureStepEnum.cert
  | DocumentCaptureStepEnum.registration
  | DocumentCaptureStepEnum.safetyCert
  | DocumentCaptureStepEnum.payoutLetter
  | DocumentCaptureStepEnum.ownershipProof;
export enum SellerPageTitle {
  Blank = '',
  vehicle = 'Vehicle',
  VIN = 'VIN',
  VinDetails = 'VIN Details',
  Review = 'Review',
  Plate = 'Number Plate',
  PlateDetails = 'Number Plate Details',
  Odometer = 'Odometer',
  OperatingHours = 'Operating Hours',
  OdometerDetails = 'Odometer Details',
  VehiclePhotos = 'Photos',
  ExternalPhotos = 'External Photos',
  InternalPhotos = 'Internal Photos',
  VehicleFrontSide = 'Front',
  VehicleFrontSeats = 'Front Seats',
  VehicleFrontDetails = 'Vehicle Front',
  VehicleBackSide = 'Back',
  VehicleBackSeats = 'Back Seats',
  VehicleBackDetails = 'Vehicle Back',
  VehicleBoot = 'Boot',
  VehicleBootDetails = 'Boot',
  VehicleLivingArea = 'Living Area',
  VehicleLivingAreaDetails = 'Living Area',
  VehicleKitchen = 'Kitchen',
  VehicleKitchenDetails = 'Kitchen',
  VehicleSleepingArea = 'Sleeping Area',
  VehicleSleepingAreaDetails = 'Sleeping Area',
  VehicleDriverSide = 'Vehicle Driver Side',
  VehicleDriverDetails = 'Vehicle Driver Side',
  VehiclePassengerSide = 'Vehicle Passenger Side',
  VehiclePassengerDetails = 'Vehicle Passenger Side',
  registration = 'Registration Certificate',
  registrationDetails = 'Registration Certificate Details',
  license = 'Licence',
  bank = 'Bank Statement',
  bankDetails = 'Bank Statement Details',
  cert = 'Roadworthy / Safety Certificate',
  certDetails = 'Roadworthy / Safety Certificate Details',
  payoutLetter = 'Payout Letter',
  payoutLetterDetails = 'Payout Letter Details',
  ownershipProof = 'Proof of ownership',
  ownershipProofDetails = 'Proof of ownership',
  safetyCert = 'Safety Certificate',
  safetyCertDetails = 'Safety Certificate Details'
}
export type SellerPageTitleType =
  | SellerPageTitle.Odometer
  | SellerPageTitle.OdometerDetails
  | SellerPageTitle.VehiclePhotos
  | SellerPageTitle.ExternalPhotos
  | SellerPageTitle.InternalPhotos
  | SellerPageTitle.VehicleFrontSide
  | SellerPageTitle.VehicleBackSide
  | SellerPageTitle.VehicleDriverSide
  | SellerPageTitle.VehiclePassengerSide
  | SellerPageTitle.VinDetails
  | SellerPageTitle.OperatingHours
  | SellerPageTitle.PlateDetails
  | SellerPageTitle.Blank
  | SellerPageTitle.vehicle
  | SellerPageTitle.registration
  | SellerPageTitle.license
  | SellerPageTitle.bank
  | SellerPageTitle.cert
  | SellerPageTitle.VIN
  | SellerPageTitle.Review
  | SellerPageTitle.Plate
  | SellerPageTitle.registrationDetails
  | SellerPageTitle.bankDetails
  | SellerPageTitle.certDetails
  | SellerPageTitle.safetyCert
  | SellerPageTitle.safetyCertDetails
  | SellerPageTitle.VehicleFrontDetails
  | SellerPageTitle.VehicleBackDetails
  | SellerPageTitle.VehicleDriverDetails
  | SellerPageTitle.VehiclePassengerDetails
  | SellerPageTitle.VehicleFrontSeats
  | SellerPageTitle.VehicleBackSeats
  | SellerPageTitle.VehicleBoot
  | SellerPageTitle.VehicleBootDetails
  | SellerPageTitle.VehicleLivingArea
  | SellerPageTitle.VehicleLivingAreaDetails
  | SellerPageTitle.VehicleKitchen
  | SellerPageTitle.VehicleKitchenDetails
  | SellerPageTitle.VehicleSleepingArea
  | SellerPageTitle.VehicleSleepingAreaDetails
  | SellerPageTitle.payoutLetter
  | SellerPageTitle.payoutLetterDetails
  | SellerPageTitle.ownershipProof
  | SellerPageTitle.ownershipProofDetails;

export type confirmModalType =
  | CardCaptureStepEnum.vin
  | CardCaptureStepEnum.plateNumber
  | DocumentCaptureStepEnum.bank
  | DocumentCaptureStepEnum.registration
  | DocumentCaptureStepEnum.safetyCert
  | NewInspectionModalEnum.close
  | NewInspectionModalEnum.draft
  | NewInspectionModalEnum.submit;
export enum CardCaptureStepEnum {
  sellerId = 'SELLER_ID',
  vin = 'VIN',
  plateNumber = 'REGO_PLATE',
  operatingHours = 'OPERATING_HOURS',
  odometer = 'ODOMETER',
  assetFront = 'ASSET_FRONT',
  assetLeft = 'ASSET_LEFT',
  assetBack = 'ASSET_BACK',
  assetRight = 'ASSET_RIGHT',
  vehiclePhoto = 'VEHICLE_PHOTO',
  internalFront = 'INTERNAL_FRONT',
  internalBack = 'INTERNAL_BACK',
  internalBoot = 'INTERNAL_BOOT',
  internalLivingArea = 'INTERNAL_LIVING_AREA',
  internalKitchen = 'INTERNAL_KITCHEN',
  internalSleepingArea = 'INTERNAL_SLEEPING_AREA'
}
export enum NewInspectionModalEnum {
  close = 'close',
  draft = 'draft',
  submit = 'submit'
}
export enum DocumentCaptureStepEnum {
  registration = 'REGO_PAPERS',
  bank = 'BANK_STATEMENTS',
  cert = 'ROADWORTHY_CERT',
  safetyCert = 'SAFETY_CERT',
  payoutLetter = 'PAYOUT_LETTER',
  ppsr = 'PPSR',
  ownershipProof = 'OWNERSHIP_PROOF'
}

export enum InspectionDriverLicenseSection {
  DRIVER_LICENCE_FRONT = 'ID Front',
  DRIVER_LICENCE_BACK = 'ID Back',
  SELLER_ID = 'Identification',
  FACE = 'Selfie',
  licenseExpiry = 'ID Expiry',
  licenseEdits = 'Data Edit',
  licenseFaceMatch = 'Face Match',
  licenseLiveness = 'Liveness',
  licenseAuthenticity = 'Authenticity'
}

export enum InspectionVehicleDetailsSection {
  rav = 'Compliance',
  ppsr = 'Financial interest (PPSR)',
  registration = 'NEVDIS Registration',
  stolen = 'Stolen Check',
  writtenOff = 'Written-off Check',
  vin = 'VIN & Number Plate',
  VIN = 'VIN',
  REGO_PLATE = 'Number Plate',
  ODOMETER = 'Odometer',
  ASSET_FRONT = 'Vehicle Front',
  ASSET_BACK = 'Vehicle Back',
  ASSET_RIGHT = "Driver's Side",
  ASSET_LEFT = 'Passenger Side',
  INTERNAL_FRONT = 'Front Seats',
  INTERNAL_BACK = 'Back Seats',
  INTERNAL_BOOT = 'Boot',
  INTERNAL_LIVING_AREA = 'Living Area',
  INTERNAL_KITCHEN = 'Kitchen',
  INTERNAL_SLEEPING_AREA = 'Sleeping Area',
  OPERATING_HOURS = 'Operating Hours',
  vehiclePhotos = 'Photos Check',
  dataEdits = 'Data Edits'
}
export enum InspectionRegoPapersSection {
  registration = 'Registration',
  REGO_PAPERS = 'Registration',
  registrationAuthenticity = 'Authenticity',
  expiry = 'Match Reg. Expiry Date',
  regoCertificate = 'Data Edit'
}
export enum DocumentFormEnum {
  DETAILS_DRIVER_LICENSE_FRONT = 'DETAILS_DRIVER_LICENSE_FRONT',
  DETAILS_DRIVER_LICENSE_BACK = 'DETAILS_DRIVER_LICENSE_BACK',
  DETAILS_DRIVER_LICENSE_SELFIE = 'DETAILS_DRIVER_LICENSE_SELFIE',
  DETAILS_DRIVER_LICENSE_AUTHENTICITY = 'LICENSE_AUTHENTICITY',
  DETAILS_DRIVER_LICENSE_EXPIRY = 'LICENSE_EXPIRY',
  DETAILS_DRIVER_LICENSE_DATA_EDIT = 'LICENSE_EDITS',
  DETAILS_DRIVER_LICENSE_FACE_MATCH = 'LICENSE_FACE_MATCH',
  DETAILS_DRIVER_LICENSE_LIVENESS = 'LICENSE_LIVENESS',
  DETAILS_DRIVER_LICENSE_OFFICIAL_SOURCE = 'DETAILS_DRIVER_LICENSE_OFFICIAL_SOURCE',
  DETAILS_BANK = 'DETAILS_BANK',
  DETAILS_CERTIFICATE = 'DETAILS_CERTIFICATE',
  DETAILS_PAYOUT_LETTER = 'DETAILS_PAYOUT_LETTER',
  DETAILS_OWNERSHIP_PROOF = 'DETAILS_OWNERSHIP_PROOF',
  DETAILS_REGO_PHOTO_BACK = 'DETAILS_REGO_PHOTO_BACK',
  DETAILS_REGO_PHOTO_FRONT = 'DETAILS_REGO_PHOTO_FRONT',
  DETAILS_VEHICLE_ASSET_BACK = 'DETAILS_VEHICLE_ASSET_BACK',
  DETAILS_VEHICLE_ASSET_FRONT = 'DETAILS_VEHICLE_ASSET_FRONT',
  DETAILS_VEHICLE_INTERNAL_FRONT = 'DETAILS_VEHICLE_INTERNAL_FRONT',
  DETAILS_VEHICLE_INTERNAL_BACK = 'DETAILS_VEHICLE_INTERNAL_BACK',
  DETAILS_VEHICLE_INTERNAL_BOOT = 'DETAILS_VEHICLE_INTERNAL_BOOT',
  DETAILS_VEHICLE_INTERNAL_LIVING_AREA = 'DETAILS_VEHICLE_INTERNAL_LIVING_AREA',
  DETAILS_VEHICLE_INTERNAL_KITCHEN = 'DETAILS_VEHICLE_INTERNAL_KITCHEN',
  DETAILS_VEHICLE_INTERNAL_SLEEPING_AREA = 'DETAILS_VEHICLE_INTERNAL_SLEEPING_AREA',
  DETAILS_VEHICLE_OPERATING_HOURS = 'DETAILS_VEHICLE_OPERATING_HOURS',
  DETAILS_VEHICLE_DRIVER_SIDE = 'DETAILS_VEHICLE_DRIVER_SIDE',
  DETAILS_VEHICLE_ODOMETER = 'DETAILS_VEHICLE_ODOMETER',
  DETAILS_VEHICLE_PASSENGER_SIDE = 'DETAILS_VEHICLE_PASSENGER_SIDE',
  DETAILS_VEHICLE_PLATE = 'VEHICLE_PLATE',
  DETAILS_VEHICLE_VIN = 'VEHICLE_VIN',
  DETAILS_VEHICLE_PHOTO = 'VEHICLE_PHOTO'
}
export enum NevdisSecurityCheckEnum {
  NEVDIS_SECURITY_RAV = 'rav',
  NEVDIS_SECURITY_PPSR = 'ppsr',
  NEVDIS_SECURITY_REGISTRATION = 'registration',
  NEVDIS_SECURITY_STOLEN = 'stolen',
  NEVDIS_SECURITY_WRITTEN_OFF = 'writtenOff',
  NEVDIS_SECURITY_VIN = 'vin',
  NEVDIS_SECURITY_PHOTO = 'vehiclePhotos',
  NEVDIS_SECURITY_DATA_EDITS = 'dataEdits'
}
export enum RegoSecurityCheckEnum {
  REGO_SECURITY_AUTHENTICITY = 'registrationAuthenticity',
  REGO_SECURITY_EXPIRY = 'expiry',
  REGO_SECURITY_DATA_EDIT = 'regoCertificate'
}
export enum BankSecurityCheckEnum {
  BANK_SECURITY_AUTHENTICITY = 'bankAuthenticity',
  BANK_SECURITY_DATA_EDIT = 'bankStatement',
  BANK_STATEMENT_ADDRESS = 'BANK_STATEMENT_ADDRESS'
}
export enum InspectionBankSection {
  BANK_STATEMENTS = 'Statement Header',
  bankAuthenticity = 'Authenticity',
  bankStatement = 'Data Edit',
  BANK_STATEMENT_ADDRESS = 'Match Rego Cert. Address'
}
export enum VehiclePhoneMappings {
  'ASSET_FRONT' = 1,
  'ASSET_RIGHT' = 2,
  'ASSET_LEFT' = 3,
  'ASSET_BACK' = 4
}
export enum InspectionRoadworthyCertSection {
  ROADWORTHY_CERT = 'Roadworthy / Safety Certificate',
  SAFETY_CERT = 'Safety Certificate'
}
export enum InspectionPayoutLetterSection {
  PAYOUT_LETTER = 'Payout Letter'
}
export enum InspectionOwnershipProofSection {
  OWNERSHIP_PROOF = 'Proof of ownership'
}
export type DocumentType =
  | InspectionDriverLicenseSection
  | InspectionVehicleDetailsSection
  | InspectionRegoPapersSection
  | InspectionRoadworthyCertSection
  | InspectionBankSection
  | InspectionPayoutLetterSection
  | InspectionOwnershipProofSection;
export type DocumentFormType =
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_FRONT
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_BACK
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_SELFIE
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_AUTHENTICITY
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_EXPIRY
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_DATA_EDIT
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_FACE_MATCH
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_LIVENESS
  | DocumentFormEnum.DETAILS_DRIVER_LICENSE_OFFICIAL_SOURCE
  | DocumentFormEnum.DETAILS_BANK
  | DocumentFormEnum.DETAILS_CERTIFICATE
  | DocumentFormEnum.DETAILS_REGO_PHOTO_BACK
  | DocumentFormEnum.DETAILS_REGO_PHOTO_FRONT
  | DocumentFormEnum.DETAILS_VEHICLE_ASSET_BACK
  | DocumentFormEnum.DETAILS_VEHICLE_ASSET_FRONT
  | DocumentFormEnum.DETAILS_VEHICLE_DRIVER_SIDE
  | DocumentFormEnum.DETAILS_VEHICLE_INTERNAL_FRONT
  | DocumentFormEnum.DETAILS_VEHICLE_INTERNAL_BACK
  | DocumentFormEnum.DETAILS_VEHICLE_INTERNAL_BOOT
  | DocumentFormEnum.DETAILS_VEHICLE_INTERNAL_LIVING_AREA
  | DocumentFormEnum.DETAILS_VEHICLE_INTERNAL_KITCHEN
  | DocumentFormEnum.DETAILS_VEHICLE_INTERNAL_SLEEPING_AREA
  | DocumentFormEnum.DETAILS_VEHICLE_ODOMETER
  | DocumentFormEnum.DETAILS_VEHICLE_PASSENGER_SIDE
  | DocumentFormEnum.DETAILS_VEHICLE_PLATE
  | DocumentFormEnum.DETAILS_VEHICLE_VIN
  | DocumentFormEnum.DETAILS_VEHICLE_PHOTO
  | DocumentFormEnum.DETAILS_PAYOUT_LETTER
  | DocumentFormEnum.DETAILS_OWNERSHIP_PROOF
  | DocumentFormEnum.DETAILS_VEHICLE_OPERATING_HOURS
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_RAV
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_PPSR
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_REGISTRATION
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_STOLEN
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_WRITTEN_OFF
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_VIN
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_PHOTO
  | NevdisSecurityCheckEnum.NEVDIS_SECURITY_DATA_EDITS
  | RegoSecurityCheckEnum.REGO_SECURITY_AUTHENTICITY
  | RegoSecurityCheckEnum.REGO_SECURITY_EXPIRY
  | BankSecurityCheckEnum.BANK_SECURITY_AUTHENTICITY
  | BankSecurityCheckEnum.BANK_SECURITY_DATA_EDIT
  | BankSecurityCheckEnum.BANK_STATEMENT_ADDRESS
  | RegoSecurityCheckEnum.REGO_SECURITY_DATA_EDIT;
