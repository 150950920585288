export const LOGIN = '/api/demoLogin';
export const AUTH_LOGIN = '/api/token/login';
export const REFRESH_TOKEN = '/api/token/refreshToken';
export const MFA_SETUP = '/api/initiateMfaSetup';
export const MFA_LOGIN = '/api/token/mfaLogin';
export const VERIFY_MFA_SETUP = '/api/verifyMfaSetup';
export const SET_MFA_SETTING = '/api/setMfaSetting';
export const CURRENT_USER = '/api/getCurrentUser';
export const UPDATE_USER = '/api/UpdateCurrentUser';
export const UPDATE_USER_PROFILE = '/api/updateCurrentUser';
export const FORGET_PASSWORD = '/api/token/startPasswordReset';
export const GET_PASSWORD_RESET = '/api/token/getPasswordReset';
export const COMPLETE_PASSWORD_RESET = '/api/token/completePasswordReset';
export const START_INSPECTION_LOGIN = '/api/token/startInspectionLogin';
export const COMPLETE_INSPECTION_LOGIN = '/api/token/completeInspectionLogin';

//Session Handover
export const START_SESSION_HANDOVER = '/api/startSessionHandover';
export const COMPLETE_SESSION_HANDOVER = '/api/token/completeSessionHandover';

//Invite User
export const USER_INVITE = '/api/token/getUserInvite';
export const ACCEPT_USER_INVITE = '/api/token/acceptUserInvite';
export const GET_INSPECTION_INVITE = '/api/token/getInspectionInvite';
export const UPDATE_LENDER_USER = '/api/updateLenderUser';
export const REMOVE_LENDER_USER = '/api/removeLenderUser';

//Lender
export const LIST_LENDER_USERS = '/api/listLenderUsers';
export const ADD_LENDER_USER = '/api/addLenderUser';
export const ADD_LENDER_DOCUMENT = '/api/setLenderDocuments';
export const LIST_LENDER_DOCUMENT = '/api/getLenderDocuments';
export const UPDATE_LENDER_ORGANISATION = '/api/updateLenderOrganisation';
export const GET_LENDER_ORGANISATION = '/api/getLenderOrganisation';
export const LIST_INSPECTION_TEMPLATES = '/api/listInspectionTemplates';
export const GET_INSPECTION_TEMPLATE = '/api/getInspectionTemplate';
export const CREATE_INSPECTION_TEMPLATE = '/api/createInspectionTemplate';

//Broker
export const LIST_LENDER_BROKERS = '/api/listLenderBrokers';
export const REMOVE_LENDER_BROKER = '/api/removeLenderBroker';
export const ADD_LENDER_BROKER = '/api/addLenderBroker';
export const UPDATE_LENDER_BROKER = '/api/updateLenderBroker';

//Inspection
export const CREATE_INSPECTION = '/api/createInspection';
export const CREATE_INSPECTION_DOCUMENT = '/api/createInspectionDocument';
export const GET_INSPECTION = '/api/getInspection';
export const GET_USER_VERIFICATION = '/api/getUserVerification';
export const GET_LENDER_INSPECTIONS = '/api/listLenderInspections';
export const GET_MY_INSPECTION = '/api/listMyInspections';
export const SEARCH_ADDRESS = '/api/searchAddress';
export const GET_ADDRESS = '/api/getAddress';
export const GET_INSPECTION_CHECK = '/api/getInspectionChecks';
export const GET_INSPECTION_COMMENTS = '/api/listInspectionComments';
export const GET_INSPECTION_FILES = '/api/listInspectionFiles';
export const GET_INSPECTION_FILE = '/api/getInspectionFile';
export const ADD_INSPECTION_COMMENT = '/api/addInspectionComment';
export const UPDATE_INSPECTION = '/api/updateInspection';
export const SEND_INSPECTION_INVITE = '/api/sendInspectionInvite';
export const UPDATE_INSPECTION_TEMPLATE = '/api/updateInspectionTemplate';
export const DELETE_INSPECTION_TEMPLATE = '/api/deleteInspectionTemplate';
export const GET_INSPECTION_REPORT = '/api/getInspectionReport';
export const SUBMIT_INSPECTION = '/api/submitInspection';
export const COMPLETE_INSPECTION = '/api/completeInspection';
export const GET_INSPECTION_FILE_OCR = '/api/getInspectionFileOcr';
export const GET_INSPECTION_PPSR = '/api/getInspectionPpsr';
export const LIST_INVITES = '/api/listInspectionInvites';
//Asset Verification
export const CREATE_AV = '/api/createAssetVerification';
export const LIST_AV = '/api/listAssetVerifications';
export const AV_DETAILS = '/api/getAssetVerification';
export const GET_ASSET_REPORT = '/api/getAssetReport';
export const REQUEST_INSPECTION_DOCUMENTS = '/api/requestInspectionDocuments';
//Seller
export const ADD_INSPECTION_FILE = '/api/addInspectionFile';
export const UPDATE_INSPECTION_FILE = '/api/updateInspectionFile';
export const SET_INSPECTION_DOCUMENT = '/api/setInspectionDocument';
export const GET_REPORT_DOWNLOAD = '/api/token/getReportDownload';
export const START_REPORT_DOWNLOAD = '/api/token/startReportDownload';
export const COMPLETE_USER_VERIFICATION = '/api/completeUserVerification';
