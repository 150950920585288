import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button
} from '@material-tailwind/react';
import { ReactComponent as ChevronDownIcon } from '@assets/icons/chevron_down_icon.svg';
import { ReactComponent as SettingIcon } from '@assets/icons/setting_icon.svg';
import { ReactComponent as UserIcon } from '@assets/icons/Profile.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/log_out_icon.svg';
import { ReactComponent as UserManagement } from '@assets/icons/user_management.svg';
import { ReactComponent as Users } from '@assets/icons/Users.svg';
import { ReactComponent as Lender } from '@assets/icons/Lender.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthStore } from '@stores';

const ProfileMenu = () => {
  const { user, lenders } = useAuthStore();
  const navigator = useNavigate();
  const email = localStorage.getItem('email');
  const [isLender, setIsLender] = useState(false);
  const onLogout = () => {
    localStorage.clear();
  };
  const [roles, setRoles] = useState<string[]>([]);
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const manageRoles = localStorage.getItem(`lenders_${userId}` as any);
      if (manageRoles) {
        if (JSON.parse(manageRoles) && JSON.parse(manageRoles).length > 0) {
          setIsLender(true);
          setRoles(JSON.parse(manageRoles)[0].roles);
        }
      }
    } else {
      navigator('login');
    }
  }, []);
  return (
    <Menu placement="bottom-end">
      <MenuHandler>
        <Button className="flex items-center gap-2 p-2" variant="text">
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center"
            style={{ backgroundColor: '#' + (user.avatarColour || 'DD3434') }}>
            <span
              className={`text-white ${
                !user?.initials ||
                (user?.initials && user?.initials?.length === 4)
                  ? 'text-xs'
                  : 'text-sm'
              } font-semibold`}>
              {user?.initials || user?.firstName?.[0] + user?.lastName?.[0]}
            </span>
          </div>
          <ChevronDownIcon className="text-grey-700" />
        </Button>
      </MenuHandler>
      <MenuList className="p-1">
        <div className="flex items-center gap-2 p-2 bg-gray-100 rounded-md">
          <div
            className="w-10 h-10 rounded-full flex items-center justify-center"
            style={{ backgroundColor: '#' + (user.avatarColour || 'DD3434') }}>
            <span
              className={`text-white ${
                !user?.initials ||
                (user?.initials && user?.initials?.length === 4)
                  ? 'text-xs'
                  : 'text-sm'
              } font-semibold`}>
              {user.initials || user?.firstName?.[0] + user?.lastName?.[0]}
            </span>
          </div>
          <div className="text-left">
            <p className="text-grey-800 font-semibold capitalize text-base">
              {user?.firstName + ' ' + user?.lastName}
            </p>
            <p className="text-grey-800">{email}</p>
          </div>
        </div>

        <Link to="/profile">
          <MenuItem className="flex items-center text-grey-900 gap-2 font-medium px-2 py-3">
            <UserIcon />
            Profile
          </MenuItem>
        </Link>
        {roles.includes('ADMIN') && (
          <Link to="/settings">
            <MenuItem className="flex items-center text-grey-900 gap-2 font-medium px-2 py-3">
              <SettingIcon />
              Settings
            </MenuItem>
          </Link>
        )}
        {isLender && (
          <>
            <Link to="/users">
              <MenuItem className="flex items-center text-grey-900 gap-2 font-medium px-2 py-3">
                <Users />
                Users
              </MenuItem>
            </Link>
            <Link to="/broker">
              <MenuItem className="flex items-center text-grey-900 gap-2 font-medium px-2 py-3">
                <UserManagement />
                Brokers
              </MenuItem>
            </Link>
          </>
        )}
        {lenders.length > 1 && (
          <Link to="/lender-select">
            <MenuItem className="flex items-center text-grey-900 gap-2 font-medium px-2 py-3">
              <Lender />
              Change Lender
            </MenuItem>
          </Link>
        )}
        <Link to="/login" onClick={onLogout}>
          <MenuItem className="flex items-center text-grey-900 gap-2 font-medium px-2 py-3">
            <LogoutIcon />
            Log out
          </MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
