/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Input from '@components/forms/Input';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@components/forms/Button';
import { ReactComponent as MobileIcon } from '@assets/icons/mobile_icon.svg';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/arrow_grey_right_icon.svg';
import {
  post,
  AUTH_LOGIN,
  CURRENT_USER,
  GET_LENDER_ORGANISATION,
  MFA_SETUP,
  VERIFY_MFA_SETUP,
  MFA_LOGIN
} from '@services';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import clsx from 'clsx';
import Loader from '@components/widgets/Loader';
import { useAuthStore } from '@stores';
import { parseISO } from 'date-fns';
import LenderSelect from './components/LenderSelect';

const loginFormSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
    rememberFor30Days: yup.boolean(),
    mfaCode: yup.string(),
    mfaSetupCode: yup.string()
  })
  .required();
const Login = () => {
  const {
    register,
    watch,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberFor30Days: false,
      mfaCode: '',
      mfaSetupCode: ''
    }
  });
  const { user, setUser, lenders, setSelectLender, setLenders } =
    useAuthStore();
  const navigate = useNavigate();
  const { email, password } = watch();
  const [showMFA, setShowMFA] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [secretCode, setSecretCode] = useState('');
  const [rememberFor30Days, setRememberFor30Days] = useState(false);
  const [svg, setSVG] = useState(
    window.atob(localStorage.getItem('qrCodeAsBase64') || '')
  );
  const handleRememberFor30DaysChange = () => {
    setRememberFor30Days(!rememberFor30Days);
  };
  const handleSetup = () => {
    setShowMFA(3);
    setUpCurrentUser(false);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const apiExpiry = localStorage.getItem('expiry');
    const longExpiry = localStorage.getItem('longExpiry');
    const rememberMe = localStorage.getItem('rememberMe') === 'true';
    const refreshToken = localStorage.getItem('refreshToken');
    const now = new Date();
    const isTokenExpired = (expiry: string) => {
      const expiryDate = Number(expiry) || parseISO(expiry).getTime();
      return now.getTime() > expiryDate;
    };
    const refreshAccessToken = async () => {
      try {
        const refreshResponse = await post('REFRESH_TOKEN', { refreshToken });
        localStorage.setItem('accessToken', refreshResponse.accessToken);
        localStorage.setItem('expiry', refreshResponse.expiry);
        navigate('/'); // Redirect to home page after successful refresh
      } catch (error) {
        console.error('Failed to refresh token:', error);
      }
    };

    if (
      rememberMe &&
      longExpiry &&
      now.getTime() < Number(longExpiry) &&
      apiExpiry
    ) {
      if (isTokenExpired(apiExpiry)) {
        // If "Remember Me" is valid but API token is expired, try to refresh the token
        refreshAccessToken();
      } else {
        // "Remember Me" is valid and API token is not expired
        navigate('/');
      }
    } else if (accessToken && apiExpiry && !isTokenExpired(apiExpiry)) {
      // If only the short-term API token is valid, also redirect to the home page
      // navigate('/');
    } else {
      // If all tokens are invalid or expired, clear local storage and stay on the login page
      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiry');
      localStorage.removeItem('longExpiry');
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('refreshToken');
    }
  }, []);

  const setUpCurrentUser = (redirect: boolean) => {
    post(CURRENT_USER, {}).then((resp) => {
      if (resp.user) {
        setUser(resp.user);
        localStorage.setItem('userId', resp.user.userId);
        localStorage.setItem('lastLogin', resp.user.lastLogin);
        localStorage.setItem('email', resp.user.email);
        localStorage.setItem('firstName', resp.user.firstName);
        localStorage.setItem('lastName', resp.user.lastName);
      }
      const userId = resp.user.userId;
      if (resp.verifiers) {
        localStorage.setItem(
          `verifiers_${userId}`,
          JSON.stringify(resp.verifiers)
        );
      }
      if (resp.system) {
        localStorage.setItem(`roles_${userId}`, resp.system.roles);
      }
      if (resp.lenderBrokers && resp.lenderBrokers.length > 0) {
        localStorage.setItem(
          `lenderBrokers_${userId}`,
          JSON.stringify(resp.lenderBrokers)
        );
        setLoading(false);
        if (redirect) {
          navigate('/');
        }
      }
      if (resp.lenders) {
        setLenders(resp.lenders);
        setLoading(false);
        if (resp.lenders.length === 1) {
          localStorage.setItem(
            `lenders_${userId}`,
            JSON.stringify(resp.lenders)
          );

          setSelectLender(resp.lenders[0]);
          post(
            GET_LENDER_ORGANISATION,
            { organisationId: resp.lenders[0].organisationId },
            false
          )
            .then((data) => {
              if (data) {
                setSelectLender({
                  ...resp.lenders[0],
                  hasInspections: data.hasInspections || true,
                  hasAssetVerifications: data.hasAssetVerifications || true
                });
              }
            })
            .catch((e) => {
              console.error(e);
            })
            .finally(() => {
              if (redirect) {
                navigate('/');
              }
            });
        } else if (resp.lenders.length > 1) {
          if (redirect) {
            setShowMFA(5);
          }
        } else {
          if (redirect) {
            navigate('/');
          }
        }
      }
    });
  };
  const onSubmit = () => {
    localStorage.setItem('email', email);
    setLoading(true);
    post(AUTH_LOGIN, { email, password }, false)
      .then(async (response) => {
        const {
          accessToken,
          refreshToken,
          expiry,
          clientId,
          id,
          challengeName,
          session
        } = response;
        setLoading(false);
        if (rememberFor30Days) {
          localStorage.setItem('rememberFor30Days', 'true');
        } else {
          localStorage.removeItem('rememberFor30Days');
        }
        //save accessToken
        if (session && challengeName) {
          localStorage.setItem('challengeName', challengeName);
          localStorage.setItem('session', session);
          setShowMFA(4);
        } else if (accessToken && id) {
          setUser(response);
          if (clientId) {
            localStorage.setItem('clientId', clientId);
          }
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('expiry', expiry);
          localStorage.setItem('id', id);
          setShowMFA(2);
          post(MFA_SETUP, {}, false).then((response) => {
            if (response.secretCode && response.qrCodeAsBase64) {
              localStorage.setItem('secretCode', response.secretCode);
              setSecretCode(response.secretCode);
              localStorage.setItem('qrCodeAsBase64', response.qrCodeAsBase64);
              setSVG(window.atob(response.qrCodeAsBase64));
            }
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setError('password', {
          type: 'custom',
          message: 'The account or password is not correct.'
        });
      });
  };
  const handleMailChange = (e: any) => {
    const value = e.target.value;
    setValue('email', value);
  };
  const handlePwdChange = (e: any) => {
    const value = e.target.value;
    setValue('password', value);
  };
  const handleVerify = (e: any) => {
    e.preventDefault();
    const email = getValues('email');
    const session = localStorage.getItem('session');
    const mfaCode = getValues('mfaCode');
    setLoading(false);
    post(MFA_LOGIN, { email, mfaToken: mfaCode, session }, false)
      .then((response) => {
        setLoading(true);
        if (response) {
          localStorage.setItem('accessToken', response.accessToken);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('expiry', response.expiry);
          if (response.clientId) {
            localStorage.setItem('clientId', response.clientId);
          }
          setUpCurrentUser(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setError('mfaCode', {
          type: 'custom',
          message: 'The authentication code is invalid, please try again.'
        });
      });
  };
  const handleSelectLender = (lender: any) => {
    localStorage.setItem(`lenders_${user.userId}`, JSON.stringify([lender]));
    setSelectLender(lender);
    navigate('/');
  };
  if (isLoading) return <Loader />;
  switch (showMFA) {
    case 1:
      return (
        <div className="mx-auto w-full md:w-[440px] bg-white px-8 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
          <form
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(onSubmit)}>
            <div className="gap-2 flex flex-col">
              <h3 className="text-2xl text-grey-900 font-semibold text-center">
                Log in
              </h3>
              <p className="text-center text-grey-600">
                Welcome! Please enter your details.
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <label className="flex flex-col">
                <span className="text-sm font-medium text-grey-700">Email</span>
                <Input
                  {...register(`email`)}
                  placeholder="Enter your email"
                  icon={errors.email && <ErrorIcon />}
                  value={email}
                  onChange={handleMailChange}
                />
                {errors.email && (
                  <span className="text-error-500 text-2sm mt-[6px]">
                    {errors.email.message}
                  </span>
                )}
              </label>
              <label className="flex flex-col">
                <span className="text-sm font-medium text-grey-700">
                  Password
                </span>
                <Input
                  type="password"
                  {...register(`password`)}
                  placeholder="Enter your password"
                  icon={errors.password && <ErrorIcon />}
                  value={password}
                  onChange={handlePwdChange}
                />
                {errors.password && (
                  <span className="text-error-500 text-2sm mt-[6px]">
                    {errors.password.message}
                  </span>
                )}
              </label>
            </div>
            <div className="flex justify-end items-center py-2">
              <Link
                to="/forgot-password"
                className="text-blue-600 font-semibold text-sm">
                Forgot password
              </Link>
            </div>
            <div className="flex flex-col justify-center">
              <Button
                type="submit"
                className="w-full bg-primary text-white text-md font-semibold normal">
                Sign in
              </Button>
            </div>
          </form>
        </div>
      );
    case 2:
      return (
        <div className="mx-auto bg-white w-full md:w-[1116px] px-8 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative flex flex-cols">
          <TwoFactorAuth
            secretCode={secretCode}
            svg={svg}
            redirect={handleSetup}
          />
        </div>
      );
    case 3:
      return (
        <div className="mx-auto w-full md:w-[500px] bg-white px-8 md:px-10 py-12 rounded-xl shadow-paper relative gap-8">
          <div className="flex flex-col gap-2">
            <h3 className="text-3xl font-semibold text-gray-800 text-black">
              Two-factor authentication is enabled for your account
            </h3>
            <p className="text-grey-700">
              We’ll ask for an authentication code any time you log in with your
              email and password
            </p>
          </div>
          <div className="flex mt-8">
            <button
              className="px-4 py-2 bg-primary text-white text-base font-semibold rounded-md shadow-sm"
              onClick={() => navigate('/')}>
              Continue
            </button>
          </div>
        </div>
      );
    case 4:
      return (
        <div className="mx-auto w-full md:w-[500px] bg-white px-[40px] py-[50px] rounded-xl shadow-paper relative flex flex-col gap-8">
          <div className="flex justify-center items-center">
            <MobileIcon />
          </div>
          <div className="flex flex-col gap-6">
            <h3 className="text-3xl font-semibold text-gray-800 text-black">
              Enter authentication code
            </h3>
            <p className="text-grey-700">
              Enter the 6-digit code generated by your authentication app.
            </p>
          </div>
          <form
            onSubmit={handleVerify}
            className="flex items-center flex-col md:flex-row justify-center md:justify-between gap-4">
            <div className="w-full">
              <Input
                className={clsx(
                  'text-gray-600 shadow-none ring-none border focus:!border-none w-full rounded-lg max-w-[300px]',
                  errors.mfaCode && '!border-error-300 '
                )}
                autoComplete="off"
                icon={errors.mfaCode && <ErrorIcon />}
                placeholder="374364"
                {...register(`mfaCode`)}
              />
              {errors.mfaCode && (
                <span className="text-error-500 text-2sm mt-[6px]">
                  {errors.mfaCode?.message?.toString()}
                </span>
              )}
            </div>
            <Button
              type="submit"
              className="px-4 py-2 bg-primary text-white text-base font-semibold w-full md:w-auto rounded-md shadow-sm items-start self-start !overflow-visible">
              Continue
            </Button>
          </form>
        </div>
      );
    case 5:
      return <LenderSelect />;
    default:
      return null;
  }
};
type Props = {
  svg: string;
  secretCode: string;
  redirect: () => void;
};
const TwoFactorAuth: React.FC<Props> = ({ svg, secretCode, redirect }) => {
  const {
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const { mfaSetupToken } = watch();
  const handleSetupCodeChange = (e: any) => {
    clearErrors('mfaSetupToken');
    const value = e.target.value;
    setValue('mfaSetupToken', value);
  };
  const handleSetup = (e: any) => {
    e.preventDefault();
    post(VERIFY_MFA_SETUP, { mfaToken: mfaSetupToken }, false)
      .then(() => {
        redirect();
      })
      .catch(() => {
        setError('mfaSetupToken', {
          type: 'custom',
          message: 'The 6-digit code is invalid, please try again.'
        });
      });
  };
  return (
    <>
      <div className="flex flex-col space-y-6 w-full">
        <div className="text-gray-600 flex w-full">
          <ol className="flex flex-col gap-6 text-grey-700 w-full">
            <li className="pb-6 w-full">
              <h3 className="text-3xl font-semibold text-gray-800 text-black text-balance">
                Two-factor authentication is required for your account
              </h3>
            </li>
            <li className="flex gap-6 pb-4 border-b whitespace-wrap w-full">
              <span className="text-grey-900 whitespace-nowrap font-semibold">
                Step 1
              </span>
              <span>
                Open up your authentication app on you phone. If you don't
                already have one, we recommend{' '}
                <span className="text-primary-light font-medium">
                  Google Authenticator
                </span>{' '}
                or{' '}
                <span className="text-primary-light font-medium">
                  Microsoft Authenticator
                </span>
                .
              </span>
            </li>
            <li className="flex gap-6 pb-4 border-b whitespace-wrap w-full">
              <span className="text-grey-900 whitespace-nowrap font-semibold">
                Step 2
              </span>
              <span>
                <span className="text-grey-700">
                  <span className="hidden lg:block">
                    Using the app, scan the QR code on the right or manually
                    enter this key:
                  </span>
                  <span className="hidden lg:block break-words break-all font-semibold">
                    {secretCode}.
                  </span>
                  <span className="block lg:hidden">
                    Using the app, manually enter this key:
                    <span className="break-words break-all font-semibold">
                      {secretCode}
                    </span>
                    .
                  </span>
                </span>
              </span>
            </li>
            <li className="flex gap-6 whitespace-wrap w-full">
              <span className="text-grey-900 whitespace-nowrap font-semibold">
                Step 3
              </span>
              <div className="flex w-full flex-col gap-4">
                <span className="text-grey-700">
                  Enter the 6-digit code generated by your authentication app.
                </span>
                <form
                  onSubmit={handleSetup}
                  className="flex flex-col md:flex-row items-start justify-start md:justify-between gap-4">
                  <div className="flex flex-col w-full">
                    <Input
                      className={clsx(
                        'text-gray-600 shadow-none ring-none focus:!border-none w-full',
                        errors.mfaSetupToken &&
                          'border !border-error-300 rounded-lg'
                      )}
                      icon={errors.mfaSetupToken && <ErrorIcon />}
                      placeholder="123-456"
                      value={mfaSetupToken}
                      {...register('mfaSetupToken')}
                      onChange={handleSetupCodeChange}
                    />
                    {errors.mfaSetupToken && (
                      <span className="text-error-500 text-2sm mt-[6px]">
                        {errors.mfaSetupToken?.message?.toString()}
                      </span>
                    )}
                  </div>
                  <Button
                    type="submit"
                    className="px-3 py-2 bg-primary text-white text-base font-semibold rounded-md shadow-sm w-full md:w-auto items-start">
                    Enable
                  </Button>
                </form>
              </div>
            </li>
          </ol>
        </div>
      </div>
      <div className="hidden items-center lg:flex">
        <div
          className="w-[280px] h-[280px]"
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      </div>
    </>
  );
};
export default Login;
