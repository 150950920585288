/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect } from 'react';
import { Welcome } from './components/WelcomePage';
import {
  InspectionStatusEnum,
  LenderDocumentTypeEnum,
  SellerPage
} from '@types';
import { License } from './components/License';
import { Vehicle } from './components/Vehicle';
import { Register } from './components/Register';
import { Bank } from './components/Bank';
import { Cert } from './components/Cert';
import { PayoutLetter } from './components/PayoutLetter';
import { ProofOwnership } from './components/ProofOwnership';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import { useOrgStore, useSellerStore, useAuthStore } from '@stores';
import { useNavigate, useParams } from 'react-router-dom';
import _groupBy from 'lodash/groupBy';
import _maxBy from 'lodash/maxBy';
import _flatMap from 'lodash/flatMap';
import {
  GET_INSPECTION,
  GET_INSPECTION_CHECK,
  GET_INSPECTION_FILES,
  GET_LENDER_ORGANISATION,
  CURRENT_USER,
  COMPLETE_SESSION_HANDOVER,
  post,
  SUBMIT_INSPECTION
} from '@services';
import _intersection from 'lodash/intersection';
import _indexOf from 'lodash/indexOf';
import Loader from '@components/widgets/Loader';
import { VEHICLE_PHOTO_LIST } from '@constants';
import { isDesktop, isTablet } from 'react-device-detect';

const SellerHome = () => {
  const [page, setPage] = useState(SellerPage.welcome);
  const {
    activePageTitle,
    status,
    driverLicense,
    vinObj,
    numberPlate,
    odometer,
    vehicleBack,
    vehicleFront,
    vehiclePassenger,
    vehicleDriver,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    vehicleLivingArea,
    vehicleKitchen,
    vehicleSleepingArea,
    operatingHours,
    vehicle,
    registration,
    bank,
    cert,
    payoutLetter,
    ownershipProof,
    setData,
    setVehiclePosition,
    setVinStatus,
    setNumberPlateStatus,
    setOdometerStatus,
    setOperatingHoursStatus,
    setExternalPhotosStatus,
    setVehicleFrontStatus,
    setVehicleDriverStatus,
    setVehiclePassengerStatus,
    setVehicleBackStatus,
    setVehicleFrontSeatsStatus,
    setVehicleBackSeatsStatus,
    setVehicleBootStatus,
    setVehicleStatus,
    setRegistrationStatus,
    setDriverLicenseStatus,
    setBankStatus,
    setCertStatus,
    setPayoutLetterStatus,
    setOwnershipProofStatus,
    setHasDriverLicense,
    setDriverLicenseAvailableStatus,
    setVinAvailableStatus,
    setNumberPlateAvailableStatus,
    setOdometerAvailableStatus,
    setOperatingHoursAvailableStatus,
    setCertAvailableStatus,
    setPayoutLetterAvailableStatus,
    setOwnershipProofAvailableStatus,
    setRegistrationAvailableStatus,
    setBankAvailableStatus,
    setVehicleAvailableStatus,
    setExternalPhotosAvailableStatus,
    setVehicleFrontAvailableStatus,
    setVehicleDriverAvailableStatus,
    setVehiclePassengerAvailableStatus,
    setVehicleBackAvailableStatus,
    setVehicleFrontSeatsAvailableStatus,
    setVehicleBackSeatsAvailableStatus,
    setVehicleBootAvailableStatus,
    setVehiclePhotoList,
    reset,
    setVinFile,
    setPlateNumberFile,
    setOdoFile,
    setOperatingHoursFile,
    setCertFile,
    setPayoutLetterFile,
    setOwnershipProofFile,
    setRegoFile,
    setBankFile,
    setDriverFile,
    setPassengerFile,
    setFrontFile,
    setBackFile,
    setFrontSeatsFile,
    setBackSeatsFile,
    setBootFile,
    setLivingAreaFile,
    setKitchenFile,
    setSleepingAreaFile,
    setVehicleLivingAreaStatus,
    setVehicleKitchenStatus,
    setVehicleSleepingAreaStatus,
    setVehicleLivingAreaAvailableStatus,
    setVehicleKitchenAvailableStatus,
    setVehicleSleepingAreaAvailableStatus,
    setStatus,
    setInspectionChecks
  } = useSellerStore();
  const params = useParams();
  const { organisation, setOrg } = useOrgStore();
  const [isLoading, setLoading] = useState(true);
  const { setUser } = useAuthStore();
  const userId = localStorage.getItem('userId');
  const lenderUser = localStorage.getItem(`lenders_${userId}` as any);
  const getLatestFiles = (files: any) => {
    const groupedFiles = _groupBy(files, 'documentType');

    const latestFiles = _flatMap(groupedFiles, (group) =>
      _maxBy(group, 'createdTime')
    );

    return latestFiles;
  };
  const hasAllDocumentTypes = (files: any, documentTypes: string[]) => {
    if (files.length === 0 || documentTypes.length === 0) {
      return false;
    }
    const foundDocumentTypes = files.map((file: any) => file.documentType);
    return documentTypes.every((type) => foundDocumentTypes.includes(type));
  };
  const navigate = useNavigate();
  const [availableItems, setAvailableItems] = useState<any[]>();
  const urlParams = new URLSearchParams(location.search);
  const email = urlParams.get('email');
  const [isHandoverLoading, setIsHandoverLoading] = useState(false);
  const hash = location.hash.slice(1); // Remove the '#' character

  useEffect(() => {
    reset();
    const fetchData = async () => {
      if (params.id) {
        setLoading(true);
        let lenderOrganisationId = organisation
          ? organisation.organisationId
          : lenderUser && JSON.parse(lenderUser)[0].organisationId;
        if (email && hash) {
          setIsHandoverLoading(true);
          const res = await post(COMPLETE_SESSION_HANDOVER, {
            sessionToken: hash,
            email
          });
          localStorage.setItem('accessToken', res.accessToken);
          localStorage.setItem('expiry', res.expiry);
          if (res.clientId) {
            localStorage.setItem('clientId', res.clientId);
          }
          const resp = await post(CURRENT_USER, {}, false);
          if (resp.user) {
            setUser(resp.user);
            localStorage.setItem('userId', resp.user.userId);
            localStorage.setItem('lastLogin', resp.user.lastLogin);
            localStorage.setItem('email', resp.user.email);
            localStorage.setItem('firstName', resp.user.firstName);
            localStorage.setItem('lastName', resp.user.lastName);
          }
          const userId = resp.user.userId;
          if (resp.verifiers) {
            localStorage.setItem(
              `verifiers_${userId}`,
              JSON.stringify(resp.verifiers)
            );
          }
          if (resp.lenders) {
            localStorage.setItem(
              `lenders_${userId}`,
              JSON.stringify(resp.lenders)
            );
            lenderOrganisationId = lenderOrganisationId
              ? lenderOrganisationId
              : resp.lenders[0].organisationId &&
                resp.lenders[0].organisationId;
          }
          if (resp.lenderBrokers && resp.lenderBrokers.length > 0) {
            localStorage.setItem(
              `lenderBrokers_${userId}`,
              JSON.stringify(resp.lenderBrokers)
            );
          }
          if (resp.system) {
            localStorage.setItem(`roles_${userId}`, resp.system.roles);
          }
        }

        post(GET_INSPECTION, { inspectionId: params.id }).then((res) => {
          setLoading(false);
          if (res && res.inspection) {
            setData(res.inspection);
            post(
              GET_LENDER_ORGANISATION,
              { organisationId: res.inspection.lender.organisationId },
              false
            )
              .then((data) => {
                if (data) {
                  setOrg(data);
                }
              })
              .catch((e) => {
                console.error(e);
              });
            if (res.inspection.status === InspectionStatusEnum.Completed) {
              navigate(
                '/seller/complete?friendlyId=' + res.inspection.friendlyId
              );
            }
            post(GET_INSPECTION_CHECK, { inspectionId: params.id })
              .then((data) => {
                if (
                  data &&
                  data.inspectionChecks &&
                  Object.keys(data.inspectionChecks).length !== 0
                ) {
                  setInspectionChecks(data.inspectionChecks);
                }
              })
              .catch((e) => {
                console.error(e);
              });
            const validationItems: any[] = [];
            if (
              res.inspection.documentTypes &&
              res.inspection.documentTypes.length > 0
            ) {
              const verificationPhotos = _intersection(
                VEHICLE_PHOTO_LIST,
                res.inspection.documentTypes
              );
              if (verificationPhotos.length > 0) {
                const vehicleIndex = _indexOf(
                  VEHICLE_PHOTO_LIST,
                  verificationPhotos[0]
                );
                setVehiclePosition({
                  vehiclePosition: vehicleIndex + 1
                });
              }
              setVehiclePhotoList(verificationPhotos);
            }
            const documentTypeMapping = {
              [LenderDocumentTypeEnum.SELLER_ID]: {
                setAvailable: setDriverLicenseAvailableStatus,
                additionalSet: setHasDriverLicense,
                item: driverLicense
              },
              [LenderDocumentTypeEnum.VIN]: {
                setAvailable: setVinAvailableStatus,
                item: vinObj
              },
              [LenderDocumentTypeEnum.REGO_PLATE]: {
                setAvailable: setNumberPlateAvailableStatus,
                item: numberPlate
              },
              [LenderDocumentTypeEnum.ODOMETER]: {
                setAvailable: setOdometerAvailableStatus,
                item: odometer
              },
              [LenderDocumentTypeEnum.OPERATING_HOURS]: {
                setAvailable: setOperatingHoursAvailableStatus,
                item: operatingHours
              },
              [LenderDocumentTypeEnum.ASSET_BACK]: {
                setAvailable: setVehicleBackAvailableStatus,
                item: vehicleBack
              },
              [LenderDocumentTypeEnum.ASSET_FRONT]: {
                setAvailable: setVehicleFrontAvailableStatus,
                item: vehicleFront
              },
              [LenderDocumentTypeEnum.ASSET_LEFT]: {
                setAvailable: setVehiclePassengerAvailableStatus,
                item: vehiclePassenger
              },
              [LenderDocumentTypeEnum.ASSET_RIGHT]: {
                setAvailable: setVehicleDriverAvailableStatus,
                item: vehicleDriver
              },
              [LenderDocumentTypeEnum.INTERNAL_FRONT]: {
                setAvailable: setVehicleFrontSeatsAvailableStatus,
                item: vehicleFrontSeats
              },
              [LenderDocumentTypeEnum.INTERNAL_BACK]: {
                setAvailable: setVehicleBackSeatsAvailableStatus,
                item: vehicleBackSeats
              },
              [LenderDocumentTypeEnum.INTERNAL_BOOT]: {
                setAvailable: setVehicleBootAvailableStatus,
                item: vehicleBoot
              },

              [LenderDocumentTypeEnum.INTERNAL_LIVING_AREA]: {
                setAvailable: setVehicleLivingAreaAvailableStatus,
                item: vehicleLivingArea
              },
              [LenderDocumentTypeEnum.INTERNAL_KITCHEN]: {
                setAvailable: setVehicleKitchenAvailableStatus,
                item: vehicleKitchen
              },
              [LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA]: {
                setAvailable: setVehicleSleepingAreaAvailableStatus,
                item: vehicleSleepingArea
              },

              [LenderDocumentTypeEnum.ROADWORTHY_CERT]: {
                setAvailable: setCertAvailableStatus,
                item: cert
              },
              [LenderDocumentTypeEnum.PAYOUT_LETTER]: {
                setAvailable: setPayoutLetterAvailableStatus,
                item: payoutLetter
              },
              [LenderDocumentTypeEnum.OWNERSHIP_PROOF]: {
                setAvailable: setOwnershipProofAvailableStatus,
                item: ownershipProof
              },
              [LenderDocumentTypeEnum.REGO_PAPERS]: {
                setAvailable: setRegistrationAvailableStatus,
                item: registration
              },
              [LenderDocumentTypeEnum.BANK_STATEMENTS]: {
                setAvailable: setBankAvailableStatus,
                item: bank
              }
            };

            const vehicleDocTypes = [
              LenderDocumentTypeEnum.ASSET_FRONT,
              LenderDocumentTypeEnum.ASSET_BACK,

              LenderDocumentTypeEnum.ASSET_LEFT,
              LenderDocumentTypeEnum.ASSET_RIGHT
            ];
            const externalPhotosTypes = [
              LenderDocumentTypeEnum.ODOMETER,
              LenderDocumentTypeEnum.INTERNAL_FRONT,
              LenderDocumentTypeEnum.INTERNAL_BACK,
              LenderDocumentTypeEnum.INTERNAL_BOOT,
              LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
              LenderDocumentTypeEnum.INTERNAL_KITCHEN,
              LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA
            ];

            if (res.inspection.documentTypes?.length > 0) {
              // Process regular document types
              Object.entries(documentTypeMapping).forEach(
                ([docType, config]: any) => {
                  if (res.inspection.documentTypes.includes(docType)) {
                    config.setAvailable(true);
                    if (config.additionalSet) {
                      config.additionalSet(true);
                    }
                    validationItems.push(config.item);
                  }
                }
              );

              // Process vehicle documents
              if (
                vehicleDocTypes.some((type) =>
                  res.inspection.documentTypes.includes(type)
                )
              ) {
                setVehicleAvailableStatus(true);
                // validationItems.push(vehicle);
              }
              // Process vehicle documents
              if (
                externalPhotosTypes.some((type) =>
                  res.inspection.documentTypes.includes(type)
                )
              ) {
                setExternalPhotosAvailableStatus(true);
                // validationItems.push(externalPhotos);
              }
            }

            setAvailableItems(validationItems);
            const vehiclePhotos = [
              LenderDocumentTypeEnum.ASSET_BACK,
              LenderDocumentTypeEnum.ASSET_FRONT,
              LenderDocumentTypeEnum.ASSET_LEFT,
              LenderDocumentTypeEnum.ASSET_RIGHT
            ];
            const externalPhotos = [
              LenderDocumentTypeEnum.ODOMETER,
              LenderDocumentTypeEnum.INTERNAL_FRONT,
              LenderDocumentTypeEnum.INTERNAL_BACK,
              LenderDocumentTypeEnum.INTERNAL_BOOT,
              LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
              LenderDocumentTypeEnum.INTERNAL_KITCHEN,
              LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA
            ];
            const externalPhotosRequired = _intersection(
              externalPhotos,
              res.inspection.documentTypes
            );
            const vehicleRequired = _intersection(
              vehiclePhotos,
              res.inspection.documentTypes
            );
            if (
              res.inspection?.documents?.ROADWORTHY_CERT?.needsResubmit ===
              false
            ) {
              setCertStatus(true);
            }
            if (
              res.inspection?.documents?.PAYOUT_LETTER?.needsResubmit === false
            ) {
              setPayoutLetterStatus(true);
            }
            if (
              res.inspection?.documents?.OWNERSHIP_PROOF?.needsResubmit ===
              false
            ) {
              setOwnershipProofStatus(true);
            }
            post(GET_INSPECTION_FILES, { inspectionId: params.id }, false)
              .then((data) => {
                if (data && data.files) {
                  const files = getLatestFiles(data.files);
                  if (files.length > 0) {
                    files.forEach((file: any) => {
                      if (
                        [
                          'SELLER_ID',
                          'DRIVER_LICENCE_FRONT',
                          'DRIVER_LICENCE_BACK',
                          'FACE'
                        ].includes(file.documentType)
                      ) {
                        setDriverLicenseStatus(true);
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.VIN
                      ) {
                        if (
                          res.inspection?.documents?.VIN?.fileId &&
                          !res.inspection?.documents?.VIN?.needsResubmit
                        ) {
                          setVinStatus(true);
                        }
                        setVinFile(file);
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.REGO_PLATE
                      ) {
                        if (
                          res.inspection?.documents?.REGO_PLATE?.fileId &&
                          !res.inspection?.documents?.REGO_PLATE?.needsResubmit
                        ) {
                          setNumberPlateStatus(true);
                        }
                        setPlateNumberFile(file);
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.OPERATING_HOURS
                      ) {
                        if (
                          res.inspection?.documents?.OPERATING_HOURS?.fileId &&
                          !res.inspection?.documents?.OPERATING_HOURS
                            ?.needsResubmit
                        ) {
                          setOperatingHoursStatus(true);
                        }
                        setOperatingHoursFile(file);
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.ODOMETER
                      ) {
                        if (
                          res.inspection?.documents?.ODOMETER?.fileId &&
                          !res.inspection?.documents?.ODOMETER?.needsResubmit
                        ) {
                          setOdometerStatus(true);
                        }
                        setOdoFile(file);
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.ROADWORTHY_CERT
                      ) {
                        if (
                          !res.inspection?.documents?.ROADWORTHY_CERT
                            ?.needsResubmit
                        ) {
                          setCertStatus(true);
                        }
                        setCertFile(file);
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.PAYOUT_LETTER
                      ) {
                        if (
                          !res.inspection?.documents?.PAYOUT_LETTER
                            ?.needsResubmit
                        ) {
                          setPayoutLetterStatus(true);
                        }
                        setPayoutLetterFile(file);
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.OWNERSHIP_PROOF
                      ) {
                        if (
                          !res.inspection?.documents?.OWNERSHIP_PROOF
                            ?.needsResubmit
                        ) {
                          setOwnershipProofStatus(true);
                        }
                        setOwnershipProofFile(file);
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.REGO_PAPERS
                      ) {
                        if (
                          file.fields2 &&
                          !res.inspection?.documents?.REGO_PAPERS?.needsResubmit
                        ) {
                          setRegistrationStatus(true);
                        }
                        setRegoFile(file);
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.BANK_STATEMENTS
                      ) {
                        if (
                          file.fields2 &&
                          !res.inspection?.documents?.BANK_STATEMENTS
                            ?.needsResubmit
                        ) {
                          setBankStatus(true);
                        }
                        setBankFile(file);
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.ASSET_FRONT
                      ) {
                        setFrontFile(file);
                        if (
                          !res.inspection?.documents?.ASSET_FRONT?.needsResubmit
                        ) {
                          setVehicleFrontStatus(true);
                        }
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.ASSET_BACK
                      ) {
                        setBackFile(file);
                        if (
                          !res.inspection?.documents?.ASSET_BACK?.needsResubmit
                        ) {
                          setVehicleBackStatus(true);
                        }
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.INTERNAL_FRONT
                      ) {
                        setFrontSeatsFile(file);
                        if (
                          !res.inspection?.documents?.INTERNAL_FRONT
                            ?.needsResubmit
                        ) {
                          setVehicleFrontSeatsStatus(true);
                        }
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.INTERNAL_BACK
                      ) {
                        setBackSeatsFile(file);
                        if (
                          !res.inspection?.documents?.INTERNAL_BACK
                            ?.needsResubmit
                        ) {
                          setVehicleBackSeatsStatus(true);
                        }
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.INTERNAL_BOOT
                      ) {
                        setBootFile(file);
                        if (
                          !res.inspection?.documents?.INTERNAL_BOOT
                            ?.needsResubmit
                        ) {
                          setVehicleBootStatus(true);
                        }
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.INTERNAL_LIVING_AREA
                      ) {
                        setLivingAreaFile(file);
                        if (
                          !res.inspection?.documents?.INTERNAL_LIVING_AREA
                            ?.needsResubmit
                        ) {
                          setVehicleLivingAreaStatus(true);
                        }
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.INTERNAL_KITCHEN
                      ) {
                        setKitchenFile(file);
                        if (
                          !res.inspection?.documents?.INTERNAL_KITCHEN
                            ?.needsResubmit
                        ) {
                          setVehicleKitchenStatus(true);
                        }
                      } else if (
                        file.documentType ===
                        LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA
                      ) {
                        setSleepingAreaFile(file);
                        if (
                          !res.inspection?.documents?.INTERNAL_SLEEPING_AREA
                            ?.needsResubmit
                        ) {
                          setVehicleSleepingAreaStatus(true);
                        }
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.ASSET_LEFT
                      ) {
                        setPassengerFile(file);
                        if (
                          !res.inspection?.documents?.ASSET_LEFT?.needsResubmit
                        ) {
                          setVehiclePassengerStatus(true);
                        }
                      } else if (
                        file.documentType === LenderDocumentTypeEnum.ASSET_RIGHT
                      ) {
                        setDriverFile(file);
                        if (
                          !res.inspection?.documents?.ASSET_RIGHT?.needsResubmit
                        ) {
                          setVehicleDriverStatus(true);
                        }
                      }
                      if (
                        res.inspection?.documents?.ROADWORTHY_CERT
                          ?.needsResubmit === false
                      ) {
                        setCertStatus(true);
                      }
                      if (
                        res.inspection?.documents?.PAYOUT_LETTER
                          ?.needsResubmit === false
                      ) {
                        setPayoutLetterStatus(true);
                      }
                      if (
                        res.inspection?.documents?.OWNERSHIP_PROOF
                          ?.needsResubmit === false
                      ) {
                        setOwnershipProofStatus(true);
                      }
                      const vehicleStatus = hasAllDocumentTypes(
                        files,
                        vehicleRequired
                      );
                      const externalPhotosStatus = hasAllDocumentTypes(
                        files,
                        externalPhotosRequired
                      );
                      setVehicleStatus(vehicleStatus);
                      setExternalPhotosStatus(externalPhotosStatus);
                    });
                  }
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }
        });
      }
    };
    fetchData();
  }, [params.id]);
  useEffect(() => {
    const checkAllIsDone = () => {
      if (
        !availableItems?.some((item) => !item.isDone) &&
        !availableItems?.some((item) => item.formStatus && !item.formStatus) &&
        ![
          InspectionStatusEnum.Completed,
          InspectionStatusEnum.Submitted
        ].includes(status)
      ) {
        setLoading(true);
        post(SUBMIT_INSPECTION, { inspectionId: params.id }, false)
          .then(() => {
            setLoading(false);
            setStatus(InspectionStatusEnum.Submitted);
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
      }
    };
    if (availableItems && availableItems.length > 0) {
      checkAllIsDone();
    }
  }, [
    vinObj.isDone,
    numberPlate.isDone,
    odometer.isDone,
    vehicleFront.isDone,
    vehicleBack.isDone,
    vehicleDriver.isDone,
    vehiclePassenger.isDone,
    vehicleFrontSeats.isDone,
    vehicleBackSeats.isDone,
    vehicleBoot.isDone,
    registration.isDone,
    bank.isDone,
    cert.isDone
  ]);
  if (isLoading)
    return <Loader bgColor="bg-gray-50" bgOpacity="bg-opacity-100" />;
  return (
    <>
      {page !== SellerPage.welcome && (
        <div className={isDesktop ? 'w-[600px] mx-auto' : ''}>
          {!isDesktop ? (
            <div className="border-b bg-white flex items-center w-full h-18 p-4 z-100 absolute">
              <div className="flex items-center justify-between w-full">
                <div
                  className="cursor-pointer flex"
                  onClick={() => {
                    // TODO: a temporary workaround for a frontend issue with the image capture process
                    window.location.reload();
                  }}>
                  <LeftIcon className="mr-2 rotate-180" />
                </div>
                <p className="text-grey-900 font-semibold">{activePageTitle}</p>
                <div className="w-8"></div>
              </div>
            </div>
          ) : (
            <div className="mt-[56px] ">
              <div
                className="cursor-pointer flex"
                onClick={() => {
                  // TODO: a temporary workaround for a frontend issue with the image capture process
                  window.location.reload();
                }}>
                <LeftIcon className="mr-2 rotate-180" />
                <div>Back</div>
              </div>
            </div>
          )}

          <div
            className={`w-full ${
              isTablet ? 'min-height-content-md' : 'min-height-content'
            }  h-full flex flex-col items-start no-scrollbar overflow-y-hidden tablet-portrait:w-[361px] tablet-portrait:mx-auto tablet-portrait:px-0 ${
              !isDesktop ? 'mt-[56px] pt-[12px] px-4' : ''
            }`}>
            {page === SellerPage.license && <License setPage={setPage} />}
            {page === SellerPage.vehicle && <Vehicle setPage={setPage} />}
            {page === SellerPage.registration && <Register setPage={setPage} />}
            {page === SellerPage.bank && <Bank setPage={setPage} />}
            {page === SellerPage.cert && <Cert setPage={setPage} />}
            {page === SellerPage.payoutLetter && (
              <PayoutLetter setPage={setPage} />
            )}
            {page === SellerPage.ownershipProof && (
              <ProofOwnership setPage={setPage} />
            )}
          </div>
        </div>
      )}

      {page === SellerPage.welcome && (
        <div className="container py-8">
          <Welcome setPage={setPage} />{' '}
        </div>
      )}
    </>
  );
};

export default SellerHome;
