import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
interface BrokerFilterStoreState {
  query: string;
  status: string;
  setQuery: (data: any) => void;
  brokerListData: any[];
  setStatus: (data: any) => void;
  setBrokerList: (data: any) => void;
  clearBrokerList: () => void;
}

export const useBrokerStore = create<BrokerFilterStoreState>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      (set) => ({
        query: '',
        status: 'all',
        brokerListData: [],
        setQuery: (data) => set({ query: data }),
        setStatus: (data) => set({ status: data }),
        setBrokerList: (data) => set({ brokerListData: data }),
        clearBrokerList: () => {
          sessionStorage.removeItem('Broker');
          set({ brokerListData: [] });
        }
      }),
      {
        name: 'Broker',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
