import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation
} from 'react-router-dom';
import AuthLayout from '@components/layouts/AuthLayout';
import PrivateLayout from '@components/layouts/PrivateLayout';
import AssetVerificationLayout from '@components/layouts/AssetVerificationLayout';
import Login from '@pages/auth/Login';
import ResetPassword from '@pages/auth/ResetPassword';
import ForgetPassword from '@pages/auth/ForgetPassword';
import ResetPasswordComplete from '@pages/auth/ResetPasswordComplete';
import Inspection from '@pages/Inspections';
import DemoInspection from '@pages/Inspections/DemoInspectionsIndex';
import InspectionCreate from '@pages/Inspections/create';
import NotFound from '@pages/NotFound';
import DetailView from '@pages/Inspections/DetailView';
import VerificationDetailView from '@pages/verifications/VerificationDetailView';
import Lender from '@pages/lender';
import Dashboard from '@pages/Dashboard';
import Verification from '@pages/verifications';
import SellerVerify from '@pages/auth/SellerVerify';
import SellerHome from '@pages/seller/Home';
import InviteUserSetup from '@pages/auth/InviteUserSetup';
import User from '@pages/user';
import Broker from '@pages/broker';
import SellerLayout from '@components/layouts/SellerLayout';
import SellerLogin from '@pages/seller/Login';
import SellerReport from '@pages/seller/Report';
import SellerComplete from '@pages/seller/Complete';
import LenderDocuments from '@pages/lender/LenderDocuments';
import Profile from '@pages/profile';
import AccountSetting from '@pages/profile/accountSetting';
import SellerCompleteLayout from '@components/layouts/SellerCompleteLayout';
import LenderBrokerLayout from '@components/layouts/LenderBrokerLayout';
import SellerErrorLogin from '@pages/seller/Error';
import ErrorHandler from './ErrorHandler';
import ProtectedRoute from '@components/ProtectedRoute';
import CreateTemplate from '@pages/lender/CreateTemplate';
import GenerateInvoice from '@pages/Inspections/generateVoice/GenerateInvoice';
import LenderSelect from '@pages/auth/components/LenderSelect';
import { recordPageView, recordError } from './utils/rumMonitoring';
import { useEffect } from 'react';
import { RouteGuard } from './RouteGuard';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

// Create a page view tracker component
function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    recordPageView(location.pathname);
  }, [location]);

  return null;
}



// Error boundary fallback component
function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div
      role="alert"
      className="flex flex-col items-center justify-center min-h-screen p-4">
      <h2 className="text-2xl font-bold text-red-600 mb-4">
        Something went wrong
      </h2>
      <pre className="text-sm bg-gray-100 p-4 rounded mb-4">
        {error.message}
      </pre>
      <button
        onClick={resetErrorBoundary}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
        Try again
      </button>
    </div>
  );
}

const App = () => {

  return (
    <Router>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error) => {
          const location = useLocation();
          recordError({
            error,
            location: location.pathname,
            userAgent: navigator.userAgent,
            timestamp: new Date().toISOString()
          });
        }}
        onReset={() => {
          // Reset the state of your app here
          window.location.href = '/';
        }}>
        <ErrorHandler />
        <PageViewTracker />
        <RouteGuard>
          <Routes>
            {/*<Route path="/" element={<Navigate to="/login" />} />*/}
            <Route path="/" element={<PrivateLayout />}>
              <Route path="" element={<Dashboard />} />
            </Route>

            <Route path="/" element={<AuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="lender-select" element={<LenderSelect />} />
              <Route path="register" element={<InviteUserSetup />} />
              <Route path="broker/register" element={<InviteUserSetup />} />
              <Route path="forgot-password" element={<ForgetPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route
                path="reset-password-complete"
                element={<ResetPasswordComplete />}
              />
              <Route path="seller-verify" element={<SellerVerify />} />
              <Route path="inviteUser" element={<InviteUserSetup />} />
            </Route>

            <Route path="/" element={<PrivateLayout />}>
              <Route path="lenders" element={<Lender />} />
              <Route path="profile" element={<Profile />} />
              <Route
                path="settings"
                element={
                  <ProtectedRoute
                    element={<AccountSetting />}
                    allowedRoles={['ADMIN', 'STANDARD']}
                    redirectPath="/"
                  />
                }
              />
              <Route
                path="inspection-templates"
                element={
                  <ProtectedRoute
                    element={<LenderDocuments />}
                    allowedRoles={['ADMIN']} // Specify the roles that can access this page
                    redirectPath="/"
                  />
                }
              />
              <Route
                path="users"
                element={
                  <ProtectedRoute
                    element={<User />}
                    allowedRoles={['ADMIN', 'STANDARD']}
                    redirectPath="/"
                  />
                }
              />
              <Route
                path="broker"
                element={
                  <ProtectedRoute
                    element={<Broker />}
                    allowedRoles={['ADMIN', 'STANDARD']}
                    redirectPath="/"
                  />
                }
              />
              <Route path="demo/inspections" element={<DemoInspection />} />
              <Route path="inspections" element={<Inspection />} />
              <Route path="inspections/detail/:id" element={<DetailView />} />
              <Route
                path="demo/inspections/detail/:id"
                element={<DetailView />}
              />
              <Route
                path="verifications"
                element={
                  <ProtectedRoute
                    element={<Verification />}
                    allowedRoles={['ADMIN', 'STANDARD']}
                    redirectPath="/"
                  />
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/" element={<AssetVerificationLayout />}>
              <Route
                path="verifications/detail/:id"
                element={<VerificationDetailView />}
              />
            </Route>
            <Route path="inspections/create" element={<InspectionCreate />} />
            <Route path="template/create" element={<CreateTemplate />} />
            <Route
              path="inspections/generateInvoice/:id"
              element={<GenerateInvoice />}
            />
            <Route path="/seller" element={<SellerLayout />}>
              <Route path="home" element={<SellerHome />} />
              <Route path="detail/:id" element={<SellerHome />} />
            </Route>
            <Route path="/seller" element={<SellerCompleteLayout />}>
              <Route path="login" element={<SellerLogin />} />
              <Route path="complete" element={<SellerComplete />} />
              <Route path="not-found/:id" element={<SellerErrorLogin />} />
            </Route>
            <Route path="/" element={<SellerLayout />}>
              <Route path="inspection/:id" element={<SellerHome />} />
            </Route>
            <Route path="/" element={<LenderBrokerLayout />}>
              <Route path="report" element={<SellerReport />} />
            </Route>
            <Route path="/inspection" element={<SellerCompleteLayout />}>
              <Route path="login" element={<SellerLogin />} />
              <Route path="not-found/:id" element={<SellerErrorLogin />} />
            </Route>
          </Routes>
        </RouteGuard>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
