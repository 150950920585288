const LoadingComponent = ({ color = '#002861' }: { color: string }) => (
  <div className="flex flex-col justify-center items-center gap-8 w-full h-screen max-h-[calc(100vh-74px)] static bg-grey-50 mt-[-72px] overflow-hidden no-scrollbar">
    <svg
      width="73"
      height="82"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72.8848 33.8619C72.2382 20.8478 64.2635 8.68711 52.4091 3.14013C39.4771 -2.6202 23.7432 -0.273396 12.9665 8.68711C2.62089 17.2209 -2.12085 31.0884 0.896619 44.1024C3.69855 56.9031 13.8286 67.3571 26.5451 70.7706C41.848 75.0375 55.8577 67.7838 64.479 55.4097C56.7198 65.6503 44.8654 72.4774 31.7179 70.1306C18.5704 67.7838 7.79369 57.9699 4.77622 45.1692C1.54322 31.5151 8.00922 17.2209 20.2946 10.1805C33.0111 2.92678 50.9004 5.70027 59.3062 18.0743C61.4615 21.0611 62.9702 24.688 63.6168 28.3149C64.2635 31.3017 64.0479 34.5019 64.479 37.4887C64.9101 40.2622 67.2809 43.8891 70.5139 41.969C73.3159 40.2622 73.1003 36.6353 72.8848 33.8619C73.1003 35.3553 72.8848 33.0085 72.8848 33.8619Z"
        fill={color}>
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          from="0 36.5 36"
          to="360 36.5 36"
          dur="2s"
          repeatCount="indefinite"
        />
      </path>
    </svg>

    <p>Uploading…</p>
    <p className="mt-2 text-center w-2/3">
      Please ensure you have a reliable internet connection
    </p>
  </div>
);
export default LoadingComponent;
