import React, { useEffect, useState, useCallback } from 'react';
import { ReactComponent as RotateIcon } from '@assets/icons/rotate_icon.svg';
import Input from '@components/forms/Input';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';

import Button from '@components/forms/Button';
import { TableHeader } from '@components/widgets/Table';
import _isNil from 'lodash/isNil';
import { LenderStatusEnum, UserStatusEnum } from '@types';
import {
  post,
  LIST_LENDER_BROKERS,
  UPDATE_LENDER_USER,
  UPDATE_LENDER_BROKER,
  ADD_LENDER_BROKER,
  REMOVE_LENDER_BROKER
} from '@services';
import { useBrokerStore } from '@stores';
import { LENDER_STATUS_VARIANT } from '@constants';
import StatusChip from '@components/widgets/StatusChip';
import { format, parseISO } from 'date-fns';
import Table from '@components/widgets/Table';
import Modal from '@components/widgets/Modal';
import { ReactComponent as MoreVertIcon } from '@assets/icons/more-icon.svg';

import {
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList
} from '@material-tailwind/react';
import _debounce from 'lodash/debounce';

const LandingTable = () => {
  const resetMFA = (row: any) => {
    setRow(row);
    setOpenMFA(true);
  };
  const changeRole = (row: any) => {
    setRow(row);
    setOpenRole(true);
  };
  const removeAccess = (row: any) => {
    setRow(row);
    setOpen(true);
  };
  const resendInvitation = (row: any) => {
    setRow(row);
    setOpenResend(true);
  };

  const TABLE_HEAD: TableHeader[] = [
    {
      label: 'Username',
      field: 'email',
      contentClassName: 'text-grey-900 font-medium'
    },
    {
      label: 'Name',
      field: 'firstName',
      render: (row) =>
        row.firstName && row.lastName && row.firstName + ' ' + row.lastName
    },
    {
      label: 'Reference Number',
      field: 'lenderReference'
    },
    // {
    //   label: 'Role',
    //   field: 'roles',
    //   render: (row) =>
    //     row.roles && row.roles.length > 0
    //       ? row.roles.includes('ADMIN')
    //         ? 'Admin'
    //         : 'Standard'
    //       : 'Standard'
    // },
    {
      label: 'Last Login Date',
      field: 'lastLogin',
      render: (row) =>
        row.lastLogin && format(parseISO(row.lastLogin), 'dd/MM/yyyy h:mma')
    },
    {
      label: 'Status',
      field: 'status',
      render: (row) => (
        <StatusChip
          variant={LENDER_STATUS_VARIANT[row.status as LenderStatusEnum]}
          label={UserStatusEnum[row.status as keyof typeof UserStatusEnum]}
        />
      )
    }
  ];
  const [tableHeaders, setTableHeaders] = useState<TableHeader[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const manageRoles = localStorage.getItem(`lenders_${userId}` as any);
      if (manageRoles) {
        if (JSON.parse(manageRoles) && JSON.parse(manageRoles).length > 0) {
          setRoles(JSON.parse(manageRoles)[0].roles);
        }
      }
    }
  }, []);
  const { query, status, brokerListData, setBrokerList, clearBrokerList } =
    useBrokerStore();

  const [row, setRow] = useState<any>({});
  const [openResend, setOpenResend] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMFA, setOpenMFA] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [showRemoveMessage, setShowRemoveMessage] = useState(false);
  const [showResendMessage, setShowResendMessage] = useState(false);
  const [orderThan, setOrderThan] = useState<string | null>(null);
  const [visibleItems, setVisibleItems] = useState(40);
  const [originalBrokerList, setOriginalBrokerList] = useState<any[]>([]);
  const loadMore = () => {
    getLenderList();
    setVisibleItems((prevVisibleItems: any) => prevVisibleItems + 40);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCloseMFA = () => {
    setOpenMFA(false);
  };

  const onCloseResend = () => {
    setOpenResend(false);
  };
  const onConfirm = () => {
    post(REMOVE_LENDER_BROKER, {
      lenderOrganisationId: row.lenderOrganisationId,
      userId: row.userId
    }).then((data) => {
      setShowRemoveMessage(true);
      getLenderList();
      setTimeout(() => {
        setShowRemoveMessage(false);
        setOpen(false);
      }, 4000);
    });
  };
  const onConfirmResend = () => {
    post(ADD_LENDER_BROKER, {
      email: row.email,
      lenderOrganisationId: row.lenderOrganisationId,
      lenderReference: row.lenderReference
    }).then((data) => {
      setShowResendMessage(true);
      getLenderList();
      setTimeout(() => {
        setShowResendMessage(false);
        setOpenResend(false);
      }, 4000);
    });
  };
  const onConfirmMFA = () => {
    post(UPDATE_LENDER_BROKER, {
      userId: row.userId,
      lenderOrganisationId: row.lenderOrganisationId,
      isMfaEnabled: false
    }).then((data) => {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setOpenMFA(false);
      }, 4000);
    });
  };
  const onConfirmRole = useCallback(
    (role: any) => {
      post(UPDATE_LENDER_USER, {
        lenderOrganisationId: row.organisationId,
        userId: row.userId,
        roles: [role]
      })
        .then(() => {
          setShowSaveMessage(true);
          getLenderList();
          setTimeout(() => {
            setShowSaveMessage(false);
            setOpenRole(false);
          }, 4000);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating user role:', error);
        });
    },
    [row]
  );

  const debouncedGetLenderList = useCallback(
    _debounce((isQuery: boolean) => {
      const userId = localStorage.getItem('userId');
      const lenders = localStorage.getItem(`lenders_${userId}`);
      const lender = lenders != null && JSON.parse(lenders)[0];
      const organisationId = lender.organisationId;

      post(LIST_LENDER_BROKERS, {
        lenderOrganisationId: organisationId,
        nextToken: isQuery ? null : orderThan
      }).then((data) => {
        let keyword = query;
        let newData = data.lenderBrokers;
        const pattern = /[`~!@#$^&*()={}':;',\\]/g;
        keyword = keyword.replace(pattern, '');

        if (data.nextToken) {
          setOrderThan(data.nextToken);
        } else {
          setOrderThan(null);
        }
        if (!_isNil(keyword) && keyword !== '') {
          const pattern = new RegExp(keyword, 'i');
          newData = newData.filter((item: any) => {
            return pattern.test(item.email);
          });
        }
        if (status !== 'all' && newData.length > 0) {
          newData = newData.filter(
            (item: any) => item.status.toLowerCase() === status.toLowerCase()
          );
        }
        newData.forEach((e: any) => {
          e.isAdmin = lender.roles.includes('ADMIN');
        });

        setOriginalBrokerList((prevBrokerList: any) =>
          isQuery ? newData : [...prevBrokerList, ...newData]
        );
      });
    }, 600),
    [query, status, orderThan]
  );

  function getLenderList(isQuery: any = false) {
    debouncedGetLenderList(isQuery);
  }

  useEffect(() => {
    setBrokerList(originalBrokerList);
  }, [originalBrokerList]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const lenders = localStorage.getItem(`lenders_${userId}`);
    const lender = lenders != null && JSON.parse(lenders)[0];

    if (lender != null && lender?.roles?.includes('ADMIN')) {
      setTableHeaders([
        ...TABLE_HEAD,
        {
          label: '',
          field: 'action',
          render: (row) => (
            <>
              {row.userId === userId ? null : (
                <Menu>
                  <MenuHandler>
                    <IconButton variant="text">
                      <MoreVertIcon />
                    </IconButton>
                  </MenuHandler>
                  <MenuList>
                    {row.status != LenderStatusEnum.Pending &&
                    row.status != LenderStatusEnum.InvitationExpired ? (
                      <MenuItem
                        onClick={() => resetMFA(row)}
                        className="font-medium text-grey-700 text-sm">
                        Reset MFA
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => resendInvitation(row)}
                        className="font-medium text-grey-700 text-sm">
                        Resend Invitation
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={() => removeAccess(row)}
                      className="font-medium text-rose-700 text-sm">
                      Remove access
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </>
          )
        }
      ]);
    } else {
      setTableHeaders(TABLE_HEAD);
    }
    setOriginalBrokerList([]);

    setTimeout(() => {
      clearBrokerList();
      getLenderList(true);
    }, 100);

    return () => {
      debouncedGetLenderList.cancel();
    };
  }, [query, status]);

  const [openRole, setOpenRole] = useState(false);
  const onCloseRole = () => {
    setOpenRole(false);
  };

  return (
    <div className="pb-12">
      <Table headers={tableHeaders} data={brokerListData} />
      {orderThan && (
        <div className="flex justify-center pt-6">
          <Button
            className="bg-white border-grey-300 text-grey-600 text-sm flex items-center gap-2"
            variant="outlined"
            onClick={loadMore}>
            <RotateIcon />
            Load more
          </Button>
        </div>
      )}
      <ResetMFAModal
        open={openMFA}
        row={row}
        showSuccessMessage={showSuccessMessage}
        onClose={onCloseMFA}
        onConfirm={onConfirmMFA}
      />
      <RemoveAccessModal
        open={open}
        row={row}
        onClose={onClose}
        showSuccessMessage={showRemoveMessage}
        onConfirm={onConfirm}
      />
      <ChangeRoleModal
        open={openRole}
        row={row}
        showSuccessMessage={showSaveMessage}
        onClose={onCloseRole}
        onConfirm={(role: any) => onConfirmRole(role)}
      />
      <ResendInvitationModal
        open={openResend}
        row={row}
        showSuccessMessage={showResendMessage}
        onClose={onCloseResend}
        onConfirm={() => onConfirmResend()}
      />
    </div>
  );
};
const RemoveAccessModal = ({
  open,
  row,
  onClose,
  showSuccessMessage,
  onConfirm
}: {
  open: boolean;
  row: any;
  onClose: any;
  onConfirm: any;
  showSuccessMessage: boolean;
}) => {
  return (
    <Modal title="Remove Access" size="md" open={open} onClose={onClose}>
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col gap-[10px] text-[#475467]">
          <p>
            Are you sure you want to remove access for{' '}
            <span className="font-medium font-bold text-[#000000]">
              {row?.email}
            </span>{' '}
            from this broker organisation?
          </p>
        </div>
        <div className="mt-2">
          <div className="h-4 w-full text-right mb-4">
            {showSuccessMessage && (
              <div className="mt-4 text-green-500">Access removed!</div>
            )}
          </div>
          <div className="grid grid-cols-2 w-full gap-4 ">
            <Button
              variant="outlined"
              data-dialog-close="true"
              onClick={onClose}
              className="border bg-white border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
              Cancel
            </Button>
            <Button
              data-dialog-close="true"
              className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
              onClick={onConfirm}>
              Remove Access
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ChangeRoleModal = ({
  open,
  row,
  onClose,
  showSuccessMessage,
  onConfirm
}: {
  open: boolean;
  showSuccessMessage: boolean;
  row: any;
  onClose: any;
  onConfirm: any;
}) => {
  const role = row?.roles?.includes('ADMIN') ? 'ADMIN' : 'STANDARD';
  const email = row?.email || '';
  const [selectedRole, setSelectedRole] = useState(role);

  useEffect(() => {
    setSelectedRole(role);
  }, [role]);
  const handleRoleChange = (e: any) => {
    setSelectedRole(e);
  };
  return (
    <Modal title="Change Broker's Role" size="md" open={open} onClose={onClose}>
      <div className="flex flex-col gap-[10px]">
        <div className="mt-4">
          <p className="text-sm font-medium text-grey-700 mb-1.5">Email</p>
          <Input disabled placeholder="Email" type="email" value={email} />
        </div>
        <div className="mt-4">
          <p className="text-sm font-medium text-grey-700 mb-1.5">Role</p>
          <Select
            className="!h-10 lg:flex items-center text-sm leading-6 text-grey-400 rounded-md ring-1 ring-grey-900/10 shadow-sm hover:ring-grey-30 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
            value={selectedRole}
            onChange={handleRoleChange}
            selected={(element) =>
              element &&
              React.cloneElement(element, {
                className: 'flex items-center px-0 gap-2 pointer-events-none'
              })
            }>
            <Option
              key="admin"
              className="flex items-center gap-2"
              value={'ADMIN'}>
              <div>Admin</div>
            </Option>
            <Option
              key="standard"
              className="flex items-center gap-2"
              value={'STANDARD'}>
              <div>Standard</div>
            </Option>
          </Select>
        </div>
        <div className="mt-2">
          <div className="h-4 w-full text-right mb-4">
            {showSuccessMessage && (
              <div className="mt-4 text-green-500">Changes saved!</div>
            )}
          </div>
          <div className="flex gap-4 justify-center lg:justify-end">
            <Button
              variant="outlined"
              data-dialog-close="true"
              onClick={onClose}
              className="border bg-white border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
              Cancel
            </Button>
            <Button
              data-dialog-close="true"
              className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
              onClick={() => onConfirm(selectedRole)}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ResendInvitationModal = ({
  open,
  row,
  onClose,
  showSuccessMessage,
  onConfirm
}: {
  open: boolean;
  showSuccessMessage: boolean;
  row: any;
  onClose: any;
  onConfirm: any;
}) => {
  const email = row?.email || '';

  return (
    <Modal title="Resend Invitation" size="md" open={open} onClose={onClose}>
      <div className="flex flex-col gap-[10px]">
        <div className="mt-4">
          <p className="text-sm font-medium text-grey-700 mb-1.5">Email</p>
          <Input disabled placeholder="Email" type="email" value={email} />
        </div>

        <div className="mt-2">
          <div className="h-4 w-full text-right mb-4">
            {showSuccessMessage && (
              <div className="mt-4 text-green-500">Invite sent!</div>
            )}
          </div>
          <div className="flex gap-4 justify-center lg:justify-end">
            <Button
              variant="outlined"
              data-dialog-close="true"
              onClick={onClose}
              className="border bg-white border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
              Cancel
            </Button>
            <Button
              data-dialog-close="true"
              className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
              onClick={() => onConfirm()}>
              Resend Invite
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ResetMFAModal = ({
  open,
  row,
  onClose,
  showSuccessMessage,
  onConfirm
}: {
  open: boolean;
  showSuccessMessage: boolean;
  row: any;
  onClose: any;
  onConfirm: any;
}) => {
  return (
    <Modal title="Reset MFA" size="md" open={open} onClose={onClose}>
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col gap-[10px] text-[#475467]">
          <p>
            Resetting the Multi-Factor Authentication (MFA) for{' '}
            <span className="font-medium font-bold text-[#000000]">
              {row?.email}
            </span>{' '}
            will require this user to set up their MFA method the next time they
            log in successfully.
          </p>
          <p className="mt-4">Are you sure you want to proceed?</p>
        </div>
        <div className="mt-2">
          <div className="h-4 w-full text-right mb-4">
            {showSuccessMessage && (
              <div className="mt-4 text-green-500">MFA Reset!</div>
            )}
          </div>
          <div className="grid grid-cols-2 w-full gap-4 ">
            <Button
              variant="outlined"
              data-dialog-close="true"
              onClick={onClose}
              className="border bg-white border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
              Cancel
            </Button>
            <Button
              data-dialog-close="true"
              className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
              onClick={onConfirm}>
              Reset MFA
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default LandingTable;
