import { ReactComponent as Tagline } from '@assets/icons/tagline-footer.svg';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

const SellerFooter = ({ classname }: { classname?: string }) => {
  const { pathname } = useLocation();

  return (
    <div className={clsx('bg-grey-50', classname)}>
      <div className="flex flex-row justify-center text-[14px] items-center gap-4 text-gray-600">
        <a
          href={'https://www.authentik8.com.au/seller-privacy-policy1'}
          target="_blank"
          rel="noopener noreferrer"
          className="underline mr-4">
          Privacy
        </a>
        <a
          href={'https://www.authentik8.com.au/seller-terms-of-use'}
          target="_blank"
          rel="noopener noreferrer"
          className="underline mr-4">
          Terms of use
        </a>
      </div>
      <div className="flex flex-row justify-center items-center">
        <span className="text-center text-grey-400 text-xs flex justify-center items-center whitespace-normal gap-[2px] p-4">
          Powered by <Tagline />{' '}
        </span>
        <span className="text-center text-grey-400 text-[14px] flex justify-center items-center whitespace-normal gap-[2px] p-4">
          © Valeur Pty Ltd
        </span>
      </div>
    </div>
  );
};
export default SellerFooter;
