/* eslint-disable react/no-unescaped-entities */
import { Button } from '@material-tailwind/react';
import { CardCaptureStepEnum, SellerPage, SellerPageTitle } from '@types';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import VinIcon from '@assets/icons/VinIcon';
import HourIcon from '@assets/icons/HourIcon';

import PlateIcon from '@assets/icons/PlateIcon';
import VehicleIcon from '@assets/icons/VehiclePhotoIcon';
import { isMobile, isTablet } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { ReactComponent as SuccessIcon } from '@assets/icons/Success.svg';
import { SellerVerifyDesktopQR } from '@components/widgets/SellerVerifyDesktopQR';
import { useOrgStore, useSellerStore } from '@stores';
import VehicleComponent from './CaptureProcess/CardCapture';
import { ReactComponent as InfoErrorIcon } from '@assets/icons/info_error_icon.svg';
import clsx from 'clsx';
import { LenderDocumentTypeEnum } from '@types';
import { getAssetSpecificContent } from '@utils';
import { AssetTypesEnum } from '@types';
const contains = (target: string[], pattern: string[]) => {
  let value = 0;
  pattern.forEach((word) => {
    value = value + Number(target.includes(word));
  });
  return value > 0;
};

export const Vehicle = ({
  setPage
}: {
  setPage: (page: SellerPage) => void;
}) => {
  const [step, setStep] = useState('init');
  const {
    vinObj,
    odometer,
    vehicle,
    numberPlate,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    vehicleBack,
    vehicleFront,
    vehicleDriver,
    vehiclePassenger,
    vehicleLivingArea,
    vehicleKitchen,
    vehicleSleepingArea,
    setActivePageTitle,
    documents,
    operatingHours,
    assetType,
    documentTypes
  } = useSellerStore();
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
    setActivePageTitle(
      getAssetSpecificContent(assetType as AssetTypesEnum).title
    );
  }, []);
  switch (step) {
    case 'vin':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.vin}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    case 'plate':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.plateNumber}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    case 'operating-hours':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.operatingHours}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    case 'odometer':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.odometer}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'internal-photos':
      return <ExternalPhotos setStep={setStep} />;
    case 'vehicle-front-seats':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.internalFront}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-back-seats':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.internalBack}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-boot':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.internalBoot}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-living-area':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.internalLivingArea}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-kitchen':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.internalKitchen}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-sleeping-area':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.internalSleepingArea}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-front':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.assetFront}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-passenger':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.assetLeft}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-back':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.assetBack}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicle-driver':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.assetRight}
          setPage={setPage}
          init={(stepName?: any) => {
            setStep(stepName);
          }}
        />
      );
    case 'vehicles-photos':
      return <VehiclePhotos setStep={setStep} />;
    case 'vehicles-photos-first':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.vehiclePhoto}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    default:
      break;
  }
  return (
    <>
      <div className="flex py-8">
        <div className="grow">
          {(isMobile || isTablet) && (
            <>
              <p className="text-gray-700">
                By submitting the information below, you help us in confirming
                that your{' '}
                {
                  getAssetSpecificContent(assetType as AssetTypesEnum)
                    .lowerTitle
                }{' '}
                meets our sale condition standards.
              </p>
              <p className="text-gray-700 mt-4">
                <span className="font-semibold">
                  Please ensure that all photos are real-time captures of the
                  actual{' '}
                  {
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle
                  }
                  .
                </span>
                <span>
                  {' '}
                  Images of printed photos or screenshots will be detected and
                  rejected.
                </span>
              </p>
            </>
          )}
        </div>
      </div>
      {isMobile || isTablet ? (
        <div className="py-4 mx-auto w-full overflow-hidden relative">
          <Button
            onClick={() => setStep('vin')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !vinObj.available && 'hidden'
            )}>
            <div className="flex gap-3">
              <VinIcon color={primaryColor} />
              <p className="mr-2 text-lg text-semibold">VIN </p>
              {vinObj.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
              {!vinObj.isDone && documents?.VIN?.needsResubmit && (
                <InfoErrorIcon className="mt-1 w-5 h-5" />
              )}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('plate')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !numberPlate.available && 'hidden'
            )}>
            <div className="flex gap-3">
              <PlateIcon color={primaryColor} />
              <p className="mr-2">Number Plate </p>
              {numberPlate.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
              {!numberPlate.isDone && documents?.REGO_PLATE?.needsResubmit && (
                <InfoErrorIcon className="mt-1 w-5 h-5" />
              )}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('odometer')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              (!documentTypes.includes(LenderDocumentTypeEnum.ODOMETER) ||
                contains(documentTypes, [
                  LenderDocumentTypeEnum.INTERNAL_BACK,
                  LenderDocumentTypeEnum.INTERNAL_BOOT,
                  LenderDocumentTypeEnum.INTERNAL_FRONT,
                  LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
                  LenderDocumentTypeEnum.INTERNAL_KITCHEN,
                  LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA
                ])) &&
                'hidden'
            )}>
            <div className="flex gap-3">
              <HourIcon color={primaryColor} />
              <p className="mr-2">Odometer</p>
              {odometer.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
              {!odometer.isDone && documents?.ODOMETER?.needsResubmit && (
                <InfoErrorIcon className="mt-1 w-5 h-5" />
              )}
            </div>
            <LeftIcon />
          </Button>

          <Button
            onClick={() => {
              setActivePageTitle(SellerPageTitle.ExternalPhotos);
              if (
                documents?.ASSET_BACK ||
                documents?.ASSET_FRONT ||
                documents?.ASSET_LEFT ||
                documents?.ASSET_RIGHT
              ) {
                setStep('vehicles-photos');
              } else {
                setStep('vehicles-photos-first');
              }
            }}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !contains(documentTypes, [
                LenderDocumentTypeEnum.ASSET_BACK,
                LenderDocumentTypeEnum.ASSET_FRONT,
                LenderDocumentTypeEnum.ASSET_LEFT,
                LenderDocumentTypeEnum.ASSET_RIGHT
              ]) && 'hidden'
            )}>
            <div className="flex gap-3">
              <VehicleIcon color={primaryColor} />
              <p className="mr-2">External Photos</p>

              {vehicleBack.isDone &&
                vehicleFront.isDone &&
                vehicleDriver.isDone &&
                vehiclePassenger.isDone &&
                !documents?.ASSET_BACK?.needsResubmit &&
                !documents?.ASSET_FRONT?.needsResubmit &&
                !documents?.ASSET_LEFT?.needsResubmit &&
                !documents?.ASSET_RIGHT?.needsResubmit && (
                  <SuccessIcon className="mt-1 w-5 h-5" />
                )}
              {(!vehicleBack.isDone && documents?.ASSET_BACK?.needsResubmit) ||
              (!vehicleFront.isDone && documents?.ASSET_FRONT?.needsResubmit) ||
              (!vehicleDriver.isDone && documents?.ASSET_LEFT?.needsResubmit) ||
              (!vehiclePassenger.isDone &&
                documents?.ASSET_RIGHT?.needsResubmit) ? (
                <InfoErrorIcon className="mt-1 w-5 h-5" />
              ) : null}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => {
              setActivePageTitle(SellerPageTitle.InternalPhotos);
              setStep('internal-photos');
            }}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !contains(documentTypes, [
                LenderDocumentTypeEnum.INTERNAL_BACK,
                LenderDocumentTypeEnum.INTERNAL_BOOT,
                LenderDocumentTypeEnum.INTERNAL_FRONT,
                LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
                LenderDocumentTypeEnum.INTERNAL_KITCHEN,
                LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA
              ]) && 'hidden'
            )}>
            <div className="flex gap-3">
              <VehicleIcon color={primaryColor} />
              <p className="mr-2">Internal Photos</p>
              {(!documentTypes?.includes(CardCaptureStepEnum.odometer) ||
                odometer.isDone) &&
                (!documentTypes?.includes(CardCaptureStepEnum.internalBack) ||
                  vehicleBackSeats.isDone) &&
                (!documentTypes?.includes(CardCaptureStepEnum.internalFront) ||
                  vehicleFrontSeats.isDone) &&
                (!documentTypes?.includes(CardCaptureStepEnum.internalBoot) ||
                  vehicleBoot.isDone) &&
                (!documentTypes?.includes(
                  CardCaptureStepEnum.internalLivingArea
                ) ||
                  vehicleLivingArea.isDone) &&
                (!documentTypes?.includes(
                  CardCaptureStepEnum.internalKitchen
                ) ||
                  vehicleKitchen.isDone) &&
                (!documentTypes?.includes(
                  CardCaptureStepEnum.internalSleepingArea
                ) ||
                  vehicleSleepingArea.isDone) && (
                  <SuccessIcon className="mt-1 w-5 h-5" />
                )}
              {documents?.ODOMETER?.needsResubmit ||
              documents?.INTERNAL_BOOT?.needsResubmit ||
              documents?.INTERNAL_BACK?.needsResubmit ||
              documents?.INTERNAL_FRONT?.needsResubmit ? (
                <InfoErrorIcon className="mt-1 w-5 h-5" />
              ) : null}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('operating-hours')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !operatingHours.available && 'hidden'
            )}>
            <div className="flex gap-3">
              <HourIcon color={primaryColor} />
              <p className="mr-2">Operating Hours </p>
              {operatingHours.isDone && (
                <SuccessIcon className="mt-1 w-5 h-5" />
              )}
              {!operatingHours.isDone &&
                documents?.OPERATING_HOURS?.needsResubmit && (
                  <InfoErrorIcon className="mt-1 w-5 h-5" />
                )}
            </div>
            <LeftIcon />
          </Button>
        </div>
      ) : (
        <>
          <h2 className="text-[24px] lg:text-[30px] font-semibold text-grey-900 -mt-12">
            {getAssetSpecificContent(assetType as AssetTypesEnum).title}
          </h2>
          <SellerVerifyDesktopQR />
        </>
      )}
    </>
  );
};

const ExternalPhotos = ({ setStep }: { setStep: (step: string) => void }) => {
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  const {
    odometer,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    vehicleLivingArea,
    vehicleKitchen,
    vehicleSleepingArea,
    assetType,
    documents
  } = useSellerStore();

  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, []);

  return (
    <div className="py-4 mx-auto w-full overflow-hidden relative">
      <div className="flex flex-col gap-4">
        <p className="text-gray-700">
          <span className="font-semibold">
            Please ensure that all photos are real-time captures of the actual{' '}
            {getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle}.
          </span>
          <span>
            {' '}
            Images of printed photos or screenshots will be detected and
            rejected.
          </span>
        </p>
      </div>
      {/* Vehicle Front */}
      <Button
        onClick={() => setStep('odometer')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b mt-6 border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !odometer.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">Odometer</p>
          {odometer.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!odometer.isDone && documents?.ODOMETER?.needsResubmit && (
            <InfoErrorIcon className="mt-1 w-5 h-5" />
          )}
        </div>
        <LeftIcon />
      </Button>

      {/* Passenger Side */}
      <Button
        onClick={() => setStep('vehicle-front-seats')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleFrontSeats.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">Front Seats</p>
          {vehicleFrontSeats.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleFrontSeats.isDone &&
            documents?.INTERNAL_FRONT?.needsResubmit && (
              <InfoErrorIcon className="mt-1 w-5 h-5" />
            )}
        </div>
        <LeftIcon />
      </Button>

      {/* Vehicle Back */}
      <Button
        onClick={() => setStep('vehicle-back-seats')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleBackSeats.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">Back Seats</p>
          {vehicleBackSeats.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleBackSeats.isDone &&
            documents?.INTERNAL_BACK?.needsResubmit && (
              <InfoErrorIcon className="mt-1 w-5 h-5" />
            )}
        </div>
        <LeftIcon />
      </Button>

      {/* Driver's Side */}
      <Button
        onClick={() => setStep('vehicle-boot')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleBoot.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">Boot</p>
          {vehicleBoot.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleBoot.isDone && documents?.INTERNAL_BOOT?.needsResubmit && (
            <InfoErrorIcon className="mt-1 w-5 h-5" />
          )}
        </div>
        <LeftIcon />
      </Button>

      {/* Living Area */}
      <Button
        onClick={() => setStep('vehicle-living-area')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleLivingArea.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">Living Area</p>
          {vehicleLivingArea.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleLivingArea.isDone &&
            documents?.INTERNAL_LIVING_AREA?.needsResubmit && (
              <InfoErrorIcon className="mt-1 w-5 h-5" />
            )}
        </div>
        <LeftIcon />
      </Button>

      {/* Kitchen */}
      <Button
        onClick={() => setStep('vehicle-kitchen')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleKitchen.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">Kitchen</p>
          {vehicleKitchen.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleKitchen.isDone &&
            documents?.INTERNAL_KITCHEN?.needsResubmit && (
              <InfoErrorIcon className="mt-1 w-5 h-5" />
            )}
        </div>
        <LeftIcon />
      </Button>

      {/* Sleeping Area */}
      <Button
        onClick={() => setStep('vehicle-sleeping-area')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleSleepingArea.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">Sleeping Area</p>
          {vehicleSleepingArea.isDone && (
            <SuccessIcon className="mt-1 w-5 h-5" />
          )}
          {!vehicleSleepingArea.isDone &&
            documents?.INTERNAL_SLEEPING_AREA?.needsResubmit && (
              <InfoErrorIcon className="mt-1 w-5 h-5" />
            )}
        </div>
        <LeftIcon />
      </Button>
    </div>
  );
};

const VehiclePhotos = ({ setStep }: { setStep: (step: string) => void }) => {
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  const {
    vehicleFront,
    vehicleDriver,
    vehiclePassenger,
    vehicleBack,
    assetType,
    documents
  } = useSellerStore();

  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, []);

  return (
    <div className="py-4 mx-auto w-full overflow-hidden relative mb-4">
      <div className="flex flex-col gap-4">
        <p className="text-gray-700">
          <span className="font-semibold">
            Please ensure that all photos are real-time captures of the actual{' '}
            {getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle}.
          </span>
          <span>
            {' '}
            Images of printed photos or screenshots will be detected and
            rejected.
          </span>
        </p>
      </div>
      {/* Vehicle Front */}
      <Button
        onClick={() => setStep('vehicle-front')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg mt-6',
          !vehicleFront.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">
            {getAssetSpecificContent(assetType as AssetTypesEnum).title} Front
          </p>
          {vehicleFront.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleFront.isDone && documents?.ASSET_FRONT?.needsResubmit && (
            <InfoErrorIcon className="mt-1 w-5 h-5" />
          )}
        </div>
        <LeftIcon />
      </Button>

      {/* Passenger Side */}
      <Button
        onClick={() => setStep('vehicle-passenger')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehiclePassenger.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">
            {
              getAssetSpecificContent(assetType as AssetTypesEnum).sides
                .passenger
            }
          </p>
          {vehiclePassenger.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehiclePassenger.isDone && documents?.ASSET_LEFT?.needsResubmit && (
            <InfoErrorIcon className="mt-1 w-5 h-5" />
          )}
        </div>
        <LeftIcon />
      </Button>

      {/* Vehicle Back */}
      <Button
        onClick={() => setStep('vehicle-back')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleBack.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">
            {getAssetSpecificContent(assetType as AssetTypesEnum).title} Back
          </p>
          {vehicleBack.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleBack.isDone && documents?.ASSET_BACK?.needsResubmit && (
            <InfoErrorIcon className="mt-1 w-5 h-5" />
          )}
        </div>
        <LeftIcon />
      </Button>

      {/* Driver's Side */}
      <Button
        onClick={() => setStep('vehicle-driver')}
        className={clsx(
          'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
          !vehicleDriver.available && 'hidden'
        )}>
        <div className="flex gap-3">
          <p className="mr-2">
            {getAssetSpecificContent(assetType as AssetTypesEnum).sides.driver}
          </p>
          {vehicleDriver.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
          {!vehicleDriver.isDone && documents?.ASSET_RIGHT?.needsResubmit && (
            <InfoErrorIcon className="mt-1 w-5 h-5" />
          )}
        </div>
        <LeftIcon />
      </Button>
    </div>
  );
};
