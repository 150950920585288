import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CardCaptureStepEnum,
  DocumentCaptureStepEnum,
  LenderDocumentTypeEnum,
  SellerPage,
  SellerPageTitle,
  SellerPageTitleType,
  VehiclePhoneMappings,
  VehiclePhotosEnum,
  cardCaptureType,
  AssetType,
  AssetTypesEnum
} from '@types';
import VIN from '@assets/images/vehiclePhoto/vin.png';
import Plate from '@assets/images/vehiclePhoto/licence_plate.png';
import Bank from '@assets/images/bank.png';
import { ReactComponent as PrimaryCheck } from '@assets/icons/check_activity_success_icon.svg';
import DocumentSellerIcon from '@assets/icons/DocumentSellerIcon';
import LightIcon from '@assets/icons/LightIcon';
import EyeIcon from '@assets/icons/EyeIcon';
import TakePicIcon from '@assets/icons/TakePicIcon';
import MobileIcon from '@assets/icons/MobileIcon';
import { ReactComponent as SuccessIcon } from '@assets/icons/Success_light.svg';
import { Button, Input } from '@material-tailwind/react';
import { useOrgStore, useSellerStore } from '@stores';
import { useForm } from 'react-hook-form';
import ConfirmSellerModal from '@components/widgets/ConfirmSellerModal';
import clsx from 'clsx';
import Modal from '@components/widgets/Modal';
import {
  ADD_INSPECTION_FILE,
  GET_INSPECTION_CHECK,
  GET_INSPECTION_FILE,
  GET_INSPECTION_FILE_OCR,
  SET_INSPECTION_DOCUMENT,
  post
} from '@services';
import { VEHICLE_PHOTO_LIST } from '@constants';
import _indexOf from 'lodash/indexOf';
import { useNavigate } from 'react-router-dom';
import Select from '@components/forms/Select';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { Option } from '@material-tailwind/react';
import { isTablet, isDesktop } from 'react-device-detect';
import { processFile } from '@utils';
import LoadingComponent from '@components/widgets/LoadingComponent';
import _find from 'lodash/find';
import VehicleLeft from '@assets/images/vehiclePhoto/car_passenger.png';
import VehicleRight from '@assets/images/vehiclePhoto/car_drivers.png';
import VehicleBack from '@assets/images/vehiclePhoto/car_back.png';
import BikeFront from '@assets/images/vehiclePhoto/bike_front.png';
import BikeBack from '@assets/images/vehiclePhoto/bike_back.png';
import BikeLeft from '@assets/images/vehiclePhoto/bike_left.png';
import BikeRight from '@assets/images/vehiclePhoto/bike_right.png';

import CaravanFront from '@assets/images/vehiclePhoto/caravan_front.png';
import CaravanBack from '@assets/images/vehiclePhoto/caravan_back.png';
import CaravanLeft from '@assets/images/vehiclePhoto/caravan_left.png';
import CaravanRight from '@assets/images/vehiclePhoto/caravan_right.png';
import CaravanKitchen from '@assets/images/vehiclePhoto/caravan_kitchen.png';
import CaravanLivingArea from '@assets/images/vehiclePhoto/caravan_living.png';
import CaravanSleepingArea from '@assets/images/vehiclePhoto/caravan_sleeping.png';
import TrailerFront from '@assets/images/vehiclePhoto/trailer_front.png';
import TrailerBack from '@assets/images/vehiclePhoto/trailer_back.png';
import TrailerLeft from '@assets/images/vehiclePhoto/trailer_left.png';
import TrailerRight from '@assets/images/vehiclePhoto/trailer_right.png';

import VehicleFront from '@assets/images/vehiclePhoto/car_front.png';
import VehicleFrontSeats from '@assets/images/vehiclePhoto/car_front_seats.png';
import VehicleBackSeats from '@assets/images/vehiclePhoto/car_back_seats.png';
import VehicleBoot from '@assets/images/vehiclePhoto/car_boot.png';
import OdoMeter from '@assets/images/vehiclePhoto/odometer1.png';
import { getAssetSpecificContent } from '@utils';

type VehicleStartProps = {
  type: cardCaptureType;
  setPage: (page: SellerPage) => void;
  init: () => void;
};

// The maximum file size, 2 MB. Larger files are compressed by adjusting the JPEG quality factor.
// File uploads that don't complete within 60s will trigger a 408 error in CloudFront.
const maxUploadFileSize = 2 * 1024 * 1024;

const VehicleComponent: React.FC<VehicleStartProps> = ({
  type,
  setPage,
  init
}) => {
  const [step, setStep] = useState(0);
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  const [image, setImage] = useState('');
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, []);
  const [isUpload, setIsUpload] = useState(false);
  const {
    vinObj,
    numberPlate,
    odometer,
    operatingHours,
    vehicle,
    bank,
    setBankImage,
    setActivePageTitle,
    vehicleFront,
    vehicleBack,
    vehicleDriver,
    vehiclePassenger,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    vehicleLivingArea,
    vehicleKitchen,
    vehicleSleepingArea,
    setVehicleImageLivingArea,
    setVehicleImageKitchen,
    setVehicleImageSleepingArea,
    setVehicleImageFront,
    setVehicleImageFrontSeats,
    setVehicleImageBackSeats,
    setVehicleImageBoot,
    setVehicleImageDriver,
    setVehicleImagePassenger,
    assetType
  } = useSellerStore();
  const handleUpload = () => {
    if (fileInputRef.current) {
      setTimeout(() => fileInputRef.current?.click(), 300);
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file, maxUploadFileSize, (imageDataUrl, compressedFile) => {
        setImage(imageDataUrl);
        setBankImage({
          ...bank,
          fileName: compressedFile.name
        });
        setIsUpload(true);
        setStep(2);
      });
    } else {
      console.log('No file selected.');
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const captureImageBtn = useRef<HTMLInputElement>(null);

  const handleTakePictureForStep1 = () => {
    if (
      (type === DocumentCaptureStepEnum.bank &&
        !bank.isDone &&
        bank.image === '') ||
      (type === CardCaptureStepEnum.vin &&
        (vinObj.image === '' || vinObj?.file?.fileId)) ||
      (type === CardCaptureStepEnum.plateNumber &&
        (numberPlate.image === '' || numberPlate?.file?.fileId)) ||
      (type === CardCaptureStepEnum.odometer &&
        (odometer.image === '' || odometer?.file?.fileId)) ||
      (type === CardCaptureStepEnum.operatingHours &&
        (operatingHours.image === '' || operatingHours?.file?.fileId)) ||
      // Add new asset types
      (type === CardCaptureStepEnum.assetFront &&
        (vehicleFront.image === '' || vehicleFront?.file?.fileId)) ||
      (type === CardCaptureStepEnum.assetLeft &&
        (vehiclePassenger.image === '' || vehiclePassenger?.file?.fileId)) ||
      (type === CardCaptureStepEnum.assetRight &&
        (vehicleDriver.image === '' || vehicleDriver?.file?.fileId)) ||
      (type === CardCaptureStepEnum.assetBack &&
        (vehicleBack.image === '' || vehicleBack?.file?.fileId)) ||
      (type === CardCaptureStepEnum.internalFront &&
        (vehicleFrontSeats.image === '' || vehicleFrontSeats?.file?.fileId)) ||
      (type === CardCaptureStepEnum.internalBack &&
        (vehicleBackSeats.image === '' || vehicleBackSeats?.file?.fileId)) ||
      (type === CardCaptureStepEnum.internalBoot &&
        (vehicleBoot.image === '' || vehicleBoot?.file?.fileId)) ||
      (type === CardCaptureStepEnum.internalLivingArea &&
        (vehicleLivingArea.image === '' || vehicleLivingArea?.file?.fileId)) ||
      (type === CardCaptureStepEnum.internalKitchen &&
        (vehicleKitchen.image === '' || vehicleKitchen?.file?.fileId)) ||
      (type === CardCaptureStepEnum.internalSleepingArea &&
        (vehicleSleepingArea.image === '' || vehicleSleepingArea?.file?.fileId))
    ) {
      if (captureImageBtn.current) {
        setTimeout(() => captureImageBtn.current?.click(), 300);
      }
    } else if (type === CardCaptureStepEnum.vehiclePhoto && !vehicle.isDone) {
      if (captureImageBtn.current) {
        setTimeout(() => captureImageBtn.current?.click(), 300);
      }
    } else if (
      (type === DocumentCaptureStepEnum.bank &&
        (bank.image !== '' || bank?.file?.fileId)) ||
      (type === CardCaptureStepEnum.vehiclePhoto && vehicle.isDone)
    ) {
      setStep(2);
    } else {
      setStep(1);
    }
  };

  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file, maxUploadFileSize, (imageDataUrl) => {
        setImage(imageDataUrl);
        if (type === DocumentCaptureStepEnum.bank) {
          setBankImage({ ...bank });
        } else if (type === CardCaptureStepEnum.assetFront) {
          setVehicleImageFront({ ...vehicleFront });
        } else if (type === CardCaptureStepEnum.assetLeft) {
          setVehicleImagePassenger({ ...vehiclePassenger });
        } else if (type === CardCaptureStepEnum.assetRight) {
          setVehicleImageDriver({ ...vehicleDriver });
        } else if (type === CardCaptureStepEnum.assetBack) {
          setVehicleImageBoot({ ...vehicleBack });
        } else if (type === CardCaptureStepEnum.internalFront) {
          setVehicleImageFrontSeats({ ...vehicleFrontSeats });
        } else if (type === CardCaptureStepEnum.internalBack) {
          setVehicleImageBackSeats({ ...vehicleBackSeats });
        } else if (type === CardCaptureStepEnum.internalBoot) {
          setVehicleImageBoot({ ...vehicleBoot });
        } else if (type === CardCaptureStepEnum.internalLivingArea) {
          setVehicleImageLivingArea({ ...vehicleLivingArea });
        } else if (type === CardCaptureStepEnum.internalKitchen) {
          setVehicleImageKitchen({ ...vehicleKitchen });
        } else if (type === CardCaptureStepEnum.internalSleepingArea) {
          setVehicleImageSleepingArea({ ...vehicleSleepingArea });
        }
        setStep(2);
        event.stopPropagation();
      });
    } else {
      console.log('No file selected.');
    }
  };
  useEffect(() => {
    scrollTo(0, 0);
    let title: any = SellerPageTitle.Blank;
    if (type === CardCaptureStepEnum.vin) {
      title =
        step === 0
          ? SellerPageTitle.VIN
          : step === 2
          ? SellerPageTitle.VinDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.plateNumber) {
      title =
        step === 0
          ? SellerPageTitle.Plate
          : step === 2
          ? SellerPageTitle.PlateDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.odometer) {
      title =
        step === 0
          ? SellerPageTitle.Odometer
          : step === 2
          ? SellerPageTitle.OdometerDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.operatingHours) {
      title =
        step === 0
          ? SellerPageTitle.OperatingHours
          : step === 2
          ? SellerPageTitle.OperatingHours
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (DocumentCaptureStepEnum.bank === type) {
      title =
        step === 0
          ? SellerPageTitle.bank
          : step === 2
          ? SellerPageTitle.bankDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === DocumentCaptureStepEnum.payoutLetter) {
      title =
        step === 0
          ? SellerPageTitle.payoutLetter
          : step === 2
          ? SellerPageTitle.payoutLetterDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.vehiclePhoto) {
      title =
        step === 0
          ? getAssetSpecificContent(assetType as AssetTypesEnum).title +
            ' ' +
            SellerPageTitle.VehiclePhotos
          : step === 2
          ? vehicle.vehiclePosition === 1
            ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.front
            : vehicle.vehiclePosition === 2
            ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.driver
            : vehicle.vehiclePosition === 3
            ? getAssetSpecificContent(assetType as AssetTypesEnum).sides
                .passenger
            : getAssetSpecificContent(assetType as AssetTypesEnum).sides.back
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.assetFront) {
      title =
        step === 0
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.front
          : step === 2
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.front
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.assetLeft) {
      title =
        step === 0
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.passenger
          : step === 2
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.passenger
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.assetRight) {
      title =
        step === 0
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.driver
          : step === 2
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.driver
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.assetBack) {
      title =
        step === 0
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.back
          : step === 2
          ? getAssetSpecificContent(assetType as AssetTypesEnum).sides.back
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.internalFront) {
      title =
        step === 0
          ? SellerPageTitle.VehicleFrontSeats
          : step === 2
          ? SellerPageTitle.VehicleFrontSeats
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.internalBack) {
      title =
        step === 0
          ? SellerPageTitle.VehicleBackSeats
          : step === 2
          ? SellerPageTitle.VehicleBackSeats
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.internalBoot) {
      title =
        step === 0
          ? SellerPageTitle.VehicleBoot
          : step === 2
          ? SellerPageTitle.VehicleBootDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.internalLivingArea) {
      title =
        step === 0
          ? SellerPageTitle.VehicleLivingArea
          : step === 2
          ? SellerPageTitle.VehicleLivingAreaDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.internalKitchen) {
      title =
        step === 0
          ? SellerPageTitle.VehicleKitchen
          : step === 2
          ? SellerPageTitle.VehicleKitchenDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    } else if (type === CardCaptureStepEnum.internalSleepingArea) {
      title =
        step === 0
          ? SellerPageTitle.VehicleSleepingArea
          : step === 2
          ? SellerPageTitle.VehicleSleepingAreaDetails
          : step === 3
          ? SellerPageTitle.Review
          : SellerPageTitle.Blank;
    }
    setActivePageTitle(title);
  }, [type, step, vehicle.vehiclePosition]);
  if (step === 1) {
    return (
      <CardCapture
        primaryColor={primaryColor}
        setLoadImage={setImage}
        type={type}
        vehiclePosition={vehicle.vehiclePosition}
        setStep={setStep}
      />
    );
  } else if (step === 2) {
    return (
      <CaptureResult
        type={type}
        isUpload={isUpload}
        primaryColor={primaryColor}
        image={image}
        setLoadImage={setImage}
        setStep={setStep}
      />
    );
  } else if (step === 3) {
    return (
      <CaptureReview
        type={type}
        primaryColor={primaryColor}
        setStep={setStep}
      />
    );
  } else if (step === 4) {
    return (
      <CaptureComplete
        type={type}
        primaryColor={primaryColor}
        setStep={setStep}
        vehiclePosition={vehicle.vehiclePosition}
        setPage={setPage}
        setLoadImage={setImage}
        init={init}
      />
    );
  }
  return (
    <div className="flex flex-col tablet-portrait:w-[361px] tablet-portrait:mx-auto">
      <div
        className={
          (clsx('flex flex-col gap-10'),
          type === DocumentCaptureStepEnum.bank ? 'pb-[120px]' : 'pb-16')
        }>
        <div className="grow flex flex-col justify-center items-center">
          {type === CardCaptureStepEnum.vin && <img className="" src={VIN} />}
          {type === CardCaptureStepEnum.plateNumber && (
            <img className="" src={Plate} />
          )}
          {type === CardCaptureStepEnum.odometer && (
            <img className="" src={OdoMeter} />
          )}
          {type === DocumentCaptureStepEnum.bank && (
            <img className="" src={Bank} />
          )}
          {type === CardCaptureStepEnum.vehiclePhoto &&
            vehicle.vehiclePosition === 1 &&
            assetType === AssetTypesEnum.CAR && (
              <img className="" src={VehicleFront} />
            )}

          {type === CardCaptureStepEnum.vehiclePhoto &&
            vehicle.vehiclePosition === 1 &&
            assetType === AssetTypesEnum.MOTOR_CYCLE && (
              <img className="" src={BikeFront} />
            )}
          {type === CardCaptureStepEnum.vehiclePhoto &&
            vehicle.vehiclePosition === 1 &&
            assetType === AssetTypesEnum.TRAILER && (
              <img className="" src={TrailerFront} />
            )}
          {type === CardCaptureStepEnum.vehiclePhoto &&
            vehicle.vehiclePosition === 1 &&
            assetType === AssetTypesEnum.CAMPERVAN && (
              <img className="" src={CaravanFront} />
            )}
          {type === CardCaptureStepEnum.assetFront &&
            assetType === AssetTypesEnum.CAR && (
              <img className="" src={VehicleFront} />
            )}

          {type === CardCaptureStepEnum.assetLeft &&
            assetType === AssetTypesEnum.CAR && (
              <img className="" src={VehicleLeft} />
            )}
          {type === CardCaptureStepEnum.assetRight &&
            assetType === AssetTypesEnum.CAR && (
              <img className="" src={VehicleRight} />
            )}
          {type === CardCaptureStepEnum.assetBack &&
            assetType === AssetTypesEnum.CAR && (
              <img className="" src={VehicleBack} />
            )}
          {type === CardCaptureStepEnum.assetFront &&
            assetType === AssetTypesEnum.MOTOR_CYCLE && (
              <img className="" src={BikeFront} />
            )}
          {type === CardCaptureStepEnum.assetBack &&
            assetType === AssetTypesEnum.MOTOR_CYCLE && (
              <img className="" src={BikeBack} />
            )}
          {type === CardCaptureStepEnum.assetLeft &&
            assetType === AssetTypesEnum.MOTOR_CYCLE && (
              <img className="" src={BikeLeft} />
            )}
          {type === CardCaptureStepEnum.assetRight &&
            assetType === AssetTypesEnum.MOTOR_CYCLE && (
              <img className="" src={BikeRight} />
            )}
          {type === CardCaptureStepEnum.assetFront &&
            assetType === AssetTypesEnum.CAMPERVAN && (
              <img className="" src={CaravanFront} />
            )}
          {type === CardCaptureStepEnum.assetBack &&
            assetType === AssetTypesEnum.CAMPERVAN && (
              <img className="" src={CaravanBack} />
            )}
          {type === CardCaptureStepEnum.assetLeft &&
            assetType === AssetTypesEnum.CAMPERVAN && (
              <img className="" src={CaravanLeft} />
            )}
          {type === CardCaptureStepEnum.assetRight &&
            assetType === AssetTypesEnum.CAMPERVAN && (
              <img className="" src={CaravanRight} />
            )}
          {type === CardCaptureStepEnum.assetLeft &&
            assetType === AssetTypesEnum.TRAILER && (
              <img className="" src={TrailerLeft} />
            )}
          {type === CardCaptureStepEnum.assetRight &&
            assetType === AssetTypesEnum.TRAILER && (
              <img className="" src={TrailerRight} />
            )}
          {type === CardCaptureStepEnum.assetBack &&
            assetType === AssetTypesEnum.TRAILER && (
              <img className="" src={TrailerBack} />
            )}
          {type === CardCaptureStepEnum.assetFront &&
            assetType === AssetTypesEnum.TRAILER && (
              <img className="" src={TrailerFront} />
            )}
          {type === CardCaptureStepEnum.internalFront && (
            <img className="" src={VehicleFrontSeats} />
          )}
          {type === CardCaptureStepEnum.internalBack && (
            <img className="" src={VehicleBackSeats} />
          )}
          {type === CardCaptureStepEnum.internalBoot && (
            <img className="" src={VehicleBoot} />
          )}
          {type === CardCaptureStepEnum.internalLivingArea && (
            <img className="" src={CaravanLivingArea} />
          )}
          {type === CardCaptureStepEnum.internalKitchen && (
            <img className="" src={CaravanKitchen} />
          )}
          {type === CardCaptureStepEnum.internalSleepingArea && (
            <img className="" src={CaravanSleepingArea} />
          )}
          {/* {type === CardCaptureStepEnum.internalSleepingArea && (
            <img className="" src={VehicleSleepingArea} />
          )} */}
          <p className="text-grey-800 text-lg text-center font-semibold mt-8">
            {type === CardCaptureStepEnum.vin && 'Take a photo of the VIN'}
            {!isDesktop &&
              type === DocumentCaptureStepEnum.bank &&
              'Take a photo of your bank statement header including account number & BSB'}
            {isDesktop &&
              type === DocumentCaptureStepEnum.bank &&
              'Upload a copy of your bank statement header including account number & BSB'}
            {type === CardCaptureStepEnum.plateNumber &&
              'Take a photo of the number plate'}
            {type === CardCaptureStepEnum.odometer &&
              'Take a photo of the odometer'}
            {type === CardCaptureStepEnum.operatingHours &&
              'Take a photo of the Operating Hours'}
            {type === CardCaptureStepEnum.vehiclePhoto &&
              (vehicle.vehiclePosition === 1
                ? 'Start with the front'
                : vehicle.vehiclePosition === 2
                ? 'Start with the ' +
                  getAssetSpecificContent(
                    assetType as AssetTypesEnum
                  ).sides.driver.toLowerCase()
                : vehicle.vehiclePosition === 3
                ? 'Start with the ' +
                  getAssetSpecificContent(
                    assetType as AssetTypesEnum
                  ).sides.passenger.toLowerCase()
                : 'Start with the side')}
            {type === CardCaptureStepEnum.assetFront &&
              'Take a photo of the front'}
            {type === CardCaptureStepEnum.assetLeft &&
              'Take a photo of the ' +
                getAssetSpecificContent(
                  assetType as AssetTypesEnum
                ).sides.passenger.toLowerCase()}
            {type === CardCaptureStepEnum.assetRight &&
              'Take a photo of the ' +
                getAssetSpecificContent(
                  assetType as AssetTypesEnum
                ).sides.driver.toLowerCase()}
            {type === CardCaptureStepEnum.assetBack &&
              'Take a photo of the back'}
            {type === CardCaptureStepEnum.internalFront &&
              'Take a photo of the front seats'}
            {type === CardCaptureStepEnum.internalBack &&
              'Take a photo of the back seats'}
            {type === CardCaptureStepEnum.internalBoot &&
              'Take a photo of the boot'}
            {type === CardCaptureStepEnum.internalLivingArea &&
              'Take a photo of the living area'}
            {type === CardCaptureStepEnum.internalKitchen &&
              'Take a photo of the kitchen'}
            {type === CardCaptureStepEnum.internalSleepingArea &&
              'Take a photo of the sleeping area'}
          </p>
          {(type === DocumentCaptureStepEnum.bank ||
            type === CardCaptureStepEnum.vin ||
            type === CardCaptureStepEnum.plateNumber ||
            type === CardCaptureStepEnum.odometer ||
            type === CardCaptureStepEnum.operatingHours ||
            type === CardCaptureStepEnum.vehiclePhoto ||
            type === CardCaptureStepEnum.assetFront ||
            type === CardCaptureStepEnum.assetLeft ||
            type === CardCaptureStepEnum.assetRight ||
            type === CardCaptureStepEnum.assetBack ||
            type === CardCaptureStepEnum.internalFront ||
            type === CardCaptureStepEnum.internalBack ||
            type === CardCaptureStepEnum.internalBoot ||
            type === CardCaptureStepEnum.internalLivingArea ||
            type === CardCaptureStepEnum.internalKitchen ||
            type === CardCaptureStepEnum.internalSleepingArea) && (
            <>
              <input
                ref={captureImageBtn}
                type="file"
                accept="image/*"
                capture="environment"
                style={{ display: 'none' }}
                onChange={handleCapture}
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </>
          )}
        </div>

        <div className="">
          {type === CardCaptureStepEnum.vin && (
            <div className="text-sm flex flex-col gap-1">
              <p className="text-gray-600">
                A Vehicle Identification Number (VIN) can typically be found on
                the following:
              </p>
              {assetType === AssetTypesEnum.CAR && (
                <ol className="list-disc pl-4 text-gray-600 text-sm">
                  <li>
                    stamped on the dashboard near the windscreen (on the
                    driver&apos;s side of the vehicle)
                  </li>
                  <li>
                    on a plate sticker on the driver&apos;s side door jamb
                  </li>
                  <li>stamped on the engine firewall</li>
                </ol>
              )}
              {assetType === AssetTypesEnum.CAMPERVAN && (
                <ol className="list-disc pl-4 text-gray-600 text-sm">
                  <li>
                    <span className="font-bold">Drawbar or A-frame</span> – Look
                    for a stamped number on the metal frame near the hitch.
                  </li>
                  <li>
                    <span className="font-bold">Near the front door</span> –
                    Some caravans have a compliance plate or sticker inside the
                    doorway or on the door frame.
                  </li>
                  <li>
                    <span className="font-bold">Chassis rails</span> – The VIN
                    may be stamped into the steel chassis, often on the
                    right-hand chassis, often on the right-hand side.
                  </li>
                </ol>
              )}
              {assetType === AssetTypesEnum.MOTOR_CYCLE && (
                <ol className="list-disc pl-4 text-gray-600 text-sm">
                  <li>
                    <span className="font-bold">
                      Headstock or steering neck
                    </span>{' '}
                    – Look for a stamped number on the metal frame just below
                    the handlebars, near the steering column.
                  </li>
                  <li>
                    <span className="font-bold">Compliance plate</span> –
                    Usually riveted to the frame or near the headstock; this
                    plate lists the VIN and other details.
                  </li>
                  <li>
                    <span className="font-bold">Frame near the engine</span> –
                    Some bikes have the VIN stamped on the main frame rails or
                    near the engine casing.
                  </li>
                </ol>
              )}
              {assetType === AssetTypesEnum.TRAILER && (
                <ol className="list-disc pl-4 text-gray-600 text-sm">
                  <li>
                    <span className="font-bold">Drawbar or A-frame</span> –
                    Check for a stamped VIN on the metal frame near the
                    coupling/hitch.
                  </li>
                  <li>
                    <span className="font-bold">Compliance plate</span> –
                    Usually riveted to the drawbar or inside a metal toolbox;
                    this plate includes the VIN and other manufacturing details.
                  </li>
                  <li>
                    <span className="font-bold">Chassis rail</span> – Some
                    trailers have the VIN stamped into the steel chassis, often
                    on the right-hand side.
                  </li>
                </ol>
              )}
              {assetType === AssetTypesEnum.EQUIPMENT && (
                <ol className="list-disc pl-4 text-gray-600 text-sm">
                  <li>
                    <span className="font-bold">Main chassis or frame</span> –
                    Look for a stamped VIN on the main structural frame, often
                    near the front or under the cabin.
                  </li>
                  <li>
                    <span className="font-bold">
                      Cab area or operator&apos;s console
                    </span>{' '}
                    – Some machines display the VIN inside the cab, often on a
                    plate or sticker near the controls.
                  </li>
                  <li>
                    <span className="font-bold">
                      Near the engine or transmission
                    </span>{' '}
                    – Check the area around the engine bay or gearbox housing,
                    depending on the equipment type.
                  </li>
                </ol>
              )}
            </div>
          )}
          {type === CardCaptureStepEnum.plateNumber && (
            <p className="text-gray-600 text-sm">
              A vehicle&apos;s number plate may also be referred to as a
              registration plate or licence plate, and can be found on the front
              and back of registered vehicles.
            </p>
          )}
          {type === CardCaptureStepEnum.odometer && (
            <div className="flex flex-col gap-4">
              <p className="text-gray-600 text-sm">
                To read your odometer on a car with a digital helper screen,
                scroll through the different display faces until you find{' '}
                <span className="font-bold">ODO</span>.
              </p>
              <p className="text-gray-600 text-sm">
                Mechanical odometers are usually placed near the information
                circle on the speedometer.
              </p>
            </div>
          )}
          {type === CardCaptureStepEnum.operatingHours && (
            <div className="flex flex-col gap-4 mt-12">
              <p className="text-[#101828] text-sm">
                Provide a record of the operating hours on this asset to verify
                its usage and condition.
              </p>
              <p className="text-[#101828] text-sm">
                The operating hours are typically displayed on the
                machine&apos;s hour meter or digital dashboard. Depending on the
                equipment type, you may find this:
              </p>
              <ul className="list-disc pl-4 text-[#101828] text-sm">
                <li>
                  On the <span className="font-bold">main dashboard</span> near
                  the ignition or control panel.
                </li>
                <li>
                  On a <span className="font-bold">digital screen</span> within
                  the machine&apos;s settings menu.
                </li>
                <li>
                  On an <span className="font-bold">analog hour meter</span>,
                  usually located in the cab, under the seat, or near the
                  engine.
                </li>
              </ul>
              <p className="text-[#101828] text-sm">
                If you are unsure, check the equipment&apos;s user manual or
                look for a label that says &quot;
                <span className="font-bold">Hours</span>,&quot; &quot;
                <span className="font-bold">Service Hours</span>,&quot; or
                &quot;<span className="font-bold">Engine Hours</span>.&quot;
              </p>
            </div>
          )}
          {type === DocumentCaptureStepEnum.bank && (
            <p className="text-gray-600 text-sm">
              We ask for your bank details to ensure that any funds or payments
              related to the transaction are correctly processed and sent to
              you.
            </p>
          )}
          {type === CardCaptureStepEnum.vehiclePhoto && (
            <>
              {vehicle.vehiclePosition === 1 && (
                <p className="text-gray-600 text-sm">
                  Ensure the photo is taken directly in front of the{' '}
                  {
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle
                  }{' '}
                  with no obstructions.
                </p>
              )}
              {vehicle.vehiclePosition === 2 && (
                <p className="text-gray-600 text-sm">
                  Ensure the photo is taken from the{' '}
                  {getAssetSpecificContent(
                    assetType as AssetTypesEnum
                  ).sides.driver.toLocaleLowerCase()}{' '}
                  of the{' '}
                  {
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle
                  }{' '}
                  with no obstructions.
                </p>
              )}
              {vehicle.vehiclePosition === 3 && (
                <p className="text-gray-600 text-sm">
                  Ensure the photo is taken from the{' '}
                  {getAssetSpecificContent(
                    assetType as AssetTypesEnum
                  ).sides.passenger.toLocaleLowerCase()}{' '}
                  of the{' '}
                  {
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle
                  }{' '}
                  with no obstructions.
                </p>
              )}
              {vehicle.vehiclePosition === 4 && (
                <p className="text-gray-600 text-sm">
                  Ensure the photo is taken from the back of the{' '}
                  {
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle
                  }{' '}
                  with no obstructions.
                </p>
              )}
            </>
          )}
          {type === CardCaptureStepEnum.assetFront && (
            <p className="text-gray-600 text-sm">
              Ensure the photo is taken directly in front of the{' '}
              {getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle}{' '}
              with no obstructions.
            </p>
          )}
          {type === CardCaptureStepEnum.assetLeft && (
            <p className="text-gray-600 text-sm">
              Ensure the photo is taken from the{' '}
              {getAssetSpecificContent(
                assetType as AssetTypesEnum
              ).sides.passenger.toLocaleLowerCase()}{' '}
              of the{' '}
              {getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle}{' '}
              with no obstructions.
            </p>
          )}
          {type === CardCaptureStepEnum.assetRight && (
            <p className="text-gray-600 text-sm">
              Ensure the photo is taken from the{' '}
              {getAssetSpecificContent(
                assetType as AssetTypesEnum
              ).sides.driver.toLocaleLowerCase()}{' '}
              of the{' '}
              {getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle}{' '}
              with no obstructions.
            </p>
          )}
          {type === CardCaptureStepEnum.assetBack && (
            <p className="text-gray-600 text-sm">
              Ensure the photo is taken from the back of the{' '}
              {getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle}{' '}
              with no obstructions.
            </p>
          )}
          {type === CardCaptureStepEnum.internalFront && (
            <p className="text-gray-600 text-sm">
              Capture a clear and complete photo of both the driver&apos;s and
              passenger&apos;s seats.
            </p>
          )}
          {type === CardCaptureStepEnum.internalBack && (
            <p className="text-gray-600 text-sm">
              Capture a clear and complete photo of the entire back seating
              area.
            </p>
          )}
          {type === CardCaptureStepEnum.internalBoot && (
            <p className="text-gray-600 text-sm">
              Capture a clear and complete photo of the boot area, showing its
              condition and space.
            </p>
          )}
          {type === CardCaptureStepEnum.internalLivingArea && (
            <p className="text-gray-600 text-sm">
              Capture the main seating, dining, or general interior space.
            </p>
          )}
          {type === CardCaptureStepEnum.internalKitchen && (
            <p className="text-gray-600 text-sm">
              Capture the kitchen area, whether it&apos;s internal or external.
            </p>
          )}
          {type === CardCaptureStepEnum.internalSleepingArea && (
            <p className="text-gray-600 text-sm">
              Capture the primary sleeping space (e.g., main bed, fixed bed, or
              primary sleeping arrangement).
            </p>
          )}
        </div>
        {!isDesktop && (
          <div className="flex flex-col gap-3 mb-20 mt-10">
            <h2 className="font-semibold">Tips</h2>
            <ul className="flex flex-col gap-3">
              <li className="inline-flex gap-2 text-grey-700 text-sm">
                <LightIcon color={primaryColor} />
                {type === CardCaptureStepEnum.internalFront ||
                type === CardCaptureStepEnum.internalBack ||
                type === CardCaptureStepEnum.internalBoot ||
                type === CardCaptureStepEnum.internalLivingArea ||
                type === CardCaptureStepEnum.internalKitchen ||
                type === CardCaptureStepEnum.internalSleepingArea
                  ? 'Ensure the area is well-lit'
                  : 'Find an area with good lighting'}
              </li>
              <li className="inline-flex gap-2 text-grey-700 text-sm">
                <EyeIcon color={primaryColor} />
                {type === CardCaptureStepEnum.internalFront ||
                type === CardCaptureStepEnum.internalBack
                  ? 'Keep the seats fully visible'
                  : type === CardCaptureStepEnum.internalLivingArea ||
                    type === CardCaptureStepEnum.internalKitchen ||
                    type === CardCaptureStepEnum.internalSleepingArea
                  ? 'Keep the area fully visible'
                  : type === CardCaptureStepEnum.internalBoot
                  ? 'Keep the entire boot visible'
                  : type === CardCaptureStepEnum.assetFront ||
                    type === CardCaptureStepEnum.assetLeft ||
                    type === CardCaptureStepEnum.assetRight ||
                    type === CardCaptureStepEnum.assetBack
                  ? 'Make sure the ' +
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle +
                    ' is in full view'
                  : 'Make sure all details are visible'}
              </li>
              <li className="inline-flex gap-2 text-grey-700 text-sm">
                <MobileIcon color={primaryColor} />
                {type === CardCaptureStepEnum.internalFront ||
                type === CardCaptureStepEnum.internalBack ||
                type === CardCaptureStepEnum.internalBoot ||
                type === CardCaptureStepEnum.internalLivingArea ||
                type === CardCaptureStepEnum.internalKitchen ||
                type === CardCaptureStepEnum.internalSleepingArea
                  ? 'Hold your device steady to avoid blur'
                  : 'Hold your device steady'}
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        {!isDesktop && (
          <Button
            onClick={handleTakePictureForStep1}
            className={clsx(
              'align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 rounded disabled:shadow-none disabled:pointer-events-none py-[10px] px-[18px]rounded shadow-none shadow-blue-500/20 hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case grow lg:flex-grow-0 text-white text-[16px]',
              primaryColor && `bg-[${primaryColor}]`
            )}
            style={{ backgroundColor: primaryColor }}>
            Continue
          </Button>
        )}
        {type === DocumentCaptureStepEnum.bank && (
          <Button
            variant="text"
            className="text-grey-600 text-base normal-case rounded font-semibold py-[10px] px-[18px]"
            onClick={handleUpload}>
            Upload from your device or computer
          </Button>
        )}
      </div>
    </div>
  );
};

interface CardCaptureProps {
  type: cardCaptureType;
  primaryColor: string;
  setLoadImage: (image: any) => void;
  vehiclePosition?: number;
  setStep: (step: number) => void;
}
const CardCapture: React.FC<CardCaptureProps> = ({
  type,
  vehiclePosition,
  primaryColor,
  setLoadImage,
  setStep
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showVideo, setShowVideo] = useState(false);
  const {
    vehicle,
    setVinImage,
    setNumberPlateImage,
    setOdometerImage,
    setOperatingHoursImage,
    setVehicleImageFront,
    setVehicleImageDriver,
    setVehicleImageBack,
    setVehicleImagePassenger,
    setBankImage,
    assetType
  } = useSellerStore();
  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode: { exact: 'environment' } }
      });
      const canvas = canvasRef.current;
      if (videoRef.current && canvas) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const stopCamera = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream != null) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      videoRef.current.srcObject = null;
    }
  };
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const capturePicture = () => {
    setShowVideo(false);
    startCamera();
    if (canvasRef.current && videoRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      if (context) {
        const putX = canvas.width - 32;
        context.drawImage(video, 72, 110, putX, 250, 0, 0, putX, 250);
        const imageData = context.getImageData(0, 0, putX, 250);
        canvas.width = putX;
        canvas.height = 250;
        context.putImageData(imageData, 0, 0);
        const capturedImage = canvas.toDataURL('image/jpeg');
        setCapturedImage(capturedImage);
        stopCamera();
        // if (type === CardCaptureStepEnum.vin) {
        //   setVinImage({ ...vinObj, image: capturedImage });
        // } else if (type === CardCaptureStepEnum.plateNumber) {
        //   setNumberPlateImage({ ...numberPlate, image: capturedImage });
        // } else if (type === CardCaptureStepEnum.odometer) {
        //   setOdometerImage({ ...odometer, image: capturedImage });
        // } else if (type === DocumentCaptureStepEnum.bank) {
        //   setBankImage({ ...bank, image: capturedImage });
        // } else if (type === CardCaptureStepEnum.vehiclePhoto) {
        //   if (vehiclePosition === 1) {
        //     setVehicleImageFront({ ...vehicle, imageFront: capturedImage });
        //   } else if (vehiclePosition === 2) {
        //     setVehicleImageDriver({ ...vehicle, imageDriver: capturedImage });
        //   } else if (vehiclePosition === 3) {
        //     setVehicleImagePassenger({
        //       ...vehicle,
        //       imagePassenger: capturedImage
        //     });
        //   } else if (vehiclePosition === 4) {
        //     setVehicleImageBack({ ...vehicle, imageBack: capturedImage });
        //   }
        // }
        setLoadImage(capturedImage);
        setStep(2);
      }
    }
  };

  useLayoutEffect(() => {
    setShowVideo(true);
    if (type === CardCaptureStepEnum.vin) {
      setVinImage({
        image: '',
        vin: '',
        available: true,
        isDone: false
      });
    } else if (type === CardCaptureStepEnum.plateNumber) {
      setNumberPlateImage({
        image: '',
        isDone: false,
        available: true,
        plateNumber: ''
      });
    } else if (type === CardCaptureStepEnum.odometer) {
      setOdometerImage({
        image: '',
        odometer: '',
        available: true,
        isDone: false
      });
    } else if (type === CardCaptureStepEnum.operatingHours) {
      setOperatingHoursImage({
        image: '',
        available: true,
        isDone: false
      });
    } else if (type === DocumentCaptureStepEnum.bank) {
      setBankImage({
        image: '',
        available: true,
        isDone: false
      });
    } else if (
      type === CardCaptureStepEnum.vehiclePhoto &&
      vehiclePosition === 1
    ) {
      setVehicleImageFront({
        imageFront: '',
        imageDriver: '',
        imageBack: '',
        imagePassenger: '',
        type: 'VEHICLE',
        vehiclePosition: 1,
        available: true,
        isDone: false
      });
    } else if (
      type === CardCaptureStepEnum.vehiclePhoto &&
      vehiclePosition === 2
    ) {
      setVehicleImageDriver({
        ...vehicle,
        imageDriver: '',
        imageBack: '',
        type: 'VEHICLE',
        available: true,
        imagePassenger: '',
        vehiclePosition: 2,
        isDone: false
      });
    } else if (
      type === CardCaptureStepEnum.vehiclePhoto &&
      vehiclePosition === 3
    ) {
      setVehicleImagePassenger({
        ...vehicle,
        imagePassenger: '',
        available: true,
        imageBack: '',
        type: 'VEHICLE',
        vehiclePosition: 3,
        isDone: false
      });
    } else if (
      type === CardCaptureStepEnum.vehiclePhoto &&
      vehiclePosition === 4
    ) {
      setVehicleImageBack({
        ...vehicle,
        imageBack: '',
        type: 'VEHICLE',
        available: true,
        vehiclePosition: 4,
        isDone: false
      });
    } else if (type === CardCaptureStepEnum.assetFront) {
      setVehicleImageFront({
        image: '',
        available: true,
        isDone: false
      });
    } else if (type === CardCaptureStepEnum.assetLeft) {
      setVehicleImagePassenger({
        image: '',
        available: true,
        isDone: false
      });
    } else if (type === CardCaptureStepEnum.assetRight) {
      setVehicleImageDriver({
        image: '',
        available: true,
        isDone: false
      });
    } else if (type === CardCaptureStepEnum.assetBack) {
      setVehicleImageBack({
        image: '',
        available: true,
        isDone: false
      });
    }

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.beginPath();
        ctx.roundRect(16, 120, canvas.width - 32, isTablet ? 650 : 250, 20);
        ctx.stroke();
        ctx.fill();

        ctx.globalCompositeOperation = 'destination-out';
        ctx.beginPath();
        ctx.roundRect(16, 120, canvas.width - 32, isTablet ? 650 : 250, 20);
        ctx.stroke();
        ctx.fill();
      }
    }
    startCamera();
  }, [capturedImage]);

  return (
    <>
      <div
        className="bg-opacity-40 flex flex-col w-screen h-full mt-[-12px] ml-[-16px] overflow-y-hidden no-scrollbar tablet-portrait:absolute tablet-portrait:left-0 tablet-portrait:top-0 tablet-portrait:ml-0"
        style={{ height: window.innerHeight - 56 }}>
        <div className="flex w-full h-full">
          {showVideo && (
            <video
              ref={videoRef}
              height={window.innerHeight}
              style={{
                height: window.innerHeight,
                zIndex: 20
              }}
              autoPlay
              playsInline
              className="webcam-feed"
            />
          )}
          <canvas
            ref={canvasRef}
            width={'361px'}
            height={window.innerHeight}
            style={{
              width: '100vw',
              border: 'none',
              zIndex: 20,
              backdropFilter: 'blur(10px)',
              height: window.innerHeight,
              position: 'absolute'
            }}
          />
        </div>
        <div
          className={clsx(
            'px-4 absolute z-100 inset-x-0 flex flex-col items-center justify-center gap-2',
            window.innerHeight > 700 ? 'bottom-[56px]' : 'bottom-[12px]'
          )}>
          <div className="text-white text-center">
            {type === CardCaptureStepEnum.vin &&
              'Please fit your VIN into the frame.'}
            {type === CardCaptureStepEnum.plateNumber &&
              'Please fit your number plate into the frame.'}
            {type === CardCaptureStepEnum.odometer &&
              'Please fit the odometer into the frame'}
            {type === DocumentCaptureStepEnum.bank &&
              'Please fit just the header section of your bank statement into the frame.'}
            {type === CardCaptureStepEnum.operatingHours &&
              'Please fit the operating hours into the frame.'}
            {type === CardCaptureStepEnum.vehiclePhoto &&
              vehiclePosition === 1 &&
              `Please fit the front of the ${
                getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle
              } into the frame.`}
            {type === CardCaptureStepEnum.vehiclePhoto &&
              vehiclePosition === 2 &&
              `Please fit the driver's side into the frame.`}
            {type === CardCaptureStepEnum.vehiclePhoto &&
              vehiclePosition === 3 &&
              `Please fit the passenger side into the frame.`}
            {type === CardCaptureStepEnum.vehiclePhoto &&
              vehiclePosition === 4 &&
              `Please fit the back into the frame.`}
            {type === CardCaptureStepEnum.assetFront &&
              'Please fit the front of the asset into the frame.'}
            {type === CardCaptureStepEnum.assetLeft &&
              'Please fit the passenger side of the asset into the frame.'}
            {type === CardCaptureStepEnum.assetRight &&
              'Please fit the driver side of the asset into the frame.'}
            {type === CardCaptureStepEnum.assetBack &&
              'Please fit the back of the asset into the frame.'}
            {type === CardCaptureStepEnum.internalFront &&
              'Please fit the front seats of the vehicle into the frame.'}
            {type === CardCaptureStepEnum.internalBack &&
              'Please fit the back seats of the vehicle into the frame.'}
            {type === CardCaptureStepEnum.internalBoot &&
              'Please fit the boot of the vehicle into the frame.'}
            {type === CardCaptureStepEnum.internalLivingArea &&
              'Please fit the living area of the caravan into the frame.'}
            {type === CardCaptureStepEnum.internalKitchen &&
              'Please fit the kitchen of the caravan into the frame.'}
            {type === CardCaptureStepEnum.internalSleepingArea &&
              'Please fit the sleeping area of the caravan into the frame.'}
          </div>
          <button onClick={capturePicture}>
            <TakePicIcon color={primaryColor} />
          </button>
        </div>
      </div>
    </>
  );
};
interface CaptureResultProps {
  type: cardCaptureType;
  isUpload: boolean;
  image: string;
  setLoadImage: (image: any) => void;
  primaryColor: string;
  setStep: (step: number) => void;
}

const CaptureResult: React.FC<CaptureResultProps> = ({
  type,
  image: initialImage,
  isUpload,
  primaryColor,
  setStep,
  setLoadImage
}) => {
  const uploadTakeNewPicture = () => {
    if (fileInputRef.current) {
      setTimeout(() => fileInputRef.current?.click(), 300);
    }
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file, maxUploadFileSize, (imageDataUrl, compressedFile) => {
        setLoadImage(imageDataUrl);
        setBankImage({
          ...bank,
          fileName: compressedFile.name
        });
        setImage(imageDataUrl);
      });
    } else {
      console.log('No file selected.');
    }
  };
  const [isLoading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    vinObj,
    numberPlate,
    odometer,
    vehicle,
    bank,
    operatingHours,
    setOdometer,
    setOperatingHours,
    setVehicle,
    inspectionId,
    vehiclePhotoList,
    setVinFile,
    setPlateNumberFile,
    setCertFile,
    setOperatingHoursFile,
    setPayoutLetterFile,
    setOwnershipProofFile,
    setBankFile,
    setBankImage,
    setVinImage,
    setNumberPlateImage,
    vehicleFront,
    vehiclePassenger,
    vehicleDriver,
    vehicleBack,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    vehicleLivingArea,
    vehicleKitchen,
    vehicleSleepingArea,
    setVehicleImageLivingArea,
    setVehicleImageKitchen,
    setVehicleImageSleepingArea,
    setVehicleImageFront,
    setVehicleImagePassenger,
    setVehicleImageDriver,
    setVehicleImageBack,
    setVehicleImageFrontSeats,
    setVehicleImageBackSeats,
    setVehicleImageBoot,
    assetType
  } = useSellerStore();
  const navigator = useNavigate();
  const [geoLocation, setGeoLocation] = useState<{
    clientAccuracy?: number;
    clientLatitude?: number;
    clientLongitude?: number;
  }>({});
  const [isAddNew, setIsAddNew] = useState(true);
  const [targeFileId, setFileId] = useState<string | undefined>();
  useEffect(() => {
    const getLocation = () => {
      if (!window.navigator.geolocation) {
        return;
      }

      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setGeoLocation({
            clientAccuracy: coords.accuracy,
            clientLatitude: coords.latitude,
            clientLongitude: coords.longitude
          });
        },
        (error) => {
          console.error(`Error retrieving location: ${error.message}`);
        }
      );
    };
    getLocation();
    const documentType =
      type === CardCaptureStepEnum.vehiclePhoto
        ? VehiclePhotosEnum[vehicle.vehiclePosition - 1]
        : type;

    let fileId;
    if (
      documentType === CardCaptureStepEnum.vin &&
      vinObj.file &&
      vinObj.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vinObj.file?.fileId);
      fileId = vinObj.file?.fileId;
    } else if (
      documentType === CardCaptureStepEnum.plateNumber &&
      numberPlate.file &&
      numberPlate.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(numberPlate.file?.fileId);
      fileId = numberPlate.file?.fileId;
    } else if (
      documentType === CardCaptureStepEnum.odometer &&
      odometer.file &&
      odometer.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(odometer.file?.fileId);
      fileId = odometer.file?.fileId;
    } else if (
      documentType === CardCaptureStepEnum.operatingHours &&
      operatingHours.file &&
      operatingHours.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(operatingHours.file?.fileId);
      fileId = operatingHours.file?.fileId;
    } else if (
      documentType === DocumentCaptureStepEnum.bank &&
      bank.file &&
      bank.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(bank.file?.fileId);
      fileId = bank.file?.fileId;
    } else if (
      documentType === 'ASSET_FRONT' &&
      vehicle.fileFront &&
      vehicle.fileFront?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicle.fileFront?.fileId);
      fileId = vehicle.fileFront?.fileId;
    } else if (
      documentType === 'ASSET_RIGHT' &&
      vehicle.fileDriver &&
      vehicle.fileDriver?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicle.fileDriver?.fileId);
      fileId = vehicle.fileDriver?.fileId;
    } else if (
      documentType === 'ASSET_BACK' &&
      vehicle.fileBack &&
      vehicle.fileBack?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicle.fileBack?.fileId);
      fileId = vehicle.fileBack?.fileId;
    } else if (
      documentType === 'ASSET_LEFT' &&
      vehicle.filePassenger &&
      vehicle.filePassenger?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicle.filePassenger?.fileId);
      fileId = vehicle.filePassenger?.fileId;
    } else if (
      documentType === 'INTERNAL_FRONT' &&
      vehicleFrontSeats.file &&
      vehicleFrontSeats.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicleFrontSeats.file?.fileId);
      fileId = vehicleFrontSeats.file?.fileId;
    } else if (
      documentType === 'INTERNAL_BACK' &&
      vehicleBackSeats.file &&
      vehicleBackSeats.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicleBackSeats.file?.fileId);
      fileId = vehicleBackSeats.file?.fileId;
    } else if (
      documentType === 'INTERNAL_BOOT' &&
      vehicleBoot.file &&
      vehicleBoot.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicleBoot.file?.fileId);
      fileId = vehicleBoot.file?.fileId;
    } else if (
      documentType === 'INTERNAL_LIVING_AREA' &&
      vehicleLivingArea.file &&
      vehicleLivingArea.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicleLivingArea.file?.fileId);
      fileId = vehicleLivingArea.file?.fileId;
    } else if (
      documentType === 'INTERNAL_KITCHEN' &&
      vehicleKitchen.file &&
      vehicleKitchen.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicleKitchen.file?.fileId);
      fileId = vehicleKitchen.file?.fileId;
    } else if (
      documentType === 'INTERNAL_SLEEPING_AREA' &&
      vehicleSleepingArea.file &&
      vehicleSleepingArea.file?.fileId !== ''
    ) {
      setIsAddNew(false);
      setFileId(vehicleSleepingArea.file?.fileId);
      fileId = vehicleSleepingArea.file?.fileId;
    }

    if (fileId && fileId !== '') {
      setLoading(true);
      post(
        GET_INSPECTION_FILE,
        { inspectionId: inspectionId, fileId: fileId },
        false
      )
        .then((data) => {
          setLoading(false);
          if (data && data.dataBase64) {
            if (image === '') {
              setImage(`data:image/jpeg;base64,${data.dataBase64}`);
              setLoadImage(`data:image/jpeg;base64,${data.dataBase64}`);
            }

            if (
              data.file &&
              data.file?.documentType === LenderDocumentTypeEnum.VIN
            ) {
              setVinFile(data.file);
            } else if (
              data.file &&
              data.file?.documentType === LenderDocumentTypeEnum.REGO_PLATE
            ) {
              setPlateNumberFile(data.file);
            } else if (
              data.file &&
              data.file?.documentType === LenderDocumentTypeEnum.OPERATING_HOURS
            ) {
              setOperatingHoursFile(data.file);
            } else if (
              data.file &&
              data.file?.documentType === LenderDocumentTypeEnum.BANK_STATEMENTS
            ) {
              setBankFile(data.file);
            } else if (
              (data.file &&
                data.file?.documentType ===
                  LenderDocumentTypeEnum.ROADWORTHY_CERT) ||
              (data.file &&
                data.file?.documentType === LenderDocumentTypeEnum.SAFETY_CERT)
            ) {
              setCertFile(data.file);
            } else if (
              data.file &&
              data.file?.documentType === LenderDocumentTypeEnum.PAYOUT_LETTER
            ) {
              setPayoutLetterFile(data.file);
            } else if (
              data.file &&
              data.file?.documentType === LenderDocumentTypeEnum.OWNERSHIP_PROOF
            ) {
              setOwnershipProofFile(data.file);
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    }
  }, []);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const handleRetry = () => {
    setShowErrorDialog(false);
    window.location.reload();
  };
  const handleUpload = () => {
    if (
      [
        CardCaptureStepEnum.vin,
        CardCaptureStepEnum.plateNumber,
        DocumentCaptureStepEnum.bank
      ].includes(type)
    ) {
      setLoading(true);

      uploadFile(type, image, (fileId?: any) => {
        if (
          CardCaptureStepEnum.vin === type ||
          CardCaptureStepEnum.plateNumber === type ||
          DocumentCaptureStepEnum.bank === type
        ) {
          setLoading(true);
          post(
            GET_INSPECTION_FILE_OCR,
            {
              inspectionId: inspectionId,
              fileId:
                fileId && fileId !== ''
                  ? fileId
                  : CardCaptureStepEnum.vin === type
                  ? vinObj.file?.fileId || ''
                  : CardCaptureStepEnum.plateNumber === type
                  ? numberPlate.file?.fileId || ''
                  : bank.file?.fileId || ''
            },
            false
          )
            .then((data) => {
              if (
                !data ||
                !data.status ||
                (data.status !== 'COMPLETE' && data.status !== 'ERROR')
              ) {
                let tryTimes = 0;
                const timer = setInterval(() => {
                  tryTimes++;
                  if (tryTimes > 25) {
                    clearInterval(timer);
                    setStep(3);
                    setLoading(false);
                  }
                  post(
                    GET_INSPECTION_FILE_OCR,
                    {
                      inspectionId: inspectionId,
                      fileId:
                        fileId && fileId !== ''
                          ? fileId
                          : CardCaptureStepEnum.vin === type
                          ? vinObj.file?.fileId || ''
                          : CardCaptureStepEnum.plateNumber === type
                          ? numberPlate.file?.fileId || ''
                          : bank.file?.fileId || ''
                    },
                    false
                  )
                    .then((res) => {
                      if (
                        res &&
                        (res.status === 'COMPLETE' || res.status === 'ERROR')
                      ) {
                        clearInterval(timer);
                        if (CardCaptureStepEnum.vin === type) {
                          setVinImage({
                            ...vinObj,
                            file: {
                              ...vinObj.file,
                              fileId: fileId || vinObj.file.fileId
                            },
                            ocrFields: res?.ocrFields,
                            ocrStatus: res.status
                          });
                        } else if (CardCaptureStepEnum.plateNumber === type) {
                          setNumberPlateImage({
                            ...numberPlate,
                            file: {
                              ...numberPlate.file,
                              fileId: fileId || numberPlate.file.fileId
                            },
                            ocrFields: res?.ocrFields,
                            ocrStatus: res.status
                          });
                        } else if (DocumentCaptureStepEnum.bank === type) {
                          setBankImage({
                            ...bank,
                            file: {
                              ...bank.file,
                              fileId: fileId || bank.file.fileId
                            },
                            ocrFields: res?.ocrFields,
                            ocrStatus: res.status
                          });
                        }
                        setStep(3);
                        setLoading(false);
                      }
                    })
                    .catch((e) => {
                      clearInterval(timer);
                      setStep(3);
                      setLoading(false);
                      console.error(e);
                    });
                }, 1000);
              } else {
                if (
                  data &&
                  (data.status === 'COMPLETE' || data.status === 'ERROR')
                ) {
                  if (CardCaptureStepEnum.vin === type) {
                    setVinImage({
                      ...vinObj,
                      ocrFields: data?.ocrFields,
                      ocrStatus: data.status
                    });
                  } else if (CardCaptureStepEnum.plateNumber === type) {
                    setNumberPlateImage({
                      ...numberPlate,
                      ocrFields: data?.ocrFields,
                      ocrStatus: data.status
                    });
                  }
                } else {
                  if (CardCaptureStepEnum.vin === type) {
                    setVinImage({
                      ...vinObj,
                      ocrFields: [],
                      ocrStatus: data.status
                    });
                  } else if (CardCaptureStepEnum.plateNumber === type) {
                    setNumberPlateImage({
                      ...numberPlate,
                      ocrFields: [],
                      ocrStatus: data.status
                    });
                  }
                }
                setStep(3);
                setLoading(false);
              }
            })
            .catch((e) => {
              setStep(3);
              setLoading(false);
              console.error(e);
            });
        }
      });
    } else {
      setLoading(true);
      if (type === CardCaptureStepEnum.odometer) {
        setOdometer({ ...odometer, isDone: true });
      } else if (type === CardCaptureStepEnum.operatingHours) {
        setOperatingHours({ ...operatingHours, isDone: true });
      } else if (
        type === CardCaptureStepEnum.vehiclePhoto &&
        VehiclePhoneMappings[
          vehiclePhotoList[
            vehiclePhotoList.length - 1
          ] as keyof typeof VehiclePhoneMappings
        ] === vehicle.vehiclePosition
      ) {
        setVehicleImageFront({ ...vehicleFront, isDone: true });
        setVehicleImageDriver({ ...vehicleDriver, isDone: true });
        setVehicleImagePassenger({ ...vehiclePassenger, isDone: true });
        setVehicleImageBack({ ...vehicleBack, isDone: true });
        setVehicle({ ...vehicle, isDone: true });
      } else if (type === CardCaptureStepEnum.assetFront) {
        setVehicleImageFront({ ...vehicleFront, isDone: true });
      } else if (type === CardCaptureStepEnum.assetLeft) {
        setVehicleImagePassenger({ ...vehiclePassenger, isDone: true });
      } else if (type === CardCaptureStepEnum.assetRight) {
        setVehicleImageDriver({ ...vehicleDriver, isDone: true });
      } else if (type === CardCaptureStepEnum.assetBack) {
        setVehicleImageBack({ ...vehicleBack, isDone: true });
      } else if (type === CardCaptureStepEnum.internalFront) {
        setVehicleImageFrontSeats({ ...vehicleFrontSeats, isDone: true });
      } else if (type === CardCaptureStepEnum.internalBack) {
        setVehicleImageBackSeats({ ...vehicleBackSeats, isDone: true });
      } else if (type === CardCaptureStepEnum.internalBoot) {
        setVehicleImageBoot({ ...vehicleBoot, isDone: true });
      } else if (type === CardCaptureStepEnum.internalLivingArea) {
        setVehicleImageLivingArea({ ...vehicleLivingArea, isDone: true });
      } else if (type === CardCaptureStepEnum.internalKitchen) {
        setVehicleImageKitchen({ ...vehicleKitchen, isDone: true });
      } else if (type === CardCaptureStepEnum.internalSleepingArea) {
        setVehicleImageSleepingArea({ ...vehicleSleepingArea, isDone: true });
      }
      uploadFile(type, image, () => {
        setStep(4);
        setLoading(false);
      });
    }
  };

  const captureImageBtn = useRef<HTMLInputElement>(null);
  const uploadFile = (type: any, image: any, cb: (fileId?: any) => void) => {
    const documentType: string =
      type === CardCaptureStepEnum.vehiclePhoto
        ? VehiclePhotosEnum[vehicle.vehiclePosition - 1]
        : type;
    setLoading(true);
    const params = {
      comment: '',
      dataBase64:
        image.split('base64,').length > 1 ? image.split('base64,')[1] : image,
      documentType: documentType,
      extension: 'jpg',
      inspectionId: inspectionId,
      clientAccuracy: geoLocation?.clientAccuracy,
      clientLatitude: geoLocation?.clientLatitude,
      clientLongitude: geoLocation?.clientLongitude
    };

    post(ADD_INSPECTION_FILE, params, false, { timeout: 58000 })
      .then((data) => {
        setLoading(false);
        if (data && data.fileId) {
          if (documentType === CardCaptureStepEnum.vin) {
            setVinFile({ fileId: data.fileId });
          } else if (documentType === CardCaptureStepEnum.plateNumber) {
            setPlateNumberFile({ fileId: data.fileId });
          } else if (documentType === DocumentCaptureStepEnum.bank) {
            setBankFile({ fileId: data.fileId });
          }
          cb(data.fileId);
        } else {
          alert('Failed to upload file');
        }
      })
      .catch((e) => {
        console.error(e);
        if (
          e.code === 'ECONNABORTED' || // Axios timeout
          e.response?.status === 408 || // Server timeout
          e.message.includes('Network Error')
        ) {
          setShowErrorDialog(true);
        } else {
          alert('Failed to upload file, Please login again.');
        }
        setLoading(false);
      });
  };
  const [image, setImage] = useState<string>(initialImage);
  const handleTakePicture = () => {
    if (captureImageBtn.current) {
      //To fix the "File chooser dialog can only be shown with a user activation." and open capture twice
      setTimeout(() => captureImageBtn.current?.click(), 300);
    }
  };

  const handleCaptureNewImage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setLoading(true);
    if (file) {
      processFile(file, maxUploadFileSize, (imageDataUrl, compressedFile) => {
        setBankImage({
          ...bank,
          fileName: compressedFile.name
        });

        setTimeout(() => {
          setLoading(false);
          setImage(imageDataUrl);
          setLoadImage(imageDataUrl);
        }, 1000);
        // event.stopPropagation();
      });
    } else {
      console.log('No file selected.');
    }
  };
  const handleTakeNewPicture = () => {
    if (
      type === DocumentCaptureStepEnum.bank ||
      type === CardCaptureStepEnum.vehiclePhoto ||
      type === CardCaptureStepEnum.vin ||
      type === CardCaptureStepEnum.plateNumber ||
      type === CardCaptureStepEnum.odometer ||
      type === CardCaptureStepEnum.operatingHours ||
      type === CardCaptureStepEnum.assetFront ||
      type === CardCaptureStepEnum.assetLeft ||
      type === CardCaptureStepEnum.assetRight ||
      type === CardCaptureStepEnum.assetBack ||
      type === CardCaptureStepEnum.internalFront ||
      type === CardCaptureStepEnum.internalBack ||
      type === CardCaptureStepEnum.internalBoot ||
      type === CardCaptureStepEnum.internalLivingArea ||
      type === CardCaptureStepEnum.internalKitchen ||
      type === CardCaptureStepEnum.internalSleepingArea
    ) {
      handleTakePicture();
    } else {
      setStep(1);
    }
  };
  if (isLoading) return <LoadingComponent color={primaryColor} />;
  const DocumentStart = () => {
    return (
      <div className="flex flex-col gap-8 h-[calc(100vh-150px)] justify-between w-full">
        <div className="gap-3 flex flex-col">
          <h2
            style={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: 'left',
              color: 'text-grey-900'
            }}>
            Document upload
          </h2>
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
              color: 'text-grey-600'
            }}>
            Your document has successfully uploaded.
          </p>
        </div>
        <div className="w-full h-full">
          {type === DocumentCaptureStepEnum.bank && (
            <div className="flex flex-row gap-2 justify-between items-start">
              <span className="flex flex-row gap-2 items-center">
                <DocumentSellerIcon color={primaryColor} />{' '}
                <span>{bank.fileName}</span>
              </span>

              <PrimaryCheck />
            </div>
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
          <Button
            className={clsx(
              'font-semibold w-auto normal-case rounded text-base shadow-none py-[10px] px-[18px]',
              primaryColor && `bg-[${primaryColor}]`
            )}
            style={{ backgroundColor: primaryColor }}
            onClick={handleUpload}>
            Continue
          </Button>
          <Button
            variant="text"
            className="text-grey-600 normal-case font-semibold rounded text-base hover:bg-blue-500/0 active:bg-blue-500/0 focus:bg-blue-500/0 py-[10px] px-[18px]"
            onClick={uploadTakeNewPicture}>
            Change uploaded document
          </Button>
        </div>
      </div>
    );
  };
  return (
    <>
      {isUpload ? (
        <DocumentStart />
      ) : (
        <div
          className={clsx(
            'flex flex-col gap-8 justify-start overflow-y-scroll no-scrollbar w-full',
            window.innerHeight < 698
              ? 'h-[calc(100vh)]'
              : 'h-[calc(100vh-150)] '
          )}>
          <div className="gap-3 flex flex-col">
            <h2
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: 'text-grey-900'
              }}>
              Check{' '}
              {type === CardCaptureStepEnum.vehiclePhoto
                ? 'visibility'
                : 'readability'}
            </h2>
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                textAlign: 'left',
                color: 'text-grey-600'
              }}>
              {type === CardCaptureStepEnum.vin &&
                'Make sure the VIN is clear to read, with no blur or glare.'}
              {type === CardCaptureStepEnum.plateNumber &&
                'Make sure your number plate details are clear to read, with no blur or glare.'}
              {type === CardCaptureStepEnum.odometer &&
                'Make sure the photo of your odometer is clear to read, with no blur or glare.'}
              {type === CardCaptureStepEnum.operatingHours &&
                'Make sure the photo of your operating hours is clear to read, with no blur or glare.'}
              {type === DocumentCaptureStepEnum.bank &&
                'Make sure all your details, including your account number and BSB are clear to read, with no blur or glare.'}
              {type === CardCaptureStepEnum.vehiclePhoto &&
                vehicle.vehiclePosition === 1 && (
                  <>
                    Make sure the{' '}
                    <span className="font-bold">
                      front of the{' '}
                      {
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .lowerTitle
                      }
                    </span>{' '}
                    is clearly visible, with no obstructions, blur or glare.
                  </>
                )}
              {type === CardCaptureStepEnum.vehiclePhoto &&
                vehicle.vehiclePosition === 2 && (
                  <>
                    Make sure the{' '}
                    <span className="font-bold">
                      {getAssetSpecificContent(
                        assetType as AssetTypesEnum
                      ).sides.driver.toLowerCase()}{' '}
                      of the{' '}
                      {
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .lowerTitle
                      }
                    </span>{' '}
                    is clearly visible, with no obstructions, blur or glare.
                  </>
                )}
              {type === CardCaptureStepEnum.vehiclePhoto &&
                vehicle.vehiclePosition === 3 && (
                  <>
                    Make sure the{' '}
                    <span className="font-bold">
                      {getAssetSpecificContent(
                        assetType as AssetTypesEnum
                      ).sides.passenger.toLowerCase()}{' '}
                      of the{' '}
                      {
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .lowerTitle
                      }
                    </span>{' '}
                    is clearly visible, with no obstructions, blur or glare.
                  </>
                )}
              {type === CardCaptureStepEnum.vehiclePhoto &&
                vehicle.vehiclePosition === 4 && (
                  <>
                    Make sure the{' '}
                    <span className="font-bold">
                      back of the{' '}
                      {
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .lowerTitle
                      }
                    </span>{' '}
                    is clearly visible, with no obstructions, blur or glare.
                  </>
                )}
              {type === CardCaptureStepEnum.assetFront && (
                <>
                  Make sure the{' '}
                  <span className="font-bold">
                    front of the{' '}
                    {
                      getAssetSpecificContent(assetType as AssetTypesEnum)
                        .lowerTitle
                    }
                  </span>{' '}
                  is clearly visible, with no obstructions, blur or glare.
                </>
              )}
              {type === CardCaptureStepEnum.assetLeft && (
                <>
                  Make sure the{' '}
                  <span className="font-bold">
                    {getAssetSpecificContent(
                      assetType as AssetTypesEnum
                    ).sides.passenger.toLowerCase()}{' '}
                    of the{' '}
                    {
                      getAssetSpecificContent(assetType as AssetTypesEnum)
                        .lowerTitle
                    }
                  </span>{' '}
                  is clearly visible, with no obstructions, blur or glare.
                </>
              )}
              {type === CardCaptureStepEnum.assetRight && (
                <>
                  Make sure the{' '}
                  <span className="font-bold">
                    {getAssetSpecificContent(
                      assetType as AssetTypesEnum
                    ).sides.driver.toLowerCase()}{' '}
                    of the{' '}
                    {
                      getAssetSpecificContent(assetType as AssetTypesEnum)
                        .lowerTitle
                    }
                  </span>{' '}
                  is clearly visible, with no obstructions, blur or glare.
                </>
              )}
              {type === CardCaptureStepEnum.assetBack && (
                <>
                  Make sure the{' '}
                  <span className="font-bold">
                    back of the{' '}
                    {
                      getAssetSpecificContent(assetType as AssetTypesEnum)
                        .lowerTitle
                    }
                  </span>{' '}
                  is clearly visible, with no obstructions, blur or glare.
                </>
              )}
              {type === CardCaptureStepEnum.internalFront &&
                'Make sure the front seats of the vehicle are clearly visible, with no obstructions, blur or glare.'}
              {type === CardCaptureStepEnum.internalBack &&
                'Make sure the back seats of the vehicle are clearly visible, with no obstructions, blur or glare.'}
              {type === CardCaptureStepEnum.internalBoot &&
                'Make sure the boot of the vehicle is clearly visible, with no obstructions, blur or glare.'}
              {type === CardCaptureStepEnum.internalLivingArea &&
                'Make sure the photo of your ' +
                  getAssetSpecificContent(assetType as AssetTypesEnum)
                    .lowerTitle +
                  "'s main living area is clearly visible."}
              {type === CardCaptureStepEnum.internalKitchen &&
                'Make sure the photo of your ' +
                  getAssetSpecificContent(assetType as AssetTypesEnum)
                    .lowerTitle +
                  "'s kitchen is clearly visible."}
              {type === CardCaptureStepEnum.internalSleepingArea &&
                'Make sure the photo of your ' +
                  getAssetSpecificContent(assetType as AssetTypesEnum)
                    .lowerTitle +
                  "'s main sleeping area is clearly visible."}
            </p>
          </div>
          <div className="flex justify-center w-full h-auto">
            {type !== DocumentCaptureStepEnum.bank &&
              type !== CardCaptureStepEnum.vehiclePhoto &&
              type !== CardCaptureStepEnum.vin &&
              type !== CardCaptureStepEnum.plateNumber &&
              type !== CardCaptureStepEnum.odometer &&
              type !== CardCaptureStepEnum.operatingHours &&
              type !== CardCaptureStepEnum.assetFront &&
              type !== CardCaptureStepEnum.assetLeft &&
              type !== CardCaptureStepEnum.assetRight &&
              type !== CardCaptureStepEnum.assetBack &&
              type !== CardCaptureStepEnum.internalFront &&
              type !== CardCaptureStepEnum.internalBack &&
              type !== CardCaptureStepEnum.internalBoot &&
              type !== CardCaptureStepEnum.internalLivingArea &&
              type !== CardCaptureStepEnum.internalKitchen &&
              type !== CardCaptureStepEnum.internalSleepingArea && (
                <img
                  src={
                    image.split('base64,').length > 1
                      ? image
                      : `data:image/jpeg;base64,${image}`
                  }
                  style={{
                    height: '250px',
                    width: '100%',
                    objectFit: 'cover',
                    borderColor: primaryColor
                  }}
                  alt="Captured Image"
                  className={clsx(
                    'border-2  rounded-lg',
                    window.innerHeight > 698 ? 'mt-[25%]' : 'mt-[10%]'
                  )}
                />
              )}
            {(type === DocumentCaptureStepEnum.bank ||
              type === CardCaptureStepEnum.vehiclePhoto ||
              type === CardCaptureStepEnum.vin ||
              type === CardCaptureStepEnum.plateNumber ||
              type === CardCaptureStepEnum.odometer ||
              type === CardCaptureStepEnum.operatingHours ||
              type === CardCaptureStepEnum.assetFront ||
              type === CardCaptureStepEnum.assetLeft ||
              type === CardCaptureStepEnum.assetRight ||
              type === CardCaptureStepEnum.assetBack ||
              type === CardCaptureStepEnum.internalFront ||
              type === CardCaptureStepEnum.internalBack ||
              type === CardCaptureStepEnum.internalBoot ||
              type === CardCaptureStepEnum.internalLivingArea ||
              type === CardCaptureStepEnum.internalKitchen ||
              type === CardCaptureStepEnum.internalSleepingArea) && (
              <>
                <img
                  src={
                    image.split('base64,').length > 1
                      ? image
                      : `data:image/jpeg;base64,${image}`
                  }
                  width={298}
                  style={{
                    width: '298px',
                    height: 'auto',
                    maxHeight: window.innerHeight < 698 ? '400px' : '420px',
                    borderColor: primaryColor,
                    objectFit: 'cover'
                  }}
                  alt="Captured Image"
                  className="border-2 rounded-lg"
                />
                <input
                  ref={captureImageBtn}
                  type="file"
                  accept="image/*"
                  capture="environment"
                  style={{ display: 'none' }}
                  onChange={handleCaptureNewImage}
                />
              </>
            )}
          </div>
          <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
            <Button
              className={clsx(
                'font-semibold w-auto normal-case rounded text-base shadow-none py-[10px] px-[18px]',
                primaryColor && `bg-[${primaryColor}]`
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={handleUpload}>
              {[
                CardCaptureStepEnum.vin,
                CardCaptureStepEnum.plateNumber,
                DocumentCaptureStepEnum.bank
              ].includes(type)
                ? 'Image is readable'
                : 'Image is clearly visible'}
            </Button>
            <Button
              variant="text"
              className="text-grey-600 normal-case font-semibold rounded text-base shadow-none hover:bg-blue-500/0 py-[10px] px-[18px]"
              onClick={handleTakeNewPicture}>
              Take a new picture
            </Button>
          </div>
        </div>
      )}
      <UploadErrorModal
        open={showErrorDialog}
        onClose={handleRetry}
        primaryColor={primaryColor}
      />
    </>
  );
};
interface CaptureReviewProps {
  type: string;
  primaryColor: string;
  setStep: (step: number) => void;
}

const CaptureReview: React.FC<CaptureReviewProps> = ({
  type,
  primaryColor,
  setStep
}) => {
  const {
    vinObj,
    numberPlate,
    registration,
    bank,
    operatingHours,
    setNumberPlate,
    setVin,
    setBank,
    inspectionId,
    assetType,
    setInspectionChecks,
    setBankFormStatus,
    setVinFormStatus,
    setNumberPlateFormStatus,
    setOperatingHours
  } = useSellerStore();

  const vinRegExp = /^[A-HJ-NPR-Z0-9]{17}$/;

  const recognizeForm = yup
    .object()
    .shape({
      type: yup.string().required(),
      vinValue: yup.string().when('type', {
        is: (value: string) => value === CardCaptureStepEnum.vin,
        then: (schema) =>
          schema.required('Please enter this field').matches(vinRegExp, {
            message: (
              <span>
                The VIN <span className="font-bold">must be 17 characters</span>{' '}
                long and can only include the following characters: 0–9, A–Z
                (uppercase){' '}
                <span className="font-bold">excluding the letters</span> I, O
                and Q
              </span>
            )
          }),
        otherwise: (schema) => schema.nullable()
      }),
      numberPlateValue: yup.string().when('type', {
        is: (value: string) => value === CardCaptureStepEnum.plateNumber,
        then: (schema) =>
          schema
            .required('Please enter the plate number.')
            .max(9, 'Registration plate must be 9 or fewer characters'),
        otherwise: (schema) => schema.nullable()
      }),
      stateValue: yup.string().when('type', {
        is: (value: string) => value === CardCaptureStepEnum.plateNumber,
        then: (schema) => schema.required('Please select the state'),
        otherwise: (schema) => schema.nullable()
      }),
      // givenName: yup.string().when('type', {
      //   is: (value: string) => value === DocumentCaptureStepEnum.bank,
      //   then: (schema) => schema.required('Please enter your given name'),
      //   otherwise: (schema) => schema.nullable()
      // }),
      // middleName: yup.string().when('type', {
      //   is: (value: string) => value === DocumentCaptureStepEnum.bank,
      //   then: (schema) => schema,
      //   otherwise: (schema) => schema.nullable()
      // }),
      // familyName: yup.string().when('type', {
      //   is: (value: string) => value === DocumentCaptureStepEnum.bank,
      //   then: (schema) => schema.required('Please enter your family name'),
      //   otherwise: (schema) => schema.nullable()
      // }),
      addressLine1: yup.string().when('type', {
        is: (value: string) => value === DocumentCaptureStepEnum.bank,
        then: (schema) => schema.required('Please enter Address Line 1'),
        otherwise: (schema) => schema.nullable()
      }),
      addressLine2: yup.string().when('type', {
        is: (value: string) => value === DocumentCaptureStepEnum.bank,
        then: (schema) => schema.required('Please enter Address Line 2'),
        otherwise: (schema) => schema.nullable()
      }),
      // statementDate: yup.string().when('type', {
      //   is: (value: string) => value === DocumentCaptureStepEnum.bank,
      //   then: (schema) =>
      //     schema
      //       .required('Please enter the bank statement date')
      //       .matches(
      //         /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
      //         'Date must be in the format dd/mm/yyyy'
      //       ),
      //   otherwise: (schema) => schema.nullable()
      // }),
      // accountName: yup.string(),
      accountNumber: yup.string(),
      bsbValue: yup.string().when('type', {
        is: (value: string) => value === DocumentCaptureStepEnum.bank,
        then: (schema) =>
          schema
            .matches(
              /^\d{3}-\d{3}$/,
              'The number must be in the format ***-***.'
            )
            .required('Please enter BSB'),
        otherwise: (schema) => schema.nullable()
      })
      // bankName: yup.string()
    })
    .required();

  const stateOptions = ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT', 'NT'];
  const [submitStatus, setSubmitStatus] = useState(true);
  const {
    register,
    setValue,
    watch,
    trigger,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(recognizeForm),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      type: type,
      vinValue: vinObj.file?.fields2?.vin
        ? vinObj.file?.fields2?.vin?.vin
        : _find(vinObj.ocrFields, { key: 'vin' })?.value || '',
      numberPlateValue: numberPlate.file?.fields2?.licencePlate
        ? numberPlate.file?.fields2.licencePlate.rego
        : _find(numberPlate.ocrFields, { key: 'registrationNumber' })?.value ||
          '',
      stateValue: numberPlate.file?.fields2?.licencePlate
        ? numberPlate.file?.fields2?.licencePlate?.state
        : _find(numberPlate.ocrFields, { key: 'state' })?.value || '',
      accountNumber:
        _find(bank.ocrFields, { key: 'accountNumber' })?.value || '',
      bsbValue: _find(bank.ocrFields, { key: 'bsb' })?.value || '',
      addressLine1: _find(bank.ocrFields, { key: 'addressLine1' })?.value || '',
      addressLine2: _find(bank.ocrFields, { key: 'addressLine2' })?.value || ''
    }
  });

  const {
    vinValue,
    numberPlateValue,
    stateValue,
    // accountName,
    accountNumber,
    bsbValue,
    // givenName,
    // middleName,
    // familyName,
    addressLine1,
    addressLine2
    // statementDate,
    // bankName
  } = watch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const formatBSB = (e: any, value: any) => {
    if (value.length > 7) {
      value = value.substring(0, 7);
    }

    const cleaned = value.replace(/\D+/g, ''); // Remove all non-digit characters
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})$/);

    if (!match) return value;

    const [, first, second] = match;
    let formatted = '';

    if (first) formatted += first;
    if (first.length === 3) {
      formatted += second
        ? '-'
        : e.nativeEvent.inputType !== 'deleteContentBackward'
        ? '-'
        : '';
    }
    if (second) formatted += second;

    return formatted;
  };
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const onSubmit = (data: any) => {
    let params = {};
    if (type === CardCaptureStepEnum.vin) {
      params = {
        inspectionId: inspectionId,
        documentType: type,
        extraFields: {
          vin: {
            vin: data.vinValue
          }
        }
      };
      setVin({
        ...vinObj,
        file: {
          fields2: {
            vin: {
              vin: data.vinValue
            }
          },
          fileId: vinObj.file?.fileId
        },
        available: true,
        isDone: true
      });
      setVinFormStatus(true);
    } else if (type === CardCaptureStepEnum.plateNumber) {
      params = {
        inspectionId: inspectionId,
        documentType: type,
        extraFields: {
          licencePlate: {
            rego: data.numberPlateValue,
            state: data.stateValue
          }
        }
      };
      setNumberPlate({
        ...numberPlate,
        available: true,
        file: {
          fields2: {
            licencePlate: {
              rego: data.numberPlateValue,
              state: data.stateValue
            }
          },
          fileId: numberPlate.file?.fileId
        },
        isDone: true
      });
      setNumberPlateFormStatus(true);
    } else {
      // const cleaned = data.statementDate.replace(/\D+/g, '');
      // const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
      // const [, dd, mm, yyyy] = match;
      params = {
        inspectionId: inspectionId,
        documentType: type,
        extraFields: {
          bankStatement: {
            accountNumber: data.accountNumber,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            // bankName: data.bankName,
            bsb: data.bsbValue
            // familyName: data.familyName,
            // givenName: data.givenName,
            // middleName: data.middleName,
            // statementDate: `${yyyy}-${mm}-${dd}`
          }
        },
        fileId: bank.file?.fileId
      };
      setBank({
        ...bank,
        file: {
          fields2: {
            bankStatement: {
              accountNumber: data.accountNumber,
              addressLine1: data.addressLine1,
              addressLine2: data.addressLine2,
              // bankName: data.bankName,
              bsb: data.bsbValue
              // familyName: data.familyName,
              // givenName: data.givenName,
              // middleName: data.middleName,
              // statementDate: data.statementDate
            }
          },
          fileId: bank.file?.fileId
        },
        isDone: true
      });
      setBankFormStatus(true);
    }
    setLoading(true);
    post(SET_INSPECTION_DOCUMENT, params, false)
      .then(() => {
        post(GET_INSPECTION_CHECK, { inspectionId: inspectionId }, false)
          .then((data) => {
            if (
              data &&
              data.inspectionChecks &&
              Object.keys(data.inspectionChecks).length !== 0
            ) {
              setInspectionChecks(data.inspectionChecks);
              setLoading(false);
              if (submitStatus) {
                // when need to compare the result with vin or rego
                setIsOpenModal(true);
                setSubmitStatus(false);
              } else {
                // not compare, it should be from modal
                setStep(4);
              }
            }
          })
          .catch((e) => {
            console.error(e);
          });
      })
      .catch(() => {
        alert('Failed to re-upload file, Please login again.');
        setLoading(false);
      });
  };
  const handleTextChangeUppercase = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: any
  ) => {
    const upperCaseTrimmedValue = event.target.value
      .replace(/\s+/g, '')
      .toUpperCase();
    setValue(fieldName, upperCaseTrimmedValue, { shouldValidate: true });
  };
  if (isLoading) return <LoadingComponent color={primaryColor} />;
  return (
    <div className="pb-[100px] w-full">
      <div className="gap-6">
        <div className="flex flex-col gap-3">
          <h2 className="text-lg font-semibold text-grey-800">
            {CardCaptureStepEnum.vin === type
              ? 'VIN'
              : CardCaptureStepEnum.plateNumber === type
              ? 'Number Plate'
              : 'Bank Statement'}
          </h2>
          <div className="p-5 border border-green-500 gap-6 flex flex-col rounded-lg">
            <h2 className="text-md font-semibold font-inter text-green-700">
              {CardCaptureStepEnum.vin === type &&
              vinObj.ocrFields &&
              Object.keys(vinObj.ocrFields).length !== 0
                ? 'Have we captured the correct details for the VIN?'
                : CardCaptureStepEnum.vin === type
                ? 'We were unable to read the image. Please enter the Vehicle Identification Number (VIN) below.'
                : CardCaptureStepEnum.plateNumber === type &&
                  numberPlate.ocrFields &&
                  Object.keys(numberPlate.ocrFields).length !== 0
                ? 'Have we captured the correct details for the number plate?'
                : CardCaptureStepEnum.plateNumber === type
                ? 'We were unable to read the image. Please enter the Number Plate details below.'
                : 'Please enter the details below.'}
            </h2>

            {CardCaptureStepEnum.vin === type &&
            vinObj.ocrFields &&
            Object.keys(vinObj.ocrFields).length !== 0 ? (
              <p className="text-sm font-regular font-inter text-green-600">
                Please review and edit the text box below to ensure it matches
                your VIN.
              </p>
            ) : CardCaptureStepEnum.plateNumber === type &&
              numberPlate.ocrFields &&
              Object.keys(numberPlate.ocrFields).length !== 0 ? (
              <p className="text-sm font-regular font-inter text-green-600">
                Please review and edit the text boxes below to ensure they
                exactly match your number plate details.
              </p>
            ) : (
              DocumentCaptureStepEnum.bank === type && (
                <p className="text-sm font-regular font-inter text-green-600">
                  Please complete the text boxes below to ensure they exactly
                  match the bank statement provided.
                </p>
              )
            )}
            {type === CardCaptureStepEnum.vin && (
              <>
                <p className="text-sm font-regular font-inter text-green-600">
                  VIN format:
                </p>
                <ul className="list-disc pl-5 text-sm font-regular font-inter text-green-600">
                  <li>
                    A VIN is{' '}
                    <span className="font-bold">always 17 characters</span>{' '}
                    long.
                  </li>
                  <li>
                    A VIN can only include the following characters: 0–9, A–Z
                    (uppercase){' '}
                    <span className="font-bold">excluding the letters</span> I,
                    O and Q. This prevents confusion with visually similar
                    numerals.
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
        <div className="mt-6 gap-4 flex flex-col">
          {type === CardCaptureStepEnum.vin ? (
            <div className="gap-2 flex flex-col">
              <h3 className="text-sm font-semibold font-inter text-gray-700">
                VIN
              </h3>
              <Input
                placeholder="VIN"
                variant="static"
                className="rounded-none pb-4"
                style={{ borderColor: primaryColor }}
                icon={errors.vinValue && <ErrorIcon />}
                value={vinValue}
                {...register(`vinValue`)}
                onChange={(event) =>
                  handleTextChangeUppercase(event, 'vinValue')
                }
              />
              {errors.vinValue && (
                <span className="text-xs text-error-light">
                  {errors.vinValue.message}
                </span>
              )}{' '}
            </div>
          ) : type === CardCaptureStepEnum.plateNumber ? (
            <>
              <div className="gap-2 flex flex-col">
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Number Plate
                </h3>
                <div>
                  <Input
                    placeholder=""
                    variant="static"
                    className="rounded-none pb-4"
                    style={{ borderColor: primaryColor }}
                    icon={errors.vinValue && <ErrorIcon />}
                    value={numberPlateValue}
                    {...register(`numberPlateValue`)}
                    onChange={(event) =>
                      handleTextChangeUppercase(event, 'numberPlateValue')
                    }
                  />
                  {errors.numberPlateValue && (
                    <span className="text-xs text-error-light">
                      {errors.numberPlateValue.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="gap-3.5 flex flex-col">
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Registration State
                </h3>
                <Select
                  placeholder="Select a State"
                  {...register(`stateValue`)}
                  className="custom-state-select dynamic-border"
                  value={stateValue}
                  style={
                    {
                      '--dynamic-border-color': primaryColor
                    } as React.CSSProperties
                  }
                  onChange={(e) => {
                    if (e && e !== '') {
                      clearErrors('stateValue');
                    }
                    setValue('stateValue', e || '');
                  }}>
                  {stateOptions.map((state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
                {errors.stateValue && (
                  <span className="text-xs text-error-light">
                    {errors.stateValue.message}
                  </span>
                )}
              </div>
            </>
          ) : (
            <div className="gap-8 flex flex-col pb-[150px]">
              <h3 className="text-sm font-semibold font-inter text-gray-700">
                Bank Statement
              </h3>
              {/* <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Given Name
                </h3>
                <Input
                  placeholder=""
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  onKeyDown={(event) => {
                    if (!/[A-Za-z]+/i.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={givenName}
                  {...register(`givenName`)}
                />
                {errors.givenName && (
                  <span className="text-xs text-error-light">
                    {errors.givenName.message}
                  </span>
                )}
              </div>
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Middle Name
                </h3>
                <Input
                  placeholder=""
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  onKeyDown={(event) => {
                    if (!/[A-Za-z]+/i.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={middleName}
                  {...register(`middleName`)}
                />
                {errors.middleName && (
                  <span className="text-xs text-error-light">
                    {errors.middleName.message}
                  </span>
                )}
              </div>
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Family Name
                </h3>
                <Input
                  placeholder=""
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  onKeyDown={(event) => {
                    if (!/[A-Za-z]+/i.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={familyName}
                  {...register(`familyName`)}
                />
                {errors.familyName && (
                  <span className="text-xs text-error-light">
                    {errors.familyName.message}
                  </span>
                )}
              </div>
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Account Name
                </h3>
                <Input
                  placeholder=""
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  value={accountName}
                  {...register(`accountName`)}
                />
                {errors.accountName && (
                  <span className="text-xs text-error-light">
                    {errors.accountName.message}
                  </span>
                )}
              </div> */}
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  BSB
                </h3>
                <Input
                  placeholder="111-000"
                  variant="static"
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      'Backspace',
                      'ArrowLeft',
                      'ArrowRight',
                      'Delete',
                      'Home',
                      'End'
                    ];
                    if (
                      !/^[\d-]+$/.test(e.key) &&
                      !allowedKeys.includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  value={bsbValue}
                  {...register(`bsbValue`, {
                    onChange: (e) => {
                      const formatted = formatBSB(e, e.target.value);
                      setValue('bsbValue', formatted);
                    }
                  })}
                />
                {errors.bsbValue && (
                  <span className="text-xs text-error-light">
                    {errors.bsbValue.message}
                  </span>
                )}
              </div>
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Account Number
                </h3>
                <Input
                  placeholder="123456789"
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  value={accountNumber}
                  {...register(`accountNumber`)}
                />
                {errors.accountNumber && (
                  <span className="text-xs text-error-light">
                    {errors.accountNumber.message}
                  </span>
                )}
              </div>
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Address Line 1
                </h3>
                <Input
                  placeholder="100 GREEN STREET"
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  value={addressLine1}
                  {...register(`addressLine1`)}
                />
                {errors.addressLine1 && (
                  <span className="text-xs text-error-light">
                    {errors.addressLine1.message}
                  </span>
                )}
              </div>
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Address Line 2
                </h3>
                <Input
                  placeholder="SYDNEY NSW 2000 "
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  value={addressLine2}
                  {...register(`addressLine2`)}
                />
                {errors.addressLine2 && (
                  <span className="text-xs text-error-light">
                    {errors.addressLine2.message}
                  </span>
                )}
              </div>
              {/* <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Bank Name
                </h3>
                <Input
                  placeholder=""
                  variant="static"
                  className="rounded-none pb-4"
                  style={{ borderColor: primaryColor }}
                  value={bankName}
                  {...register(`bankName`)}
                />
                {errors.bankName && (
                  <span className="text-xs text-error-light">
                    {errors.bankName.message}
                  </span>
                )}
              </div>
              <div>
                <h3 className="text-sm font-semibold font-inter text-gray-700">
                  Statement Date
                </h3>
                <Input
                  placeholder="dd/mm/yyyy"
                  variant="static"
                  className="rounded-none pb-4"
                  maxLength={10}
                  style={{ borderColor: primaryColor }}
                  value={statementDate}
                  {...register(`statementDate`, {
                    onChange: (e) => {
                      clearErrors('statementDate');
                      const formatted = formatStatementDate(e, e.target.value);
                      setValue('statementDate', formatted);
                    }
                  })}
                />
                {errors.statementDate && (
                  <span className="text-xs text-error-light">
                    {errors.statementDate.message}
                  </span>
                )}{' '}
              </div>*/}
            </div>
          )}
        </div>
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        <Button
          onClick={() => {
            console.log(errors);
            clearErrors();
            if (isValid) {
              if (registration.file && registration.file.fields2) {
                handleSubmit(onSubmit)();
              } else {
                setSubmitStatus(false);
                setIsOpenModal(true);
              }
            } else {
              trigger();
            }
          }}
          className={clsx(
            'align-middle select-none  font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-[10px] px-[18px] rounded shadow-none hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none normal-case grow lg:flex-grow-0 text-white text-[16px]',
            primaryColor && `bg-[${primaryColor}]`
          )}
          style={{ backgroundColor: primaryColor }}>
          Confirm
        </Button>
      </div>

      <ConfirmSellerModal
        open={isOpenModal}
        type={
          CardCaptureStepEnum.vin === type
            ? CardCaptureStepEnum.vin
            : type === CardCaptureStepEnum.plateNumber
            ? CardCaptureStepEnum.plateNumber
            : DocumentCaptureStepEnum.bank
        }
        vin={type === CardCaptureStepEnum.vin ? vinValue : ''}
        licencePlate={
          type === CardCaptureStepEnum.plateNumber
            ? { rego: numberPlateValue || '', state: stateValue || '' }
            : { rego: '', state: '' }
        }
        onSubmit={handleSubmit(onSubmit)}
        onClose={() => {
          setIsOpenModal(false);
          setSubmitStatus(true);
        }}
        setStep={() => setStep(4)}
      />
    </div>
  );
};

interface CaptureCompleteProps {
  type: cardCaptureType;
  vehiclePosition: number;
  primaryColor: string;
  setLoadImage: (image: string) => void;
  setStep: (step: number) => void;
  setPage: (page: SellerPage) => void;
  init: (param?: string) => void;
}
const CaptureComplete: React.FC<CaptureCompleteProps> = ({
  type,
  vehiclePosition,
  setStep,
  setLoadImage,
  primaryColor,
  setPage,
  init
}) => {
  const {
    vinObj,
    numberPlate,
    odometer,
    operatingHours,
    vehicle,
    externalPhotos,
    setVehiclePosition,
    vehiclePhotoList,
    setVehicleImageFront,
    setVehicleImageDriver,
    setVehicleImageBack,
    setVehicleImagePassenger,
    setExternalPhotos,
    setVehicle,
    vehicleFront,
    vehiclePassenger,
    vehicleDriver,
    vehicleBack,
    vehicleFrontSeats,
    vehicleBackSeats,
    vehicleBoot,
    vehicleLivingArea,
    vehicleKitchen,
    vehicleSleepingArea,
    setVehicleImageLivingArea,
    setVehicleImageKitchen,
    setVehicleImageSleepingArea,
    setActivePageTitle,
    documentTypes,
    assetType
  } = useSellerStore();

  const [nextVehiclePosition, setNextVehiclePosition] = useState(0);
  useEffect(() => {
    if (
      type === CardCaptureStepEnum.vehiclePhoto &&
      vehiclePosition <
        VehiclePhoneMappings[
          vehiclePhotoList[
            vehiclePhotoList.length - 1
          ] as keyof typeof VehiclePhoneMappings
        ]
    ) {
      const vehicleIndex = _indexOf(
        vehiclePhotoList,
        VEHICLE_PHOTO_LIST[vehiclePosition - 1]
      );
      const nextIndex =
        VehiclePhoneMappings[
          vehiclePhotoList[
            vehicleIndex + 1
          ] as keyof typeof VehiclePhoneMappings
        ];
      setNextVehiclePosition(nextIndex);
    }
  }, [vehicle.vehiclePosition]);

  const captureImageBtn = useRef<HTMLInputElement>(null);

  const handleCaptureNewImage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file, maxUploadFileSize, (imageDataUrl, compressedFile) => {
        if (type === CardCaptureStepEnum.vehiclePhoto) {
          const vehicleIndex = _indexOf(
            vehiclePhotoList,
            VEHICLE_PHOTO_LIST[vehiclePosition - 1]
          );
          const nextIndex =
            VehiclePhoneMappings[
              vehiclePhotoList[
                vehicleIndex + 1
              ] as keyof typeof VehiclePhoneMappings
            ];
          setVehiclePosition({
            ...vehicle,
            vehiclePosition: nextIndex
          });

          // Update the appropriate vehicle image based on the new position
          if (nextIndex === 1) {
            setVehicleImageFront({
              imageFront: '',
              imageDriver: '',
              imageBack: '',
              imagePassenger: '',
              type: 'VEHICLE',
              vehiclePosition: 1,
              available: true,
              isDone: true
            });
          } else if (nextIndex === 2) {
            setVehicleImageDriver({
              ...vehicle,
              imageDriver: '',
              imageBack: '',
              type: 'VEHICLE',
              available: true,
              imagePassenger: '',
              vehiclePosition: 2,
              isDone: true
            });
          } else if (nextIndex === 3) {
            setVehicleImagePassenger({
              ...vehicle,
              imagePassenger: '',
              available: true,
              imageBack: '',
              type: 'VEHICLE',
              vehiclePosition: 3,
              isDone: true
            });
          } else if (nextIndex === 4) {
            setVehicleImageBack({
              ...vehicle,
              imageBack: '',
              type: 'VEHICLE',
              available: true,
              vehiclePosition: 4,
              isDone: true
            });
          }
        }
        setLoadImage(imageDataUrl);
        setStep(2);
      });
    } else {
      console.log('No file selected.');
    }
  };
  return (
    <>
      <div className="flex flex-col h-full w-full justify-center items-center mt-4">
        <div className="flex flex-col justify-center items-center">
          <SuccessIcon />
        </div>
        <h2 className="text-center font-semibold text-[20px] mt-5">Success</h2>
        <div className="gap-2 flex flex-col text-center text-sm mt-5">
          {type === CardCaptureStepEnum.vin &&
            'Your VIN has been successfully scanned. '}
          {type === CardCaptureStepEnum.plateNumber &&
            'Your number plate has been successfully scanned. '}
          {type === CardCaptureStepEnum.odometer &&
            'Your odometer has been successfully scanned. '}
          {type === CardCaptureStepEnum.operatingHours &&
            'Your photo has been successfully uploaded.'}
          {type === DocumentCaptureStepEnum.bank &&
            'Your bank statement has been successfully scanned. '}
          {type === CardCaptureStepEnum.vehiclePhoto && vehiclePosition < 4 && (
            <>
              <p className="text-gray-600 text-sm text-center">
                {vehiclePosition === 1 &&
                  'The photo of the front of the ' +
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle +
                    ' has been successfully captured.'}
                {vehiclePosition === 2 &&
                  'The photo of the ' +
                    getAssetSpecificContent(
                      assetType as AssetTypesEnum
                    ).sides.driver.toLowerCase() +
                    ' of the ' +
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle +
                    ' has been successfully captured.'}
                {vehiclePosition === 3 &&
                  'The photo of the ' +
                    getAssetSpecificContent(
                      assetType as AssetTypesEnum
                    ).sides.passenger.toLowerCase() +
                    ' of the ' +
                    getAssetSpecificContent(assetType as AssetTypesEnum)
                      .lowerTitle +
                    ' has been successfully captured.'}
              </p>
              {vehiclePosition < 4 && (
                <>
                  <p className="text-gray-600 text-sm mt-6">
                    Next, take a photo of the
                  </p>
                  <p className="text-gray-600 font-bold text-[18px]">
                    {nextVehiclePosition === 2 &&
                      getAssetSpecificContent(
                        assetType as AssetTypesEnum
                      ).sides.driver.toLowerCase() +
                        ' of the ' +
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .lowerTitle +
                        '.'}
                    {nextVehiclePosition === 3 &&
                      getAssetSpecificContent(
                        assetType as AssetTypesEnum
                      ).sides.passenger.toLowerCase() +
                        ' of the ' +
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .lowerTitle +
                        '.'}
                    {nextVehiclePosition === 4 &&
                      'back of the ' +
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .lowerTitle +
                        '.'}
                  </p>
                </>
              )}
              <p className="flex justify-center items-center mb-12 mt-4">
                {nextVehiclePosition === 2 &&
                  assetType === AssetTypesEnum.CAR && (
                    <img src={VehicleRight} />
                  )}
                {nextVehiclePosition === 3 &&
                  assetType === AssetTypesEnum.CAR && <img src={VehicleLeft} />}
                {nextVehiclePosition === 4 &&
                  assetType === AssetTypesEnum.CAR && <img src={VehicleBack} />}
                {nextVehiclePosition === 2 &&
                  assetType === AssetTypesEnum.MOTOR_CYCLE && (
                    <img src={BikeRight} />
                  )}
                {nextVehiclePosition === 3 &&
                  assetType === AssetTypesEnum.MOTOR_CYCLE && (
                    <img src={BikeLeft} />
                  )}
                {nextVehiclePosition === 4 &&
                  assetType === AssetTypesEnum.MOTOR_CYCLE && (
                    <img src={BikeBack} />
                  )}
                {nextVehiclePosition === 2 &&
                  assetType === AssetTypesEnum.CAMPERVAN && (
                    <img src={CaravanRight} />
                  )}
                {nextVehiclePosition === 3 &&
                  assetType === AssetTypesEnum.CAMPERVAN && (
                    <img src={CaravanLeft} />
                  )}
                {nextVehiclePosition === 4 &&
                  assetType === AssetTypesEnum.CAMPERVAN && (
                    <img src={CaravanBack} />
                  )}
                {nextVehiclePosition === 2 &&
                  assetType === AssetTypesEnum.TRAILER && (
                    <img src={TrailerRight} />
                  )}
                {nextVehiclePosition === 3 &&
                  assetType === AssetTypesEnum.TRAILER && (
                    <img src={TrailerLeft} />
                  )}
                {nextVehiclePosition === 4 &&
                  assetType === AssetTypesEnum.TRAILER && (
                    <img src={TrailerBack} />
                  )}
              </p>
            </>
          )}
          {type === CardCaptureStepEnum.vehiclePhoto &&
            vehiclePosition === 4 &&
            `Your ${
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle
            } photos have been successfully captured. `}
          {type === CardCaptureStepEnum.assetFront &&
            'The photo of the front of the ' +
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle +
              ' has been successfully captured.'}
          {type === CardCaptureStepEnum.assetLeft &&
            'The photo of the ' +
              getAssetSpecificContent(
                assetType as AssetTypesEnum
              ).sides.passenger.toLowerCase() +
              ' of the ' +
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle +
              ' has been successfully captured.'}
          {type === CardCaptureStepEnum.assetRight &&
            'The photo of the ' +
              getAssetSpecificContent(
                assetType as AssetTypesEnum
              ).sides.driver.toLowerCase() +
              ' of the ' +
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle +
              ' has been successfully captured.'}
          {type === CardCaptureStepEnum.assetBack &&
            'The photo of the back of the ' +
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle +
              ' has been successfully captured.'}
          {type === CardCaptureStepEnum.internalFront &&
            'The photo of the front seats of the vehicle has been successfully captured.'}
          {type === CardCaptureStepEnum.internalBack &&
            'The photo of the back seats area of the vehicle has been successfully captured.'}
          {type === CardCaptureStepEnum.internalBoot &&
            'The photo of the boot of the vehicle has been successfully captured.'}
          {type === CardCaptureStepEnum.internalLivingArea &&
            'The photo of the living area of the ' +
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle +
              ' has been successfully captured.'}
          {type === CardCaptureStepEnum.internalKitchen &&
            'The photo of the kitchen of the ' +
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle +
              ' has been successfully captured.'}
          {type === CardCaptureStepEnum.internalSleepingArea &&
            'The photo of the sleeping area of the ' +
              getAssetSpecificContent(assetType as AssetTypesEnum).lowerTitle +
              ' has been successfully captured.'}
        </div>
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        <input
          ref={captureImageBtn}
          type="file"
          accept="image/*"
          capture="environment"
          style={{ display: 'none' }}
          onChange={handleCaptureNewImage}
        />
        <Button
          onClick={() => {
            if (
              type === CardCaptureStepEnum.vehiclePhoto &&
              vehiclePosition <
                VehiclePhoneMappings[
                  vehiclePhotoList[
                    vehiclePhotoList.length - 1
                  ] as keyof typeof VehiclePhoneMappings
                ]
            ) {
              if (captureImageBtn.current) {
                setTimeout(() => captureImageBtn.current?.click(), 300);
              }
            } else {
              setStep(0);
              const vehicleIndex = _indexOf(
                VEHICLE_PHOTO_LIST,
                vehiclePhotoList[0]
              );
              setVehiclePosition({
                ...vehicle,
                vehiclePosition: vehicleIndex + 1
              });
              if (
                odometer.isDone &&
                vehicleFrontSeats.isDone &&
                vehicleBackSeats.isDone &&
                vehicleBoot.isDone
              ) {
                setExternalPhotos({
                  ...externalPhotos,
                  isDone: true
                });
              }
              if (
                vehicleFront.isDone &&
                vehicleBack.isDone &&
                vehicleDriver.isDone &&
                vehiclePassenger.isDone
              ) {
                setVehicle({
                  ...vehicle,
                  isDone: true
                });
              }

              // TODO: #1032
              if (
                DocumentCaptureStepEnum.bank === type ||
                ((!documentTypes?.includes(CardCaptureStepEnum.vin) ||
                  vinObj.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.plateNumber) ||
                    numberPlate.isDone) &&
                  (!documentTypes?.includes(
                    CardCaptureStepEnum.operatingHours
                  ) ||
                    operatingHours.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.assetFront) ||
                    vehicle.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.assetBack) ||
                    vehicleBack.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.assetLeft) ||
                    vehicleDriver.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.assetRight) ||
                    vehiclePassenger.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.odometer) ||
                    odometer.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.internalBack) ||
                    vehicleBackSeats.isDone) &&
                  (!documentTypes?.includes(
                    CardCaptureStepEnum.internalFront
                  ) ||
                    vehicleFrontSeats.isDone) &&
                  (!documentTypes?.includes(CardCaptureStepEnum.internalBoot) ||
                    vehicleBoot.isDone) &&
                  (!documentTypes?.includes(
                    CardCaptureStepEnum.internalLivingArea
                  ) ||
                    vehicleLivingArea.isDone) &&
                  (!documentTypes?.includes(
                    CardCaptureStepEnum.internalKitchen
                  ) ||
                    vehicleKitchen.isDone) &&
                  (!documentTypes?.includes(
                    CardCaptureStepEnum.internalSleepingArea
                  ) ||
                    vehicleSleepingArea.isDone))
              ) {
                window.location.reload();
              } else {
                const contains = (target: string[], pattern: string[]) => {
                  let value = 0;
                  pattern.forEach((word) => {
                    value = value + Number(target.includes(word));
                  });
                  return value > 0;
                };
                if (
                  contains(documentTypes, [
                    CardCaptureStepEnum.internalFront,
                    CardCaptureStepEnum.internalBack,
                    CardCaptureStepEnum.internalBoot,
                    CardCaptureStepEnum.internalLivingArea,
                    CardCaptureStepEnum.internalKitchen,
                    CardCaptureStepEnum.internalSleepingArea
                  ])
                ) {
                  if (
                    type === CardCaptureStepEnum.internalBoot ||
                    type === CardCaptureStepEnum.internalFront ||
                    type === CardCaptureStepEnum.internalBack ||
                    type === CardCaptureStepEnum.internalLivingArea ||
                    type === CardCaptureStepEnum.internalKitchen ||
                    type === CardCaptureStepEnum.internalSleepingArea ||
                    type === CardCaptureStepEnum.odometer
                  ) {
                    if (
                      (!documentTypes.includes(
                        CardCaptureStepEnum.internalFront
                      ) ||
                        vehicleFrontSeats.isDone) &&
                      (!documentTypes.includes(
                        CardCaptureStepEnum.internalBack
                      ) ||
                        vehicleBackSeats.isDone) &&
                      (!documentTypes.includes(
                        CardCaptureStepEnum.internalBoot
                      ) ||
                        vehicleBoot.isDone) &&
                      (!documentTypes.includes(
                        CardCaptureStepEnum.internalLivingArea
                      ) ||
                        vehicleLivingArea.isDone) &&
                      (!documentTypes.includes(
                        CardCaptureStepEnum.internalKitchen
                      ) ||
                        vehicleKitchen.isDone) &&
                      (!documentTypes.includes(
                        CardCaptureStepEnum.internalSleepingArea
                      ) ||
                        vehicleSleepingArea.isDone) &&
                      (!documentTypes.includes(CardCaptureStepEnum.odometer) ||
                        odometer.isDone)
                    ) {
                      init('init');
                      setActivePageTitle(
                        getAssetSpecificContent(assetType as AssetTypesEnum)
                          .title
                      );
                      setPage(SellerPage.vehicle);
                      return;
                    }
                    // Return to internal-photos step
                    init('internal-photos');
                    setActivePageTitle(SellerPageTitle.InternalPhotos);
                    return;
                  }
                }

                if (
                  type === CardCaptureStepEnum.assetFront ||
                  type === CardCaptureStepEnum.assetBack ||
                  type === CardCaptureStepEnum.assetLeft ||
                  type === CardCaptureStepEnum.assetRight
                ) {
                  if (
                    (!documentTypes.includes(CardCaptureStepEnum.assetFront) ||
                      vehicle.isDone) &&
                    (!documentTypes.includes(CardCaptureStepEnum.assetBack) ||
                      vehicleBack.isDone) &&
                    (!documentTypes.includes(CardCaptureStepEnum.assetLeft) ||
                      vehicleDriver.isDone) &&
                    (!documentTypes.includes(CardCaptureStepEnum.assetRight) ||
                      vehiclePassenger.isDone)
                  ) {
                    // Return to vehicles-photos step
                    init('init');
                    setActivePageTitle(
                      getAssetSpecificContent(assetType as AssetTypesEnum).title
                    );
                    setPage(SellerPage.vehicle);
                    return;
                  }
                  // Return to vehicles-photos step
                  setActivePageTitle(SellerPageTitle.ExternalPhotos);
                  init('vehicles-photos');
                  return;
                }

                init('init');
                setActivePageTitle(
                  getAssetSpecificContent(assetType as AssetTypesEnum).title
                );
                setPage(SellerPage.vehicle);
                return;
              }
              window.location.reload();
              init();
            }
          }}
          className={clsx(
            'align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 shadow-none disabled:shadow-none disabled:pointer-events-none py-[10px] px-[18px] hover:shadow-none hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] rounded active:shadow-none normal-case grow lg:flex-grow-0 text-white text-[16px]',
            primaryColor && `bg-[${primaryColor}]`
          )}
          style={{ backgroundColor: primaryColor }}>
          Continue
        </Button>
      </div>
    </>
  );
};
const UploadErrorModal = ({
  open,
  onClose,
  primaryColor
}: {
  open: boolean;
  onClose: () => void;
  primaryColor: string;
}) => {
  return (
    <Modal size="md" open={open} onClose={onClose} hideClose>
      <div className="pt-6 flex flex-col items-center gap-4">
        <h2 className="text-grey-900 font-semibold flex gap-2 flex-row w-full">
          Upload Unsuccessful
        </h2>
        <p className="text-gray-800">
          It seems your image couldn&apos;t be uploaded. This may be due to a
          slow or unstable internet connection. Please check your connection and
          try again.
        </p>
        <Button
          className="w-full normal-case font-semibold text-white"
          style={{ backgroundColor: primaryColor }}
          onClick={onClose}>
          Try Again
        </Button>
      </div>
    </Modal>
  );
};
export default VehicleComponent;
