import { AssetType, AssetTypesEnum } from '@types';

export const processFile = (
  file: File,
  maxSize: number,
  callback: (imageDataUrl: string, file: File) => void
) => {
  const img = new Image();
  const reader = new FileReader();

  reader.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const compressImage = (quality: number) => {
        canvas.toBlob(
          (compressedBlob) => {
            if (!compressedBlob) return;

            const compressedFile = new File([compressedBlob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });

            const compressedReader = new FileReader();
            compressedReader.onload = () => {
              const compressedImageDataUrl = compressedReader.result as string;
              if (compressedImageDataUrl.length > maxSize && quality > 0.1) {
                // Still too big, continue compressing
                const newQuality = quality - 0.08;
                compressImage(Math.max(newQuality, 0.1));
              } else {
                // Size is acceptable or we've reached the minimum quality
                callback(compressedImageDataUrl, compressedFile);
              }
            };
            compressedReader.readAsDataURL(compressedBlob);
          },
          'image/jpeg',
          quality
        );
      };

      // Start the compression process
      const originalImageDataUrl = reader.result as string;
      if (originalImageDataUrl.length > maxSize) {
        // If the original image is too large, start compression
        const initialQuality = 0.95;
        compressImage(initialQuality);
      } else {
        // Use the original if it's small enough
        callback(originalImageDataUrl, file);
      }
    };
    img.src = reader.result as string;
  };
  reader.readAsDataURL(file);
};

export const validatePassword = (password: string) => {
  // Password rules:
  // 1. At least one lowercase letter
  const hasLowerCase = /(?=.*[a-z])/;

  // 2. At least one uppercase letter
  const hasUpperCase = /(?=.*[A-Z])/;

  // 3. At least one digit
  const hasDigit = /(?=.*\d)/;

  // 4. At least one special character (as defined by Cognito)
  const hasSpecialChar = /(?=.*[$*.[\]{}()?"!@#%&/\\,><':;|_~`+=-])/;

  // 5. Minimum length of 8 characters and only allowed characters
  const validLength = /^[A-Za-z\d$*.[\]{}()?"!@#%&/\\,><':;|_~`+=-]{8,}$/;

  return (
    hasLowerCase.test(password) &&
    hasUpperCase.test(password) &&
    hasDigit.test(password) &&
    hasSpecialChar.test(password) &&
    validLength.test(password)
  );
};
interface AssetContent {
  title: string;
  lowerTitle: string;
  sides: {
    front: string;
    back: string;
    passenger: string;
    driver: string;
  };
}
export const getAssetSpecificContent = (assetType: AssetType): AssetContent => {
  switch (assetType) {
    case AssetTypesEnum.CAR:
      return {
        title: 'Vehicle',
        lowerTitle: 'vehicle',
        sides: {
          front: 'Vehicle Front',
          back: 'Vehicle Back',
          passenger: 'Passenger side',
          driver: "Driver's side"
        }
      };
    case AssetTypesEnum.MOTOR_CYCLE:
      return {
        title: 'Motorbike',
        lowerTitle: 'motorbike',
        sides: {
          front: 'Motorbike Front',
          back: 'Motorbike Back',
          passenger: 'Left Side',
          driver: 'Right Side'
        }
      };
    case AssetTypesEnum.CAMPERVAN:
      return {
        title: 'Caravan',
        lowerTitle: 'caravan',
        sides: {
          front: 'Caravan Front',
          back: 'Caravan Back',
          passenger: 'Left Side',
          driver: 'Right Side'
        }
      };
    case AssetTypesEnum.TRAILER:
      return {
        title: 'Trailer',
        lowerTitle: 'trailer',
        sides: {
          front: 'Trailer Front',
          back: 'Trailer Back',
          passenger: 'Left Side',
          driver: 'Right Side'
        }
      };
    case AssetTypesEnum.EQUIPMENT:
      return {
        title: 'Heavy Vehicle / Equipment',
        lowerTitle: 'heavy vehicle / equipment',
        sides: {
          front: 'Heavy Vehicle / Equipment Front',
          back: 'Heavy Vehicle / Equipment Back',
          passenger: 'Left Side',
          driver: 'Right Side'
        }
      };
    default:
      return {
        title: 'Asset',
        lowerTitle: 'asset',
        sides: {
          front: 'Asset Front',
          back: 'Asset Back',
          passenger: 'Left Side',
          driver: 'Right Side'
        }
      };
  }
};
