import React, { useState } from 'react';
import Button from '@components/forms/Button';
import { ReactComponent as ExclamationCircleIcon } from '@assets/icons/alert-circle.svg';
import { post, UPDATE_USER } from '@services';
import { validatePassword } from '@utils';

interface ChangePasswordProps {
  title: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ title }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const resetForm = () => {
    setConfirmPassword('');
    setNewPassword('');
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (!validatePassword(newPassword)) {
      setError(
        'Password must contain at least 8 characters, including at least one number, one lowercase letter, one uppercase letter, and one of the following symbols: ^ $ * . [ ] { } ( ) ? - " ! @ # % & /  , > < \' : ; | _ ~ ` + ='
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('New password and confirm password do not match.');
      return;
    }
    post(UPDATE_USER, { password: newPassword }, false)
      .then(() => {
        setSuccess('Profile saved!');
        resetForm();
        const timer = setTimeout(() => {
          setSuccess('');
        }, 5000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        console.log(error);
        setError('Error updating password. Please try again.');
      });
  };

  return (
    <div>
      <h1 className="font-semibold text-3xl text-gray-900 mb-6">{title}</h1>
      <form onSubmit={handleSubmit} style={{ maxWidth: '520px' }}>
        <div className="mb-6 relative">
          <label className="block font-medium text-gray-700 text-sm">
            New Password
          </label>
          <input
            type="password"
            className={`w-full p-2 border rounded ${
              error ? 'border-primary' : ''
            }`}
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {error && (
            <ExclamationCircleIcon className="absolute right-2 top-[30px] h-5 w-5 text-primary" />
          )}
          {error && <div className="mb-2 text-primary text-sm">{error}</div>}
        </div>
        <div className="mb-6 relative">
          <label className="block font-medium text-gray-700 text-sm">
            Confirm New Password
          </label>
          <input
            type="password"
            className={`w-full p-2 border rounded ${
              error ? 'border-primary' : ''
            }`}
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && (
            <ExclamationCircleIcon className="absolute right-2 top-[30px] h-5 w-5 text-primary" />
          )}
        </div>
        {success && (
          <div className="mb-2 text-green-700 text-sm">{success}</div>
        )}
        <Button type="submit" className="bg-primary">
          Save
        </Button>
      </form>
    </div>
  );
};

export default ChangePassword;
