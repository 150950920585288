import React from 'react';

import Button from '@components/forms/Button';
import { useInspectionDetailStore } from '@stores';
import _find from 'lodash/find';
import { LenderDocumentTypeEnum } from '@types';
import _intersection from 'lodash/intersection';
import { Chip, ChipProps } from '@material-tailwind/react';
import { DOCUMENT_TYPE } from '@constants';
import Modal from '@components/widgets/Modal';
import { useNavigate } from 'react-router-dom';
import { getAssetSpecificContent } from '@utils';
import { AssetTypesEnum } from '@types';
type Props = {
  onPrevious: () => void;
  onClose: () => void;
  onNext: () => void;
};
const TextChip = (props: ChipProps) => {
  return (
    <Chip
      color="gray"
      variant="outlined"
      className="rounded-full border-grey-200 bg-grey-50 text-grey-700 normal-case w-fit text-sm font-medium"
      {...props}
    />
  );
};

const ReviewStep: React.FC<Props> = ({ onPrevious, onNext }) => {
  const { inspectionDetailData } = useInspectionDetailStore();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const navigate = useNavigate();

  const getTimeZone = (state: string): string => {
    const timeZones: { [key: string]: string } = {
      NSW: 'Australia/Sydney',
      VIC: 'Australia/Melbourne',
      QLD: 'Australia/Brisbane',
      SA: 'Australia/Adelaide',
      WA: 'Australia/Perth',
      TAS: 'Australia/Hobart',
      ACT: 'Australia/Sydney',
      NT: 'Australia/Darwin'
    };
    return timeZones[state] || 'Australia/Sydney';
  };

  const getLocalTime = (timeZone: string): Date => {
    const localTimeStr = new Date().toLocaleString('en-US', { timeZone });
    return new Date(localTimeStr);
  };

  const handleSubmit = () => {
    const timeZone = getTimeZone(inspectionDetailData?.saleState);

    const now = getLocalTime(timeZone);
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Check if current time is between 7:00 AM and 9:30 PM
    const isWithinHours =
      (hours > 7 || (hours === 7 && minutes >= 0)) &&
      (hours < 21 || (hours === 21 && minutes <= 30));

    if (!isWithinHours) {
      setIsModalVisible(true);
      return;
    }

    onNext();
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleBack = () => {
    navigate('/inspections');
  };

  return (
    <div>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">Review</h3>
      <p className="text-grey-600 mb-3 text-sm">
        Please review the inspection details carefully before proceeding to
        submission.
      </p>
      <div className="p-8 rounded-lg border border-grey-200 bg-grey-50 mt-4 flex flex-col gap-8">
        <div className="mt-6 font-medium text-grey-800">
          <p className="mb-0.5 font-semibold text-md">
            Lender: {inspectionDetailData.lender.lenderName}
          </p>
          <p className="mb-0.5 font-semibold ">
            Inspection template: {inspectionDetailData?.lender?.template?.name}
          </p>
          <p className="mb-0.5 font-semibold">
            Lender reference number: {inspectionDetailData.lenderReference}
          </p>
        </div>
        <div className="font-medium text-grey-800">
          <p className="text-sm text-grey-600 font-medium mb-3">
            Requested Documentation
          </p>

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.SELLER_ID,
            LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
            LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
            LenderDocumentTypeEnum.FACE
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Seller Identification
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.SELLER_ID,
                  LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
                  LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
                  LenderDocumentTypeEnum.FACE
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return doc && doc.value ? (
                    <TextChip key={doc?.value} value={doc?.label} />
                  ) : null;
                })}
              </div>
            </div>
          )}

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.ASSET_BACK,
            LenderDocumentTypeEnum.ASSET_FRONT,
            LenderDocumentTypeEnum.ASSET_LEFT,
            LenderDocumentTypeEnum.ASSET_RIGHT,
            LenderDocumentTypeEnum.INTERNAL_FRONT,
            LenderDocumentTypeEnum.INTERNAL_BACK,
            LenderDocumentTypeEnum.INTERNAL_BOOT,
            LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
            LenderDocumentTypeEnum.INTERNAL_KITCHEN,
            LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
            LenderDocumentTypeEnum.VIN,
            LenderDocumentTypeEnum.REGO_PLATE,
            LenderDocumentTypeEnum.ODOMETER,
            LenderDocumentTypeEnum.OPERATING_HOURS
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                {
                  getAssetSpecificContent(
                    inspectionDetailData.assetType as AssetTypesEnum
                  ).title
                }
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.ASSET_BACK,
                  LenderDocumentTypeEnum.ASSET_FRONT,
                  LenderDocumentTypeEnum.ASSET_LEFT,
                  LenderDocumentTypeEnum.ASSET_RIGHT,
                  LenderDocumentTypeEnum.INTERNAL_FRONT,
                  LenderDocumentTypeEnum.INTERNAL_BACK,
                  LenderDocumentTypeEnum.INTERNAL_BOOT,
                  LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
                  LenderDocumentTypeEnum.INTERNAL_KITCHEN,
                  LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
                  LenderDocumentTypeEnum.VIN,
                  LenderDocumentTypeEnum.REGO_PLATE,
                  LenderDocumentTypeEnum.ODOMETER,
                  LenderDocumentTypeEnum.OPERATING_HOURS
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  let label = doc?.label;
                  const assetType =
                    inspectionDetailData.assetType as AssetTypesEnum;
                  if (item === LenderDocumentTypeEnum.ASSET_BACK) {
                    label = `Photo ${
                      getAssetSpecificContent(assetType).lowerTitle
                    } back`;
                  }
                  if (item === LenderDocumentTypeEnum.ASSET_FRONT) {
                    label = `Photo ${
                      getAssetSpecificContent(assetType).lowerTitle
                    } front`;
                  }
                  if (item === LenderDocumentTypeEnum.ASSET_LEFT) {
                    label = `Photo ${
                      getAssetSpecificContent(assetType).lowerTitle
                    } ${getAssetSpecificContent(
                      assetType
                    ).sides.passenger.toLowerCase()}`;
                  }
                  if (item === LenderDocumentTypeEnum.ASSET_RIGHT) {
                    label = `Photo ${
                      getAssetSpecificContent(assetType).lowerTitle
                    } ${getAssetSpecificContent(
                      assetType
                    ).sides.driver.toLowerCase()}`;
                  }
                  return <TextChip key={doc?.value} value={label} />;
                })}
              </div>
            </div>
          )}

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.REGO_PAPERS,
            LenderDocumentTypeEnum.OWNERSHIP_PROOF
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Ownership Documents
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.REGO_PAPERS,
                  LenderDocumentTypeEnum.OWNERSHIP_PROOF
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}

          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.BANK_STATEMENTS,
            LenderDocumentTypeEnum.PAYOUT_LETTER
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Financial Documentation
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.BANK_STATEMENTS,
                  LenderDocumentTypeEnum.PAYOUT_LETTER
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}
          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.ROADWORTHY_CERT,
            LenderDocumentTypeEnum.SAFETY_CERT
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                Roadworthy / Safety Certificate
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.ROADWORTHY_CERT,
                  LenderDocumentTypeEnum.SAFETY_CERT
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}
          {_intersection(inspectionDetailData.documentTypes, [
            LenderDocumentTypeEnum.PPSR,
            LenderDocumentTypeEnum.NEVDIS,
            LenderDocumentTypeEnum.RAV
          ]).length > 0 && (
            <div className="mb-8">
              <p className="text-grey-700 font-semibold mb-3 text-sm">
                External Verifications
              </p>
              <div className="flex gap-2 flex-wrap">
                {_intersection(inspectionDetailData.documentTypes, [
                  LenderDocumentTypeEnum.PPSR,
                  LenderDocumentTypeEnum.NEVDIS,
                  LenderDocumentTypeEnum.RAV
                ]).map((item: any) => {
                  const doc = _find(DOCUMENT_TYPE, ['value', item]);
                  return <TextChip key={doc?.value} value={doc?.label} />;
                })}
              </div>
            </div>
          )}
        </div>

        <div className="font-medium text-grey-800">
          <p className="text-sm text-grey-600 font-medium">Seller</p>
          {inspectionDetailData.seller && (
            <>
              {inspectionDetailData.seller.firstName && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.firstName}{' '}
                  {inspectionDetailData.seller.lastName}
                </p>
              )}
              {inspectionDetailData.seller.email && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.email}
                </p>
              )}
              {inspectionDetailData.seller.mobile && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.mobile}
                </p>
              )}
              {inspectionDetailData.seller.address && (
                <p className="mb-0.5 font-medium">
                  {inspectionDetailData.seller.address}
                </p>
              )}
            </>
          )}
        </div>
        {inspectionDetailData?.lender?.template?.buyerRequired && (
          <div className="font-medium text-grey-800 ">
            <p className="text-sm text-grey-600 font-medium">Buyer</p>
            {(inspectionDetailData.buyer.firstName ||
              inspectionDetailData.buyer.lastName) && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.firstName +
                  ' ' +
                  inspectionDetailData.buyer.lastName}
              </p>
            )}
            {inspectionDetailData.buyer.email && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.email}
              </p>
            )}
            {inspectionDetailData.buyer.email && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.mobile}
              </p>
            )}
            {inspectionDetailData.buyer.address && (
              <p className="mb-0.5 font-medium">
                {inspectionDetailData.buyer.address}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between border-t border-t-grey-200 pt-5 mt-4 gap-3">
        <div className="flex flex-row gap-2">
          <Button
            className="bg-white text-grey-700 border-grey-300 !text-sm"
            variant="outlined"
            onClick={onPrevious}>
            Previous
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button
            className="text-white bg-primary !text-sm"
            onClick={handleSubmit}
            disabled={
              !(
                inspectionDetailData.seller.isDone &&
                (inspectionDetailData?.lender?.template?.buyerRequired
                  ? inspectionDetailData.buyer.isDone
                  : true) &&
                inspectionDetailData.documentValidation &&
                inspectionDetailData.lender.isDone
              )
            }>
            Submit Inspection
          </Button>
        </div>
      </div>
      <Modal
        size="sm"
        open={isModalVisible}
        onClose={handleClose}
        title="Outside of Operating Hours">
        <p className="text-[14px] text-[#475467]">
          Inspection invites can only be submitted between 7:00 AM and 9:30 PM
          in the seller’s local time. Please try again during those hours.
        </p>
        <Button
          className="text-gray-800 text-[16px] bg-white mt-8 w-full border border-gray-300 hover:bg-gray-400"
          onClick={handleBack}>
          Back to Inspections
        </Button>
      </Modal>
    </div>
  );
};

export default ReviewStep;
