import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@components/forms/Button';
import StatusChip from '@components/widgets/StatusChip';
import VehicleInfo from '@pages/verifications/components/VehicleInfo';
import { ReactComponent as DownloadIcon } from '@assets/icons/download-icon.svg';
import { AV_DETAILS, GET_ASSET_REPORT, post } from '@services';
import { BiLoaderAlt } from 'react-icons/bi';
import clsx from 'clsx';
import {
  DocumentFormType,
  InspectionSecurityStatusEnum,
  InspectionStatusEnum,
  NevdisSecurityCheckEnum
} from '@types';
import Loader from '@components/widgets/Loader';
import { IVerification } from '@interface';
import { format, parseISO } from 'date-fns';
import Header from '@components/layouts/Header';
import RightDetailsBar from './components/RightDetailsBar';
import { useMediaQuery } from 'usehooks-ts';
import { useInspectionDetailStore } from '@stores';
import { LOGOS_LIST } from '@constants';
const VerificationDetailView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const formattedDate = (date: any) =>
    format(parseISO(date), 'dd/MM/yyyy h:mma');
  const [verification, setVerification] = useState<IVerification>();
  const [checks, setChecks] = useState<any>();
  const [type, setType] = useState<DocumentFormType>(
    NevdisSecurityCheckEnum.NEVDIS_SECURITY_RAV
  );
  const [status, setStatus] = useState<InspectionSecurityStatusEnum>(
    InspectionSecurityStatusEnum.error
  );
  const [isCollapsed, setCollapse] = useState(true);
  const [variant, setVariant] = useState('');
  const [statusChipLabel, setStatusChipLabel] = useState('');
  const onDownload = () => {
    setDownLoadLoading(true);
    post(GET_ASSET_REPORT, {
      verificationId: params.id
    }).then((data) => {
      setDownLoadLoading(false);
      const base64Pdf = data.base64Pdf;
      const linkSource = `data:application/pdf;base64,${base64Pdf}`;
      const fileName = 'inspection-report.pdf';
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };
  const [isLoading, setLoading] = useState(true);
  const [isDownLoadLoading, setDownLoadLoading] = useState(false);
  const fetchData = () => {
    post(AV_DETAILS, {
      verificationId: params.id
    })
      .then((response) => {
        setVerification(response.assetVerification);
        setChecks(response.checks);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        navigate('/login');
      });
  };
  const isMobile = useMediaQuery('(max-width: 720px)');
  const inspectionDetailStore = useInspectionDetailStore();
  const isSideCollapsed = inspectionDetailStore.isSideCollapsed;
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) return <Loader />;

  return (
    <>
      <div
        style={{
          maxWidth: isMobile
            ? '100%'
            : `calc(100vw - ${isSideCollapsed ? '80px' : '256px'})`
        }}
        className={`main min-h-full md:min-h-screen w-full flex flex-col`}>
        <Header />
        <div className="content overflow-y-auto overflow-x-hidden w-full md:w-auto sm:justify-start">
          <div className="flex-1 p-4 lg:p-6 flex flex-col w-full ">
            <div>
              <div className="">
                <div className="flex flex-col justify-start lg:justify-between gap-y-4 w-full md:mb-6">
                  <div className="w-ful flex flex-col gap-6 pt-6 pb-0 md:pb-6 px-3 lg:p-0">
                    <div className="flex gap-3 items-center">
                      <span className="text-base font-semibold text-grey-900">
                        {verification?.clientReference !== '' &&
                          verification?.clientReference}{' '}
                      </span>
                      <StatusChip
                        variant={
                          verification?.status ===
                          InspectionStatusEnum.Completed
                            ? 'success1'
                            : 'info2'
                        }
                        label={verification?.status || ''}
                        clsName="rounded border-0"
                      />
                    </div>
                    <div className="flex flex-col  2xl:flex-row gap-6 2xl:gap-0">
                      <div className="mt-1 text-grey-600 gap-6 xl:gap-12 2xl:inline-flex grid 2xl:grid-cols-4 grid-cols-2 w-full xl:w-4/5">
                        {verification && (
                          <IconCard data={verification} type={'vehicle'} />
                        )}
                        {verification?.createdTime && (
                          <IconCard
                            data={formattedDate(verification.createdTime)}
                            type={'createdDate'}
                          />
                        )}
                        {verification?.vin && (
                          <IconCard data={verification.vin} type={'vin'} />
                        )}
                        {verification?.rego && (
                          <IconCard data={verification.rego} type={'rego'} />
                        )}
                        {verification?.state && (
                          <IconCard data={verification.state} type={'state'} />
                        )}
                      </div>
                      {/* TODO:Some updates are required for the Asset Verification Report, that are not yet ready.  Please hide the ‘Asset Verification Report’ for now.  We’ll show it again when the report is ready. */}

                      {/* <div className="w-full 2xl:w-1/5 flex 2xl:justify-end 2xl:items-end sm:justify-start sm: items-start gap-2">
                        <Button
                          onClick={onDownload}
                          className={clsx(
                            'text-grey-700 border border-grey-300 bg-light !text-sm capitalize flex flex-row gap-2 items-center justify-center min-w-[181px] min-h-[44px]  rounded-lg'
                          )}>
                          {!isDownLoadLoading ? (
                            <span className="py-4 px-[10px] !text-sm font-semibold whitespace-nowrap flex flex-row gap-2">
                              <DownloadIcon />
                              Asset Verification Report
                            </span>
                          ) : (
                            <>
                              <BiLoaderAlt className="animate-spin" />
                            </>
                          )}
                        </Button> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-flow-row xl:grid-cols-4 grid-cols-1 xl:flex xl:flex-row justify-between md:border-t md:border-t-grey-200 pt-0 gap-3 lg:gap-2 xl:gap-6 md:border md:min-w-[960px] pl-2 md:pl-0">
                <div className="h-full md:col-span-3 xl:flex-1 xl:h-auto pl-2 pr-2 pb-2 xl:pr-6 xl:pl-6 xl:pb-6 lg:p-2 lg:pb-6">
                  <VehicleInfo
                    checks={checks}
                    verification={verification}
                    setType={setType}
                    setStatus={setStatus}
                    setIsCollapsed={setCollapse}
                    setVariant={setVariant}
                    setStatusChipLabel={setStatusChipLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[84px]"></div>
      </div>
      <RightDetailsBar
        type={type}
        status={status}
        data={checks}
        isCollapsed={isCollapsed}
        setCollapse={setCollapse}
        variant={variant}
        statusChipLabel={statusChipLabel}
      />
    </>
  );
};
type Props = {
  data: any;
  type: string;
};
const IconCard = ({ data, type }: Props) => {
  switch (type) {
    case 'createdDate':
      return (
        <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
          <div className="flex flex-col w-full">
            <div className="text-grey-900 font-semibold">{data}</div>
            <div className="text-gray-light-grey font-medium text-sm">
              Created date/time
            </div>
          </div>
        </div>
      );
    case 'vin':
      return (
        <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
          <div className="flex flex-col w-full">
            <div className="text-grey-900 font-semibold">{data}</div>
            <div className="text-gray-light-grey font-medium text-sm">VIN</div>
          </div>
        </div>
      );
    case 'rego':
      return (
        <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
          <div className="flex flex-col w-full">
            <div className="text-grey-900 font-semibold">{data}</div>
            <div className="text-gray-light-grey font-medium text-sm">
              Number Plate
            </div>
          </div>
        </div>
      );
    case 'state':
      return (
        <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
          <div className="flex flex-col w-full">
            <div className="text-grey-900 font-semibold">{data}</div>
            <div className="text-gray-light-grey font-medium text-sm">
              State
            </div>
          </div>
        </div>
      );
    case 'vehicle':
      return (
        <div className="flex flex-row gap-2 width-1/4 md:max-width-1/2 ">
          {data.vehicleMake &&
            data.vehicleMake !== '' &&
            LOGOS_LIST.includes(data.vehicleMake.toUpperCase()) && (
              <div className="flex justify-center items-center min-w-[52px] w-[52px] h-[52px]">
                <img
                  src={`/images/logo/${data.vehicleMake.toUpperCase()}.png`}
                  alt=""
                  className="w-[52px] h-[52px]"
                />
              </div>
            )}

          <div className="flex flex-col w-full">
            <div className="text-grey-900 font-semibold xl:truncate md:flex-wrap">
              {data.vehicleModel}
            </div>
            <div className="text-gray-light-grey font-medium text-sm">
              {data.vehicleYear}
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};
export default VerificationDetailView;
