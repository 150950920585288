import Loader from '@components/widgets/Loader';
import { DETAILS_BANK, DETAILS_VIEW_BANK } from '@constants';
import { Button } from '@material-tailwind/react';
import { GET_INSPECTION_FILE, post } from '@services';
import { useInspectionDetailStore } from '@stores';
import _get from 'lodash/get';
import _map from 'lodash/map';
import {
  DocumentFormType,
  InspectionSecurityStatusEnum,
  BankSecurityCheckEnum
} from '@types';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
interface IProps {
  type: DocumentFormType;
  status: InspectionSecurityStatusEnum;
  data: any;
}

const BankCheckCard = ({ type, data }: IProps) => {
  const { activePanel, isCollapsed, setCollapse, inspectionDetailData } =
    useInspectionDetailStore();
  const isDemo = location.pathname.includes('/demo/inspection');
  const formattedDate = (date: any) => format(date, 'dd/MM/yyyy h:mma');
  const [formElements, setFormElements] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [imgsrc, setImgSrc] = useState<any>();
  const param = useParams();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [ocrData, setOcrData] = useState<any>();
  const [seller, setSeller] = useState<any>([]);
  const [specialFormElements, setSpecialFormElements] = useState<any>([]);
  const handleClose = () => {
    setCollapse(true);
  };
  useEffect(() => {
    if (type === BankSecurityCheckEnum.BANK_SECURITY_DATA_EDIT && !isDemo) {
      const fileTypes = inspectionDetailData.files.filter(
        (e: any) => e.documentType === 'BANK_STATEMENTS'
      );
      setOcrData(inspectionDetailData.inspectionChecks.bankStatement);
      if (fileTypes.length > 0) {
        setSeller(fileTypes[0].user);
        setLoading(true);
        post(
          GET_INSPECTION_FILE,
          { inspectionId: param.id, fileId: fileTypes[0].fileId },
          false
        )
          .then((data) => {
            setLoading(false);
            if (data && data.dataBase64) {
              setImgSrc(data.dataBase64);
              setSelectedFile(data.file);
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
      }
    }
    setSpecialFormElements(DETAILS_VIEW_BANK);
    setFormElements(DETAILS_BANK);
  }, [type]);
  if (isLoading) return <Loader />;
  switch (type) {
    case BankSecurityCheckEnum.BANK_SECURITY_DATA_EDIT:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                Seller-submitted data differs from OCR-extracted data. This may
                be due to an OCR misread or manual input where OCR retrieval was
                incomplete. Compare the extracted data with the seller&apos;s
                entry and the document image to verify accuracy.
              </p>

              <h1 className="text-grey-800 font-semibold text-base">
                Provided by{' '}
                {seller
                  ? seller.firstName + ' ' + seller.lastName
                  : inspectionDetailData.seller.firstName +
                    ' ' +
                    inspectionDetailData.seller.lastName}
              </h1>

              <div className="flex flex-col gap-8">
                {imgsrc && !isDemo && (
                  <img src={`data:image/jpeg;base64,${imgsrc}`} />
                )}
                {isDemo && <img className="" src={imgsrc} alt="card" />}
              </div>
              <div className="flex flex-col justify-center items-center">
                {_map(
                  formElements,
                  (row: any, index: number) =>
                    (_get(selectedFile, row.keyPath) ||
                      (row.key === 'location' &&
                        _get(selectedFile, 'clientAddress'))) && (
                      <div key={index} className="flex flex-row gap-x-1">
                        <p className="text-grey-500 text-xs leading-[18px]">
                          {row.displayName + ':'}
                        </p>
                        <p className="text-grey-500 text-xs leading-[18px]">
                          {row.type === 'Date'
                            ? formattedDate(_get(selectedFile, row.keyPath))
                            : row.keyPath === 'location'
                            ? _get(selectedFile, 'clientAddress.label')
                            : _get(selectedFile, row.keyPath)}
                        </p>
                      </div>
                    )
                )}
              </div>
              {specialFormElements.length > 0 && (
                <div className="flex flex-col justify-center items-center gap-6 mt-6">
                  <p className="font-semibold text-grey-800 w-full border-b border-grey-200 pb-3.5">
                    Seller submitted details
                  </p>
                  {_map(specialFormElements, (row: any, index: number) => (
                    <>
                      <div className="flex flex-col border-b border-grey-200 w-full gap-3">
                        {_get(ocrData, row.keyPath) && (
                          <div
                            key={index}
                            className="flex flex-row justify-between py-3.5 mt-[-8px]">
                            <p className="text-grey-500 text-sm leading-5">
                              {row.displayName}
                            </p>
                            <p className="text-base text-grey-700">
                              {_get(ocrData, row.keyPath)?.userEditValue}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                  <p className="font-semibold text-grey-800 w-full  border-b border-grey-200 pb-3.5">
                    OCR-extracted data
                  </p>
                  {_map(specialFormElements, (row: any, index: number) => (
                    <>
                      <div className="flex flex-col border-b  border-grey-200 w-full gap-3">
                        {_get(ocrData, row.keyPath) && (
                          <div
                            key={index}
                            className="flex flex-row justify-between py-3.5 mt-[-8px]">
                            <p className="text-grey-500 text-sm leading-5">
                              {row.displayName}
                            </p>
                            <p className="text-base text-grey-700">
                              {_get(ocrData, row.keyPath)?.ocrValue}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              )}
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case BankSecurityCheckEnum.BANK_SECURITY_AUTHENTICITY:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                Unable to verify with high confidence that the Bank Statement
                provided is authentic.
              </p>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case BankSecurityCheckEnum.BANK_STATEMENT_ADDRESS:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                The address on the bank statement does not match the address on
                the registration certificate.
              </p>
              <div className="flex flex-col border-none w-full">
                <p className="font-semibold border-b border-grey-200 text-grey-800  py-3.5">
                  Registration certificate
                </p>
                <div className="grid grid-cols-2  py-3.5 border-b border-grey-200">
                  <p className="text-grey-500 text-sm font-medium leading-4">
                    Address Line1
                  </p>
                  <p className="text-sm font-medium text-grey-950  text-right">
                    {data?.regoCertificateAddress?.addressNumber}{' '}
                    {data?.regoCertificateAddress?.street}
                  </p>
                </div>
                <div className="grid grid-cols-2  py-3.5 border-b border-grey-200">
                  <p className="text-grey-500 text-sm font-medium leading-4">
                    Address Line2
                  </p>
                  <p className="text-sm font-medium text-grey-950  text-right">
                    {data?.regoCertificateAddress?.suburb}{' '}
                    {data?.regoCertificateAddress?.city}{' '}
                    {data?.regoCertificateAddress?.state}{' '}
                    {data?.regoCertificateAddress?.postcode}
                  </p>
                </div>
              </div>
              <div className="flex flex-col border-none w-full">
                <p className="font-semibold border-b border-grey-200 text-grey-800  py-3.5">
                  Bank statement
                </p>
                <div className="grid grid-cols-2  py-3.5 border-b border-grey-200">
                  <p className="text-grey-500 text-sm font-medium leading-4">
                    Address Line1
                  </p>
                  <p className="text-sm font-medium text-grey-950  text-right">
                    {data?.bankStatementAddress?.addressNumber}{' '}
                    {data?.bankStatementAddress?.street}
                  </p>
                </div>
                <div className="grid grid-cols-2  py-3.5 border-b border-grey-200">
                  <p className="text-grey-500 text-sm font-medium leading-4">
                    Address Line2
                  </p>
                  <p className="text-sm font-medium text-grey-950  text-right">
                    {data?.bankStatementAddress?.suburb}{' '}
                    {data?.bankStatementAddress?.city}{' '}
                    {data?.bankStatementAddress?.state}{' '}
                    {data?.bankStatementAddress?.postcode}
                  </p>
                </div>
              </div>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return <> </>;
  }
};
export default BankCheckCard;
