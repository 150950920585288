import Input from '@components/forms/Input';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ACCEPT_USER_INVITE, post, USER_INVITE } from '@services';
import { useEffect, useState } from 'react';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { ReactComponent as Tick } from '@assets/icons/Tick.svg';
import { validatePassword } from '@utils';
const phoneRegExp = /^(04\d{8}|\+614\d{8}|614\d{8})$/;

const setUpFormSchema = yup
  .object({
    firstName: yup.string().required('Please enter your first name.'),
    lastName: yup.string().required('Please enter your last name.'),
    mobile: yup
      .string()
      .matches(
        phoneRegExp,
        'Invalid mobile number.  Please enter a valid mobile.'
      )
      .required('Please enter your mobile.'),
    password: yup
      .string()
      .test(
        'password-validation',
        'Password must contain at least 8 characters, including at least one number, one lowercase letter, one uppercase letter, and one of the following symbols: ^ $ * . [ ] { } ( ) ? - " ! @ # % & /  , > < \' : ; | _ ~ ` + =',
        (value) => validatePassword(value || '')
      )
      .required('Please enter your password.'),
    confirmPassword: yup.string().required('Please confirm your password.')
  })
  .required();
const InviteUserSetup = () => {
  const {
    register,
    watch,
    clearErrors,
    setValue,
    getValues,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(setUpFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      mobile: '',
      password: '',
      confirmPassword: ''
    }
  });
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { firstName, lastName, mobile, password, confirmPassword } = watch();
  const [selectedColor, setSelectedColor] = useState('');
  const [hasError, setHasError] = useState(false);

  const colors = [
    '#DD3434',
    '#EC6BC0',
    '#58A356',
    '#862E9C',
    '#FD9C2B',
    '#667085',
    '#39ACFF'
  ];
  const handleFirstNameChange = (e: any) => {
    const value = e.target.value;
    clearErrors('firstName');
    setValue('firstName', value);
  };
  const handleLastNameChange = (e: any) => {
    const value = e.target.value;
    clearErrors('lastName');
    setValue('lastName', value);
  };
  const handleMobileChange = (e: any) => {
    const value = e.target.value;
    clearErrors('mobile');
    setValue('mobile', value);
  };
  const handlePasswordChange = (e: any) => {
    const value = e.target.value;
    clearErrors('password');
    setValue('password', value);
  };
  const handleConfirmPasswordChange = (e: any) => {
    const value = e.target.value;
    clearErrors('confirmPassword');
    setValue('confirmPassword', value);
  };
  const verifyConfirmPwd = (e: any) => {
    const value = e.target.value;
    if (value !== password) {
      setError('confirmPassword', {
        type: 'custom',
        message: 'Please ensure the passwords entered match.'
      });
    }
  };

  const onSubmit = () => {
    const tokenMatch = hash.match(/token=(.+)$/);
    if (!tokenMatch) {
      setHasError(true);
      return;
    }

    let token = tokenMatch[1];
    try {
      token = decodeURIComponent(token);
    } catch (error) {
      token = tokenMatch[1];
    }
    post(
      ACCEPT_USER_INVITE,
      {
        password: getValues('password'),
        token,
        user: {
          firstName: getValues('firstName'),
          lastName: getValues('lastName'),
          mobile: getValues('mobile'),
          avatarColour: selectedColor ? selectedColor.replace('#', '') : '',
          initials: (
            getValues('firstName')[0] + getValues('lastName')[0]
          ).toUpperCase()
        }
      },
      false
    )
      .then((data) => {
        localStorage.setItem('user', data.user);
        localStorage.setItem('verifierUser', data.verifierUser);
        navigate('/login');
      })
      .catch((e) => {
        if (e.response.data.error.code === 'InvalidPassword') {
          setError('confirmPassword', {
            type: 'custom',
            message:
              'Password must contain at least 8 characters, including at least one number, one lowercase letter, one uppercase letter, and one of the following symbols: ^ $ * . [ ] { } ( ) ? - " ! @ # % & /  , > < \' : ; | _ ~ ` + ='
          });
        } else {
          setError('root.server', {
            type: 'custom',
            message: e.response.data.error.code
          });
        }
      });
  };
  useEffect(() => {
    try {
      const tokenMatch = hash.match(/token=(.+)$/);
      if (!tokenMatch) {
        setHasError(true);
        return;
      }

      let token = tokenMatch[1];
      try {
        token = decodeURIComponent(token);
      } catch (error) {
        token = tokenMatch[1];
      }
      post(USER_INVITE, { token }, false)
        .then((data) => {
          if (data.userInvite) {
            if (data.userInvite.hasAccepted) {
              navigate('/login');
            } else {
              setValue('firstName', data.userInvite.firstName);
              setValue('lastName', data.userInvite.lastName);
            }
          }
        })
        .catch((e) => {
          setHasError(true);
        });
    } catch (error) {
      setHasError(true);
    }
  }, [hash]);
  if (hasError) {
    return (
      <div className="mx-auto w-full md:w-[500px] bg-white px-4 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
        <h3 className="text-[30px] text-grey-900 font-semibold mb-6 text-center">
          Unable to Complete Setup
        </h3>
        <p className="text-[#101828] text-left">
          We couldn&apos;t complete your profile setup. Please raise a support
          request with{' '}
          <a
            className="underline"
            href="https://www.authentik8.com.au/support-centre-contact-us">
            Authentik8 Support
          </a>{' '}
          and include a brief description of the steps you took before
          encountering this issue.
        </p>
      </div>
    );
  }
  return (
    <div className="mx-auto w-full md:w-[440px] bg-white px-3.5 gap-6 md:px-10 py-12 rounded-xl shadow-paper relative">
      <form className="flex flex-col gap-6">
        <h3 className="text-2xl text-grey-900 font-semibold mb-6 text-center">
          Setup Profile
        </h3>
        {errors?.root?.server && (
          <>
            <p className="text-xs text-error-light">
              {' '}
              {errors?.root?.server.message}
            </p>
          </>
        )}
        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">First Name</span>
          <Input
            type="text"
            {...register(`firstName`)}
            onKeyDown={(event) => {
              if (!/[A-Za-z]+/i.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="First Name"
            value={firstName}
            icon={errors.firstName && <ErrorIcon />}
            onChange={handleFirstNameChange}
          />
          {errors.firstName && (
            <span className="text-error-light text-2sm mt-[6px]">
              {errors.firstName?.message}
            </span>
          )}
        </label>

        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">Last name</span>
          <Input
            type="text"
            {...register(`lastName`)}
            onKeyDown={(event) => {
              if (!/[A-Za-z]+/i.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Last Name"
            icon={errors.lastName && <ErrorIcon />}
            value={lastName}
            onChange={handleLastNameChange}
          />
          {errors.lastName && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.lastName.message}
            </span>
          )}
        </label>

        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">Mobile</span>
          <Input
            type="tel"
            {...register(`mobile`)}
            placeholder="Mobile"
            icon={errors.mobile && <ErrorIcon />}
            value={mobile}
            autoComplete="tel-national"
            inputMode="tel"
            onChange={handleMobileChange}
          />
          {errors.mobile && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.mobile.message}
            </span>
          )}
        </label>

        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">
            Avatar Colour
          </span>
          <div className="flex">
            {colors.map((color) => (
              <div
                key={color}
                style={{
                  backgroundColor: color
                }}
                className="w-10 h-10 mr-2 rounded-[10px] flex items-center justify-center"
                onClick={(event) => {
                  event.preventDefault(); // Prevent form submission
                  setSelectedColor(color);
                }}>
                {selectedColor === color && <Tick />}
              </div>
            ))}
          </div>
        </label>
        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">Password</span>
          <Input
            type="password"
            {...register(`password`)}
            placeholder="Password"
            icon={errors.password && <ErrorIcon />}
            value={password}
            onChange={handlePasswordChange}
          />
          {errors.password && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.password.message}
            </span>
          )}
        </label>

        <label className="flex flex-col">
          <span className="text-sm font-medium text-grey-700">
            Confirm Password
          </span>
          <Input
            type="password"
            {...register(`confirmPassword`)}
            placeholder="Confirm Password"
            icon={errors.confirmPassword && <ErrorIcon />}
            value={confirmPassword}
            onBlur={verifyConfirmPwd}
            onChange={handleConfirmPasswordChange}
          />
          {errors.confirmPassword && (
            <span className="text-2sm mt-[6px] text-error-light">
              {errors.confirmPassword.message}
            </span>
          )}
        </label>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Button
            className="bg-white border-grey-300 text-grey-700"
            variant="outlined">
            Cancel
          </Button>
          <Button
            className="w-full bg-primary text-white text-md font-semibold normal"
            onClick={(e) => {
              handleSubmit(onSubmit)();
            }}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InviteUserSetup;
