import { NewInspectionModalEnum, AssetType, AssetTypesEnum } from '@types';
import { ASSET_OPTIONS_FOR_CREATE } from '@constants';
import React, { useState, useEffect, useRef } from 'react';
import Button from '@components/forms/Button';
import { Select } from '@components/forms';
import { Chip, Radio } from '@material-tailwind/react';
import {
  CREATE_INSPECTION_TEMPLATE,
  GET_INSPECTION_TEMPLATE,
  UPDATE_INSPECTION_TEMPLATE,
  post
} from '@services';
import ConfirmInspectionModal from '@components/widgets/ConfirmInspectionModal';
import { DOCUMENT_TYPE } from '@constants';
import { MultiSelect } from 'react-multi-select-component';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow-down.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _intersection from 'lodash/intersection';
import _find from 'lodash/find';
import { LenderDocumentTypeEnum } from '@types';
import { useForm } from 'react-hook-form';
import { Input } from '@components/forms';
import phone from '@assets/images/phone.png';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { useNavigate } from 'react-router-dom';
import { useTemplateStore } from '@stores';
import { Tooltip } from 'react-tooltip';
import { Option } from '@material-tailwind/react';
import { ReactComponent as Info } from '@assets/icons/info-circle.svg';
import OnboardingLayout from '@components/layouts/OnboardingLayout';
import { getAssetSpecificContent } from '@utils';

// Define invalid documents mapping for each asset type
const INVALID_DOCUMENTS_BY_ASSET: Record<
  AssetTypesEnum,
  LenderDocumentTypeEnum[]
> = {
  [AssetTypesEnum.CAR]: [
    LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
    LenderDocumentTypeEnum.INTERNAL_KITCHEN,
    LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
    LenderDocumentTypeEnum.OPERATING_HOURS
  ],
  [AssetTypesEnum.MOTOR_CYCLE]: [
    LenderDocumentTypeEnum.INTERNAL_FRONT, // Front seats
    LenderDocumentTypeEnum.INTERNAL_BACK, // Back seats
    LenderDocumentTypeEnum.INTERNAL_BOOT, // Boot
    LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
    LenderDocumentTypeEnum.INTERNAL_KITCHEN,
    LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
    LenderDocumentTypeEnum.OPERATING_HOURS
  ],
  [AssetTypesEnum.CAMPERVAN]: [
    LenderDocumentTypeEnum.ODOMETER,
    LenderDocumentTypeEnum.INTERNAL_FRONT,
    LenderDocumentTypeEnum.INTERNAL_BACK,
    LenderDocumentTypeEnum.INTERNAL_BOOT,
    LenderDocumentTypeEnum.OPERATING_HOURS
  ],
  [AssetTypesEnum.TRAILER]: [
    LenderDocumentTypeEnum.ODOMETER,
    LenderDocumentTypeEnum.INTERNAL_FRONT,
    LenderDocumentTypeEnum.INTERNAL_BACK,
    LenderDocumentTypeEnum.INTERNAL_BOOT,
    LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
    LenderDocumentTypeEnum.INTERNAL_KITCHEN,
    LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
    LenderDocumentTypeEnum.OPERATING_HOURS
  ],
  [AssetTypesEnum.EQUIPMENT]: [
    LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
    LenderDocumentTypeEnum.INTERNAL_KITCHEN,
    LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA
  ]
};

function validateDocumentsForAssetType(
  documents: LenderDocumentTypeEnum[],
  assetType: AssetTypesEnum
): string {
  const invalidDocs = INVALID_DOCUMENTS_BY_ASSET[assetType] || [];
  const invalidDocLabels: string[] = [];

  documents.forEach((doc) => {
    if (invalidDocs.includes(doc)) {
      const docLabel = DOCUMENT_TYPE.find((d) => d.value === doc)?.label;
      if (docLabel) {
        invalidDocLabels.push(docLabel);
      }
    }
  });

  return invalidDocLabels.length > 0
    ? `Cannot include ${invalidDocLabels.join(
        ', '
      )} for the selected asset type`
    : '';
}

const CreateTemplate = () => {
  const { templateObject } = useTemplateStore();
  const [step, setStep] = useState(0);
  const [backToDashboard, setBackToDashboard] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState(NewInspectionModalEnum.close);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [documentType, setDocumentType] = useState(DOCUMENT_TYPE);

  const [open, setOpen] = useState(true);
  const [isBuyer, setIsBuyer] = useState(true);
  const [assetType, setAssetType] = useState<any>(AssetTypesEnum.CAR);
  const [selectedDocuments, setSelected] = useState<LenderDocumentTypeEnum[]>(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [showSuccessMsg, setSuccessMsg] = useState(false);
  const [documentsError, setDocumentsError] = useState('');
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const documentRef = useRef<HTMLDivElement>(null);
  const [template, setTemplate] = useState<any>(templateObject);
  const [inspectionMessage, setInspectionMessage] = useState<string>(
    'Hi {SellerFirstName} {SellerLastName}, {BuyerFirstName} {BuyerLastName} is interested in purchasing your vehicle. {OrganisationName} needs to verify some details about you and the vehicle before proceeding.\n\n' +
      'Please complete our online inspection process before {ExpiryDate}, after which it will expire.  This process will take {ProcessDurationMinutes} minutes and you will require:\n' +
      '{DocumentList}\n\n' +
      'Get started - {InspectionLink}'
  );
  const [templateHtml, setTemplateHtml] = useState<any>('');
  const [errorMegLength, setErrorMsgLength] = useState<any>(0);
  const liList = [
    '{SellerFirstName}',
    '{SellerLastName}',
    '{BuyerFirstName}',
    '{BuyerLastName}',
    '{OrganisationName}',
    '{ExpiryDate}',
    '{ProcessDurationMinutes}',
    '{DocumentList}',
    '{InspectionLink}'
  ];
  const templateMsg = {
    SellerFirstName: 'Sellerfirst',
    SellerLastName: 'Sellerlast',
    BuyerFirstName: 'Buyerfirst',
    BuyerLastName: 'Buyerlast',
    OrganisationName: 'Acme Corp',
    ExpiryDate: 'dd/mm/yyyy',
    ProcessDurationMinutes: 'X',
    DocumentList: 'Document 1, Document 2, Document 3',
    InspectionLink:
      'https://authentik8.com.au/inspection/login#token=1234567890123456789012345'
  };
  const onDashboardClick = () => {
    setBackToDashboard(true);
    setIsOpenModal(true);
    setModalType(NewInspectionModalEnum.close);
  };
  const onCloseClick = () => {
    setIsOpenModal(true);
    setModalType(NewInspectionModalEnum.close);
  };

  const {
    register,
    setError,
    setFocus,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm();
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const lender = localStorage.getItem(`lenders_${userId}` as any);
    const organisationName = lender
      ? JSON.parse(lender)[0]?.organisationName
      : '';
    templateMsg.OrganisationName = organisationName;
    const newDocStr = (() => {
      const list = new Set<string>();

      // Check for driver's license
      if (selectedDocuments.includes(LenderDocumentTypeEnum.SELLER_ID)) {
        list.add("driver's license");
      }

      // Check for vehicle access related documents
      const vehicleAccessDocs = [
        LenderDocumentTypeEnum.VIN,
        LenderDocumentTypeEnum.REGO_PLATE,
        LenderDocumentTypeEnum.ODOMETER,
        LenderDocumentTypeEnum.OPERATING_HOURS,
        LenderDocumentTypeEnum.ASSET_FRONT,
        LenderDocumentTypeEnum.ASSET_BACK,
        LenderDocumentTypeEnum.INTERNAL_FRONT,
        LenderDocumentTypeEnum.INTERNAL_BACK,
        LenderDocumentTypeEnum.INTERNAL_BOOT,
        LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
        LenderDocumentTypeEnum.INTERNAL_KITCHEN,
        LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
        LenderDocumentTypeEnum.ASSET_LEFT,
        LenderDocumentTypeEnum.ASSET_RIGHT
      ];
      if (vehicleAccessDocs.some((doc) => selectedDocuments.includes(doc))) {
        list.add('access to the vehicle');
      }

      // Check for registration certificate
      if (selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PAPERS)) {
        list.add('registration certificate');
      }

      // Check for bank statement
      if (selectedDocuments.includes(LenderDocumentTypeEnum.BANK_STATEMENTS)) {
        list.add('recent bank statement');
      }

      // // Check for roadworthy/safety certificate
      // if (
      //   selectedDocuments.includes(LenderDocumentTypeEnum.ROADWORTHY_CERT) ||
      //   selectedDocuments.includes(LenderDocumentTypeEnum.SAFETY_CERT)
      // ) {
      //   list.add('roadworthy / safety certificate');
      // }
      const items = Array.from(list);
      // If there's only one item, return it directly
      if (items.length <= 1) return items.join('');

      // For multiple items, join with commas and add "and" before the last item
      return items.slice(0, -1).join(', \n') + ' and \n' + items.slice(-1);
    })();
    const fullTemplateMsg = {
      ...templateMsg,
      DocumentList: newDocStr
    };

    let newTemplateHtml = inspectionMessage;
    Object.entries(fullTemplateMsg).forEach(([key, value]) => {
      if (key === 'InspectionLink') {
        newTemplateHtml = newTemplateHtml.replace(
          new RegExp(`{${key}}`, 'g'),
          `<span style="word-break: break-all;">${value}</span>`
        );
      } else {
        newTemplateHtml = newTemplateHtml.replace(
          new RegExp(`{${key}}`, 'g'),
          value as string
        );
      }
    });

    setTemplateHtml(newTemplateHtml);
    const charCount = newTemplateHtml.replace(/<[^>]+>/g, '').length;

    if (charCount > 600) {
      setErrorMsgLength(charCount);
    } else {
      setErrorMsgLength(0);
    }
  }, [selectedDocuments, inspectionMessage]);
  function onCloseModalClick() {
    setIsOpenModal(false);
    setBackToDashboard(false);
  }
  function handleClose() {
    setIsOpenModal(false);
    setBackToDashboard(false);
    navigate('/inspection-templates');
  }
  const setLenderDocuments = (data: any) => {
    const itemElement = documentRef.current?.children[0] as HTMLElement;
    if (selectedDocuments.length === 0) {
      setError('selectedDocuments', {
        type: 'custom',
        message: 'Please select at least one document.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    const assetTypeValidationError = validateDocumentsForAssetType(
      selectedDocuments,
      assetType
    );
    if (assetTypeValidationError) {
      setError('selectedDocuments', {
        type: 'custom',
        message: assetTypeValidationError
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check NEVDIS dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.VIN) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PLATE) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PAPERS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'VIN, Number Plate or Registration Certificate must be included when NEVDIS is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check VIN dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.VIN) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'NEVDIS external verification must be included when VIN is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check Registration Certificate dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PAPERS) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'NEVDIS external verification must be included when Registration Certificate is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    // Check Number Plate dependency
    if (
      selectedDocuments.includes(LenderDocumentTypeEnum.REGO_PLATE) &&
      !selectedDocuments.includes(LenderDocumentTypeEnum.NEVDIS)
    ) {
      setError('selectedDocuments', {
        type: 'custom',
        message:
          'NEVDIS external verification must be included when Number Plate is included.'
      });
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('selectedDocuments');
      return;
    }

    if (errorMegLength > 640) {
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
      setFocus('inspectionMessage');
      return;
    }
    // Check buyer details usage when not required
    if (
      !isBuyer &&
      (inspectionMessage.includes('{BuyerFirstName}') ||
        inspectionMessage.includes('{BuyerLastName}'))
    ) {
      setFocus('inspectionMessage');
      return;
    }

    // Check for required inspection link
    if (!inspectionMessage.includes('{InspectionLink}')) {
      setFocus('inspectionMessage');
      return;
    }
    const params: any = {
      assetType,
      name: data.name,
      description: data.description,
      buyerRequired: isBuyer,
      documentTypes: selectedDocuments
    };
    if (inspectionMessage !== '') {
      params.sellerInviteMessage = inspectionMessage;
    }
    if (template && template.templateId) {
      post(
        UPDATE_INSPECTION_TEMPLATE,
        {
          ...params,
          templateId: template.templateId
        },
        false
      )
        .then((data) => {
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(false);
            navigate('/inspection-templates');
          }, 3000);
        })
        .catch((e: any) => {
          setError('selectedDocuments', {
            type: 'custom',
            message: e.response.data.error.message
          });
        });
    } else {
      const userId = localStorage.getItem('userId');

      const lenders = localStorage.getItem(`lenders_${userId}`);
      if (lenders != null) {
        const lender = JSON.parse(lenders)[0];
        const organisationId = lender.organisationId;
        post(
          CREATE_INSPECTION_TEMPLATE,
          {
            assetType,
            lenderOrganisationId: organisationId,
            name: data.name,
            description: data.description,
            buyerRequired: isBuyer,
            sellerInviteMessage: inspectionMessage,
            documentTypes: selectedDocuments
          },
          false
        )
          .then((data) => {
            setSuccessMsg(true);
            setTemplate(data);
            setTimeout(() => {
              setSuccessMsg(false);
              navigate('/inspection-templates');
            }, 3000);
          })
          .catch((e: any) => {
            setError('selectedDocuments', {
              type: 'custom',
              message: e.response.data.error.message
            });
          });
      }
    }
  };
  const removeItems = (key: any) => {
    const filters = selectedDocuments.filter(
      (item) =>
        item !== key &&
        ![
          LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
          LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
          LenderDocumentTypeEnum.FACE
        ].includes(item as LenderDocumentTypeEnum)
    );
    const validationError = validateDocumentsForAssetType(filters, assetType);
    if (validationError) {
      setError('selectedDocuments', {
        type: 'custom',
        message: validationError
      });
    } else {
      clearErrors();
    }
    setSelected(filters);
    const removeOptions = selectedOptions.filter((item) => item.value !== key);
    setSelectedOptions(removeOptions);
  };
  const onMultiSelectChange = (e: { label: string; value: string }[]) => {
    const selectValues = e.map(
      (item) => item.value
    ) as LenderDocumentTypeEnum[];
    const validationError = validateDocumentsForAssetType(
      selectValues,
      assetType
    );

    if (validationError) {
      setError('selectedDocuments', {
        type: 'custom',
        message: validationError
      });
    } else {
      clearErrors();
    }
    setSelectedOptions(e);
    setSelected(selectValues);
  };

  const ArrowRenderer = ({ expanded }: { expanded: any }) => (
    <div
      className={clsx(
        'grid place-items-center absolute top-2/4 right-2 pt-px w-5 h-5 text-blue-gray-400 rotate-0 -translate-y-2/4 transition-all',
        expanded && 'rotate-180'
      )}>
      <ArrowDownIcon />
    </div>
  );

  ArrowRenderer.propTypes = {
    expanded: PropTypes.bool
  };

  useEffect(() => {
    if (templateObject && templateObject.templateId) {
      post(GET_INSPECTION_TEMPLATE, {
        templateId: templateObject.templateId
      }).then((res) => {
        if (res.template) {
          const targetTemplate = res.template;
          setTemplate(targetTemplate);
          setSelected(targetTemplate.documentTypes);
          setValue('name', targetTemplate.name);
          setValue('description', targetTemplate.description);
          setIsBuyer(targetTemplate.buyerRequired);
          setAssetType(targetTemplate.assetType);

          if (targetTemplate.sellerInviteMessage) {
            setInspectionMessage(targetTemplate.sellerInviteMessage);
            setValue('inspectionMessage', targetTemplate.sellerInviteMessage);
          }
          setSelectedOptions(
            DOCUMENT_TYPE.filter((item) => {
              return targetTemplate.documentTypes.includes(item.value);
            })
          );

          handleAssetTypeChange(
            targetTemplate.assetType,
            targetTemplate.documentTypes
          );
        }
      });
      return;
    }
    if (template === undefined && templateObject === undefined) {
      setSelected(
        Object.keys(LenderDocumentTypeEnum) as LenderDocumentTypeEnum[]
      );
      return;
    }
  }, [templateObject]);
  const handleAssetTypeChange = (
    newType: AssetTypesEnum,
    selectedDocumentsFromTemplate?: LenderDocumentTypeEnum[]
  ) => {
    setAssetType(newType);

    const invalidDocs = INVALID_DOCUMENTS_BY_ASSET[newType] || [];

    const validDocuments = selectedDocumentsFromTemplate
      ? selectedDocumentsFromTemplate
      : selectedDocuments.filter((doc) => !invalidDocs.includes(doc));
    const validDocumentType = DOCUMENT_TYPE.filter(
      (doc) => !invalidDocs.includes(doc.value as LenderDocumentTypeEnum)
    );
    validDocumentType.forEach((doc) => {
      if (doc.value === LenderDocumentTypeEnum.ASSET_FRONT) {
        doc.label =
          'Photo ' + getAssetSpecificContent(assetType).lowerTitle + ' front';
      }
      if (doc.value === LenderDocumentTypeEnum.ASSET_BACK) {
        doc.label =
          'Photo ' + getAssetSpecificContent(assetType).lowerTitle + ' back';
      }
      if (doc.value === LenderDocumentTypeEnum.ASSET_LEFT) {
        doc.label =
          'Photo ' +
          getAssetSpecificContent(assetType).lowerTitle +
          ' ' +
          getAssetSpecificContent(assetType).sides.passenger.toLowerCase();
      }
      if (doc.value === LenderDocumentTypeEnum.ASSET_RIGHT) {
        doc.label =
          'Photo ' +
          getAssetSpecificContent(assetType).lowerTitle +
          ' ' +
          getAssetSpecificContent(assetType).sides.driver.toLowerCase();
      }
    });
    setSelected(validDocuments);
    setDocumentType(validDocumentType);
    const validOptions = selectedOptions.filter(
      (option) => !invalidDocs.includes(option.value as LenderDocumentTypeEnum)
    );
    setSelectedOptions(validOptions);
  };

  useEffect(() => {
    handleAssetTypeChange(AssetTypesEnum.CAR);
  }, []);

  return (
    <OnboardingLayout
      step={step}
      onChangeStep={setStep}
      successSubmit={successSubmit}
      backToDashboard={() => onDashboardClick()}
      title="Create inspection template"
      type="template"
      onClose={() => onCloseClick()}>
      <div className="max-w-[768px] w-full">
        <div
          ref={containerRef}
          className="h-full flex flex-col overflow-y-auto justify-between ">
          <div className="h-full flex flex-col overflow-y-auto">
            <div className="text-[20px] mb-4 font-semibold text-grey-900">
              Inspection Details
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Inspection template name
              </p>
              <Input
                type="text"
                {...register(`name`, { required: 'Name is required' })}
                placeholder="Inspection template name"
                onBlur={() => {
                  clearErrors();
                }}
                icon={errors.name && <ErrorIcon />}
              />
              {errors.name ? (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.name?.message?.toString()}
                </span>
              ) : (
                <span className="text-grey-600 text-2sm mt-[6px]">
                  Displays as an option when creating a new inspection
                </span>
              )}
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Description
              </p>
              <textarea
                className="w-full h-32 border rounded-lg py-3 px-3.5 gap-2 border-grey-300"
                {...register(`description`, {
                  required: 'Description is required',
                  maxLength: {
                    value: 280,
                    message:
                      'Inspection Template description cannot be longer than 280 characters.'
                  }
                })}
                placeholder="Inspection template description"
                onBlur={() => {
                  clearErrors();
                }}
              />
              {errors.description && (
                <span className="text-error-light text-2sm mt-[6px]">
                  {errors.description?.message?.toString()}
                </span>
              )}
            </div>
            <div className="mb-4">
              <Tooltip
                id="badge-tooltip"
                place="bottom"
                className="rounded-lg z-10 w-[320px] text-sm !text-grey-700 bg-[#F2F4F7] bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-4"
                offset={2}></Tooltip>
              <label className="flex flex-row gap-1 text-sm font-medium text-gray-700">
                Asset Type
                <div
                  data-tooltip-id="badge-tooltip"
                  data-tooltip-content="Choose the asset type relevant to this inspection. The selected asset type customises the seller's inspection experience with appropriate wording and images.">
                  <Info />
                </div>
              </label>
              <Select
                value={assetType}
                onChange={(e) => handleAssetTypeChange(e as AssetType)}
                className="mt-1 block w-[300px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                {ASSET_OPTIONS_FOR_CREATE.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Are &apos;Buyer&apos; details required?
              </p>

              <Radio
                name="type"
                containerProps={{
                  className: 'p-2'
                }}
                checked={isBuyer}
                onChange={() => setIsBuyer(true)}
                label="Yes"
              />
              <Radio
                name="type"
                containerProps={{
                  className: 'p-2 ml-4'
                }}
                checked={!isBuyer}
                onChange={() => setIsBuyer(false)}
                label="No"
              />
              <p className="text-grey-600 text-sm">
                Choosing &apos;Yes&apos; to the above will require entry of
                Buyer First Name and Last Name details when creating an
                inspection.
              </p>
            </div>
            <div className="text-[20px] mt-8 mb-4 font-semibold text-grey-900">
              Invitation Message
            </div>
            <div className="mb-4">
              <div className="flex flex-row gap-4">
                <div className="flex-1">
                  <p className="text-grey-700 text-sm mb-1.5">
                    Inspection Invitation SMS Message
                  </p>
                  <textarea
                    className="w-full h-[388px] border rounded-lg py-3 px-3.5 gap-2 border-grey-300"
                    {...register(`inspectionMessage`, {
                      required: 'Name is required',
                      value: inspectionMessage
                    })}
                    onChange={(e) => {
                      setValue('inspectionMessage', e.target.value);
                      setInspectionMessage(e.target.value);
                    }}
                    placeholder="Inspection template description"
                    onBlur={() => {
                      clearErrors();
                    }}
                  />
                  {errorMegLength > 640 && (
                    <span className="text-error-light text-2sm mt-[6px]">
                      Message exceeds 640 characters. It may be split into
                      multiple SMS.
                    </span>
                  )}
                  {errorMegLength > 600 && errorMegLength < 640 && (
                    <span className="text-error-light text-2sm mt-[6px]">
                      Message is approaching 640 characters limit.
                    </span>
                  )}
                  {!isBuyer &&
                    (inspectionMessage.includes('{BuyerFirstName}') ||
                      inspectionMessage.includes('{BuyerLastName}')) && (
                      <div className="text-error-light text-2sm mt-[6px]">
                        {'{BuyerFirstName} '} and {'{BuyerLastName} '}
                        personalisation variables cannot be used when Buyer
                        details are not required in the inspection.
                      </div>
                    )}
                  {!inspectionMessage.includes('{InspectionLink}') &&
                    inspectionMessage !== '' && (
                      <div className="text-error-light text-2sm mt-[6px]">
                        Message must include the {'{InspectionLink} '} variable.
                      </div>
                    )}
                  <div className="flex flex-col">
                    <p className="text-grey-600 text-xs mb-4">
                      Personalisation variables:
                    </p>
                    <ul className="text-grey-600 text-xs mb-1.5 list-disc">
                      {liList.map((item, index) => (
                        <li className="ml-4 mb-1" key={index}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="relative">
                    <img src={phone} alt="phone" className="w-[376px]" />
                    <div className="absolute border rounded-[12px] p-2 top-[140px] left-[30px] w-[316px] bg-[#EAECF0]">
                      {templateHtml
                        .split('\n')
                        .map((paragraph: string, index: number) => (
                          <React.Fragment key={index}>
                            {paragraph
                              .split(/(<span.*?<\/span>)/)
                              .map((part, partIndex) => {
                                if (part.startsWith('<span')) {
                                  // Parse the span element
                                  const match = part.match(
                                    /<span style="([^"]*)">(.*?)<\/span>/
                                  );
                                  if (match) {
                                    const [, className, content] = match;
                                    return (
                                      <span
                                        key={partIndex}
                                        style={{
                                          wordBreak: 'break-all'
                                        }}>
                                        {content}
                                      </span>
                                    );
                                  }
                                }
                                return part;
                              })}
                            {index < templateHtml.split('\n').length - 1 && (
                              <br />
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={documentRef}
              className="text-[20px] mt-8 mb-4 font-semibold text-grey-900">
              Documents & Verifications
            </div>
            <div className="mb-4">
              <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                Add documents
              </p>
              <MultiSelect
                options={documentType}
                value={selectedOptions}
                className="border-grey-300 mb-1.5 shadow-sm rounded-md w-full !h-10 text-sm text-grey-400 ring-1 ring-grey-900/10 hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
                ArrowRenderer={ArrowRenderer}
                onChange={onMultiSelectChange}
                labelledBy="Add additional documents"
              />
              {errors.selectedDocuments && (
                <span className="text-error-500 text-2sm mt-[6px]">
                  {errors.selectedDocuments?.message?.toString()}
                </span>
              )}
              <p className="text-grey-600 text-sm">
                Select all the documents you require for your inspections.
              </p>
            </div>
            <div className="min-h-[380px]">
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.SELLER_ID
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Seller Identification
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.SELLER_ID
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.ASSET_BACK,
                LenderDocumentTypeEnum.ASSET_FRONT,
                LenderDocumentTypeEnum.ASSET_LEFT,
                LenderDocumentTypeEnum.ASSET_RIGHT,
                LenderDocumentTypeEnum.INTERNAL_FRONT,
                LenderDocumentTypeEnum.INTERNAL_BACK,
                LenderDocumentTypeEnum.INTERNAL_BOOT,
                LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
                LenderDocumentTypeEnum.INTERNAL_KITCHEN,
                LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
                LenderDocumentTypeEnum.VIN,
                LenderDocumentTypeEnum.REGO_PLATE,
                LenderDocumentTypeEnum.ODOMETER,
                LenderDocumentTypeEnum.OPERATING_HOURS
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    {getAssetSpecificContent(assetType).title}
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.ASSET_BACK,
                      LenderDocumentTypeEnum.ASSET_FRONT,
                      LenderDocumentTypeEnum.ASSET_LEFT,
                      LenderDocumentTypeEnum.ASSET_RIGHT,
                      LenderDocumentTypeEnum.INTERNAL_FRONT,
                      LenderDocumentTypeEnum.INTERNAL_BACK,
                      LenderDocumentTypeEnum.INTERNAL_BOOT,
                      LenderDocumentTypeEnum.INTERNAL_LIVING_AREA,
                      LenderDocumentTypeEnum.INTERNAL_KITCHEN,
                      LenderDocumentTypeEnum.INTERNAL_SLEEPING_AREA,
                      LenderDocumentTypeEnum.VIN,
                      LenderDocumentTypeEnum.REGO_PLATE,
                      LenderDocumentTypeEnum.ODOMETER,
                      LenderDocumentTypeEnum.OPERATING_HOURS
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      let label = doc?.label;
                      if (assetType !== AssetTypesEnum.CAR) {
                        if (item === LenderDocumentTypeEnum.ASSET_BACK) {
                          label = `Photo ${
                            getAssetSpecificContent(assetType).lowerTitle
                          } back`;
                        }
                        if (item === LenderDocumentTypeEnum.ASSET_FRONT) {
                          label = `Photo ${
                            getAssetSpecificContent(assetType).lowerTitle
                          } front`;
                        }
                        if (item === LenderDocumentTypeEnum.ASSET_LEFT) {
                          label = `Photo ${
                            getAssetSpecificContent(assetType).lowerTitle
                          } ${getAssetSpecificContent(
                            assetType
                          ).sides.passenger.toLowerCase()}`;
                        }
                        if (item === LenderDocumentTypeEnum.ASSET_RIGHT) {
                          label = `Photo ${
                            getAssetSpecificContent(assetType).lowerTitle
                          } ${getAssetSpecificContent(
                            assetType
                          ).sides.driver.toLowerCase()}`;
                        }
                      }
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.REGO_PAPERS,
                LenderDocumentTypeEnum.OWNERSHIP_PROOF
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Ownership Documents
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.REGO_PAPERS,
                      LenderDocumentTypeEnum.OWNERSHIP_PROOF
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.BANK_STATEMENTS,
                LenderDocumentTypeEnum.PAYOUT_LETTER
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Financial Documentation
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.BANK_STATEMENTS,
                      LenderDocumentTypeEnum.PAYOUT_LETTER
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.ROADWORTHY_CERT
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    Roadworthy / Safety Certificate
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.ROADWORTHY_CERT
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {_intersection(selectedDocuments, [
                LenderDocumentTypeEnum.PPSR,
                LenderDocumentTypeEnum.RAV,
                LenderDocumentTypeEnum.NEVDIS
              ]).length > 0 && (
                <div className="mb-4">
                  <p className="text-grey-700 text-sm mb-1.5 font-semibold">
                    External Verifications
                  </p>
                  <div className="text-grey-600 text-sm flex gap-2 w-full flex-wrap">
                    {_intersection(selectedDocuments, [
                      LenderDocumentTypeEnum.PPSR,
                      LenderDocumentTypeEnum.RAV,
                      LenderDocumentTypeEnum.NEVDIS
                    ]).map((item: any) => {
                      const doc = _find(DOCUMENT_TYPE, ['value', item]);
                      return (
                        <Chip
                          key={doc?.value}
                          open={open}
                          className="bg-white pr-1.5 pl-3 py-1 text-grey-700 font-medium text-sm border border-grey-200 normal-case rounded-2xl"
                          variant="outlined"
                          color="gray"
                          value={doc?.label}
                          onClose={() => removeItems(doc?.value)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 justify-end">
            {showSuccessMsg && (
              <div className="text-success-700 sticky font-medium text-sm text-right my-3">
                Inspection templates saved!
              </div>
            )}
            <div className="flex gap-4 justify-end">
              <Button
                className="font-semibold bg-primary w-auto capitalize"
                onClick={() => {
                  handleSubmit(setLenderDocuments, () => {
                    const itemElement = containerRef.current
                      ?.children[0] as HTMLElement;
                    itemElement?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start'
                    });
                  })();
                }}>
                Save Inspection Template
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmInspectionModal
        open={isOpenModal}
        type={modalType}
        onSubmit={() => handleClose()}
        onClose={() => onCloseModalClick()}
      />
    </OnboardingLayout>
  );
};
export default CreateTemplate;
