import React, { useEffect, useState, useRef } from 'react';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { useInvoiceDetailStore } from '@stores';
import { Tooltip } from 'react-tooltip';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { SEARCH_ADDRESS, GET_ADDRESS, post } from '@services';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';
type Props = {
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
};

const BuyerDetailStep: React.FC<Props> = ({ onClose, onPrevious, onNext }) => {
  const phoneRegExp = /^(04\d{8}|\+614\d{8}|614\d{8})$/;
  const [items, setItems] = useState([
    {
      text: ''
    }
  ]);

  const states = [
    { value: '', label: 'Select a State' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' }
  ];
  const buyerFormSchema = yup
    .object({
      email: yup
        .string()
        .required('Please enter your email.')
        .email('Please provide a valid email address'),
      firstName: yup
        .string()
        .required('Please enter the buyer’s first name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      lastName: yup.string().required('Please enter the buyer’s last name'),
      mobile: yup
        .string()
        .matches(
          phoneRegExp,
          'Invalid mobile number.  Please enter a valid mobile.'
        )
        .required('Please enter your mobile.'),
      address: yup.string().required('Please provide the buyer’s address'),
      state: yup.string().required('Please select a state.'),
      postcode: yup.string().required('Please enter the postcode.'),
      suburb: yup.string().required('Please enter the suburb.'),
      streetNumber: yup.string(),
      streetName: yup.string(),
      unitNumber: yup.string()
    })
    .required();
  const { invoiceDetailData, setInvoiceDetailData } = useInvoiceDetailStore();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(buyerFormSchema),
    defaultValues: {
      email: invoiceDetailData?.buyer?.email,
      firstName: invoiceDetailData?.buyer?.firstName,
      lastName: invoiceDetailData?.buyer?.lastName,
      mobile: invoiceDetailData?.buyer?.mobile,
      address: invoiceDetailData?.buyer?.address,
      postcode: invoiceDetailData?.buyer?.postcode,
      state: invoiceDetailData?.buyer?.state,
      suburb: invoiceDetailData?.buyer?.suburb
    }
  });
  // Add this useEffect to update form values when invoiceDetailData changes
  useEffect(() => {
    if (invoiceDetailData?.buyer) {
      setValue('email', invoiceDetailData.buyer.email || '');
      setValue('firstName', invoiceDetailData.buyer.firstName || '');
      setValue('lastName', invoiceDetailData.buyer.lastName || '');
      setValue('mobile', invoiceDetailData.buyer.mobile || '');
      setValue('address', invoiceDetailData.buyer.address || '');
      setValue('postcode', invoiceDetailData.buyer.postcode || '');
      setValue('state', invoiceDetailData.buyer.state || '');
      setValue('suburb', invoiceDetailData.buyer.suburb || '');
    }
  }, [invoiceDetailData, setValue]);
  const { firstName, lastName, email, mobile, state, postcode, suburb } =
    watch();

  const onSubmit = async (data: any) => {
    const buyer = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      address: data.address,
      streetNumber: data.streetNumber,
      streetName: data.streetName,
      unitNumber: data.unitNumber,
      postcode: data.postcode,
      suburb: data.suburb,
      state: data.state,
      isDone: true
    };
    setInvoiceDetailData({ ...invoiceDetailData, buyer });
    onNext();
  };
  const handleOnSelect = (item: any) => {
    if (!item || !item.placeId) {
      setValue('suburb', '');
      setValue('state', '');
      setValue('postcode', '');
      return;
    }
    setValue('address', item.text.split(',')[0]);
    post(GET_ADDRESS, { placeId: item.placeId }).then((data: any) => {
      if (data.place) {
        setValue('suburb', data.place?.neighborhood);
        if (data.place?.region) {
          const stateObjs = states.filter(
            (state) => state.label === data.place?.region
          );
          if (stateObjs.length > 0) {
            setValue('state', stateObjs[0].value);
          }
        }
        setValue('postcode', data.place?.postalCode);
      }
    });
  };
  const handleOnSearch = (text: any) => {
    if (text.length < 1) {
      return;
    }
    setValue('address', text);
    post(SEARCH_ADDRESS, { text }).then((data: any) => {
      const addresses = data.addresses.map((address: any, index: number) => ({
        ...address,
        id: index,
        displayText: address.text.split(',')[0]
      }));
      if (addresses) {
        setItems(addresses);
      }
    });
  };

  const handleStateChange = (e: any) => {
    setValue('state', e);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">Buyer</h3>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">First name</p>
        <Input
          placeholder="First name"
          {...register(`firstName`)}
          onKeyDown={(event) => {
            if (!/[A-Za-z]+/i.test(event.key)) {
              event.preventDefault();
            }
          }}
          icon={errors.firstName && <ErrorIcon />}
          value={firstName}
        />
        {errors.firstName && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.firstName.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Last name</p>
        <Input
          placeholder="Last name"
          {...register(`lastName`)}
          onKeyDown={(event) => {
            if (!/[A-Za-z]+/i.test(event.key)) {
              event.preventDefault();
            }
          }}
          icon={errors.lastName && <ErrorIcon />}
          value={lastName}
        />
        {errors.lastName && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.lastName.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Email</p>
        <Input
          placeholder="Email"
          {...register(`email`)}
          value={email}
          icon={errors.email && <ErrorIcon />}
        />
        {errors.email && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.email.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Mobile</p>
        <Input
          placeholder="Mobile number"
          {...register(`mobile`)}
          icon={errors.mobile && <ErrorIcon />}
          value={mobile}
        />
        {errors.mobile && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.mobile.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Address</p>
        <ReactSearchAutocomplete
          items={items}
          fuseOptions={{ keys: ['text'] }}
          resultStringKeyName="displayText"
          inputDebounce={500}
          showNoResults={false}
          onSearch={handleOnSearch}
          formatResult={(item: any) => item.text}
          autoFocus={false}
          placeholder="Search Address"
          onSelect={handleOnSelect}
          showIcon={false}
          styling={{ borderRadius: '8px' }}
          className="!border-grey-300 focus:!border-blue-500 !text-base !shadow-none bg-white text-grey-900 z-50 custom-auto-complete"
        />

        {errors.address && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.address.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Suburb</p>
        <Input
          placeholder="Suburb"
          {...register(`suburb`)}
          value={suburb}
          icon={errors.suburb && <ErrorIcon />}
        />

        {errors.suburb && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.suburb.message}`}
          </span>
        )}
      </div>
      <div className="mb-5 flex flex-row justify-start gap-5">
        <div className="min-w-[260px]">
          <span className="text-[14px] text-grey-700 font-medium">State</span>
          <Select
            placeholder="Select a State"
            {...register(`state`)}
            value={state}
            onChange={handleStateChange}>
            {states.map((state, index) => (
              <Option
                key={index}
                value={state.value}
                disabled={state.value === ''}>
                {state.label}
              </Option>
            ))}
          </Select>
          {errors.state && (
            <span className="text-error-500 text-2sm mt-[6px]">
              {`${errors.state.message}`}
            </span>
          )}
        </div>
        <div className="w-auto">
          <span className="text-[14px] text-grey-700 font-medium">
            Postcode
          </span>
          <Input
            placeholder="Postcode"
            {...register(`postcode`)}
            className="min-w-fit"
            value={postcode}
            icon={errors.postcode && <ErrorIcon />}
          />

          {errors.postcode && (
            <span className="text-error-500 text-2sm mt-[6px]">
              {`${errors.postcode.message}`}
            </span>
          )}
        </div>
      </div>
      <Tooltip
        id="badge-tooltip"
        place="bottom"
        className="rounded-lg z-10 w-[320px] text-sm !text-grey-700 bg-[#F2F4F7] bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-4"
        offset={2}></Tooltip>

      <div className="flex justify-end border-t border-t-grey-200 mt-4 pt-5">
        <Button
          type="submit"
          onSubmit={handleSubmit(onSubmit)}
          className="text-white bg-primary !text-sm">
          Next
        </Button>
      </div>
    </form>
  );
};

export default BuyerDetailStep;
