import Button from '@components/forms/Button';
import { ReactComponent as ArrowRight } from '@assets/icons/arrow_grey_right_icon.svg';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@stores';
import { post, GET_LENDER_ORGANISATION } from '@services';
const LenderSelect = () => {
  const { user, lenders, setSelectLender } = useAuthStore();
  const navigate = useNavigate();
  const handleSelectLender = (lender: any) => {
    localStorage.setItem(`lenders_${user.userId}`, JSON.stringify([lender]));
    setSelectLender(lender);
    post(
      GET_LENDER_ORGANISATION,
      { organisationId: lender.organisationId },
      false
    )
      .then((data) => {
        if (data) {
          setSelectLender({
            ...lender,
            hasInspections: data.hasInspections,
            hasAssetVerifications: data.hasAssetVerifications
          });
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        navigate('/');
      });
  };
  const sortedLenders = lenders.sort((a: any, b: any) =>
    a.organisationName.localeCompare(b.organisationName)
  );
  return (
    <div className="mx-auto w-full md:w-[500px] bg-white px-8 gap-6 md:px-10 py-[50px] rounded-xl shadow-paper relative">
      <div className="flex flex-col gap-8">
        <div className="gap-2 flex flex-col">
          <h3 className="text-3xl text-grey-900 font-semibold text-center">
            Select your lender instance
          </h3>
        </div>
        <div className="flex flex-col gap-1 mx-auto w-full bg-white rounded-xl shadow-none relative max-w-[600px] border-none">
          {sortedLenders.map((lender: any, index: number) => (
            <Button
              key={index}
              onClick={() => handleSelectLender(lender)}
              className={clsx(
                'border border-grey-300 h-[44px] bg-grey-50 w-full p-3 flex rounded-xl justify-between items-center',
                index === 0 && 'rounded-t-lg',
                index === lenders.length - 1 && 'rounded-b-lg'
              )}>
              <p className="text-left mr-1 text-sm font-semibold text-grey-900">
                {lender.organisationName}
              </p>
              <ArrowRight />
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LenderSelect;
