import React from 'react';
import { ReactComponent as InfoErrorIcon } from '@assets/icons/info_error_icon.svg';
import { ReactComponent as CheckSuccessIcon } from '@assets/icons/check_activity_success_icon.svg';
import clsx from 'clsx';

type Props = {
  label: any;
  value: string;
  success?: boolean;
  onClick: (value: string) => void;
  active: boolean;
  hideIcon?: boolean;
  className?: string;
  progressLabel?: string;
};

const StatusListItem: React.FC<Props> = ({
  label,
  value,
  success,
  onClick,
  active,
  hideIcon,
  className,
  progressLabel
}) => {
  return (
    <div
      className={clsx(
        'px-3 py-2 flex items-center gap-2 border border-transparent rounded-lg mb-1 cursor-pointer hover:bg-grey-50',
        active && 'bg-grey-50 !border-error-light',
        className
      )}
      onClick={() => onClick(value)}>
      <div className="flex gap-2">
        <span
          className={clsx(
            'font-semibold text-grey-500 text-sm whitespace-pre-line',
            active && 'text-secondary'
          )}>
          {label}
        </span>
        {progressLabel && (
          <span className="text-secondary text-xs border border-grey-300 rounded-md bg-white px-[6px] py-[2px] self-start flex-shrink-0">
            {progressLabel}
          </span>
        )}
      </div>
      <div className="">
        {!hideIcon &&
          (success ? (
            <CheckSuccessIcon className="w-4 h-4" />
          ) : (
            <InfoErrorIcon className="w-4 h-4" />
          ))}
      </div>
    </div>
  );
};

export default StatusListItem;
