import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

export interface InvoiceDetailStoreState {
  invoiceDetailData: {
    seller: {
      applicationType: string;
      address: string;
      suburb: string;
      postcode: string;
      email: string;
      firstName: string;
      lastName: string;
      mobile: string;
      userId?: string;
      state?: string;
      bsb?: string;
      accountNumber?: string;
      isDone?: boolean;
      businessName?: string;
      businessNumber?: string;
      fullName?: string;
      phone?: string;
    };
    buyer: {
      email?: string;
      firstName?: string;
      lastName?: string;
      mobile?: string;
      address?: string;
      userId?: string;
      isDone?: boolean;
      state?: string;
      suburb?: string;
      postcode?: string;
    };
    vehicle: {
      make: string;
      model: string;
      year: string;
      vin: string;
      regoPlate: string;
      regoState: string;
      engineNumber: string;
      complianceMonth: string;
      purchasePrice: number;
      odometer: string;
      deposit: number;
      balance: number;
      isDone: boolean;
    };
  };
  setInvoiceDetailData: (data: any) => void;
  resetInvoiceDetailData: () => void;
}
const initialState: any = {
  invoiceDetailData: {
    seller: {
      applicationType: '',
      address: '',
      suburb: '',
      postcode: '',
      email: '',
      firstName: '',
      lastName: '',
      mobile: '',
      bsb: '',
      accountNumber: '',
      isDone: false
    },
    buyer: {
      email: '',
      firstName: '',
      lastName: '',
      mobile: '',
      state: '',
      suburb: '',
      postcode: '',
      isDone: false
    },
    vehicle: {
      make: '',
      model: '',
      year: '',
      vin: '',
      regoPlate: '',
      regoState: '',
      engineNumber: '',
      complianceMonth: '',
      purchasePrice: 0,
      deposit: 0,
      balance: 0,
      isDone: false
    }
  }
};
export const useInvoiceDetailStore = create<InvoiceDetailStoreState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setInvoiceDetailData: (data: any) =>
          set((state) => ({
            ...state,
            invoiceDetailData: data
          })),
        resetInvoiceDetailData: () =>
          set((state) => ({
            ...state,
            invoiceDetailData: initialState.invoiceDetailData
          }))
      }),
      {
        name: 'invoiceDetails',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
