import React from 'react';
const HourIcon = ({ color = '#002861' }: { color: string }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.80581 6.15103C1.80581 5.27822 1.80363 4.40468 1.80581 3.53186C1.80873 2.58759 2.41613 1.96999 3.35383 1.9678C5.13665 1.96415 6.91946 1.96488 8.70227 1.9678C9.2105 1.96853 9.51748 2.37905 9.32571 2.7903C9.20175 3.05572 8.97279 3.13593 8.69717 3.13447C8.06061 3.13228 7.42404 3.13447 6.78748 3.13447C5.68717 3.13447 4.58613 3.13447 3.48581 3.13447C3.02425 3.13447 2.97467 3.18478 2.97467 3.65655C2.97467 5.32999 2.97467 7.00343 2.97467 8.67759C2.97467 8.75926 2.97685 8.84165 2.97248 8.92332C2.9506 9.29811 2.71144 9.54822 2.38258 9.54238C2.053 9.53655 1.81019 9.28061 1.808 8.90728C1.80217 7.98853 1.80581 7.06978 1.80581 6.15103Z"
        fill={color}
      />
      <path
        d="M5.95522 26.4309C5.10938 26.4309 4.26355 26.4331 3.41772 26.4309C2.39397 26.428 1.80626 25.8425 1.80553 24.826C1.8048 23.0709 1.80407 21.3158 1.80553 19.56C1.80553 19.1254 2.03595 18.8644 2.40199 18.8702C2.68345 18.8746 2.90657 19.0583 2.95397 19.3391C2.97365 19.455 2.97293 19.5746 2.97293 19.692C2.97438 21.3836 2.97293 23.0753 2.97365 24.767C2.97365 25.214 3.03126 25.2708 3.47605 25.2708C5.2224 25.2708 6.96803 25.2708 8.71438 25.2716C9.08845 25.2716 9.33418 25.467 9.37574 25.7849C9.42168 26.1385 9.14678 26.4258 8.73699 26.428C7.80949 26.4339 6.88199 26.4295 5.95376 26.4302L5.95522 26.4309Z"
        fill={color}
      />
      <path
        d="M26.2689 6.13875C26.2689 7.06625 26.2733 7.99448 26.2667 8.92198C26.2638 9.29167 26.0101 9.54834 25.6834 9.54323C25.3546 9.53813 25.119 9.27854 25.1081 8.90813C25.1059 8.84469 25.1081 8.78052 25.1081 8.71708C25.1081 7.58906 25.1081 6.46177 25.1081 5.33375C25.1081 4.77011 25.1081 4.20573 25.1081 3.64208C25.1081 3.19073 25.0541 3.13531 24.6115 3.13531C22.9293 3.13531 21.2464 3.13531 19.5642 3.13531C19.4556 3.13531 19.3455 3.13677 19.2376 3.12438C18.9218 3.09011 18.7184 2.87136 18.7111 2.56875C18.7038 2.25886 18.916 2.01386 19.2325 1.97448C19.3134 1.96427 19.3958 1.96792 19.4775 1.96792C21.2056 1.96792 22.9337 1.96719 24.6618 1.96792C25.6827 1.96792 26.2682 2.55344 26.2704 3.575C26.2726 4.42958 26.2704 5.2849 26.2704 6.13948H26.2697L26.2689 6.13875Z"
        fill={color}
      />
      <path
        d="M22.1075 26.4307C21.1887 26.4307 20.2707 26.4336 19.3519 26.4292C18.9808 26.4277 18.7496 26.2367 18.7154 25.9275C18.6738 25.5491 18.9276 25.2757 19.3366 25.2727C20.0731 25.2684 20.8095 25.2713 21.5467 25.2713C22.5741 25.2713 23.6022 25.2713 24.6296 25.2713C25.0372 25.2713 25.1087 25.2013 25.1087 24.801C25.1087 23.0459 25.108 21.2908 25.1102 19.5357C25.1102 19.12 25.3413 18.8663 25.7015 18.8707C25.9946 18.8743 26.2258 19.0719 26.2593 19.3636C26.2768 19.5167 26.2717 19.672 26.2717 19.8266C26.2717 21.4906 26.2732 23.1552 26.2717 24.8192C26.271 25.8517 25.6986 26.4277 24.6727 26.4307C23.8181 26.4329 22.9628 26.4307 22.1082 26.4307H22.1075Z"
        fill={color}
      />
      <g clipPath="url(#clip0_17408_62580)">
        <path
          d="M5.75 17.5088C5.79083 17.2113 5.81917 16.9113 5.87417 16.6167C6.29042 14.3851 7.44333 12.6288 9.32083 11.3597C10.6996 10.4276 12.2371 9.96174 13.8971 10.033C17.1533 10.1734 19.515 11.7372 20.9721 14.6488C21.4621 15.628 21.6787 16.6838 21.7162 17.7767C21.7183 17.8388 21.7167 17.9013 21.7158 17.9638C21.7158 17.973 21.7092 17.9822 21.7025 17.9997H20.7717C20.7154 15.7867 19.8654 13.9517 18.155 12.548C16.855 11.4813 15.3446 10.9617 13.6604 10.9842C11.7413 11.0101 10.0925 11.7063 8.7425 13.0663C7.39583 14.4226 6.73542 16.0792 6.70125 18.0076H5.75C5.75 17.8413 5.75 17.6751 5.75 17.5088Z"
          fill={color}
        />
        <path
          d="M20.3995 18.0011H15.0158C14.9383 17.0998 14.267 16.7398 13.737 16.7369C13.0679 16.7332 12.5958 17.1953 12.4504 17.9998H7.05078C7.11453 15.7953 7.96161 13.9669 9.7462 12.6719C12.0004 11.0357 14.4366 10.8615 16.9083 12.1482C19.2849 13.3853 20.4279 15.8244 20.3999 18.0011H20.3995ZM12.1108 17.2269C12.3008 16.844 12.5799 16.5782 12.9462 16.3807C11.9724 15.6815 11.022 14.9998 10.072 14.3178L10.0433 14.3461C10.727 15.2986 11.4108 16.2511 12.1108 17.2269Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_17408_62580">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(5.75 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HourIcon;
