import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import {
  InspectionDocumentStatusEnum,
  InspectionSecurityStatusEnum,
  InspectionStatusEnum,
  InspectionStatusType,
  SellerPageTitle,
  SellerPageTitleType
} from '@types';
interface SellerStoreState {
  isHome: boolean;
  hasDriverLicense: boolean;
  friendlyId: string;
  inspectionId: string;
  status: InspectionStatusType;
  lenderOrganisationId: string;
  lenderReference: string;
  lenderUserId: string;
  state: string;
  createdTime: Date;
  assetType: string;
  documentTypes: any;
  files: any;
  vehicleInfo: any;
  vehiclePhotoList: any;
  stepsComplete: number;
  idStepsRemaining: number;
  stepsTotal: number;
  documentType: string;
  checkType: string;
  sellerSession: {
    qrCode: string;
    expiry: string;
    sessionToken: string;
    url: string;
  };
  seller: {
    address: string;
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    userId?: string;
  };
  documents: any;
  buyer: {
    email?: string;
    firstName?: string;
    lastName?: string;
    mobile?: string;
    address?: string;
    userId?: string;
  };
  lender: {
    email?: string;
    firstName?: string;
    lastName?: string;
    mobile?: string;
    name?: string;
    organisationId?: string;
  };
  progress: number;
  inspectionChecks: {
    licenseAuthenticity: any;
    licenseEdits: any;
    licenseExpiry: any;
    licenseFaceMatch: any;
    licenseLiveness: any;
    rav: any;
    stolen: any;
    writtenOff: any;
    registration: any;
    nevdisVehicleDetails: any;
    ppsr: any;
    glassesVehicleData: any;
    ASSET_FRONT: any;
    ASSET_BACK: any;
    INTERNAL_FRONT: any;
    INTERNAL_BACK: any;
    INTERNAL_BOOT: any;
    INTERNAL_LIVING_AREA: any;
    INTERNAL_KITCHEN: any;
    INTERNAL_SLEEPING_AREA: any;
    BANK_STATEMENTS: any;
    ROADWORTHY_CERT: any;
    PAYOUT_LETTER: any;
    OWNERSHIP_PROOF: any;
    regoCertificate: any;
    bankStatement: any;
    vin: any;
    vehiclePhotos: any;
    licencePlate: any;
  };
  setDocumentType: (data: string) => void;
  setCheckType: (data: string) => void;
  setInspectionChecks: (data: any) => void;
  setVehiclePhotoList: (data: any) => void;
  uploadFile: (data: any) => void;
  setData: (data: any) => void;
  setHasDriverLicense: (data: boolean) => void;
  setIsHome: (data: boolean) => void;
  activePageTitle: SellerPageTitleType;
  setActivePageTitle: (data: any) => void;
  driverLicense: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
  };
  setDriverLicense: (data: any) => void;
  selfie: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
  };
  setSelfie: (data: any) => void;
  vin: string;
  vinObj: {
    type: string;
    available: boolean;
    image: string;
    vin: string;
    isDone: boolean;
    file?: any;
    ocrFields?: any;
    formStatus?: boolean;
  };
  setVin: (data: any) => void;
  setVinImage: (data: any) => void;
  setVinOCR: (data: any) => void;
  setVinFormStatus: (data: boolean) => void;
  rego: string;
  numberPlate: {
    type: string;
    available: boolean;
    image: string;
    plateNumber: string;
    state: string;
    isDone: boolean;
    file?: any;
    ocrFields?: any;
    formStatus?: boolean;
  };
  setNumberPlateFormStatus: (data: boolean) => void;
  setNumberPlate: (data: any) => void;
  setNumberPlateImage: (data: any) => void;
  setNumberPlateOCR: (data: any) => void;
  odometer: {
    type: string;
    available: boolean;
    image: string;
    odometer: string;
    isDone: boolean;
    file?: any;
  };
  operatingHours: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleFront: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleFrontSeats: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleBack: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleBackSeats: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleBoot: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleLivingArea: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleKitchen: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleSleepingArea: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  
  vehiclePassenger: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  vehicleDriver: {
    type: string;
    available: boolean;
    image: string;
    isDone: boolean;
    file?: any;
  };
  externalPhotos: {
    type: string;
    available: boolean;
    isDone: boolean;
    file?: any;
  };
  setExternalPhotos: (data: any) => void;
  setOdometer: (data: any) => void;
  setOperatingHours: (data: any) => void;
  setOdometerImage: (data: any) => void;
  setOperatingHoursImage: (data: any) => void;
  vehicle: {
    type: string;
    available: boolean;
    imageFront: string;
    imageDriver: string;
    imageBack: string;
    imagePassenger: string;
    vehiclePosition: number;
    isDone: boolean;
    fileDriver?: any;
    filePassenger?: any;
    fileFront?: any;
    fileBack?: any;
  };
  setVehicleImageFront: (data: any) => void;
  setVehicleImageDriver: (data: any) => void;
  setVehicleImageBack: (data: any) => void;
  setVehicleImagePassenger: (data: any) => void;
  setVehicleImageFrontSeats: (data: any) => void;
  setVehicleImageBackSeats: (data: any) => void;
  setVehicleImageBoot: (data: any) => void;
  setVehicleImageLivingArea: (data: any) => void;
  setVehicleImageKitchen: (data: any) => void;
  setVehicleImageSleepingArea: (data: any) => void;
  setVehiclePosition: (data: any) => void;
  setVehicle: (data: any) => void;
  registration: {
    type: string;
    available: boolean;
    registrationNumberPlate: string;
    registrationState: string;
    registrationVin: string;
    registrationExpiryDate: string;
    image: string;
    fileName?: string;
    isDone: boolean;
    file?: any;
    ocrFields?: any;
    ocrStatus?: string;
    formStatus?: boolean;
  };
  setRegistrationFormStatus: (data: boolean) => void;
  setRegistration: (data: any) => void;
  setRegistrationImage: (data: any) => void;
  setRegistrationOCR: (data: any) => void;
  setSellerSession: (data: any) => void;
  bank: {
    type: string;
    available: boolean;
    accountName: string;
    accountNumber: string;
    bsb: string;
    image: string;
    fileName?: string;
    isDone: boolean;
    file?: any;
    ocrFields?: any;
    ocrStatus?: string;
    formStatus?: boolean;
  };
  setBank: (data: any) => void;
  setBankImage: (data: any) => void;
  setBankOCR: (data: any) => void;
  setBankFormStatus: (data: boolean) => void;
  ownership: {
    available: boolean;
    image: string;
    isDone: boolean;
    isOwner: boolean;
    relationship: string;
    other: string;
  };
  setOwnership: (data: any) => void;
  setOwnershipImage: (data: any) => void;
  cert: {
    type: string;
    available: boolean;
    image: string;
    fileName: string;
    isDone: boolean;
    file?: any;
  };
  payoutLetter: {
    type: string;
    available: boolean;
    image: string;
    fileName: string;
    isDone: boolean;
    file?: any;
  };
  ownershipProof: {
    type: string;
    available: boolean;
    image: string;
    fileName: string;
    isDone: boolean;
    file?: any;
  };
  setStatus: (data: InspectionStatusType) => void;
  setDriverLicenseStatus: (data: boolean) => void;
  setCert: (data: any) => void;
  setCertImage: (data: any) => void;
  setPayoutLetter: (data: any) => void;
  setPayoutLetterImage: (data: any) => void;
  setOwnershipProof: (data: any) => void;
  setOwnershipProofImage: (data: any) => void;
  setSellerVerification: (data: any) => void;
  setVinStatus: (data: boolean) => void;
  setNumberPlateStatus: (data: boolean) => void;
  setOdometerStatus: (data: boolean) => void;
  setOperatingHoursStatus: (data: boolean) => void;
  setVehicleFrontStatus: (data: boolean) => void;
  setVehicleDriverStatus: (data: boolean) => void;
  setVehiclePassengerStatus: (data: boolean) => void;
  setVehicleBackStatus: (data: boolean) => void;
  setVehicleFrontSeatsStatus: (data: boolean) => void;
  setVehicleBackSeatsStatus: (data: boolean) => void;
  setVehicleBootStatus: (data: boolean) => void;
  setVehicleLivingAreaStatus: (data: boolean) => void;
  setVehicleKitchenStatus: (data: boolean) => void;
  setVehicleSleepingAreaStatus: (data: boolean) => void;
  setExternalPhotosStatus: (data: boolean) => void;
  setVehicleStatus: (data: boolean) => void;
  setRegistrationStatus: (data: boolean) => void;
  setBankStatus: (data: boolean) => void;
  setCertStatus: (data: boolean) => void;
  setPayoutLetterStatus: (data: boolean) => void;
  setOwnershipProofStatus: (data: boolean) => void;
  setVinFile: (data: any) => void;
  setPlateNumberFile: (data: any) => void;
  setOdoFile: (data: any) => void;
  setOperatingHoursFile: (data: any) => void;
  setFrontFile: (data: any) => void;
  setBackFile: (data: any) => void;
  setFrontSeatsFile: (data: any) => void;
  setBackSeatsFile: (data: any) => void;
  setBootFile: (data: any) => void;
  setLivingAreaFile: (data: any) => void;
  setKitchenFile: (data: any) => void;
  setSleepingAreaFile: (data: any) => void;
  setDriverFile: (data: any) => void;
  setPassengerFile: (data: any) => void;
  setBankFile: (data: any) => void;
  setCertFile: (data: any) => void;
  setPayoutLetterFile: (data: any) => void;
  setOwnershipProofFile: (data: any) => void;
  setRegoFile: (data: any) => void;
  setVinAvailableStatus: (data: boolean) => void;
  setNumberPlateAvailableStatus: (data: boolean) => void;
  setOdometerAvailableStatus: (data: boolean) => void;
  setOperatingHoursAvailableStatus: (data: boolean) => void;
  setVehicleAvailableStatus: (data: boolean) => void;
  setExternalPhotosAvailableStatus: (data: boolean) => void;
  setVehicleFrontAvailableStatus: (data: boolean) => void;
  setVehicleDriverAvailableStatus: (data: boolean) => void;
  setVehiclePassengerAvailableStatus: (data: boolean) => void;
  setVehicleBackAvailableStatus: (data: boolean) => void;
  setVehicleFrontSeatsAvailableStatus: (data: boolean) => void;
  setVehicleBackSeatsAvailableStatus: (data: boolean) => void;
  setVehicleBootAvailableStatus: (data: boolean) => void;
  setVehicleLivingAreaAvailableStatus: (data: boolean) => void;
  setVehicleKitchenAvailableStatus: (data: boolean) => void;
  setVehicleSleepingAreaAvailableStatus: (data: boolean) => void;
  setRegistrationAvailableStatus: (data: boolean) => void;
  setBankAvailableStatus: (data: boolean) => void;
  setCertAvailableStatus: (data: boolean) => void;
  setDriverLicenseAvailableStatus: (data: boolean) => void;
  setPayoutLetterAvailableStatus: (data: boolean) => void;
  setOwnershipProofAvailableStatus: (data: boolean) => void;
  reset: () => void;
}

const inspectionDetailData = {
  hasDriverLicense: false,
  friendlyId: '',
  documentType: '',
  checkType: '',
  inspectionId: '',
  createdTime: new Date(),
  status: InspectionStatusEnum.Initiated,
  lenderOrganisationId: '',
  vehiclePhotoList: ['ASSET_FRONT', 'ASSET_LEFT', 'ASSET_RIGHT', 'ASSET_BACK'],
  lenderReference: '',
  lenderUserId: '',
  stepsComplete: 0,
  idStepsRemaining: 0,
  stepsTotal: 0,
  state: '',
  vehicleInfo: {},
  assetType: '',
  documentTypes: [],
  files: [],
  seller: {
    address: '',
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
    userId: ''
  },
  sellerSession: {
    qrCode: '',
    expiry: '',
    sessionToken: '',
    url: ''
  },
  buyer: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    userId: ''
  },
  lender: {
    email: '',
    firstName: '',
    lastName: '',
    name: '',
    phone: '',
    organisationId: ''
  },
  progress: 0,
  activePageTitle: SellerPageTitle.Blank,
  driverLicense: {
    type: 'SELLER_ID',
    available: false,
    image: '',
    isDone: false
  },
  selfie: {
    type: 'SELLER_ID',
    available: false,
    image: '',
    isDone: false
  },
  cert: {
    type: 'ROADWORTHY_CERT',
    available: false,
    image: '',
    fileName: '',
    isDone: false,
    file: null
  },

  bank: {
    type: 'BANK_STATEMENTS',
    available: false,
    image: '',
    fileName: '',
    accountName: '',
    accountNumber: '',
    bsb: '',
    isDone: false,
    file: null,
    formStatus: false,
    ocrFields: [],
    ocrStatus: ''
  },
  registration: {
    type: 'REGO_PAPERS',
    available: false,
    registrationNumberPlate: '',
    registrationState: '',
    registrationVin: '',
    registrationExpiryDate: '',
    image: '',
    fileName: '',
    isDone: false,
    file: null,
    ocrFields: [],
    ocrStatus: '',
    formStatus: false
  },
  vin: '',
  vinObj: {
    type: 'VIN',
    available: false,
    image: '',
    vin: '',
    isDone: false,
    file: null,
    ocrFields: [],
    formStatus: false
  },
  rego: '',
  numberPlate: {
    type: 'REGO_PLATE',
    available: false,
    image: '',
    isDone: false,
    plateNumber: '',
    state: '',
    file: null,
    ocrFields: [],
    formStatus: false
  },
  odometer: {
    type: 'ODOMETER',
    available: false,
    image: '',
    odometer: '',
    isDone: false,
    file: null
  },
  operatingHours: {
    type: 'OPERATING_HOURS',
    available: false,
    image: '',
    isDone: false,
    file: null
  },
  payoutLetter: {
    type: 'PAYOUT_LETTER',
    available: false,
    image: '',
    fileName: '',
    isDone: false,
    file: null
  },
  ownershipProof: {
    type: 'OWNERSHIP_PROOF',
    available: false,
    image: '',
    fileName: '',
    isDone: false,
    file: null
  },
  externalPhotos: {
    type: 'EXTERNAL_PHOTOS',
    available: false,
    isDone: false
  },
  vehicleFront: {
    type: 'ASSET_FRONT',
    available: false,
    image: '',
    isDone: false
  },
  vehicleDriver: {
    type: 'ASSET_LEFT',
    available: false,
    image: '',
    isDone: false
  },
  vehiclePassenger: {
    type: 'ASSET_RIGHT',
    available: false,
    image: '',
    isDone: false
  },
  vehicleBack: {
    type: 'ASSET_BACK',
    available: false,
    image: '',
    isDone: false
  },
  vehicleFrontSeats: {
    type: 'INTERNAL_FRONT',
    available: false,
    image: '',
    isDone: false
  },
  vehicleBackSeats: {
    type: 'INTERNAL_BACK',
    available: false,
    image: '',
    isDone: false
  },
  vehicleBoot: {
    type: 'INTERNAL_BOOT',
    available: false,
    image: '',
    isDone: false
  },
  vehicleLivingArea: {
    type: 'INTERNAL_LIVING_AREA',
    available: false,
    image: '',
    isDone: false
  },
  vehicleKitchen: {
    type: 'INTERNAL_KITCHEN',
    available: false,
    image: '',
    isDone: false
  },
  vehicleSleepingArea: {
    type: 'INTERNAL_SLEEPING_AREA',
    available: false,
    image: '',
    isDone: false
  },
  vehicle: {
    type: 'VEHICLE',
    available: false,
    imageFront: '',
    imageDriver: '',
    imageBack: '',
    imagePassenger: '',
    vehiclePosition: 1,
    isDone: false,
    fileDriver: {},
    filePassenger: {},
    fileFront: {},
    fileBack: {},
    fileFrontSeats: {},
    fileBackSeats: {},
    fileBoot: {},
    fileLivingArea: {},
    fileKitchen: {},
    fileSleepingArea: {}
  },
  ownership: {
    available: false,
    image: '',
    isDone: false,
    isOwner: true,
    relationship: 'partner',
    other: ''
  },
  inspectionChecks: {
    licenseAuthenticity: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'LICENSE_AUTHENTICITY',
      subChecks: []
    },
    licenseEdits: {
      type: 'LICENSE_EDITS',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    licenseExpiry: {
      type: 'LICENSE_EXPIRY',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    licenseFaceMatch: {
      type: 'LICENSE_FACE_MATCH',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    licenseLiveness: {
      type: 'LICENSE_LIVENESS',
      status: InspectionSecurityStatusEnum.notPerformed
    },
    rav: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'RAV_COMPLIANCE'
    },
    stolen: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_STOLEN'
    },
    writtenOff: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_WRITTEN_OFF'
    },
    registration: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_REGISTRATION'
    },
    nevdisVehicleDetails: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'NEVDIS_VEHICLE_DETAILS'
    },
    ppsr: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'PPSR_ENCUMBRANCE'
    },
    glassesVehicleData: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'VEHICLE_DATA'
    },
    vehiclePhotos: {
      status: InspectionDocumentStatusEnum.Incomplete,
      type: 'VEHICLE_PHOTOS'
    },
    ASSET_FRONT: { status: InspectionDocumentStatusEnum.Incomplete },
    ASSET_BACK: { status: InspectionDocumentStatusEnum.Incomplete },
    INTERNAL_FRONT: { status: InspectionDocumentStatusEnum.Incomplete },
    INTERNAL_BACK: { status: InspectionDocumentStatusEnum.Incomplete },
    INTERNAL_BOOT: { status: InspectionDocumentStatusEnum.Incomplete },
    INTERNAL_LIVING_AREA: { status: InspectionDocumentStatusEnum.Incomplete },
    INTERNAL_KITCHEN: { status: InspectionDocumentStatusEnum.Incomplete },
    INTERNAL_SLEEPING_AREA: { status: InspectionDocumentStatusEnum.Incomplete },
    BANK_STATEMENTS: { status: InspectionDocumentStatusEnum.Incomplete },
    ROADWORTHY_CERT: { status: InspectionDocumentStatusEnum.Incomplete },
    PAYOUT_LETTER: { status: InspectionDocumentStatusEnum.Incomplete },
    OWNERSHIP_PROOF: { status: InspectionDocumentStatusEnum.Incomplete },
    VIN: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    REGO_PLATE: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    ODOMETER: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    OPERATING_HOURS: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    ASSET_LEFT: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    ASSET_RIGHT: {
      status: InspectionDocumentStatusEnum.Incomplete
    },
    bankStatement: null,
    regoCertificate: null,
    vin: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'VEHICLE_VIN'
    },
    licencePlate: {
      status: InspectionSecurityStatusEnum.notPerformed,
      type: 'VEHICLE_PLATE'
    }
  }
};
export const useSellerStore = create<SellerStoreState>()(
  devtools(
    persist(
      (set) => ({
        isHome: Boolean(true),
        ...inspectionDetailData,
        documents: {},
        setDocumentType: (data) => set({ documentType: data }),
        setCheckType: (data) => set({ checkType: data }),
        setData: (data) => set({ ...data }),
        setStatus: (data) => set({ status: data }),
        setExternalPhotos: (data) => set({ externalPhotos: { ...data } }),
        setVehiclePhotoList: (data) => set({ vehiclePhotoList: [...data] }),
        uploadFile: (data) => set({ files: [...data] }),
        setDriverLicense: (data) =>
          set({ driverLicense: { ...data, type: 'SELLER_ID' } }),
        setHasDriverLicense: (data) => set({ hasDriverLicense: data }),
        setIsHome: (data) => set({ isHome: data }),
        setVehicleImageFront: (data) => set({ vehicleFront: { ...data } }),
        setVehicleImageDriver: (data) => set({ vehicleDriver: { ...data } }),
        setVehicleImageBack: (data) => set({ vehicleBack: { ...data } }),
        setVehicleImagePassenger: (data) =>
          set({ vehiclePassenger: { ...data } }),
        setVehiclePosition: (data) => set({ vehicle: { ...data } }),
        setVinImage: (data) => set({ vinObj: { ...data } }),
        setNumberPlateImage: (data) => set({ numberPlate: { ...data } }),
        setOdometerImage: (data) => set({ odometer: { ...data } }),
        setBankImage: (data) => set({ bank: { ...data } }),
        setCertImage: (data) => set({ cert: { ...data } }),
        setOwnershipImage: (data) => set({ ownership: { ...data } }),
        setRegistrationImage: (data) => set({ registration: { ...data } }),
        setActivePageTitle: (data: SellerPageTitleType) =>
          set({ activePageTitle: data }),
        setSelfie: (data) => set({ selfie: data }),
        setSellerSession: (data) => set({ sellerSession: data }),
        setVehicleImageFrontSeats: (data) =>
          set({ vehicleFrontSeats: { ...data } }),
        setVehicleImageBackSeats: (data) =>
          set({ vehicleBackSeats: { ...data } }),
        setVehicleImageBoot: (data) => set({ vehicleBoot: { ...data } }),
        setVehicleImageLivingArea: (data) => set({ vehicleLivingArea: { ...data } }),
        setVehicleImageKitchen: (data) => set({ vehicleKitchen: { ...data } }),
        setVehicleImageSleepingArea: (data) => set({ vehicleSleepingArea: { ...data } }),
        setVin: (data) => set({ vinObj: { ...data, type: 'VIN' } }),
        setOdometer: (data) => set({ odometer: { ...data, type: 'ODOMETER' } }),
        setOperatingHours: (data) => set({ operatingHours: { ...data } }),
        setOperatingHoursImage: (data) => set({ operatingHours: { ...data } }),
        setOwnership: (data) => set({ ownership: { ...data } }),
        setVehicle: (data) => set({ vehicle: { ...data, type: 'VEHICLE' } }),
        setRegistration: (data) =>
          set({ registration: { ...data, type: 'REGO_PAPERS' } }),
        setNumberPlate: (data) =>
          set({ numberPlate: { ...data, type: 'REGO_PLATE' } }),
        setBank: (data) => set({ bank: { ...data, type: 'BANK_STATEMENTS' } }),
        setCert: (data) => set({ cert: { ...data, type: 'ROADWORTHY_CERT' } }),
        setPayoutLetter: (data) =>
          set({ payoutLetter: { ...data, type: 'PAYOUT_LETTER' } }),
        setOwnershipProof: (data) =>
          set({ ownershipProof: { ...data, type: 'OWNERSHIP_PROOF' } }),
        setPayoutLetterImage: (data) =>
          set({ payoutLetter: { ...data, type: 'PAYOUT_LETTER' } }),
        setOwnershipProofImage: (data) => set({ ownershipProof: { ...data } }),
        setDriverLicenseStatus: (data) =>
          set((state) => ({
            driverLicense: { ...state.driverLicense, isDone: data }
          })),
        setVinStatus: (data) =>
          set((state) => ({
            vinObj: { ...state.vinObj, type: 'VIN', isDone: data }
          })),
        setNumberPlateStatus: (data) =>
          set((state) => ({
            numberPlate: {
              ...state.numberPlate,
              type: 'REGO_PLATE',
              isDone: data
            }
          })),
        setOdometerStatus: (data) =>
          set((state) => ({
            odometer: { ...state.odometer, type: 'ODOMETER', isDone: data }
          })),
        setOperatingHoursStatus: (data) =>
          set((state) => ({
            operatingHours: { ...state.operatingHours, isDone: data }
          })),
        setExternalPhotosStatus: (data) =>
          set((state) => ({
            externalPhotos: { ...state.externalPhotos, isDone: data }
          })),
        setVehicleFrontStatus: (data) =>
          set((state) => ({
            vehicleFront: { ...state.vehicleFront, isDone: data }
          })),
        setVehicleDriverStatus: (data) =>
          set((state) => ({
            vehicleDriver: { ...state.vehicleDriver, isDone: data }
          })),
        setVehiclePassengerStatus: (data) =>
          set((state) => ({
            vehiclePassenger: { ...state.vehiclePassenger, isDone: data }
          })),
        setVehicleBackStatus: (data) =>
          set((state) => ({
            vehicleBack: { ...state.vehicleBack, isDone: data }
          })),
        setVehicleFrontSeatsStatus: (data) =>
          set((state) => ({
            vehicleFrontSeats: { ...state.vehicleFrontSeats, isDone: data }
          })),
        setVehicleBackSeatsStatus: (data) =>
          set((state) => ({
            vehicleBackSeats: { ...state.vehicleBackSeats, isDone: data }
          })),
        setVehicleBootStatus: (data) =>
          set((state) => ({
            vehicleBoot: { ...state.vehicleBoot, isDone: data }
          })),
        setVehicleLivingAreaStatus: (data) =>
          set((state) => ({
            vehicleLivingArea: { ...state.vehicleLivingArea, isDone: data }
          })),
        setVehicleKitchenStatus: (data) =>
          set((state) => ({
            vehicleKitchen: { ...state.vehicleKitchen, isDone: data }
          })),
        setVehicleSleepingAreaStatus: (data) =>
          set((state) => ({
            vehicleSleepingArea: { ...state.vehicleSleepingArea, isDone: data }
          })),
        setVehicleStatus: (data) =>
          set((state) => ({
            vehicle: { ...state.vehicle, type: 'VEHICLE', isDone: data }
          })),
        setRegistrationStatus: (data) =>
          set((state) => ({
            registration: {
              ...state.registration,
              type: 'REGO_PAPERS',
              isDone: data
            }
          })),
        setBankStatus: (data) =>
          set((state) => ({
            bank: { ...state.bank, type: 'BANK_STATEMENTS', isDone: data }
          })),
        setCertStatus: (data) =>
          set((state) => ({
            cert: { ...state.cert, type: 'ROADWORTHY_CERT', isDone: data }
          })),

        setPayoutLetterStatus: (data) =>
          set((state) => ({
            payoutLetter: {
              ...state.payoutLetter,
              type: 'PAYOUT_LETTER',
              isDone: data
            }
          })),
        setOwnershipProofStatus: (data) =>
          set((state) => ({
            ownershipProof: {
              ...state.ownershipProof,
              type: 'OWNERSHIP_PROOF',
              isDone: data
            }
          })),
        setSellerVerification: (data) => set({ ...data }),
        setDriverLicenseAvailableStatus: (data) =>
          set((state) => ({
            driverLicense: { ...state.driverLicense, available: data }
          })),
        setVinAvailableStatus: (data) =>
          set((state) => ({ vinObj: { ...state.vinObj, available: data } })),
        setNumberPlateAvailableStatus: (data) =>
          set((state) => ({
            numberPlate: { ...state.numberPlate, available: data }
          })),
        setOdometerAvailableStatus: (data) =>
          set((state) => ({
            odometer: { ...state.odometer, available: data }
          })),
        setOperatingHoursAvailableStatus: (data) =>
          set((state) => ({
            operatingHours: { ...state.operatingHours, available: data }
          })),
        setExternalPhotosAvailableStatus: (data) =>
          set((state) => ({
            externalPhotos: { ...state.externalPhotos, available: data }
          })),
        setVehicleAvailableStatus: (data) =>
          set((state) => ({ vehicle: { ...state.vehicle, available: data } })),
        setVehicleFrontAvailableStatus: (data) =>
          set((state) => ({
            vehicleFront: { ...state.vehicleFront, available: data }
          })),
        setVehicleDriverAvailableStatus: (data) =>
          set((state) => ({
            vehicleDriver: { ...state.vehicleDriver, available: data }
          })),
        setVehiclePassengerAvailableStatus: (data) =>
          set((state) => ({
            vehiclePassenger: { ...state.vehiclePassenger, available: data }
          })),
        setVehicleBackAvailableStatus: (data) =>
          set((state) => ({
            vehicleBack: { ...state.vehicleBack, available: data }
          })),
        setVehicleFrontSeatsAvailableStatus: (data) =>
          set((state) => ({
            vehicleFrontSeats: { ...state.vehicleFrontSeats, available: data }
          })),
        setVehicleBackSeatsAvailableStatus: (data) =>
          set((state) => ({
            vehicleBackSeats: { ...state.vehicleBackSeats, available: data }
          })),
        setVehicleBootAvailableStatus: (data) =>
          set((state) => ({
            vehicleBoot: { ...state.vehicleBoot, available: data }
          })),
        setVehicleLivingAreaAvailableStatus: (data) =>
          set((state) => ({
            vehicleLivingArea: { ...state.vehicleLivingArea, available: data }
          })),
        setVehicleKitchenAvailableStatus: (data) =>
          set((state) => ({
            vehicleKitchen: { ...state.vehicleKitchen, available: data }
          })),
        setVehicleSleepingAreaAvailableStatus: (data) =>
          set((state) => ({
            vehicleSleepingArea: { ...state.vehicleSleepingArea, available: data }
          })),
        setRegistrationAvailableStatus: (data) =>
          set((state) => ({
            registration: { ...state.registration, available: data }
          })),
        setPayoutLetterAvailableStatus: (data) =>
          set((state) => ({
            payoutLetter: { ...state.payoutLetter, available: data }
          })),
        setOwnershipProofAvailableStatus: (data) =>
          set((state) => ({
            ownershipProof: { ...state.ownershipProof, available: data }
          })),
        reset: () => set({ ...inspectionDetailData }),
        setVinFile: (data) =>
          set((state) => ({
            vinObj: { ...state.vinObj, file: data }
          })),
        setPlateNumberFile: (data) =>
          set((state) => ({
            numberPlate: { ...state.numberPlate, file: data }
          })),
        setOdoFile: (data) =>
          set((state) => ({ odometer: { ...state.odometer, file: data } })),
        setOperatingHoursFile: (data) =>
          set((state) => ({
            operatingHours: { ...state.operatingHours, file: data }
          })),
        setBackFile: (data) =>
          set((state) => ({ vehicle: { ...state.vehicle, fileBack: data } })),
        setFrontSeatsFile: (data) =>
          set((state) => ({
            vehicle: { ...state.vehicle, fileFrontSeats: data }
          })),
        setBackSeatsFile: (data) =>
          set((state) => ({
            vehicle: { ...state.vehicle, fileBackSeats: data }
          })),
        setBootFile: (data) =>
          set((state) => ({
            vehicle: { ...state.vehicle, fileBoot: data }
          })),
        setLivingAreaFile: (data) =>
          set((state) => ({
            vehicleLivingArea: { ...state.vehicleLivingArea, file: data }
          })),
        setKitchenFile: (data) =>
          set((state) => ({
            vehicleKitchen: { ...state.vehicleKitchen, file: data }
          })),
        setSleepingAreaFile: (data) =>
          set((state) => ({
            vehicleSleepingArea: { ...state.vehicleSleepingArea, file: data }
          })),
        setFrontFile: (data) =>
          set((state) => ({
            vehicle: { ...state.vehicle, fileFront: data }
          })),
        setDriverFile: (data) =>
          set((state) => ({
            vehicle: { ...state.vehicle, fileDriver: data }
          })),
        setPassengerFile: (data) =>
          set((state) => ({
            vehicle: { ...state.vehicle, filePassenger: data }
          })),
        setBankFile: (data) =>
          set((state) => ({ bank: { ...state.bank, file: data } })),
        setCertFile: (data) =>
          set((state) => ({ cert: { ...state.cert, file: data } })),
        setPayoutLetterFile: (data) =>
          set((state) => ({
            payoutLetter: { ...state.payoutLetter, file: data }
          })),
        setOwnershipProofFile: (data) =>
          set((state) => ({
            ownershipProof: { ...state.ownershipProof, file: data }
          })),
        setRegoFile: (data) =>
          set((state) => ({
            registration: { ...state.registration, file: data }
          })),
        setBankAvailableStatus: (data) =>
          set((state) => ({ bank: { ...state.bank, available: data } })),
        setCertAvailableStatus: (data) =>
          set((state) => ({ cert: { ...state.cert, available: data } })),
        setInspectionChecks: (data) => set({ inspectionChecks: data }),
        setRegistrationOCR: (data) =>
          set((state) => ({
            registration: { ...state.registration, ocrFields: data }
          })),
        setBankOCR: (data) =>
          set((state) => ({ bank: { ...state.bank, ocrFields: data } })),
        setVinOCR: (data) =>
          set((state) => ({
            vinObj: { ...state.vinObj, ocrFields: data }
          })),
        setNumberPlateOCR: (data) =>
          set((state) => ({
            numberPlate: { ...state.numberPlate, ocrFields: data }
          })),
        setNumberPlateFormStatus: (data) =>
          set((state) => ({
            numberPlate: { ...state.numberPlate, formStatus: data }
          })),
        setVinFormStatus: (data) =>
          set((state) => ({
            vinObj: { ...state.vinObj, formStatus: data }
          })),
        setRegistrationFormStatus: (data) =>
          set((state) => ({
            registration: { ...state.registration, formStatus: data }
          })),
        setBankFormStatus: (data) =>
          set((state) => ({ bank: { ...state.bank, formStatus: data } }))
      }),
      {
        name: 'seller',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
