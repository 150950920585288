import React, { useEffect, useState } from 'react';
import Modal from '@components/widgets/Modal';
import Button from '@components/forms/Button';
import { useOrgStore, useSellerStore } from '@stores';
import { useParams, useSearchParams } from 'react-router-dom';
import { ADD_INSPECTION_COMMENT, post } from '@services';
import clsx from 'clsx';
type Props = {
  open: boolean;
  onClose: () => void;
};

const ContactSupportSellerModal: React.FC<Props> = ({ open, onClose }) => {
  const { organisation } = useOrgStore();
  const { documentType, checkType, documents } = useSellerStore();
  const [brand, setBrand] = React.useState('');
  const [helpText, setHelpText] = React.useState('');
  const [error, setError] = React.useState('');
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const params = useParams();
  const inspectionId = params.id;
  const [primaryColor, setPrimaryColor] = useState('#002861');
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
  }, []);
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setBrand(organisation.name);
    }
  }, []);

  const handleSubmit = () => {
    if (!helpText.trim()) {
      setError('Please provide details on how we can assist you');
      return;
    }

    const payload = {
      inspectionId,
      documentType,
      fileId: documents[documentType]?.fileId || '',
      checkType,
      isSupport: true,
      text: helpText
    };

    post(ADD_INSPECTION_COMMENT, payload).then((res) => {
      if (res.commentId) {
        setError('');
        setShowConfirmation(true);

        // Close modal after 5 seconds
        setTimeout(() => {
          onClose();
          setShowConfirmation(false);
          setHelpText('');
          window.location.reload();
        }, 5000);
      } else {
        setError('Failed to submit comment');
      }
    });
  };

  return (
    <Modal size="md" open={open} onClose={onClose} hideClose>
      <div className="px-2 pt-6 pb-2">
        {!showConfirmation ? (
          <>
            <h2 className="text-[18px] font-medium text-grey-900">
              Need help with your inspection?
            </h2>
            <p className="text-[18px] font-medium text-grey-900 mb-6">
              We&apos;re here to assist.
            </p>
            <div className="mb-6">
              <label className="block text-[14px] text-grey-700 mb-2">
                How can we help?
              </label>
              <textarea
                value={helpText}
                onChange={(e) => setHelpText(e.target.value)}
                className={clsx(
                  'w-full p-3 border rounded-lg min-h-[120px] focus:outline-none focus:ring-2',
                  'focus:ring'
                )}
                style={
                  { '--tw-ring-color': primaryColor } as React.CSSProperties
                }
                placeholder="Type your question or describe the issue here..."
              />
              {error && <p className="mt-2 text-error-500 text-sm">{error}</p>}
            </div>
            <Button
              className={clsx(
                'capitalize flex items-center gap-2 w-full text-sm font-semibold py-2.5 font-[600] text-white lg:self-end sm:self-center mb-1'
              )}
              style={{ backgroundColor: primaryColor }}
              onClick={handleSubmit}>
              Submit
            </Button>
          </>
        ) : (
          <div className="text-center py-2">
            <h2 className="text-[18px] font-medium text-grey-900 mb-1">
              Thanks!
            </h2>
            <p className="text-[18px] text-grey-900">
              We&apos;ve got your message and will follow up with you shortly.
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ContactSupportSellerModal;
