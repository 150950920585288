/* eslint-disable react/prop-types */
import { Button } from '@material-tailwind/react';
import { SellerPage } from '@types';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import { ReactComponent as FrontIcon } from '@assets/icons/Front.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/Success.svg';
import { ReactComponent as Light } from '@assets/icons/light_icon.svg';
import { ReactComponent as Eye } from '@assets/icons/eye_icon.svg';
import { ReactComponent as Mobile } from '@assets/icons/device_icon.svg';
import { ReactComponent as SelfieIcon } from '@assets/icons/Selfie.svg';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { Radio } from '@material-tailwind/react';
import Input from '@components/forms/Input';
import { SellerVerifyDesktopQR } from '@components/widgets/SellerVerifyDesktopQR';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useSellerStore } from '@stores';

const ownershipSchema = yup
  .object({
    other: yup.string().required(),
    isOwner: yup.bool().required(),
    relationship: yup.string().required()
  })
  .required();

export const License = ({
  setPage
}: {
  setPage: (page: SellerPage) => void;
}) => {
  const [step, setStep] = useState('init');
  const { ownership, driverLicense, selfie } = useSellerStore();

  if (step === 'ownership') {
    return <VehicleOwnership setStep={setStep} />;
  }

  if (step === 'license') {
    return <DriverLicense setStep={setStep} setPage={setPage} />;
  }
  if (step === 'selfie') {
    return <Selfie setStep={setStep} />;
  }
  return (
    <>
      <div className="flex mt-4">
        <div className="grow">
          <h2 className="text-[24px] lg:text-[30px] font-semibold text-grey-900 mt-0">
            Licence or Photo Card
          </h2>
          {isMobile && (
            <p className="mt-4 text-gray-600">
              Your licence helps verify your identity and ownership of the
              vehicle, ensuring trust in the transaction.
            </p>
          )}
        </div>
      </div>
      {isMobile ? (
        <div className="py-8 mx-auto w-full bg-white rounded-xl overflow-hidden shadow-paper relative">
          <Button
            onClick={() => setStep('ownership')}
            className="bg-white normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between text-[18px]">
            <div className="flex">
              <p className="mr-2">Vehicle ownership </p>
              {ownership.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('license')}
            className="bg-white normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between text-[18px]">
            <div className="flex">
              <p className="mr-2">Driver&apos;s licence </p>
              {driverLicense.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('selfie')}
            className="bg-white normal-case !rounded-none w-full p-5 text-dark-primary flex justify-between text-[18px]">
            <div className="flex">
              <p className="mr-2">Selfie</p>
              {selfie.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
            </div>
            <LeftIcon />
          </Button>
        </div>
      ) : (
        <SellerVerifyDesktopQR />
      )}
    </>
  );
};

function VehicleOwnership({ setStep }: { setStep: (page: string) => void }) {
  const { ownership, setOwnership } = useSellerStore();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(ownershipSchema),
    defaultValues: ownership
  });

  const { isOwner, other, relationship } = watch();

  const onSubmit = (data: any) => {
    setOwnership({ ...data, isDone: true });
    setStep('init');
  };

  return (
    <>
      <div className="flex flex-col pb-20 ">
        <div className="flex flex-col mt-8">
          <div className="grow">
            <h2 className="text-[24px] lg:text-[30px] font-semibold text-grey-900 mt-0 lg:mt-6">
              Vehicle ownership
            </h2>
          </div>
          <p className="mt-4 text-gray-600">
            Are you the owner of the Vehicle?
          </p>
          <div className="flex flex-col -ml-3">
            <Radio
              name="type"
              checked={isOwner}
              onChange={() => setValue('isOwner', true)}
              label="Yes"
            />
            <Radio
              name="type"
              checked={!isOwner}
              onChange={() => setValue('isOwner', false)}
              label="No"
            />
          </div>
          {!isOwner && (
            <>
              <p className="mt-8 text-gray-600">
                What is your relationship with the owner?
              </p>
              <div className="flex flex-col -ml-3">
                <Radio
                  name="rela"
                  label="Partner / Spouse"
                  checked={relationship === 'partner'}
                  onChange={() => setValue('relationship', 'partner')}
                />
                <Radio
                  name="rela"
                  label="Relative"
                  checked={relationship === 'relative'}
                  onChange={() => setValue('relationship', 'relative')}
                />
                <Radio
                  name="rela"
                  label="Director"
                  checked={relationship === 'director'}
                  onChange={() => setValue('relationship', 'director')}
                />
                <Radio
                  name="rela"
                  label="Employee"
                  checked={relationship === 'employee'}
                  onChange={() => setValue('relationship', 'employee')}
                />
                <Radio
                  name="rela"
                  label="Other"
                  checked={relationship === 'other'}
                  onChange={() => setValue('relationship', 'other')}
                />
              </div>
            </>
          )}
          {relationship === 'other' && !isOwner && (
            <>
              <p className="mt-8 mb-2">Please specify other </p>
              <Input
                {...register(`other`)}
                placeholder="Your relationship with the vehicle owner"
              />
              {errors.other && (
                <span className="text-xs text-error-light">
                  {errors.other.message}
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full p-8 bg-grey-50 flex">
        <Button
          onClick={handleSubmit(onSubmit)}
          className="align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-3 px-6 rounded-lg shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none bg-rose normal-case grow lg:flex-grow-0 text-white text-[16px]">
          Submit Ownership Status
        </Button>
      </div>
    </>
  );
}
type DriverLicenseProps = {
  setStep: (page: string) => void;
  setPage: (page: SellerPage) => void;
};

const DriverLicense: React.FC<DriverLicenseProps> = ({ setStep, setPage }) => {
  return (
    <div className="flex flex-col tablet-portrait:w-[361px] tablet-portrait:mx-auto">
      <div className="flex flex-col mt-8">
        <div className="grow flex flex-col justify-center items-center">
          <h2 className="text-[24px] lg:text-[30px] font-semibold text-grey-900 mt-0 lg:mt-6">
            <FrontIcon />
          </h2>
          <p className="mt-4 text-grey-800 text-lg">Start with the front</p>
        </div>
        <div className="flex cursor-pointer mt-8 h-[140px] flex-col items-center justify-center">
          <p className="text-gray-600">
            This is the side of the card with your photo or name on it.
          </p>
        </div>
        <div>
          <h2>Tips</h2>
          <ul>
            <li className="inline-flex gap-2 text-grey-700 text-sm">
              <Light />
              Find an area with good lighting
            </li>
            <li className="inline-flex gap-2 text-grey-700 text-sm">
              <Eye />
              Make sure all details are visible
            </li>
            <li className="inline-flex gap-2 text-grey-700 text-sm">
              <Mobile />
              Hold your device steady
            </li>
          </ul>
        </div>
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full px-4 py-6 bg-grey-50 flex flex-col gap-4 tablet-portrait:w-[361px] tablet-portrait:justify-center tablet-portrait:right-auto tablet-portrait:px-0">
        <Button
          onClick={() => setPage(SellerPage.driverLicenseCapture)}
          className="align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-3 px-6 rounded-lg shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none bg-rose normal-case grow lg:flex-grow-0 text-white text-[16px]">
          Continue
        </Button>
        {/* <Button
          className="font-semibold bg-white border-grey-300 text-grey-700 w-auto capitalize"
          variant="outlined">
          Upload from your device or computer
        </Button> */}
      </div>
    </div>
  );
};

function Selfie({ setStep }: { setStep: (page: string) => void }) {
  const { setSelfie } = useSellerStore();

  return (
    <div className="flex flex-col pb-20 self-center">
      <div className="flex flex-col mt-8">
        <div className="grow">
          <h2 className="text-[24px] lg:text-[30px] font-semibold text-grey-900 mt-0 lg:mt-6">
            Selfie
          </h2>
        </div>
        <p className="mt-4 text-gray-600">
          Please take a selfie for ID verification
        </p>
        <div className="bg-white flex cursor-pointer bg-white border border-gray-200 rounded-lg mt-8 h-[140px] flex-col items-center justify-center">
          <SelfieIcon />
          <p className="text-gray-600">Capture a photo yourself</p>
        </div>
      </div>
      <div className="fixed lg:relative lg:w-full lg:justify-end bottom-0 right-0 w-full p-8 bg-grey-50 flex">
        <Button
          onClick={() => {
            setSelfie({ isDone: true });
            setStep('init');
          }}
          className="align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-3 px-6 rounded-lg shadow-md shadow-blue-500/20 hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none bg-rose normal-case grow lg:flex-grow-0 text-white text-[16px]">
          Submit Selfie
        </Button>
      </div>
    </div>
  );
}
