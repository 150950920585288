import DocumentCard from '@pages/Inspections/components/RightPanelCards/DocumentCard';
import { DocumentFormEnum } from '@types';
import { useEffect, useState } from 'react';
import { useInspectionDetailStore } from '../../../../stores/inspection';
import SecurityCheck from '../SecurityCheck';
import SecurityCheckCard from '@pages/Inspections/components/RightPanelCards/SecurityCheckCard';
import React, { memo } from 'react';

const DriverLicense = memo(() => {
  const { activePanel, rightBarStatus } = useInspectionDetailStore();
  return (
    <>
      {activePanel.activeSection === 'DRIVER_LICENCE_FRONT' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_FRONT} />
      )}
      {activePanel.activeSection === 'DRIVER_LICENCE_BACK' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_BACK} />
      )}
      {activePanel.activeSection === 'FACE' && (
        <DocumentCard type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_SELFIE} />
      )}
      {activePanel.activeSection === 'licenseAuthenticity' && (
        <SecurityCheckCard
          type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_AUTHENTICITY}
          status={rightBarStatus}
        />
      )}
      {activePanel.activeSection === 'licenseExpiry' && (
        <SecurityCheckCard
          type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_EXPIRY}
          status={rightBarStatus}
        />
      )}
      {activePanel.activeSection === 'licenseEdits' && (
        <SecurityCheckCard
          type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_DATA_EDIT}
          status={rightBarStatus}
        />
      )}
      {activePanel.activeSection === 'licenseFaceMatch' && (
        <SecurityCheckCard
          type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_FACE_MATCH}
          status={rightBarStatus}
        />
      )}
      {activePanel.activeSection === 'licenseLiveness' && (
        <SecurityCheckCard
          type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_LIVENESS}
          status={rightBarStatus}
        />
      )}
      {activePanel.activeSection === 'licenseOfficialSource' && (
        <SecurityCheckCard
          type={DocumentFormEnum.DETAILS_DRIVER_LICENSE_OFFICIAL_SOURCE}
          status={rightBarStatus}
        />
      )}
    </>
  );
});

export default DriverLicense;
