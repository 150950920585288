import { Button } from '@material-tailwind/react';
import { useInspectionDetailStore } from '@stores';
import {
  NevdisSecurityCheckEnum,
  DocumentFormType,
  InspectionSecurityStatusEnum,
  InspectionVehicleDetailsSection
} from '@types';
import Loader from '@components/widgets/Loader';
import { useEffect, useState } from 'react';
import {
  DETAILS_VIEW_REGISTRATION,
  PPSR_VIEW,
  STOLEN_VIEW,
  WRITTEN_OFF_VIEW,
  ADDRESS_VIEW,
  GRANTOR_INDIVIDUAL_VIEW,
  GRANTOR_ORG_VIEW,
  SECURED_INDIVIDUAL_PARTIES_VIEW,
  SECURED_ORG_PARTIES_VIEW,
  COMPLIANCE_VIEW,
  SELLER_SUBMITTED_VIEW,
  NEVDIS_RETRIEVE_VIEW
} from '@constants';
import { GET_INSPECTION_FILE, GET_INSPECTION_PPSR, post } from '@services';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _merge from 'lodash/merge';
import { useParams } from 'react-router-dom';
import { getAssetSpecificContent } from '@utils';
import { AssetTypesEnum } from '@types';
interface IProps {
  type: DocumentFormType;
  status: InspectionSecurityStatusEnum;
  data: any;
}

const NevdisCheckCard = ({ type, status, data }: IProps) => {
  const { isCollapsed, setCollapse, inspectionDetailData } =
    useInspectionDetailStore();
  const [seller, setSeller] = useState<any>([]);
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [imgSrcRego, setImgSrcRego] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const param = useParams();
  function handleDownloadPpsr() {
    post(GET_INSPECTION_PPSR, { inspectionId: param.id }, false).then(
      (data) => {
        const base64Pdf = data.base64Pdf;
        const linkSource = `data:application/pdf;base64,${base64Pdf}`;
        const fileName = 'ppsr-report.pdf';
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    );
  }
  useEffect(() => {
    if (type === NevdisSecurityCheckEnum.NEVDIS_SECURITY_DATA_EDITS) {
      const vinFile = inspectionDetailData.files.find(
        (e: any) => e.documentType === 'VIN'
      );
      const regoFile = inspectionDetailData.files.find(
        (e: any) => e.documentType === 'REGO_PLATE'
      );
      setIsLoading(true);
      const promises = [];

      if (vinFile) {
        setSeller(vinFile.user);
        promises.push(
          post(
            GET_INSPECTION_FILE,
            { inspectionId: param.id, fileId: vinFile.fileId },
            false
          ).then((data) => {
            if (data && data.dataBase64) {
              setImgSrc(data.dataBase64);
            }
          })
        );
      }

      if (regoFile) {
        promises.push(
          post(
            GET_INSPECTION_FILE,
            { inspectionId: param.id, fileId: regoFile.fileId },
            false
          ).then((data) => {
            if (data && data.dataBase64) {
              setImgSrcRego(data.dataBase64);
            }
          })
        );
      }

      Promise.all(promises)
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);
  const states = {
    ACT: 'Australian Capital Territory',
    NSW: 'New South Wales',
    NT: 'Northern Territory',
    QLD: 'Queensland',
    SA: 'South Australia',
    TAS: 'Tasmania',
    VIC: 'Victoria',
    WA: 'Western Australia'
  };
  const handleClose = () => {
    setCollapse(true);
  };
  if (
    [
      InspectionSecurityStatusEnum.notPerformed,
      InspectionSecurityStatusEnum.notFound
    ].includes(status)
  )
    return null;
  const vinChecksData = data &&
    data.licencePlate &&
    data.vin && {
      userRego: data.licencePlate.userRego,
      userState: data.licencePlate.userState,
      userVin: data.vin.userVin,
      nevdis: _merge(data.vin.nevdis, data.licencePlate.nevdis),
      vinNevdis: {
        ...data.vin.nevdis
      },
      regoNevdis: {
        ...data.licencePlate.nevdis
      }
    };
  if (isLoading) return <Loader />;
  switch (type) {
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_RAV:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex flex-col gap-y-6 justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Compliance Details{' '}
                  {data.rav.results && data.rav.results.length > 0 && (
                    <>(1 / 2)</>
                  )}
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    COMPLIANCE_VIEW,
                    (row: any, index: number) =>
                      _get(data.result, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {_get(data.result, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            {!isCollapsed && (
              <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Close
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_PPSR:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <div className="flex flex-col border-b border-grey-200 w-full">
                  {_map(data.ppsrData, (ppsrData: any, dIndex: number) => {
                    return (
                      <>
                        <p
                          className="font-semibold text-grey-800 py-6 border-b first:pt-0 "
                          key={'ppsrTitle' + dIndex}>
                          Registration {dIndex + 1} / {data.ppsrData.length}
                        </p>
                        {_map(PPSR_VIEW, (row: any, index: number) => {
                          if (_get(ppsrData, row.keyPath) && !row.type)
                            return (
                              <div
                                key={'ppsrData' + index}
                                className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                <p className="text-grey-500 text-sm font-medium leading-5">
                                  {row.displayName}
                                </p>
                                <p className="text-sm font-medium text-grey-950 text-right">
                                  {_get(ppsrData, row.keyPath)}
                                </p>
                              </div>
                            );
                          else if (
                            _get(ppsrData, row.keyPath) &&
                            row.type === 'array'
                          ) {
                            const dataRow = _get(ppsrData, row.keyPath);
                            return (
                              _get(ppsrData, row.keyPath).length > 0 && (
                                <>
                                  <p
                                    className="font-semibold py-3.5 border-b border-grey-200 text-grey-800"
                                    key={'ppsrData' + index}>
                                    {row.displayName}
                                  </p>
                                  {_map(dataRow, (item: any, i: number) => {
                                    if (dataRow.length === 0) return null;
                                    return _map(
                                      row.key === 'grantors' &&
                                        item.grantorType === 'Individual'
                                        ? GRANTOR_INDIVIDUAL_VIEW
                                        : row.key !== 'grantors' &&
                                          item.grantorType === 'Individual'
                                        ? SECURED_INDIVIDUAL_PARTIES_VIEW
                                        : row.key === 'grantors' &&
                                          item.grantorType === 'Organisation'
                                        ? GRANTOR_ORG_VIEW
                                        : SECURED_ORG_PARTIES_VIEW,
                                      (subRow: any, subIndex: number) => (
                                        <div
                                          key={
                                            (row.key === 'grantors'
                                              ? 'grantors_'
                                              : 'secured_parties_') +
                                            dIndex +
                                            '_' +
                                            i +
                                            '_' +
                                            subIndex
                                          }
                                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                          <p className="text-grey-500 text-sm font-medium leading-5">
                                            {subRow.displayName}
                                          </p>
                                          <p className="text-sm font-medium text-grey-950 text-right">
                                            {_get(item, subRow.keyPath)}
                                          </p>
                                        </div>
                                      )
                                    );
                                  })}
                                </>
                              )
                            );
                          } else if (
                            _get(data, row.keyPath) &&
                            row.type === 'object'
                          ) {
                            return (
                              <>
                                <p
                                  className="font-semibold border-b border-grey-200 py-3.5 text-grey-800"
                                  key={'addresseeTitle_' + dIndex}>
                                  {row.displayName}
                                </p>
                                {_map(
                                  ADDRESS_VIEW,
                                  (subRow: any, subIndex: number) => {
                                    const dataRow = _get(data, row.keyPath);
                                    return (
                                      _get(dataRow, subRow.keyPath) && (
                                        <div
                                          key={
                                            'addressee_' +
                                            dIndex +
                                            '_' +
                                            subIndex
                                          }
                                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                          <p className="text-grey-500 text-sm font-medium leading-5">
                                            {subRow.displayName}
                                          </p>
                                          <p className="text-sm font-medium text-grey-950 text-right">
                                            {_get(dataRow, subRow.keyPath)}
                                          </p>
                                        </div>
                                      )
                                    );
                                  }
                                )}
                              </>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
              {inspectionDetailData.hasPpsrCertificate && (
                <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                  <Button
                    className="w-full font-semibold bg-primary border-primary text-white text-base capitalize"
                    variant="outlined"
                    onClick={handleDownloadPpsr}>
                    Download Full PPSR Report
                  </Button>
                </div>
              )}
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-2 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_REGISTRATION:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Registration Details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    DETAILS_VIEW_REGISTRATION,
                    (row: any, index: number) =>
                      _get(data, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {row.key === 'regoStatus' &&
                            status === 'REGISTRATION_EXPIRED'
                              ? 'Registration Expired'
                              : _get(data, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_STOLEN:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                {_map(data.stolenDetails, (item, i) => (
                  <>
                    <p className="font-semibold text-grey-800" key={i}>
                      Stolen report details ({i + 1}/{data.stolenDetails.length}
                      )
                    </p>
                    <div className="flex flex-col border-y border-grey-200 w-full">
                      {_map(
                        STOLEN_VIEW,
                        (row: any, index: number) =>
                          _get(item, row.keyPath) && (
                            <div
                              key={index}
                              className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                              <p className="text-grey-500 text-sm font-medium leading-5">
                                {row.displayName}
                              </p>
                              <p className="text-sm font-medium text-grey-950 text-right">
                                {_get(item, row.keyPath)}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ))}
              </div>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_WRITTEN_OFF:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                {_map(data.writtenOffDetails, (item, i) => (
                  <>
                    <p className="font-semibold text-grey-800" key={i}>
                      Written-off Report Details ({i + 1}/
                      {data.writtenOffDetails.length})
                    </p>
                    <div className="flex flex-col border-y border-grey-200 w-full">
                      {_map(
                        WRITTEN_OFF_VIEW,
                        (row: any, index: number) =>
                          _get(item, row.keyPath) && (
                            <div
                              key={index}
                              className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                              <p className="text-grey-500 text-sm font-medium leading-5">
                                {row.displayName}
                              </p>
                              <p className="text-sm font-medium text-grey-950 text-right">
                                {_get(item, row.keyPath)}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ))}
              </div>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_VIN:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Seller submitted details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    SELLER_SUBMITTED_VIEW,
                    (row: any, index: number) =>
                      _get(vinChecksData, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {row.type && row.type === 'STATE'
                              ? _get(states, _get(vinChecksData, row.keyPath))
                              : _get(vinChecksData, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  NEVDIS registration details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    NEVDIS_RETRIEVE_VIEW,
                    (row: any, index: number) =>
                      _get(vinChecksData.nevdis, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {row.type && row.type === 'STATE'
                              ? _get(
                                  states,
                                  _get(vinChecksData.nevdis, row.keyPath)
                                )
                              : _get(vinChecksData.nevdis, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_DATA_EDITS: {
      const vinMatch = data?.vin?.ocrMatch === 'DIFFERENT';
      const regoMatch = data?.licencePlate?.ocrMatch === 'DIFFERENT';
      const warningMessage = `Seller-submitted data differs from OCR-extracted data. This may
                be due to an OCR misread or manual input where OCR retrieval was
                incomplete. Compare the extracted data with the seller&apos;s
                entry and the document image to verify accuracy.`;
      let showVin = false;
      let showRego = false;

      if (vinMatch && regoMatch) {
        showVin = true;
        showRego = true;
      } else if (vinMatch) {
        showVin = true;
      } else if (regoMatch) {
        showRego = true;
      }
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          {warningMessage && (
            <div className="bg-[#fffaeb] p-4 rounded-sm ">{warningMessage}</div>
          )}
          {showVin && (
            <div className="border-t mt-6">
              <h1 className="text-grey-800 font-semibold text-base mt-6">
                Provided by{' '}
                {seller
                  ? seller.firstName + ' ' + seller.lastName
                  : inspectionDetailData.seller.firstName +
                    ' ' +
                    inspectionDetailData.seller.lastName}
              </h1>
              <div className="flex flex-col gap-8">
                {imgSrc && <img src={`data:image/jpeg;base64,${imgSrc}`} />}
              </div>

              <div className="flex flex-col gap-4 mt-6">
                <h2 className="font-semibold text-[16px]">
                  Seller submitted details
                </h2>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      VIN
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {inspectionDetailData?.inspectionChecks?.vin?.userVin}
                    </p>
                  </div>
                </div>
                <h2 className="font-semibold text-[16px]">
                  OCR-extracted data
                </h2>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      VIN
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {inspectionDetailData?.inspectionChecks?.vin?.ocrVin}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showRego && (
            <div>
              <h1 className="text-grey-800 font-semibold text-base">
                Provided by{' '}
                {seller
                  ? seller.firstName + ' ' + seller.lastName
                  : inspectionDetailData.seller.firstName +
                    ' ' +
                    inspectionDetailData.seller.lastName}
              </h1>
              <div className="flex flex-col gap-8">
                {imgSrcRego && (
                  <img src={`data:image/jpeg;base64,${imgSrcRego}`} />
                )}
              </div>
              <div className="flex flex-col gap-4 mt-6">
                <h2 className="font-semibold text-[16px]">
                  Seller submitted details
                </h2>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Number Plate
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.licencePlate
                          ?.userRego
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      State / Territory
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.licencePlate
                          ?.userState
                      }
                    </p>
                  </div>
                </div>
                <h2 className="font-semibold text-[16px]">
                  OCR-extracted data
                </h2>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      Number Plate
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.licencePlate
                          ?.ocrRego
                      }
                    </p>
                  </div>
                  <div className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                    <p className="text-grey-500 text-sm font-medium leading-5">
                      State / Territory
                    </p>
                    <p className="text-sm font-medium text-grey-950 text-right">
                      {
                        inspectionDetailData?.inspectionChecks?.licencePlate
                          ?.ocrState
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isCollapsed && (
            <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
              <Button
                className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                variant="outlined"
                onClick={handleClose}>
                Close
              </Button>
            </div>
          )}
        </div>
      );
    }
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_PHOTO:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full overflow-auto">
          <div className="flex flex-col gap-x-2 h-full min-h-0">
            <div className="flex flex-col w-full gap-y-2">
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                Authentik8's artificial intelligence detected the following
                issues when analysing the vehicle photos.
              </p>

              {_map(data.photos, (photo, index) => {
                if (photo.status === 'WARNING') {
                  return (
                    <div className="flex flex-col w-full" key={index}>
                      <div className="mt-4">
                        <p className="font-semibold text-grey-800 text-base">
                          {photo.documentType === 'ASSET_FRONT'
                            ? getAssetSpecificContent(
                                inspectionDetailData.assetType as AssetTypesEnum
                              ).sides.front
                            : photo.documentType === 'ASSET_BACK'
                            ? getAssetSpecificContent(
                                inspectionDetailData.assetType as AssetTypesEnum
                              ).sides.back
                            : photo.documentType === 'ASSET_LEFT'
                            ? getAssetSpecificContent(
                                inspectionDetailData.assetType as AssetTypesEnum
                              ).sides.passenger
                            : photo.documentType === 'ASSET_RIGHT'
                            ? getAssetSpecificContent(
                                inspectionDetailData.assetType as AssetTypesEnum
                              ).sides.driver
                            : InspectionVehicleDetailsSection[
                                photo.documentType as keyof typeof InspectionVehicleDetailsSection
                              ]}
                        </p>
                        {photo.authenticityReason && (
                          <p className="text-sm text-grey-700 mt-2 mb-2">
                            {photo.authenticityReason}
                          </p>
                        )}
                        {photo.qualityReason && (
                          <p className="text-sm text-grey-700 mt-2">
                            {photo.qualityReason}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              {!isCollapsed && (
                <div className="w-full bg-white flex flex-col md:flex-row gap-4 mt-6 mb-[60px]">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Close
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return <> </>;
  }
};
export default NevdisCheckCard;
