import { AwsRum, AwsRumConfig, PageAttributes } from 'aws-rum-web';

// Helper function to extract page attributes from the URL path
function getPageAttributes(path: string): PageAttributes {
  const attributes: Record<string, string> = {};
  let pageId = path;

  // Extract inspection ID from path
  const inspectionMatch = path.match(/\/inspections\/detail\/([^/]+)/);
  if (inspectionMatch) {
    pageId = '/inspections/detail/id';
    attributes.inspectionId = inspectionMatch[1];
  }

  // Extract verification ID from path
  const verificationMatch = path.match(/\/verifications\/detail\/([^/]+)/);
  if (verificationMatch) {
    pageId = '/verifications/detail/id';
    attributes.verificationId = verificationMatch[1];
  }

  // Extract seller detail ID from path
  const sellerDetailMatch = path.match(/\/seller\/detail\/([^/]+)/);
  if (sellerDetailMatch) {
    pageId = '/seller/detail/id';
    attributes.sellerId = sellerDetailMatch[1];
  }

  // Extract inspection invoice ID from path
  const invoiceMatch = path.match(/\/inspections\/generateInvoice\/([^/]+)/);
  if (invoiceMatch) {
    pageId = '/inspections/generateInvoice/id';
    attributes.invoiceId = invoiceMatch[1];
  }

  const result: PageAttributes = {
    pageId: pageId,
    pageAttributes: attributes
  };

  return result;
}

function initializeRUM(): AwsRum | null {
  const hostname = window.location.hostname;

  // the per-environment RUM application ID, or null if disabled
  let rumApplicationId: string | null = null;

  if (hostname.endsWith('authentik8.sandbox.aws.valeur.io') || hostname === 'localhost') {
    rumApplicationId = '66456259-c7f3-431f-a79c-851339cc20b4';
  } else if (hostname.indexOf('authentik8.ak8demo.aws.valeur.io') !== -1) {
    rumApplicationId = '27b13298-707e-4548-a7e5-eedcd466572f';
  }

  // disabled in Production for now, while we continue testing
  if (!rumApplicationId) return null;

  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
      alias: 'authentik8',
      disableAutoPageView: true,
      signing: false
    };
    
    // filter out development resources which would quickly fill the RUM session
    if (hostname === 'localhost') {
      config.telemetries = ['errors', 'http'];
    }

    const awsRum = new AwsRum(
      rumApplicationId,
      '1.0.0',
      'ap-southeast-2',
      config
    );

    return awsRum;
  } catch (error) {
    console.error('CloudWatch RUM initialization failed:', error);
    return null;
  }
};

// Initialize RUM monitor once and store it
let rumInstance: AwsRum | null = null;

// Initialize the monitor on first use
function getRumMonitor() {
  if (rumInstance === null) {
    rumInstance = initializeRUM();
  }
  return rumInstance;
}

// Export a type-safe page view recorder
export function recordPageView(path: string) {
  const rumMonitor = getRumMonitor();
  if (!rumMonitor) return;

  const data = getPageAttributes(path);
  rumMonitor.recordPageView(data);
}

// Export a type-safe error recorder
export function recordError(errorContext?: Record<string, any>) {
  const rumMonitor = getRumMonitor();
  if (!rumMonitor) return;

  rumMonitor.recordError({
    errorContext
  });
}
