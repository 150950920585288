import { useState, useEffect } from 'react';
import OnboardingLayout from '@components/layouts/OnboardingLayout';
import {
  CreateInspectionStepEnum,
  NewInspectionModalEnum,
  confirmModalType
} from '@types';
import SellerDetailStep from '@pages/Inspections/create/SellerDetailStep';
import LenderStep from '@pages/Inspections/create/LenderStep';
import DocumentStep from '@pages/Inspections/create/DocumentStep';
import ReviewStep from '@pages/Inspections/create/ReviewStep';
import { ReactComponent as SuccessIcon } from '@assets/icons/Success.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/plus_icon.svg';
import { Button } from '@material-tailwind/react';
import ConfirmInspectionModal from '@components/widgets/ConfirmInspectionModal';
import { useNavigate } from 'react-router-dom';
import { useInspectionDetailStore } from '@stores';
import { CREATE_INSPECTION, post } from '@services';
const InspectionCreate = () => {
  const [step, setStep] = useState<CreateInspectionStepEnum>(
    CreateInspectionStepEnum.Lender
  );
  const [modalType, setModalType] = useState<confirmModalType>(
    NewInspectionModalEnum.close
  );
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedLender, setSelectedLender] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [backToDashboard, setBackToDashboard] = useState(false);
  const { reset, setDraft, hasDraft, inspectionDetailData } =
    useInspectionDetailStore();
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsOpenModal(false);
    setIsInit(false);
    if (modalType === NewInspectionModalEnum.close) {
      // setDraft(true);
      if (backToDashboard) {
        navigate('/');
      } else {
        navigate('/inspections');
      }
    } else if (modalType === NewInspectionModalEnum.submit) {
      const userId = localStorage.getItem('userId');
      if (userId) {
        const lender = localStorage.getItem(`lenders_${userId}` as any);
        if (lender || selectedLender) {
          const organisationId = selectedLender
            ? selectedLender
            : lender
            ? JSON.parse(lender)[0].organisationId
            : null;
          setLoading(true);
          const { buyer, ...restInspectionData } = inspectionDetailData;
          const postData: any = {
            lenderOrganisationId: organisationId,
            templateId: inspectionDetailData?.lender?.template?.templateId,
            lenderUserId: userId,
            lenderReference: restInspectionData.lenderReference,
            saleState: restInspectionData.saleState,
            address: restInspectionData.address,
            documentTypes: restInspectionData?.documentTypes,
            documents: restInspectionData?.documents,
            seller: restInspectionData.seller
          };
          if (postData.templateId) {
            delete postData.assetType;
          }
          if (inspectionDetailData?.lender?.template?.buyerRequired) {
            postData.buyer = buyer;
          }
          post(CREATE_INSPECTION, postData, false)
            .then((data: any) => {
              setLoading(false);
              setDraft(false);
              reset();
              setSuccessSubmit(true);
            })
            .catch((e) => {
              setLoading(false);
              alert(e.response.data.error.message);
            });
        }
      }
    }
  };
  const onCloseClick = () => {
    setIsOpenModal(true);
    setModalType(NewInspectionModalEnum.close);
  };
  const onCloseModalClick = () => {
    setIsOpenModal(false);
    setBackToDashboard(false);
    if (modalType === NewInspectionModalEnum.draft) {
      reset();
      setDraft(false);
    }
  };
  const onDashboardClick = () => {
    setBackToDashboard(true);
    setIsOpenModal(true);
    setModalType(NewInspectionModalEnum.close);
  };
  useEffect(() => {
    setStep(CreateInspectionStepEnum.Lender);
    if (isInit || successSubmit) {
      reset();
    }
    setSuccessSubmit(false);
  }, []);
  return (
    <OnboardingLayout
      step={step}
      onChangeStep={(value) => setStep(value as CreateInspectionStepEnum)}
      successSubmit={successSubmit}
      backToDashboard={() => onDashboardClick()}
      onClose={() => onCloseClick()}>
      {!successSubmit && (
        <div className="max-w-[600px] w-full">
          {step === CreateInspectionStepEnum.Seller && (
            <SellerDetailStep
              onPrevious={() => setStep(CreateInspectionStepEnum.Documents)}
              onNext={() => {
                setStep(CreateInspectionStepEnum.Review);
                setIsInit(false);
              }}
              onClose={onCloseClick}
            />
          )}

          {step === CreateInspectionStepEnum.Lender && (
            <LenderStep
              onNext={(lender: any) => {
                setSelectedLender(lender);
                setStep(CreateInspectionStepEnum.Documents);
                setIsInit(false);
              }}
              onClose={onCloseClick}
            />
          )}
          {step === CreateInspectionStepEnum.Documents && (
            <DocumentStep
              onPrevious={() => setStep(CreateInspectionStepEnum.Lender)}
              onNext={() => {
                setStep(CreateInspectionStepEnum.Seller);
                setIsInit(false);
              }}
              selectedLender={selectedLender}
              onClose={onCloseClick}
            />
          )}
          {step === CreateInspectionStepEnum.Review && (
            <ReviewStep
              onPrevious={() => setStep(CreateInspectionStepEnum.Seller)}
              onNext={() => {
                setIsOpenModal(true);
                setModalType(NewInspectionModalEnum.submit);
                setIsInit(false);
              }}
              onClose={onCloseClick}
            />
          )}
        </div>
      )}
      {successSubmit && <SuccessPage />}
      <ConfirmInspectionModal
        open={isOpenModal}
        type={modalType}
        onSubmit={() => handleSubmit()}
        onClose={() => onCloseModalClick()}
      />
    </OnboardingLayout>
  );
};

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8 py-15">
      <div className="flex flex-col gap-5 justify-center items-center">
        <SuccessIcon />
      </div>
      <div className="gap-5 flex flex-grow flex-col w-full h-full justify-center items-center max-w-[551px]">
        <h2 className="text-center font-semibold">Inspection Initiated!</h2>
        <p className="gap-2 flex flex-col text-center text-sm text-grey-600">
          Congratulations! You have successfully initiated a new inspection.
        </p>
        <p className="gap-2 flex flex-col text-center text-sm text-grey-600">
          The seller will shortly receive an SMS with the necessary instructions
          to complete the inspection.
        </p>
        <p className="gap-2 flex flex-col text-center text-sm text-grey-600">
          You can monitor the progress of this inspection on your Inspections
          Dashboard.
        </p>
      </div>
      <div className="flex flex-grow-0 w-full flex-col  md:flex-row items-center justify-center gap-4">
        <Button
          className="bg-white text-grey-700 border-grey-300 !text-sm font-semibold capitalize w-full md:w-auto"
          variant="outlined"
          onClick={() => navigate('/inspections')}>
          Inspection Dashboard
        </Button>
        <Button
          className="flex items-center gap-2 text-sm py-2.5 px-4 bg-primary font-semibold !text-center justify-center text-white capitalize w-full md:w-auto"
          onClick={() => {
            navigate('/inspections/create');
            window.location.reload();
          }}>
          <PlusIcon />
          Create inspection
        </Button>
      </div>
    </div>
  );
};

export default InspectionCreate;
